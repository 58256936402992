import React from "react";
import { maxLength, minLength } from "./utils";
const PasswordValicationDropdownConfirmPassword = ({ formik }) => {
  const {
    values: { confirmpassword, password, newpassword },
  } = formik || {};
  return (
    <>
      {minLength(confirmpassword, 8) ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            Password should be at least 8 characters
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            Password should be at least 8 characters
          </div>
        </div>
      )}

      {maxLength(confirmpassword, 64) && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            Password should not be more than 64 characters
          </div>
        </div>
      )}

      {confirmpassword.search(/[A-Z]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 uppercase letter (A - Z)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 uppercase letter (A - Z)
          </div>
        </div>
      )}

      {confirmpassword.search(/[a-z]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 lowercase letter (a - z)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 lowercase letter (a - z)
          </div>
        </div>
      )}

      {confirmpassword.search(/[0-9]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 number (0-9)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 number (0-9)
          </div>
        </div>
      )}

      {confirmpassword.search(/[!#$%&?@^*()_`~"]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 symbol (non-alphabetical character)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 symbol (non-alphabetical character)
          </div>
        </div>
      )}

      {confirmpassword !== (newpassword || password) && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            Confirm Password should be same as Password
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordValicationDropdownConfirmPassword;
