import {
  deleteCall,
  getCall,
  getPDFCall,
  getPDFPrintCall,
  patchCall,
  postCall,
  postCallWithMedia,
  putCall
} from "../api";
import {
  checkUserExistOrNotUrl,
  contactListUrl,
  counterUrl,
  historyListUrl,
  loginUrl,
  recentHistoryUrl,
  removeContactUrl,
  signUpUrl,
  uploadProfilePicUrl,
  verifyOTPUrl,
  singleUserDetailUrl,
  editUserDetailUrl,
  forgotPasswordUrl,
  blockContactUrl,
  resetPasswordUrl,
  changePasswordUrl,
  myChatListingUrl,
  oneToOneChatListingUrl,
  sendChatUrl,
  resendEmailOTPUrl,
  resendOTPUrl,
  resendCellNumberOTPUrl,
  editProfilePicUrl,
  recentHistoryPDFUrl,
  getPaymentTransactionListUrl,
  notificationListUrl,
  sendReminderUrl,
  addAsContactUrl,
  transactionDetailsByIdUrl,
  logoutUrl,
  notificationDetailsByIdUrl,
  checkUserIsAddedAsContactUrl,
  searchUserUrl,
  makeAPaymentUrl,
  requestAPaymentUrl,
  getBankDetailsUrl,
  acceptFundUrl,
  addNewBankUrl,
  twoFactorAuthUrl,
  twoFactorVerificationUrl,
  transferToBankUrl,
  unreadChatCounterUrl,
  countryListUrl,
  stateListUrl,
  cityListUrl,
  bankListUrl,
  getDocsUrl,
  resendtwofactorOtpUrl,
  confirmBankUrl,
  uploadFrontChequeUrl,
  loginTwoFactorVerificationUrl,
  firebaseDeviceTokenUrl,
  validateSCTransactionIdUrl,
  addressSCTransactionUrl,
  addCardUrl,
  getFundingAccountsUrl,
  notificationCounterUrl,
  markAsReadNotificationUrl,
  uploadAutoAcceptFundUrl,
  cancelTransactionUrl,
  supportUrl,
  primaryBankConfirmUrl,
  getCommissionBasedOnAmountUrl,
  generateLinkTokenUrl,
  publicTokenUrl,
  editBankUrl,
  deleteBankUrl,
  editCardUrl,
  deleteCardUrl,
  getUpdateAbaDisclosureUrl,
  updateChatCounteUrl,
  pendingNotificationListUrl,
  recentHistoryPrintPDFUrl,
  editProfile2FAUrl,
  editProfile2FAVerifyUrl,
  uploadPayFeeForCustomerUrl
} from "./apiList";
import {
  checkUserExistOrNotObj,
  loginObj,
  signUpObj,
  uploadProfilePicObj,
  verifyOTPObj,
  editUserDetailObj,
  blockContactObj,
  forgotPasswordObj,
  resetPasswordObj,
  changePasswordObj,
  sendChatObj,
  resendEmailOTPObj,
  resendOTPObj,
  resendCellNumberOTPObj,
  editProfilePicObj,
  sendReminderObj,
  addAsContactObj,
  checkUserIsAddedAsContactObj,
  makeAPaymentObj,
  resendtwofactorOtpObj,
  requestAPaymentObj,
  acceptFundObj,
  addNewBankObj,
  twoFactorAuthObj,
  twoFactorVerificationObj,
  transferToBankObj,
  confirmBankObj,
  uploadFrontChequeObj,
  loginTwoFactorVerificationObj,
  firebaseDeviceTokenObj,
  addCardObj,
  uploadAutoAcceptFundObj,
  supportObj,
  primaryBankConfirmObj,
  getCommissionBasedOnAmountObj,
  generateLinkTokenObj,
  publicTokenObj,
  editBankObj,
  editCardObj,
  editProfile2FAObj,
  editProfile2FAVerifyObj,
  uploadPayFeeForCustomerObj
} from "./apiObjects";

export function checkUserExistOrNotApi({
  cellnumber,
  email,
  username,
  social_signin
}) {
  return postCall(
    checkUserExistOrNotUrl(),
    checkUserExistOrNotObj({ cellnumber, email, username, social_signin })
  );
}

export function signUpApi({
  firstname,
  lastname,
  email,
  key,
  cellnumber,
  password,
  username,
  business_name,
  address_1,
  address_2,
  countryId,
  stateId,
  cityId,
  zip_code,
  role_id,
  profile,
  social_signin,
  bankId,
  bank_routing_number,
  bank_account_number,
  account_type,
  is_aba_disclosure_signed
}) {
  return postCall(
    signUpUrl(),
    signUpObj({
      firstname,
      lastname,
      email,
      key,
      cellnumber,
      password,
      username,
      business_name,
      address_1,
      address_2,
      countryId,
      stateId,
      cityId,
      zip_code,
      role_id,
      profile,
      social_signin,
      bankId,
      bank_routing_number,
      bank_account_number,
      account_type,
      is_aba_disclosure_signed
    })
  );
}

export function signUpPhase1Api({
  firstname,
  lastname,
  email,
  cellnumber,
  business_name,
  address_1,
  address_2,
  countryId,
  stateId,
  cityId,
  zip_code,
  role_id,
  activeStep
}) {
  return postCall(
    signUpUrl(),
    signUpObj({
      firstname,
      lastname,
      email,
      cellnumber,
      business_name,
      address_1,
      address_2,
      countryId,
      stateId,
      cityId,
      zip_code,
      role_id,
      activeStep
    })
  );
}

export function signUpPhase2Api({
  id,
  username,
  password,
  profile,
  activeStep
}) {
  return postCall(
    signUpUrl(),
    signUpObj({
      id,
      username,
      password,
      profile,
      activeStep
    })
  );
}

export function signUpPhase3Api({
  id,
  key,
  bankId,
  bank_nickname,
  is_aba_disclosure_signed,
  bank_routing_number,
  bank_account_number,
  account_type,
  activeStep
}) {
  return postCall(
    signUpUrl(),
    signUpObj({
      id,
      key,
      bankId,
      bank_nickname,
      is_aba_disclosure_signed,
      bank_routing_number,
      bank_account_number,
      account_type,
      activeStep
    })
  );
}

export function verifyOTPApi({ userId, otp_email, otp_mobile }) {
  return postCall(
    verifyOTPUrl(),
    verifyOTPObj({ userId, otp_email, otp_mobile })
  );
}

export function loginApi({ email, password, isWeb, social_signin }) {
  return postCall(
    loginUrl(),
    loginObj({ email, password, isWeb, social_signin })
  );
}

export function resendtwofactorOtpApi({ userId, cellnumber, email }) {
  return postCall(
    resendtwofactorOtpUrl(),
    resendtwofactorOtpObj({ email, userId, cellnumber })
  );
}
export function twoFactorAuthApi({ cellnumber, email, userId }) {
  return postCall(
    twoFactorAuthUrl(),
    twoFactorAuthObj({ cellnumber, email, userId })
  );
}

export function uploadProfilePicApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadProfilePicUrl(),
    uploadProfilePicObj({ file }),
    containerkey
  );
}

export function counterApi({ userId, counterFilter }) {
  if (!userId) {
    return;
  }
  return getCall(counterUrl({ userId, counterFilter }));
}

export function recentHistoryApi() {
  return getCall(recentHistoryUrl());
}

export function contactListApi({ searchTextContact, sortIndexContact, skip }) {
  return getCall(contactListUrl({ searchTextContact, sortIndexContact, skip }));
}

export function updateAbaDisclosureApi({ email }) {
  return getCall(getUpdateAbaDisclosureUrl({ email }));
}

export function removeContactApi({ id }) {
  return deleteCall(removeContactUrl() + id);
}

export function historyListApi({ currentPage, filterValues }) {
  return getCall(historyListUrl({ currentPage, filterValues }));
}

export function singleUserDetailApi({ id }) {
  return getCall(singleUserDetailUrl({ id }));
}

export function forgotPasswordApi({ cellnumberoremail }) {
  return postCall(
    forgotPasswordUrl(),
    forgotPasswordObj({ cellnumberoremail })
  );
}

export function editUserDetailApi({
  id,
  email,
  cellnumber,
  account_number,
  firstname,
  lastname,
  username,
  address_1,
  address_2,
  countryId,
  stateId,
  cityId,
  zip_code
}) {
  return patchCall(
    editUserDetailUrl({ id }),
    editUserDetailObj({
      id,
      email,
      cellnumber,
      firstname,
      lastname,
      username,
      account_number,
      address_1,
      address_2,
      countryId,
      stateId,
      cityId,
      zip_code
    })
  );
}

export function blockContactApi({
  id,
  added_contact_id,
  is_block_contact,
  is_report_contact,
  block_reason
}) {
  return postCall(
    blockContactUrl(),
    blockContactObj({
      id,
      added_contact_id,
      is_block_contact,
      is_report_contact,
      block_reason
    })
  );
}

export function resetPasswordApi({ id, password, forgotpassword_otp }) {
  return postCall(
    resetPasswordUrl(),
    resetPasswordObj({ id, password, forgotpassword_otp })
  );
}

export function changePasswordApi({ id, currentPassword, password }) {
  return patchCall(
    changePasswordUrl(),
    changePasswordObj({
      currentPassword,
      password
    })
  );
}

export function myChatListingApi({ skip, searchTextChat }) {
  return getCall(myChatListingUrl({ skip, searchTextChat }));
}

export function unreadChatCounterApi(getChatId) {
  return patchCall(unreadChatCounterUrl(getChatId));
}

export function updateChatCounteApi(chat_id) {
  return patchCall(updateChatCounteUrl({ chat_id }));
}

export function oneToOneChatListingApi({ skip, id }) {
  return getCall(oneToOneChatListingUrl({ skip, id }));
}

export function sendChatApi({ userId, receiver_id, text, created_by }) {
  return postCall(
    sendChatUrl(),
    sendChatObj({ userId, receiver_id, text, created_by })
  );
}

export function sendOTPApi({ userId, email, cellnumber }) {
  return postCall(resendOTPUrl(), resendOTPObj({ userId, email, cellnumber }));
}

export function resendEmailOTPApi({ userId, email }) {
  return postCall(resendEmailOTPUrl(), resendEmailOTPObj({ userId, email }));
}

export function resendCellNumberOTPApi({ userId, cellnumber }) {
  return postCall(
    resendCellNumberOTPUrl(),
    resendCellNumberOTPObj({ userId, cellnumber })
  );
}

export function editProfilePicApi({ id, profile, cellnumber }) {
  return patchCall(
    editProfilePicUrl({ id }),
    editProfilePicObj({
      id,
      profile,
      cellnumber
    })
  );
}

export function uploadAutoAcceptFundApi({ id, is_autoaccept }) {
  return patchCall(
    uploadAutoAcceptFundUrl({ id }),
    uploadAutoAcceptFundObj({
      is_autoaccept
    })
  );
}

export function uploadPayFeeForCustomerApi({ id, pay_fee_for_sender }) {
  return patchCall(
    uploadPayFeeForCustomerUrl({ id }),
    uploadPayFeeForCustomerObj({
      pay_fee_for_sender
    })
  );
}

export function recentHistoryPDFApi({ id, customPdfName }) {
  return getPDFCall(recentHistoryPDFUrl({ id }), customPdfName);
}

export function recentHistoryPrintApi({ id }) {
  return getCall(recentHistoryPrintPDFUrl({ id }));
}

export function getPaymentTransactionListApi({ queryParams }) {
  return getCall(getPaymentTransactionListUrl({ queryParams }));
}

export function earilierNotificationListApi({ skip }) {
  return getCall(notificationListUrl({ skip }));
}

export function pendingNotificationListApi({ skip }) {
  return getCall(pendingNotificationListUrl({ skip }));
}

export function sendReminderApi({ id }) {
  return postCall(sendReminderUrl(), sendReminderObj({ id }));
}

export function addAsContactApi({ userId, added_contact_id }) {
  return postCall(
    addAsContactUrl(),
    addAsContactObj({ userId, added_contact_id })
  );
}

export function transactionDetailsByIdApi({ id }) {
  return getCall(transactionDetailsByIdUrl({ id }));
}

export function logoutApi({ id }) {
  return deleteCall(logoutUrl({ id }));
}

export function deleteBankApi({ bankId }) {
  return deleteCall(deleteBankUrl({ bankId }));
}

export function deleteCardApi({ bankId }) {
  return deleteCall(deleteCardUrl({ bankId }));
}

export function notificationDetailsByIdApi({ id }) {
  return getCall(notificationDetailsByIdUrl({ id }));
}

export function checkUserIsAddedAsContactApi({ added_contact_id }) {
  return postCall(
    checkUserIsAddedAsContactUrl(),
    checkUserIsAddedAsContactObj({ added_contact_id })
  );
}

export function searchUserApi({ cellnumber }) {
  return getCall(searchUserUrl({ cellnumber }));
}

export function makeAPaymentApi({
  amount,
  cellnumber,
  senderId,
  receiverId,
  transactionTypeId,
  transactionStatusId,
  transactionModeId,
  transaction_number,
  sc_transactionId,
  address_1,
  address_2,
  city,
  state_province,
  country_region,
  zip_code,
  sc_type,
  fundingAccountId,
  front_cheque,
  back_cheque,
  other,
  created_by
}) {
  return postCall(
    makeAPaymentUrl(),
    makeAPaymentObj({
      amount,
      cellnumber,
      senderId,
      receiverId,
      transactionTypeId,
      transactionStatusId,
      transactionModeId,
      transaction_number,
      sc_transactionId,
      address_1,
      address_2,
      city,
      state_province,
      country_region,
      zip_code,
      sc_type,
      fundingAccountId,
      front_cheque,
      back_cheque,
      other,
      created_by
    })
  );
}

export function requestAPaymentApi({
  amount,
  cellnumber,
  senderId,
  receiverId,
  transactionTypeId,
  transactionStatusId,
  transactionModeId,
  transaction_number,
  sc_transactionId,
  address_1,
  address_2,
  city_name,
  state_name,
  country_name,
  zip_code,
  sc_type,
  other,
  created_by
}) {
  return postCall(
    requestAPaymentUrl(),
    requestAPaymentObj({
      amount,
      cellnumber,
      senderId,
      receiverId,
      transactionTypeId,
      transactionStatusId,
      transactionModeId,
      transaction_number,
      sc_transactionId,
      address_1,
      address_2,
      city_name,
      state_name,
      country_name,
      zip_code,
      sc_type,
      other,
      created_by
    })
  );
}

export function getBankDetailsApi({ id }) {
  return getCall(getBankDetailsUrl({ id }));
}

export function acceptFundApi({
  isaccept,
  transactionId,
  notificationreceiverId,
  accept_reject_note,
  fundingAccountId
}) {
  return postCall(
    acceptFundUrl(),
    acceptFundObj({
      isaccept,
      transactionId,
      notificationreceiverId,
      accept_reject_note,
      fundingAccountId
    })
  );
}

export function addNewBankApi({
  userId,
  bankId,
  bank_nickname,
  account_type,
  bank_routing_number,
  bank_account_number
}) {
  return postCall(
    addNewBankUrl(),
    addNewBankObj({
      userId,
      bankId,
      bank_nickname,
      account_type,
      bank_routing_number,
      bank_account_number
    })
  );
}

export function editBankApi({ id, userId, bank_nickname }) {
  return putCall(
    editBankUrl({ id }),
    editBankObj({
      userId,
      bank_nickname
    })
  );
}

export function twoFactorVerificationApi({ twofactorauth_otp }) {
  return postCall(
    twoFactorVerificationUrl(),
    twoFactorVerificationObj({ twofactorauth_otp })
  );
}

export function transferToBankApi({ amount, bankId }) {
  return postCall(transferToBankUrl(), transferToBankObj({ amount, bankId }));
}

export function countryListApi() {
  return getCall(countryListUrl());
}

export function stateListApi({ countryId }) {
  return getCall(stateListUrl({ countryId }));
}

export function cityListApi({ stateId, isActiveOnly }) {
  return getCall(cityListUrl({ stateId, isActiveOnly }));
}

export function bankListApi() {
  return getCall(bankListUrl());
}

export function getDocsApi() {
  return getCall(getDocsUrl());
}

export function confirmBankApi({ id, amount_one, amount_two }) {
  return patchCall(
    confirmBankUrl(),
    confirmBankObj({ id, amount_one, amount_two })
  );
}

export function uploadFrontChequeApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadFrontChequeUrl(),
    uploadFrontChequeObj({ file }),
    containerkey
  );
}

export function uploadBackChequeApi({ file, containerkey }) {
  return postCallWithMedia(
    uploadFrontChequeUrl(),
    uploadFrontChequeObj({ file }),
    containerkey
  );
}

export function loginTwoFactorVerificationApi({
  twofactorauth_otp,
  userId,
  remember_me
}) {
  return postCall(
    loginTwoFactorVerificationUrl(),
    loginTwoFactorVerificationObj({ twofactorauth_otp, userId, remember_me })
  );
}

export function firebaseDeviceTokenApi({ devicetoken }) {
  return patchCall(
    firebaseDeviceTokenUrl(),
    firebaseDeviceTokenObj({ devicetoken })
  );
}

export function validateSCTransactionIdApi({ txId }) {
  return getCall(validateSCTransactionIdUrl({ txId }));
}

export function addressSCTransactionApi({ txId }) {
  return getCall(addressSCTransactionUrl({ txId }));
}

export function addCardApi({
  cardnumber,
  // cvv_number,
  nameonthecard,
  expirydate,
  cardstatus,
  account_type,
  cardtype
}) {
  return postCall(
    addCardUrl(),
    addCardObj({
      cardnumber,
      // cvv_number,
      nameonthecard,
      expirydate,
      cardstatus,
      account_type,
      cardtype
    })
  );
}

export function editCardApi({ id, cardnickname }) {
  return patchCall(
    editCardUrl({ id }),
    editCardObj({
      cardnickname
    })
  );
}

export function getFundingAccountsApi() {
  return getCall(getFundingAccountsUrl());
}

export function notificationCounterApi() {
  return getCall(notificationCounterUrl());
}

export function markAsReadNotificationApi() {
  return getCall(markAsReadNotificationUrl());
}

export function cancelTransactionApi({ id }) {
  return getCall(cancelTransactionUrl({ id }));
}

export function supportApi({
  firstname,
  lastname,
  email,
  mobilenumber,
  supporttype,
  prefferedmethod,
  description
}) {
  return postCall(
    supportUrl(),
    supportObj({
      firstname,
      lastname,
      email,
      mobilenumber,
      supporttype,
      prefferedmethod,
      description
    })
  );
}

export function primaryBankConfirmApi({ bankId }) {
  return patchCall(
    primaryBankConfirmUrl(),
    primaryBankConfirmObj({
      bankId
    })
  );
}

export function getCommissionBasedOnAmountApi({
  amount,
  transactionModeId,
  userType,
  is_sc
}) {
  return postCall(
    getCommissionBasedOnAmountUrl(),
    getCommissionBasedOnAmountObj({
      amount,
      transactionModeId,
      userType,
      is_sc
    })
  );
}

export function generateLinkTokenApi({ userId }) {
  return postCall(
    generateLinkTokenUrl(),
    generateLinkTokenObj({
      userId
    })
  );
}

export function publicTokenApi({ userId, publicToken }) {
  return postCall(
    publicTokenUrl(),
    publicTokenObj({
      userId,
      publicToken
    })
  );
}

export function editProfile2FAApi({ email, cellnumber }) {
  return postCall(
    editProfile2FAUrl(),
    editProfile2FAObj({
      email,
      cellnumber
    })
  );
}

export function editProfile2FAVerifyApi({ otp_cellnumber, otp_email }) {
  return postCall(
    editProfile2FAVerifyUrl(),
    editProfile2FAVerifyObj({
      otp_cellnumber,
      otp_email
    })
  );
}
