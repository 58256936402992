import React from "react";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../_metronic/_partials/controls";

export function HistoryCancelTransactionDialog({
  isLoadingForCancelTransaction,
  isCancelTransactionShow,
  onHideCancelTransaction,
  onClickOfCancelTransaction,
}) {
  return (
    <Modal
      show={isCancelTransactionShow}
      onHide={onHideCancelTransaction}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoadingForCancelTransaction && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Cancel Transaction
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to cancel this transaction?</span>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onClickOfCancelTransaction}
            className="btn btn-primary btn-elevate"
            disabled={isLoadingForCancelTransaction}
            
          >
            Yes
          </button>
          <> </>
          <button
            type="button"
            onClick={onHideCancelTransaction}
            className="btn btn-light btn-elevate"
            disabled={isLoadingForCancelTransaction}
          >
            No
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
