/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { getStatusClass } from "../../../../utils/utils";
export const StatusTypeFormatter = (cellContent, row, rowIndex) => {
  const { transaction_status } = row;
  const { name = "" } = transaction_status || {};

  const renderStatus = () => {
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClass(name)} status-color text-capitalize`}
        >
          {name ? name : "-"}
        </span>
      </span>
    );
  };

  return renderStatus();
};
