import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import * as auth from "../Auth/_redux/authRedux";
import ABApdf from "../../../pdf/ABA-5-2-22.pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

function ABADisclosure(props) {
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div className="Example">
        <div className="Example__container">
          <div className="Example__container__document">
            <Document
              file={ABApdf}
              onLoadSuccess={onDocumentLoadSuccess}
              options={options}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page key={`page_${index + 1}`} pageNumber={index + 1} />
              ))}
            </Document>
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ABADisclosure));
