import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import { Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"; //, checkIsActive
import { useFormik } from "formik";
import * as Yup from "yup";
import RadioButtonModal from "./RadioButtonModal";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  // bankListApi,
  checkUserExistOrNotApi,
  cityListApi,
  countryListApi,
  generateLinkTokenApi,
  publicTokenApi,
  resendCellNumberOTPApi,
  resendEmailOTPApi,
  sendOTPApi,
  signUpPhase1Api,
  signUpPhase2Api,
  signUpPhase3Api,
  stateListApi,
  uploadProfilePicApi,
  verifyOTPApi,
} from "../../../axios/services/services";
import ErrorModal from "../Modal/ErrorModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import TermsModal from "./TermsModal";
import SuccessModal from "../Modal/SuccessModal";
import { normalizeInput } from "../../utils/utils";
import { usePlaidLink } from "react-plaid-link";
import ABADisclosureModal from "../Modal/ABADisclosureModal";
import {
  ChevronDownMaster,
  DropdownMaster,
  DropdownIndicatorMaster,
} from "../../utils/helper";
import ButtonAtl from "@atlaskit/button";
import CreatableSelect from "react-select/creatable";
import PasswordValicationDropdownConfirmPassword from "../../utils/PasswordValicationDropdownConfirmPassword";
import PasswordValidationDropdownPassword from "../../utils/PasswordValidationDropdownPassword";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  cellnumber: "",
  businessname: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "",
  zipcode: "",
  username: "",
  password: "",
  confirmpassword: "",
  bankaccounttype: "Savings",
  bankroutenumber: "",
  bankaccountnumber: "",
  banknickname: "",
  acceptTerms: false,
  is_aba_disclosure_signed: false,
  emailOTPOne: "",
  emailOTPTwo: "",
  emailOTPThree: "",
  emailOTPFour: "",
  phoneOTPOne: "",
  phoneOTPTwo: "",
  phoneOTPThree: "",
  phoneOTPFour: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "800px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  connectorLine: {
    display: "none",
  },
  active: {
    backgroundColor: "#ff0000",
  },
}));

function SignupProfile(props) {
  const { intl } = props;
  const history = useHistory();
  const location = useLocation();
  const [pic, setPicForPreview] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [option, setOptions] = useState();
  const [isConfirmModal, setConfirmModal] = useState(false);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [user, setUser] = useState(null);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isOpenRadiobuttonModal, setOpenRadiobuttonModal] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isTermsModalOpen, setTermsModalOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  // const [bankList, setBankList] = useState([]);
  const [isUserReadTerms, setUserReadTerms] = useState(false);
  const [plaidLinkToken, setPlaidLinkToken] = useState("");
  const [publicToken, setPublicToken] = useState("");
  const [initiatePlaidLinkToken, setInitiatePlaidLinkToken] = useState(0);
  const [currentStep, setCurrentStep] = useState(
    location.hash.substring(1).length > 0 ? location.hash.substring(1) : "step1"
  );
  const [isAbaDisclosureModalOpen, setAbaDiclosureModalOpen] = useState(false);
  const [isOpenCityDropdown, setOpenCityDropdown] = useState(false);
  const [isVisibleBankAddedWithPlaid, setVisibleBankAddedWithPlaid] = useState(
    false
  );

  let emailOTPOne = useRef(null);
  let emailOTPTwo = useRef(null);
  let emailOTPThree = useRef(null);
  let emailOTPFour = useRef(null);
  let phoneOTPOne = useRef(null);
  let phoneOTPTwo = useRef(null);
  let phoneOTPThree = useRef(null);
  let phoneOTPFour = useRef(null);
  const handleCityDropdown = useRef();

  const { open, ready } = usePlaidLink({
    token: plaidLinkToken,
    onSuccess: (public_token, metadata) => {
      updatePublicToken(public_token);
    },
  });

  useEffect(() => {
    if (currentStep === "step1") {
      setActiveStep(0);
    } else if (currentStep === "step2") {
      setActiveStep(1);
    } else if (currentStep === "step3") {
      setActiveStep(2);
    } else if (currentStep === "step4") {
      setActiveStep(3);
    }
  }, [currentStep]);

  useEffect(() => {
    const unlisten = history.listen(() => {});
    setCurrentStep(location.hash.substring(1));
    return unlisten;
  }, [location]);

  const showRadioButtonModal = () => {
    setOpenRadiobuttonModal(true);
  };

  const hideRadioButtonModal = () => {
    setOpenRadiobuttonModal(false);
  };

  const handleClickRadioOption = (value) => {
    setOptions(value);
    if (value === "manually") {
    } else if (ready) {
      open();
    }
    hideRadioButtonModal();
  };

  useEffect(() => {
    generateLinkToken();
  }, [initiatePlaidLinkToken]);

  // useEffect(() => {
  //   if (ready) {
  //     open();
  //   }
  // }, [ready, open, plaidLinkToken]);

  // async function getBankList() {
  //   const bankListResponse = await bankListApi();
  //   if (bankListResponse.Error) {
  //     setErrorMessage(bankListResponse.Error.message);
  //     handleErrorModalShow();
  //     return;
  //   }
  //   if (bankListResponse && bankListResponse.data) {
  //     setBankList(bankListResponse.data);
  //   } else {
  //     setBankList([]);
  //   }
  // }

  // useEffect(() => {
  //   getBankList();
  // }, []);

  useEffect(() => {
    async function getCountryList() {
      const countryListResponse = await countryListApi();
      if (countryListResponse.Error) {
        setErrorMessage(countryListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      if (countryListResponse && countryListResponse.data) {
        setCountryList(countryListResponse.data);
        countryListResponse.data.map((country) => {
          if (country.name === "USA") {
            formik.values.country = country.name;
            setSelectedCountry(country);
            getStateList();
          }
          return country;
        });
      } else {
        setCountryList([]);
      }
    }
    getCountryList();
  }, []);

  async function getStateList() {
    if (selectedCountry === null) {
      return;
    }
    if (selectedCountry && selectedCountry.id) {
      const stateListResponse = await stateListApi({
        countryId: selectedCountry.id,
      });
      if (stateListResponse.Error) {
        setErrorMessage(stateListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      if (stateListResponse && stateListResponse.data) {
        setStateList(stateListResponse.data);
      } else {
        setStateList([]);
      }
    }
  }

  useEffect(() => {
    getStateList();
  }, [selectedCountry]);

  async function getCityList() {
    const cityListResponse = await cityListApi({
      stateId: selectedState.id,
      isActiveOnly: 0,
    });
    const { Error, data } = cityListResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    if (data) {
      const datalist = data.map((city) => {
        const { name } = city || {};
        city.label = name;
        city.value = name;
        return city;
      });
      setCityList(datalist);
    } else {
      setCityList([]);
    }
  }

  useEffect(() => {
    if (selectedState === null) {
      return;
    }
    if (selectedState && selectedState.id) {
      getCityList();
    }
  }, [selectedState]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // Below code is to close product selection dropdown
      if (handleCityDropdown.current) {
        if (
          handleCityDropdown &&
          !handleCityDropdown.current.contains(e.target)
        ) {
          setOpenCityDropdown(false);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  });

  const generateLinkToken = async () => {
    if (!user) {
      return;
    }
    const { id } = user || {};
    let generateLinkTokenObj = { userId: id };
    const generateLinkTokenResponse = await generateLinkTokenApi(
      generateLinkTokenObj
    );

    const { Error } = generateLinkTokenResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setPlaidLinkToken(generateLinkTokenResponse.link_token);
  };

  const updatePublicToken = async (public_token) => {
    const { id } = user || {};
    let publicTokenObj = { userId: id, publicToken: public_token };
    const publicTokenResponse = await publicTokenApi(publicTokenObj);
    const { Error } = publicTokenResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setPublicToken(publicTokenResponse);
    const { accounts, numbers } = publicTokenResponse || {};
    const [accountdetail] = accounts || [];
    const { name, subtype } = accountdetail || {};
    const { ach } = numbers || {};
    const [acountandrounting] = ach || [];
    const { account, routing } = acountandrounting || {};
    formik.setFieldValue(
      "bankaccounttype",
      subtype === "savings" ? "Savings" : "Checking"
    );
    formik.setFieldValue("bankroutenumber", routing);
    formik.setFieldValue("bankaccountnumber", account);
    formik.setFieldValue("banknickname", name);
    setVisibleBankAddedWithPlaid(true);
  };

  const getSteps = () => {
    return ["Profile", "Password", "Bank Info", "Verification"];
  };

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleTermsModalShow = () => {
    setTermsModalOpen(true);
  };

  const handleTermsModalHide = () => {
    setTermsModalOpen(false);
  };

  const handleAbaDiclosureModal = () => {
    setUserReadTerms(true);
    formik.setFieldValue("is_aba_disclosure_signed", true);
    handleAbaDiclosureModalHide();
  };

  const handleAbaDiclosureModalHide = () => {
    setAbaDiclosureModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    history.goBack();
  }

  function handleReset() {
    setActiveStep(0);
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleChange = (event) => {
    const cellNumberWithFormat = normalizeInput(
      event.target.value,
      formik.values.cellnumber
    );
    formik.setFieldValue("cellnumber", cellNumberWithFormat);
  };

  const resendEmailOTP = async () => {
    const resendEmailOTPResponse = await resendEmailOTPApi({
      userId: user.id,
      email: formik.values.email,
    });
    if (resendEmailOTPResponse.Error) {
      setErrorMessage(resendEmailOTPResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    // setEmailOTP(resendEmailOTPResponse.otp_email);
  };

  const resendCellNumberOTP = async () => {
    const resendCellNumberOTPResponse = await resendCellNumberOTPApi({
      userId: user.id,
      cellnumber: formik.values.cellnumber,
    });

    if (resendCellNumberOTPResponse.Error) {
      setErrorMessage(resendCellNumberOTPResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    // setMobileOTP(resendCellNumberOTPResponse.otp_mobile);
  };

  const getStepContent = () => {
    switch (currentStep) {
      case "step1":
        return renderStepOne();
      case "step2":
        return renderStepTwo();
      case "step3":
        return renderStepThree();
      case "step4":
        return renderStepFour();
      default:
        return renderStepOne();
    }
  };

  const ProfileSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Only characters are allowed in firstname")
      .min(2, "First name must be at least 2 characters")
      .max(64, "First name must not be more than 64 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    lastname: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, "Only characters are allowed in lastname")
      .min(2, "Last name must be at least 2 characters")
      .max(64, "Last must not be more than 64 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Please enter valid Email")
      .min(8, "Email must be at least 8 characters")
      .max(132, "Email must not be more than 132 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    cellnumber: Yup.string()
      .matches(
        /^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/,
        "Wrong mobile number format"
      )
      .min(13, "Phone number must be at least 13 digits")
      .max(14, "Phone number must not be more than 14 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    businessname:
      props.match.params.type === "2"
        ? Yup.string()
            .min(4, "Business name must be at least 4 characters")
            .max(132, "Business name must not be more than 132 characters")
            .required(
              intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD",
              })
            )
        : Yup.string(),
    address1: Yup.string()
      .min(2, "Address 1 must be at least 2 characters")
      .max(32, "Address 1 must not be more than 32 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    address2: Yup.string().max(
      32,
      "Address 2 must not be more than 32 characters"
    ),

    city: Yup.string()
      .min(2, "City must be at least 2 characters")
      .max(32, "City must not be more than 32 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    state: Yup.string()
      .min(2, "State must be at least 2 characters")
      .max(32, "State must not be more than 32 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    country: Yup.string()
      .min(2, "Country must be at least 2 characters")
      .max(32, "Country must not be more than 32 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    zipcode: Yup.string()
      .matches(/^[0-9]*$/, "Must be only digits")
      .min(5, "Zipcode must be at least 5 digits")
      .max(5, "Zipcode must not be more than 5 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const PasswordSchema = Yup.object().shape({
    username: Yup.string()
      // .min(6, "User name must be at least 6 characters")
      // .max(64, "User name must not be more than 64 characters")
      .matches(
        "^(?=[a-zA-Z0-9._]{6,64}$)(?!.*[_.]{2})[^_.].*[^_.]$",
        "This username is invalid. Usernames must be a minimum of 6 characters, maximum of 64 characters, and cannot contain any spaces"
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(64, "Password must not be more than 64 characters")
      .matches(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
        "Passwords must have least uppercase letter, lowercase letter, number and symbol"
      )
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    confirmpassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match"
        ),
      }),
  });

  const BankDetailSchema = Yup.object().shape({
    banknickname: Yup.string()
      .min(4, "Bank name must be at least 4 characters")
      .max(128, "Bank name must not be more than 128 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    bankaccounttype: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    bankroutenumber: Yup.string()
      .matches(/^[0-9]*$/, "Must be only digits")
      .min(9, "Bank routing number must be at least 9 digits")
      .max(9, "Bank routing number must not be more than 9 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    bankaccountnumber: Yup.string()
      .matches(/^[0-9]*$/, "Must be only digits")
      .min(9, "Account number must be at least 9 digits")
      .max(19, "Account number must not be more than 19 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    acceptTerms: Yup.boolean()
      .required("You must accept the terms and conditions")
      .oneOf([true], "You must accept the terms and conditions."),
  });

  const OTPSchema = Yup.object().shape({
    emailOTPOne: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    emailOTPTwo: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    emailOTPThree: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    emailOTPFour: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneOTPOne: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneOTPTwo: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneOTPThree: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    phoneOTPFour: Yup.string()
      .min(1, "Please enter atleast 1 digit")
      .max(1, "must not be more than 1 digit")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const validationSchemaList = [
    ProfileSchema,
    PasswordSchema,
    BankDetailSchema,
    OTPSchema,
  ];

  const phaseOneCheckExistingEmailAndCellnumber = async () => {
    const { email, cellnumber } = formik.values;
    enableLoading();
    const isEmailOrCellNumberExist = await checkUserExistOrNotApi({
      email,
      cellnumber,
    });
    const { Error } = isEmailOrCellNumberExist || {};
    disableLoading();
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    phaseOneSignUp();
  };

  const phaseOneSignUp = async () => {
    const {
      firstname,
      lastname,
      email,
      cellnumber,
      businessname,
      address1,
      address2,
      zipcode,
      city,
    } = formik.values;

    let signUpPhase1Obj = {
      firstname,
      lastname,
      email,
      cellnumber,
      ...(businessname && { business_name: businessname }),
      address_1: address1,
      ...(address2 && { address_2: address2 }),
      countryId: selectedCountry.id,
      stateId: selectedState.id,
      cityId: city,
      zip_code: zipcode,
      role_id: props.match.params.type === "2" ? 3 : 2,
      activeStep: 1,
    };

    enableLoading();
    const signUpPhase1Response = await signUpPhase1Api(signUpPhase1Obj);
    disableLoading();
    const { Error, user_detail } = signUpPhase1Response || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setUser(user_detail);
    handleNext();
    history.push("#step2");
  };

  const phaseOneCheckExistingUsername = async () => {
    const { username } = formik.values;
    enableLoading();
    const isUserNameExist = await checkUserExistOrNotApi({
      username,
    });
    disableLoading();
    const { Error } = isUserNameExist || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    phaseTwoSignUp();
  };

  const phaseTwoSignUp = async () => {
    const { username, password } = formik.values;
    const { id } = user || {};
    let signUpPhase2Obj = {
      id,
      username,
      password,
      ...(profilePic && { profile: profilePic }),
      activeStep: 2,
    };
    enableLoading();
    const signUpPhase2Response = await signUpPhase2Api(signUpPhase2Obj);
    disableLoading();
    const { Error } = signUpPhase2Response || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    handleNext();
    history.push("#step3");
    showRadioButtonModal();
    setInitiatePlaidLinkToken(Math.random());
  };

  const phaseThreeSignUp = async () => {
    const {
      bankaccounttype,
      bankroutenumber,
      bankaccountnumber,
      banknickname,
      is_aba_disclosure_signed,
    } = formik.values;
    const { id } = user || {};
    const { accounts } = publicToken || {};
    const [firstBankAccount] = accounts || [];
    const { account_id } = firstBankAccount || {};
    let signUpPhase3Obj = {
      id,
      bankId: account_id,
      bank_nickname: banknickname,
      is_aba_disclosure_signed,
      bank_routing_number: bankroutenumber,
      bank_account_number: bankaccountnumber,
      account_type: bankaccounttype,
      activeStep: 3,
    };
    enableLoading();
    const signUpPhase3Response = await signUpPhase3Api(signUpPhase3Obj);
    disableLoading();
    const { Error, user_detail } = signUpPhase3Response || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setUser(user_detail);
    phaseThreeSendOTP(user_detail);
  };

  const phaseFourSignUp = async () => {
    const {
      emailOTPOne,
      emailOTPTwo,
      emailOTPThree,
      emailOTPFour,
      phoneOTPOne,
      phoneOTPTwo,
      phoneOTPThree,
      phoneOTPFour,
    } = formik.values;
    const { id } = user || {};
    let verifyOTPObj = {
      userId: id,
      otp_email: `${emailOTPOne}${emailOTPTwo}${emailOTPThree}${emailOTPFour}`,
      otp_mobile: `${phoneOTPOne}${phoneOTPTwo}${phoneOTPThree}${phoneOTPFour}`,
    };
    enableLoading();
    const verifyOTPResponse = await verifyOTPApi(verifyOTPObj);
    disableLoading();
    const { Error, user: userDetails, token } = verifyOTPResponse || {};
    const { id: tokenId } = token || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }

    setUserInstance(userDetails);
    localStorage.setItem("auth-token", tokenId);
    handleConfirmShow();
  };

  const phaseThreeSendOTP = async (user_detail) => {
    const { id: userId } = user_detail || {};
    const { email, cellnumber } = formik.values;
    let sendOTPObj = {
      userId,
      email,
      cellnumber,
    };
    enableLoading();
    const sendOTPResponse = await sendOTPApi(sendOTPObj);
    disableLoading();
    const { Error, otp_mobile, otp_email } = sendOTPResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    // setMobileOTP(otp_mobile);
    // setEmailOTP(otp_email);
    handleNext();
    history.push("#step4");
  };

  const handleToggleCityDropdown = () => {
    setOpenCityDropdown(!isOpenCityDropdown);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaList[activeStep],
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (currentStep === "step1") {
        // Profile
        // activeStep === 0;
        phaseOneCheckExistingEmailAndCellnumber();
      } else if (currentStep === "step2") {
        // Password
        // activeStep === 1;
        phaseOneCheckExistingUsername();
      } else if (currentStep === "step3") {
        // Bank Details
        // activeStep === 3;
        phaseThreeSignUp();
      } else if (currentStep === "step4") {
        // OTP
        // activeStep === 4;
        phaseFourSignUp();
      }
    },
  });

  const renderStepOne = () => {
    return (
      <div>
        <div className="row g-2">
          <div className="col-6 mb-4">
            <input
              placeholder="First Name*"
              type="text"
              className="form-control h-auto py-5 px-6"
              name="firstname"
              {...formik.getFieldProps("firstname")}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.firstname}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-4">
            <input
              placeholder="Last Name*"
              type="text"
              className="form-control h-auto py-5 px-6"
              name="lastname"
              {...formik.getFieldProps("lastname")}
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.lastname}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 mb-4">
            <input
              placeholder="Email Address*"
              type="email"
              className="form-control h-auto py-5 px-6"
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.email}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              placeholder="Mobile Number*"
              type="tel"
              className="form-control h-auto py-5 px-6"
              name="cellnumber"
              value={formik.values.cellnumber}
              {...formik.getFieldProps("cellnumber")}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {formik.touched.cellnumber && formik.errors.cellnumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.cellnumber}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {props.match.params.type === "2" && (
          <div className="row">
            <div className="col-md-12 mb-4">
              <input
                placeholder="Business Name*"
                type="businessname"
                className="form-control h-auto py-5 px-6"
                name="businessname"
                {...formik.getFieldProps("businessname")}
              />
              {formik.touched.businessname && formik.errors.businessname ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <i className="fas fa-times text-danger mr-1 text-center"></i>
                    {formik.errors.businessname}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        )}

        <div className="row">
          <div className="col-md-12 mb-5">
            <h5 className="font-weight-boldest fs-18 mt-8">Mailing Address</h5>
          </div>
          <div className="col-md-12 mb-4">
            <input
              placeholder="Address 1*"
              type="text"
              className="form-control h-auto py-5 px-6"
              name="address1"
              {...formik.getFieldProps("address1")}
            />
            {formik.touched.address1 && formik.errors.address1 ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.address1}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              placeholder="Address 2"
              type="text"
              className="form-control h-auto py-5 px-6"
              name="address2"
              {...formik.getFieldProps("address2")}
            />
            {formik.touched.address2 && formik.errors.address2 ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.address2}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="row g-2">
          <div className="col-6 mb-4">
            <select
              className="form-control h-auto py-5 px-6"
              name="country"
              value={formik.values.country}
              disabled
              {...formik.getFieldProps("country")}
            >
              <option value="">Country</option>
              {countryList &&
                countryList.map((country, index) => (
                  <option value={country.name} key={index}>
                    {country.name}
                  </option>
                ))}
            </select>
            {formik.touched.country && formik.errors.country ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.country}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-4">
            <select
              className="form-control h-auto py-5 px-6"
              name="state"
              value={formik.values.state}
              {...formik.getFieldProps("state")}
              onChange={(event) => {
                const stateName = event.target.value;
                if (stateName === "") {
                  setCityList(null);
                }
                formik.setFieldValue("state", event.target.value);
                const [selectedStateDetails] = stateList.filter(
                  (state) => state.state_name === stateName
                );
                setSelectedState(selectedStateDetails);
              }}
            >
              <option value="">State</option>
              {stateList &&
                stateList.map((state, index) => (
                  <option value={state.state_name} key={index}>
                    {state.state_name}
                  </option>
                ))}
            </select>
            {formik.touched.state && formik.errors.state ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.state}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-4">
            {/* <select
              className="form-control h-auto py-5 px-6"
              name="city"
              value={formik.values.city}
              {...formik.getFieldProps("city")}
              onChange={(event) => {
                const cityName = event.target.value;
                formik.setFieldValue("city", event.target.value);
                const [selectedCityDetails] = cityList.filter(
                  (city) => city.name === cityName
                );
                setSelectedCity(selectedCityDetails);
              }}
            >
              <option value="">City</option>
              {cityList &&
                cityList.map((city, index) => (
                  <option value={city.name} key={index}>
                    {city.name}
                  </option>
                ))}
            </select> */}
            <div
              ref={handleCityDropdown}
              className="citydrop-down city-padding-none"
            >
              <DropdownMaster
                isOpen={isOpenCityDropdown}
                onClose={handleToggleCityDropdown}
                target={
                  <ButtonAtl
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "left",
                    }}
                    iconAfter={<ChevronDownMaster />}
                    onClick={handleToggleCityDropdown}
                    isSelected={isOpenCityDropdown}
                    className="form-control w-100 custom-select-btn mb-0i"
                  >
                    {selectedCity ? selectedCity : "Enter City Name"}
                  </ButtonAtl>
                }
              >
                <CreatableSelect
                  menuPlacement="top"
                  placeholder={"Enter City Name"}
                  onChange={(data) => {
                    if (data) {
                      setSelectedCity(data.value);
                      formik.setFieldValue("city", data.value);
                      handleToggleCityDropdown();
                    }
                  }}
                  options={cityList}
                  createOptionPosition="top"
                  isSearchable
                  autoFocus
                  isClearable
                  controlShouldRenderValue={true}
                  menuIsOpen={isOpenCityDropdown}
                  components={{
                    DropdownIndicatorMaster,
                    IndicatorSeparator: null,
                  }}
                  tabSelectsValue={true}
                  className="react-custom-select-container text-capitalize"
                  classNamePrefix="react-select"
                  formatCreateLabel={(inputValue) => {
                    inputValue = inputValue.trim();
                    return inputValue;
                  }}
                />
              </DropdownMaster>
            </div>
            {formik.touched.city && formik.errors.city ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.city}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-6 mb-4">
            <input
              placeholder="Zip/Postal Code*"
              type="number"
              className="form-control h-auto py-5 px-6"
              name="zipcode"
              {...formik.getFieldProps("zipcode")}
            />
            {formik.touched.zipcode && formik.errors.zipcode ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.zipcode}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div>
        <div className="row">
          <div className="col-md-12 mb-8 mt-10">
            <div className="upload-user-profile d-flex justify-content-center">
              <div className="round-image-border">
                <div
                  className="image-input rounded-circle"
                  id="kt_profile_avatar"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      "/media/svg/avatars/007-boy-2-blue.svg"
                    )}`,
                  }}
                >
                  <img
                    className="image-input-wrapper rounded-circle"
                    src={
                      pic
                        ? URL.createObjectURL(pic)
                        : `${toAbsoluteUrl(
                            "/media/svg/avatars/007-boy-2-blue.svg"
                          )}`
                    }
                    alt=""
                  />
                  <label
                    className="btn btn-md btn-icon btn-circle btn-camera btn-hover-text-primary"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Change avatar"
                  >
                    <i className="fa fa-camera icon-md text-dark"></i>
                    <input
                      type="file"
                      name="pic"
                      accept=".png, .jpg, .jpeg"
                      onChange={async (event) => {
                        setImageLoading(true);
                        let file = {
                          file: event.target.files[0],
                          containerkey: 1,
                        };
                        const profilePicReponse = await uploadProfilePicApi(
                          file
                        );
                        setImageLoading(false);
                        if (profilePicReponse.Error) {
                          setErrorMessage(profilePicReponse.Error.message);
                          handleErrorModalShow();
                          return;
                        } else {
                          setPicForPreview(file.file);
                          setProfilePic(profilePicReponse.newFilename);
                        }
                      }}
                    />
                  </label>
                </div>
                {imageLoading && (
                  <div style={{ position: "absolute" }}>
                    <span className="spinner spinner-danger"></span>
                  </div>
                )}
              </div>
            </div>
            <div className="upload-content text-center mt-3">
              <p className="text-secondary fs-14">
                Tap on the camera & help other users recognize you
                <br className="d-none d-md-block" /> by choosing a profile
                picture (company logo, headshot, etc.)
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <input
              placeholder="Username*"
              type="text"
              autoComplete="off"
              className="form-control h-auto py-5 px-6 mb-2"
              name="username"
              value=""
              {...formik.getFieldProps("username")}
            />
            {formik.touched.username && formik.errors.username ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.username}
                </div>
              </div>
            ) : null}
            <span className="d-block mb-5 mt-5">
              Note: While your other information is kept private, your Username
              will be public
            </span>
          </div>
          <div className="col-md-6 mb-4">
            <input
              placeholder="Password*"
              type="password"
              className="form-control h-auto py-5 px-6 mb-2"
              name="password"
              {...formik.getFieldProps("password")}
            />
            {/* {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null} */}
            {formik.touched.password && (
              <PasswordValidationDropdownPassword formik={formik} />
            )}
          </div>
          <div className="col-md-6 mb-4">
            <input
              placeholder="Re-Enter Password*"
              type="password"
              className="form-control h-auto py-5 px-6 mb-2"
              name="confirmpassword"
              {...formik.getFieldProps("confirmpassword")}
            />
            {/* {formik.touched.confirmpassword && formik.errors.confirmpassword ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.confirmpassword}
                </div>
              </div>
            ) : null} */}
            {formik.touched.confirmpassword && (
              <PasswordValicationDropdownConfirmPassword formik={formik} />
            )}
          </div>
          {/* <div className="col-md-8 mb-4">
            <span className="text-danger">
              Passwords must have at least 8 characters and contain at least one
              of the following: uppercase letters, lowercase letters, numbers,
              and symbols
            </span>
          </div> */}
        </div>
      </div>
    );
  };

  const renderStepThree = () => {
    return (
      <div>
        <div className="text-center mb-12">
          <div className="bank-icon-wrapper">
            <i className="fa fa-landmark text-success icon-3x"></i>
          </div>
          <h5 className="fs-18 font-weight-boldest mt-4">
            {/* Enable ACH, eChecks, and Wires */}
            Connect Your Bank
          </h5>
          <span className="fs-16">
            Bank Accounts are used for ACH Payments and Transaction Fees. Also,
            funds you receive will directly deposit into your Default Bank
            Account.
          </span>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <input
              placeholder="Bank Nickname*"
              autoComplete="off"
              type="text"
              className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
              name="banknickname"
              {...formik.getFieldProps("banknickname")}
            />
            {formik.touched.banknickname && formik.errors.banknickname ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.banknickname}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-6 mb-4">
            <select
              autocomplete="off"
              className="form-control h-auto py-5 px-6 mb-2 text-capitalize"
              {...formik.getFieldProps("bankaccounttype")}
              disabled={option === "plaid"}
            >
              <option value="">Select Account Type</option>
              <option value="Savings">Savings</option>
              <option value="Checking">Checking</option>
            </select>
            {formik.touched.bankaccounttype && formik.errors.bankaccounttype ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.bankaccounttype}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              placeholder="Bank Routing Number*"
              autoComplete="off"
              type="text"
              disabled={option === "plaid"}
              className="form-control h-auto py-5 px-6 mb-2"
              name="bankroutenumber"
              {...formik.getFieldProps("bankroutenumber")}
            />
            {formik.touched.bankroutenumber && formik.errors.bankroutenumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.bankroutenumber}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 mb-4">
            <input
              placeholder="Bank Account Number*"
              autoComplete="off"
              type="text"
              className="form-control h-auto py-5 px-6 mb-2"
              name="bankaccountnumber"
              disabled={option === "plaid"}
              {...formik.getFieldProps("bankaccountnumber")}
            />
            {formik.touched.bankaccountnumber &&
            formik.errors.bankaccountnumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.bankaccountnumber}
                </div>
              </div>
            ) : null}
          </div>
          <div className="col-md-12 mb-5">
            {isVisibleBankAddedWithPlaid && (
              <>
                <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    Your bank account is successfully linked with your PayXtreme
                    account. Please find the bank details above and complete the
                    account creation process.
                  </div>
                </div>
              </>
            )}
            {/* <p className="fs-14 font-weight-light text-secondary">
              <strong>Please note:</strong> adding your bank information is
              optional for now, but will be required for the transactions listed
              above
            </p> */}
            {/* <p className="fs-14 font-weight-light mt-8 pb-8 border-bottom text-secondary">
              We will deposit 2 small amounts into your bank account for
              verification, usually takes 2-3 bussiness days. When you receive
              the deposits, go to your Account Settings and enter the amounts.
              This will verify your bank account and you'll be good to go!
            </p> */}
          </div>
          <div className="col-md-12 mb-0">
            <div className="d-block d-md-flex justify-content-md-between align-items-center">
              <div
                className="text-danger d-block text-center cursor-pointer"
                onClick={() => {
                  setUserReadTerms(true);
                  setAbaDiclosureModalOpen(true);
                }}
              >
                View Terms and Conditions
              </div>
              <div className="form-group mb-0 mt-3 mt-md-0">
                <label
                  className="d-flex justify-content-center checkbox text-dark font-weight-bold"
                  onClick={(e) => {
                    if (!isUserReadTerms) {
                      e.preventDefault();
                      setErrorMessage(
                        "You must read and agree to our Terms and Conditions before continuing."
                      );
                      handleErrorModalShow();
                      return;
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    name="acceptTerms"
                    {...formik.getFieldProps("acceptTerms")}
                  />
                  <span className="mr-2"></span>I authorize PayXtreme to...
                </label>
                {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.acceptTerms}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderStepFour = () => {
    return (
      <div>
        <form autoComplete="off">
          <div className="text-center">
            <h5 className="fs-18 font-weight-boldest mt-8 mb-8">
              Verify Your Email and Phone Number
            </h5>
            <p className="fs-14 mb-0 text-dark">
              Please enter the 4 digit code sent to your
            </p>
            <p className="fs-14 text-danger">
              EMAIL ****
              {formik.values.email &&
                formik.values.email.substring(
                  formik.values.email.length -
                    (formik.values.email.length > 10 ? 10 : 5)
                )}{" "}
              below:
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center otp-wrapper">
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              <input
                type="number"
                autoComplete="off"
                className="form-control mr-4"
                maxLength="1"
                autoFocus
                name="emailOTPOne"
                ref={emailOTPOne}
                value={formik.values.emailOTPOne}
                {...formik.getFieldProps("emailOTPOne")}
                onChange={(event) => {
                  formik.setFieldValue("emailOTPOne", event.target.value);
                  if (event.target.value.length === 1) {
                    emailOTPTwo.current.focus();
                  }
                }}
              />
              <input
                type="number"
                autoComplete="emailOTPTwo"
                className="form-control mr-4"
                maxLength="1"
                name="emailOTPTwo"
                ref={emailOTPTwo}
                {...formik.getFieldProps("emailOTPTwo")}
                onChange={(event) => {
                  formik.setFieldValue("emailOTPTwo", event.target.value);
                  if (event.target.value.length === 1) {
                    emailOTPThree.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    emailOTPOne.current.focus();
                  }
                }}
              />
              <input
                type="number"
                autoComplete="emailOTPThree"
                className="form-control mr-4"
                maxLength="1"
                name="emailOTPThree"
                ref={emailOTPThree}
                {...formik.getFieldProps("emailOTPThree")}
                onChange={(event) => {
                  formik.setFieldValue("emailOTPThree", event.target.value);
                  if (event.target.value.length === 1) {
                    emailOTPFour.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    emailOTPTwo.current.focus();
                  }
                }}
              />
              <input
                type="number"
                autoComplete="emailOTPFour"
                className="form-control"
                maxLength="1"
                name="emailOTPFour"
                ref={emailOTPFour}
                {...formik.getFieldProps("emailOTPFour")}
                onChange={(event) => {
                  formik.setFieldValue("emailOTPFour", event.target.value);
                  if (event.target.value.length === 1) {
                    phoneOTPOne.current.focus();
                  }
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    emailOTPThree.current.focus();
                  }
                }}
              />
            </div>
          </div>
          {(formik.touched.emailOTPOne ||
            formik.touched.emailOTPTwo ||
            formik.touched.emailOTPThree ||
            formik.touched.emailOTPFour) &&
          (formik.errors.emailOTPOne ||
            formik.errors.emailOTPTwo ||
            formik.errors.emailOTPThree ||
            formik.errors.emailOTPFour) ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-center">
                <i className="fas fa-times text-danger mr-1 text-center"></i>
                {formik.errors.emailOTPOne
                  ? formik.errors.emailOTPOne
                  : formik.touched.emailOTPTwo
                  ? formik.touched.emailOTPTwo
                  : formik.touched.emailOTPThree
                  ? formik.touched.emailOTPThree
                  : formik.touched.emailOTPFour}
              </div>
            </div>
          ) : null}
          <div className="text-center mb-15">
            <a
              href
              className="fs-13 mt-4 font-weight-bold d-block text-danger"
              onClick={resendEmailOTP}
            >
              Resend Email Code?
            </a>
          </div>
          <div className="text-center">
            <p className="fs-14 mb-0 text-dark">
              Please enter the 4 digit code sent to your
            </p>
            <p className="fs-14 text-danger">
              PHONE (xxx) xxx-
              {formik.values.cellnumber.substring(
                formik.values.cellnumber.length - 4
              )}{" "}
              below:
            </p>
          </div>
          <div className="d-flex justify-content-center align-items-center otp-wrapper">
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              <input
                type="number"
                autoComplete="phoneOTPOne"
                className="form-control mr-4"
                maxLength="1"
                name="phoneOTPOne"
                ref={phoneOTPOne}
                {...formik.getFieldProps("phoneOTPOne")}
                onChange={(event) => {
                  if (event.target.value.length === 1) {
                    phoneOTPTwo.current.focus();
                  }
                  formik.setFieldValue("phoneOTPOne", event.target.value);
                }}
              />
              <input
                type="number"
                autoComplete="phoneOTPTwo"
                className="form-control mr-4"
                maxLength="1"
                name="phoneOTPTwo"
                ref={phoneOTPTwo}
                {...formik.getFieldProps("phoneOTPTwo")}
                onChange={(event) => {
                  if (event.target.value.length === 1) {
                    phoneOTPThree.current.focus();
                  }
                  formik.setFieldValue("phoneOTPTwo", event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    phoneOTPOne.current.focus();
                  }
                }}
              />
              <input
                type="number"
                autoComplete="phoneOTPThree"
                className="form-control mr-4"
                maxLength="1"
                name="phoneOTPThree"
                ref={phoneOTPThree}
                {...formik.getFieldProps("phoneOTPThree")}
                onChange={(event) => {
                  if (event.target.value.length === 1) {
                    phoneOTPFour.current.focus();
                  }
                  formik.setFieldValue("phoneOTPThree", event.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    phoneOTPTwo.current.focus();
                  }
                }}
              />
              <input
                type="number"
                autoComplete="phoneOTPFour"
                className="form-control"
                maxLength="1"
                name="phoneOTPFour"
                ref={phoneOTPFour}
                {...formik.getFieldProps("phoneOTPFour")}
                onKeyDown={(e) => {
                  if (e.keyCode === 8 && e.target.value.length === 0) {
                    phoneOTPThree.current.focus();
                  }
                }}
              />
            </div>
          </div>
          {(formik.touched.phoneOTPOne ||
            formik.touched.phoneOTPTwo ||
            formik.touched.phoneOTPThree ||
            formik.touched.phoneOTPFour) &&
          (formik.errors.phoneOTPOne ||
            formik.errors.phoneOTPTwo ||
            formik.errors.phoneOTPThree ||
            formik.errors.phoneOTPFour) ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block text-center">
                <i className="fas fa-times text-danger mr-1 text-center"></i>
                {formik.errors.phoneOTPOne
                  ? formik.errors.phoneOTPOne
                  : formik.touched.phoneOTPTwo
                  ? formik.touched.phoneOTPTwo
                  : formik.touched.phoneOTPThree
                  ? formik.touched.phoneOTPThree
                  : formik.touched.phoneOTPFour}
              </div>
            </div>
          ) : null}
          <div className="text-center mb-15">
            <a
              href
              className="fs-13 mt-4 font-weight-bold d-block text-danger"
              alt=""
              onClick={resendCellNumberOTP}
            >
              Resend Phone Code?
            </a>
          </div>

          <div className="text-center mb-15 text-danger">
            <span>
              By continuing you will opt-in for PayXtreme notifications. Please
              note we may be required to send you timely and critical text
              messages about a transaction you’re a part of. Standard data and
              message rates apply
            </span>
          </div>
        </form>
      </div>
    );
  };

  const connector = (
    <StepConnector
      classes={{
        active: classes.connectorActive,
        completed: classes.connectorCompleted,
        disabled: classes.connectorDisabled,
        line: classes.connectorLine,
      }}
    />
  );

  return (
    <>
      <div className={classes.root}>
        <div className="text-left mb-5">
          <h3 className="font-size-h1">
            <img
              src={toAbsoluteUrl("/media/logos/PayXtreme.png")}
              height="35"
              alt=""
            />
          </h3>
        </div>

        <Stepper activeStep={activeStep} connector={connector}>
          <Step key="Profile">
            <StepLabel>
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h5 className="fs-16 font-weight-bolder mb-1">Profile </h5>{" "}
                  <p className="mb-0">Personal Details</p>
                </div>
                <div className="">
                  <span className="svg-icon menu-icon svg-icon-2x ml-10">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-right.svg"
                      )}
                    />
                  </span>{" "}
                </div>
              </div>
            </StepLabel>
          </Step>

          <Step key="Password">
            <StepLabel>
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h5 className="fs-16 font-weight-bolder mb-1">Password </h5>{" "}
                  <p className="mb-0">Set Password</p>
                </div>
                <div className="">
                  <span className="svg-icon menu-icon svg-icon-2x ml-10">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-right.svg"
                      )}
                    />
                  </span>{" "}
                </div>
              </div>
            </StepLabel>
          </Step>
          <Step key="Bank Info">
            <StepLabel>
              <div className="d-flex justify-content-between align-items-center">
                <div className="">
                  <h5 className="fs-16 font-weight-bolder mb-1">Bank Info </h5>{" "}
                  <p className="mb-0">Set up payment</p>
                </div>
                <div className="">
                  <span className="svg-icon menu-icon svg-icon-2x ml-10">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Arrow-right.svg"
                      )}
                    />
                  </span>{" "}
                </div>
              </div>
            </StepLabel>
          </Step>
          <Step key="Verification">
            <StepLabel>
              <h5 className="fs-16 font-weight-bolder mb-1">Verification </h5>{" "}
              <p className="mb-0">Submit form</p>
            </StepLabel>
          </Step>
        </Stepper>
        <div>
          {activeStep === getSteps().length ? (
            <div></div>
          ) : (
            <div>
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
                autoComplete="off"
              >
                {formik.status && (
                  <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                )}
                {/* <Typography className={classes.instructions}> */}
                {getStepContent(activeStep)}
                {/* </Typography> */}
                <div className="d-flex justify-content-between mt-10">
                  <Button
                    // disabled={activeStep === 0}
                    variant="btn btn-light-brand btn-lg"
                    onClick={handleBack}
                    className={classes.button}
                  >
                    <span className="svg-icon menu-icon ml-0 mr-2">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-left.svg"
                        )}
                      />
                    </span>
                    Previous
                  </Button>
                  <Button
                    type="submit"
                    variant="btn btn-brand btn-lg"
                    disabled={loading}
                    className={classes.button}
                  >
                    {activeStep === getSteps().length - 1 ? "Submit" : "Next"}
                    <span className="svg-icon menu-icon ml-2 mr-0">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Arrow-right.svg"
                        )}
                      />
                    </span>
                    {loading && (
                      <span className="ml-3 mr-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </div>
              </form>
            </div>
          )}
        </div>
        {/* </form> */}
      </div>

      <SuccessModal
        backdrop={true}
        message={"You have successfully verified your account!"}
        isConfirmModal={isConfirmModal}
        handleConfirmHide={handleConfirmHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        buttonLabel={"Go to Dashboard"}
        onClick={() => {
          window.location.reload();
        }}
      />

      {/* <Modal
        backdrop="static"
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            <div className="success-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>{" "}
            </div>
          </div>

          <p className="text-center fs-16 text-dark confirmation-content">
            You have successfully verified <br></br> your account!
          </p>
          <Button
            variant="btn-lg btn-success mt-5 mb-5"
            onClick={() => {
              props.history.push("/dashboard");
            }}
          >
            Go to Dashboard
          </Button>
        </Modal.Body>
      </Modal> */}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <RadioButtonModal
        option={option}
        handleClick={handleClickRadioOption}
        isOpenRadiobuttonModal={isOpenRadiobuttonModal}
        hideRadioButtonModal={hideRadioButtonModal}
      />

      {isTermsModalOpen && (
        <TermsModal
          isTermsModalOpen={isTermsModalOpen}
          handleTermsModalHide={handleTermsModalHide}
        />
      )}

      {isAbaDisclosureModalOpen && (
        <ABADisclosureModal
          pageType="Signup"
          isAbaDisclosureModalOpen={isAbaDisclosureModalOpen}
          formik={formik}
          handleAbaDiclosureModalHide={handleAbaDiclosureModal}
        />
      )}
    </>
  );
}

export default injectIntl(connect(null, null)(SignupProfile));
