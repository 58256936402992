import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { SELECT_A_PAYMENT_TYPE_BELOW } from "../../../utils/const";

function MakePaymentWizardStepOne({ setGeneralPayment, isGeneralPayment }) {
  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span className="svg-icon svg-icon-5x svg-icon-dark menu-icon mr-1">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Statistics/made-funds.svg"
                )}
              />
            </span>
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center">
              {SELECT_A_PAYMENT_TYPE_BELOW}
            </h5>
            <p className="fs-14 font-weight-light text-dark text-center mt-2">
              If you use ScheduleClosings.com and want to make a payment as part
              of one of your real estate transactions, select
              <b> Schedule Closings Transaction</b> below. Otherwise, continue
              by selecting <b>General Payment</b>.
            </p>
          </div>
          <div className="text-center mt-15">
            <div className="form-group">
              <div className="radio-inline justify-content-center">
                <label className="radio radio-success radio-lg">
                  <input
                    type="radio"
                    value="radio1"
                    checked={isGeneralPayment}
                    onChange={() => {
                      setGeneralPayment(true);
                    }}
                  />
                  <span></span>
                  General Payment
                </label>
                <label className="radio radio-success radio-lg">
                  <input
                    type="radio"
                    value="radio2"
                    name="radios1"
                    checked={!isGeneralPayment}
                    onChange={() => {
                      setGeneralPayment(false);
                    }}
                  />
                  <span></span>
                  Schedule Closings Transaction
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePaymentWizardStepOne;
