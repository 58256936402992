import React, { useEffect, useState } from "react";
// import { Route } from "react-router-dom";
import { Card, Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import RequestPaymentWizardStepOne from "./RequestPaymentWizardComponant/RequestPaymentWizardStepOne";
import RequestPaymentWizardStepTwo from "./RequestPaymentWizardComponant/RequestPaymentWizardStepTwo";
import RequestPaymentWizardStepThree from "./RequestPaymentWizardComponant/RequestPaymentWizardStepThree";
import RequestPaymentWizardStepFour from "./RequestPaymentWizardComponant/RequestPaymentWizardStepFour";
import RequestPaymentWizardStepFive from "./RequestPaymentWizardComponant/RequestPaymentWizardStepFive";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import FundSuccessModal from "../Modal/FundSuccessModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useFormik } from "formik";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import ErrorModal from "../Modal/ErrorModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import {
  addressSCTransactionApi,
  recentHistoryPDFApi,
  requestAPaymentApi,
  singleUserDetailApi,
  validateSCTransactionIdApi,
} from "../../../axios/services/services";
import { isUnauthorized } from "../../utils/utils";
import {
  AmountSchema,
  PaymentVerifyAddressSchema,
  ProfileSchema,
  SCTrasnsactionIdSchema,
} from "../../utils/FormikSchema";
import InfoModal from "../Modal/InfoModal";
import { NumberFormatter } from "../../utils/NumberFormatter";

const initialValues = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "USA",
  zipcode: "",
  cellnumber: "",
  scTransactionId: "",
};

const wizardStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Step-1", "Step-2", "Step-3", "Step-4", "Step-5"];
}

function RequestPayment(props) {
  const [isLoading, setLoading] = useState(false);
  const [isLoadingNextButton, setLoadingNextButton] = useState(false);
  const classes = wizardStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [
    disableAuthorizeAndConfirmButton,
    setDisableAuthorizeAndConfirmButton,
  ] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [isGeneralPayment, setGeneralPayment] = useState(true);
  const [searchUserDetails, setSearchUserDetails] = useState(null);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [makeAPaymentDetails, setMakeAPaymentDetails] = useState({});
  const [paymentNotes, setPaymentNote] = useState("");
  const [paymentSCTransactionType, setPaymentSCTransactionType] = useState("");
  const [addressSCTransaction, setAddressSCTransaction] = useState(null);
  const [isInfoModal, setInfoModal] = useState(false);

  const { contactid, txId } = props.match.params;

  useEffect(() => {
    if (txId) {
      setGeneralPayment(false);
      formik.setFieldValue("scTransactionId", txId);
    }
    if (!contactid) {
      formik.setFieldValue("cellnumber", "");
    }
  }, []);

  useEffect(() => {
    async function getsingleUserDetail() {
      if (!contactid) return;
      const singleUserDetailResponse = await singleUserDetailApi({
        id: contactid,
      });
      if (singleUserDetailResponse.Error) {
        isUnauthorized({ response: singleUserDetailResponse });
        setErrorMessage(singleUserDetailResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setSearchUserDetails(singleUserDetailResponse);
      const { cellnumber } = singleUserDetailResponse;
      if (cellnumber) {
        formik.values.cellnumber = cellnumber;
      }
    }
    getsingleUserDetail();
  }, [contactid]);

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const handleSuccessShow = () => {
    setSuccessModal(true);
  };

  const handleSuccessHide = () => {
    setSuccessModal(false);
  };

  const handleSearchUserDetails = (searchUserDetails) => {
    setSearchUserDetails(searchUserDetails);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingNextButton = () => {
    setLoadingNextButton(true);
  };

  const disableLoadingNextButton = () => {
    setLoadingNextButton(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleInfoShow = () => {
    setInfoModal(true);
  };

  const handleInfoHide = () => {
    setInfoModal(false);
  };

  const hadleWhatisTxId = () => {
    setInfoMessage(
      "Transaction IDs are required to complete Schedule Closings real estate payments. Please visit scheduleclosings.com and sign into your account to find your transaction ID."
    );
    handleInfoShow();
  };

  const handleIdontHaveTxId = () => {
    setInfoMessage(
      "This type of transaction is only available to Schedule Closings users. If you don't have a transaction ID please go back and choose General Payment to proceed."
    );
    handleInfoShow();
  };

  function getStepContent(stepIndex, formik) {
    switch (stepIndex) {
      case 0:
        return (
          <RequestPaymentWizardStepOne
            isGeneralPayment={isGeneralPayment}
            setGeneralPayment={setGeneralPayment}
          />
        );
      case 1:
        return (
          <RequestPaymentWizardStepTwo
            formik={formik}
            hadleWhatisTxId={hadleWhatisTxId}
            handleIdontHaveTxId={handleIdontHaveTxId}
          />
        );
      case 2:
        return <RequestPaymentWizardStepThree formik={formik} />;
      case 3:
        return (
          <RequestPaymentWizardStepFour
            formik={formik}
            searchUserDetails={searchUserDetails}
            setSearchUserDetails={handleSearchUserDetails}
          />
        );
      case 4:
        return <RequestPaymentWizardStepFive formik={formik} />;
      default:
        return (
          <RequestPaymentWizardStepOne
            isGeneralPayment={isGeneralPayment}
            setGeneralPayment={setGeneralPayment}
          />
        );
    }
  }

  const handleNext = async () => {
    if (isGeneralPayment) {
      if (activeStep === 0) {
        setActiveStep(3);
      }
      if (activeStep === 3) {
        const { cellnumber } = formik.values;
        if (cellnumber.trim().length !== 14) {
          return;
        }
        const { cellnumber: userCellnumber } = userInstance;
        if (cellnumber === userCellnumber) {
          setErrorMessage("You can't make payment with your own phone number");
          handleErrorModalShow();
          return;
        } else {
          if (cellnumber) {
            setActiveStep(4);
          }
        }
      }
      if (activeStep === 4) {
        const { amount } = formik.values;

        if (formik.errors.amount) {
          return;
        }

        if (amount) {
          handleConfirmShow();
        }
      }
    } else {
      // SC Steps

      //Step 1 -  Enter Transaction Id
      if (activeStep === 1) {
        if (formik.errors.scTransactionId) {
          return;
        }
        const validateSCTxId = await validateSCTransactionId();
        if (validateSCTxId.exists) {
          getAddressSCTransaction();
        } else {
          setErrorMessage(
            "You have entered an invalid transaction ID. Please verify the correct ID and try again."
          );
          handleErrorModalShow();
          return;
        }
      }

      //Step 2 - Proprty Address
      if (activeStep === 2) {
        if (addressSCTransaction === null) {
          setErrorMessage(
            "Property address should not be blank, Try again later!"
          );
          handleErrorModalShow();
          return;
        }
      }

      // Step 3 - Recipient
      if (activeStep === 3) {
        const { cellnumber } = formik.values;
        if (cellnumber.trim().length !== 14) {
          return;
        }
        const { cellnumber: userCellnumber } = userInstance;
        if (cellnumber === userCellnumber) {
          setErrorMessage("You can't make payment with your own phone number");
          handleErrorModalShow();
          return;
        }
      }

      // Step 4 - Amount & Bank
      if (activeStep === 4) {
        const { amount } = formik.values;

        if (formik.errors.amount) {
          return;
        }

        if (!amount) {
          setErrorMessage(
            "You must have to enter amount before submit payment"
          );
          handleErrorModalShow();
          return;
        }

        handleConfirmShow();
        return;
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function handleBack() {
    if (isGeneralPayment) {
      if (activeStep === 3) {
        setActiveStep(0);
      }
      if (activeStep === 4) {
        setActiveStep(3);
      }
    } else {
      if (activeStep === 2) {
        formik.setFieldValue("address1", "");
        formik.setFieldValue("address2", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("country", "");
        formik.setFieldValue("zipcode", "");
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  function handleActiveStepReset() {
    setActiveStep(0);
  }

  const handleConfirmCancelButton = () => {
    setDisableAuthorizeAndConfirmButton(false);
    setGeneralPayment(true);
    setSearchUserDetails(null);
    formik.values.amount = "";
    formik.values.cellnumber = "";
    formik.values.scTransactionId = "";
    formik.values.address1 = "";
    formik.values.address2 = "";
    formik.values.city = "";
    formik.values.state = "";
    formik.values.country = "";
    formik.values.zipcode = "";
    setPaymentSCTransactionType("");
    handleConfirmHide();
    handleActiveStepReset();
    props.history.push("/request-payment");
  };

  const validationSchemaList = [
    null,
    SCTrasnsactionIdSchema,
    PaymentVerifyAddressSchema,
    ProfileSchema,
    AmountSchema,
  ];

  const validateSCTransactionId = async () => {
    return new Promise(async (resolve, reject) => {
      const { scTransactionId } = formik.values;
      const scTransactionIdObj = { txId: scTransactionId };
      enableLoadingNextButton();
      const validateSCTransactionIdReponse = await validateSCTransactionIdApi(
        scTransactionIdObj
      );
      disableLoadingNextButton();
      const { Error } = validateSCTransactionIdReponse || {};
      const { message } = Error || {};
      if (Error) {
        isUnauthorized({ response: validateSCTransactionIdReponse });
        setErrorMessage(message);
        handleErrorModalShow();
        resolve(Error);
      } else {
        // getAddressSCTransaction();
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        resolve(validateSCTransactionIdReponse);
      }
    });
  };

  const getAddressSCTransaction = async () => {
    const { scTransactionId } = formik.values;
    const scTransactionAddressObj = { txId: scTransactionId };
    enableLoadingNextButton();
    const addressSCTransactionReponse = await addressSCTransactionApi(
      scTransactionAddressObj
    );
    disableLoadingNextButton();
    if (addressSCTransactionReponse.Error) {
      isUnauthorized({ response: addressSCTransactionReponse });
      setErrorMessage(addressSCTransactionReponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setAddressSCTransaction(addressSCTransactionReponse);
    setSCTransactionDetailsToInputBoxes(addressSCTransactionReponse);
  };

  const setSCTransactionDetailsToInputBoxes = (addressSCTransactionReponse) => {
    const { address } = addressSCTransactionReponse || {};
    const {
      propertyStreetAddress = "",
      propertyStreetAddressSecond = "",
      propertyCity = "",
      propertyState = "",
      propertyZipCode = "",
      country = "",
    } = address || {};
    formik.setFieldValue("address1", propertyStreetAddress);
    formik.setFieldValue("address2", propertyStreetAddressSecond);
    formik.setFieldValue("city", propertyCity);
    formik.setFieldValue("state", propertyState);
    formik.setFieldValue("country", country);
    formik.setFieldValue("zipcode", propertyZipCode);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaList[activeStep],
    onSubmit: (values, { setStatus, setSubmitting }) => {
      // enableLoading();
      // setTimeout(() => {
      //   setStatus();
      //   setSubmitting(false);
      //   disableLoading();
      // }, 1000);
    },
  });

  const handleRequestAPayment = async () => {
    if (!isGeneralPayment && paymentSCTransactionType.trim().length === 0) {
      setErrorMessage("Type should be required");
      handleErrorModalShow();
      return;
    }

    if (!isGeneralPayment && paymentNotes.trim().length === 0) {
      setErrorMessage("Notes should not be blank");
      handleErrorModalShow();
      return;
    }

    try {
      const {
        amount,
        cellnumber,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        scTransactionId,
      } = formik.values;
      const { id: userId } = userInstance;
      const { id: recipientUserId } = searchUserDetails || {};
      setDisableAuthorizeAndConfirmButton(true);
      enableLoading();
      const requestPaymentObj = {
        amount,
        cellnumber: cellnumber,
        senderId: recipientUserId,
        receiverId: userId,
        transactionTypeId: 2, // 1 = General, 2 = Request Payment, 3 = Make Payment
        transactionStatusId: 1, // 1 = Pending, 2 = Rejected, 3 = Accepted, 4 = Completed, 5 = Cancelled, 6 = Declined, 7 = Failed, 8 = Awaiting Processing, 9 = Being Processed, 10 = Hold, 11 = Returned / Refunded, 12 = Returned Ineligible, 13 = Refund Processing
        transactionModeId: 2, // 1 = e-Check, 2 = ACH, 3 = CC, 4 = Wire Transfer, 5 = General
        ...(!isGeneralPayment && { sc_transactionId: scTransactionId }),
        ...(!isGeneralPayment && { address_1: address1 }),
        ...(!isGeneralPayment && { address_2: address2 }),
        ...(!isGeneralPayment && { city_name: city }),
        ...(!isGeneralPayment && { state_name: state }),
        ...(!isGeneralPayment && { country_name: country }),
        ...(!isGeneralPayment && { zip_code: zipcode }),
        ...(!isGeneralPayment && { sc_type: paymentSCTransactionType }), // "Due Dilligence", "Earnest Money", "Other"
        ...(paymentNotes && { other: paymentNotes }),
        created_by: userId,
      };
      const requestAPaymentResponse = await requestAPaymentApi(
        requestPaymentObj
      );
      setDisableAuthorizeAndConfirmButton(false);
      disableLoading();
      if (requestAPaymentResponse.Error) {
        setErrorMessage(requestAPaymentResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      handleSuccessShow();
      handleConfirmHide();
      setMakeAPaymentDetails(requestAPaymentResponse);
    } catch (error) {
      // logger("handleMakeAPayment", error);
      setDisableAuthorizeAndConfirmButton(false);
      disableLoading();
    }
  };

  const onClickOfDownloadButton = async () => {
    enableLoading();
    const { id } = makeAPaymentDetails;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`,
    });
    disableLoading();
    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  const renderCellnumberOrFirstname = () => {
    const { firstname, lastname } = searchUserDetails || {};
    const { cellnumber } = formik.values;
    if (firstname && lastname) {
      return "@" + firstname + " " + lastname;
    } else {
      return cellnumber;
    }
  };

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">
              Request a Payment
            </span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div className="col-md-10 offset-md-1">
            <div className="d-flex justify-content-center">
              <div className={classes.root}>
                <Stepper
                  className="d-none"
                  activeStep={activeStep}
                  alternativeLabel
                >
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>
                  {activeStep === getSteps().length ? (
                    <div>
                      <Typography className={classes.instructions}>
                        All steps completed
                      </Typography>
                      <Button onClick={handleActiveStepReset}>Reset</Button>
                    </div>
                  ) : (
                    <div>
                      <form
                        // onSubmit={formik.handleSubmit}
                        onKeyDown={(keyEvent) => {
                          if (keyEvent.keyCode === 13) {
                            keyEvent.preventDefault();
                          }
                        }}
                        className="form fv-plugins-bootstrap fv-plugins-framework"
                        autocomplete="off"
                      >
                        {getStepContent(activeStep, formik)}
                        <div>
                          <div className="d-flex justify-content-between mt-15 mb-3">
                            {activeStep !== 0 && (
                              <Button
                                disabled={activeStep === 0}
                                variant="btn btn-light-brand btn-lg"
                                onClick={handleBack}
                                className={classes.button}
                              >
                                <span className="svg-icon menu-icon ml-0 mr-2">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Arrow-left.svg"
                                    )}
                                  />
                                </span>{" "}
                                Previous
                              </Button>
                            )}
                            {activeStep === 0 && <div></div>}
                            <Button
                              variant="btn btn-brand btn-lg"
                              onClick={handleNext}
                              className={classes.button}
                            >
                              {activeStep === getSteps().length - 1
                                ? "Submit"
                                : "Next"}
                              <span className="svg-icon menu-icon ml-2 mr-0">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Navigation/Arrow-right.svg"
                                  )}
                                />
                              </span>
                              {isLoadingNextButton && (
                                <span
                                  style={{
                                    top: "10px",
                                    right: "0px",
                                    float: "right",
                                  }}
                                  className="mr-3 ml-3 spinner spinner-white"
                                ></span>
                              )}
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
      <Modal
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            <div className="text-center mb-12">
              <h1 className="font-weight-bolder text-dark mb-6">
                Review and Authorize Request
              </h1>
              <p className="fs-18 font-weight-normal text-dark">
                {`Request `}
                <NumberFormatter amount={formik.values.amount} />
                {` from ${renderCellnumberOrFirstname()}`}
              </p>
            </div>
          </div>
          <div className="row">
            {!isGeneralPayment && (
              <div className="col-md-6 mb-5">
                <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                  SCHEDULE CLOSINGS TRANS. ID:
                </h6>
                <p className="text-dark fs-14 font-weight-bold text-left">
                  {formik.values.scTransactionId}
                </p>
              </div>
            )}

            {!isGeneralPayment && (
              <div className="col-md-6 mb-5">
                <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                  TYPE: *
                </h6>
                <select
                  className="form-control h-auto py-5 px-6"
                  name="state"
                  onChange={(event) => {
                    setPaymentSCTransactionType(event.target.value);
                  }}
                >
                  <option value="">Select Type</option>
                  {/* <option value="Due Dilligence">Due Dilligence</option> */}
                  <option value="Earnest Money">Earnest Money</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            )}

            <div className="col-md-12 mb-5">
              <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                Send a Note to the Recipient (Optional)
              </h6>
              <p className="text-dark fs-14 font-weight-bold text-left">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control h-auto py-5 px-6"
                    placeholder="Note"
                    name="notes"
                    autoFocus
                    maxLength="512"
                    onInput={(event) => {
                      setPaymentNote(event.target.value);
                    }}
                  />
                </div>
              </p>
            </div>
          </div>
          <div className="modal-button-wrapper mb-3 mt-7">
            <button
              className="btn btn-light-warning font-weight-bold mr-2"
              onClick={handleConfirmHide}
              disabled={isLoading}
            >
              Edit Info
            </button>
            <button
              className="btn btn-light-primary font-weight-bold mr-2"
              onClick={handleConfirmCancelButton}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <Button
            variant="btn-lg btn-success btn-brand mt-5 py-4 mb-5 w-100"
            onClick={handleRequestAPayment}
            disabled={isLoading}
          >
            Authorize and Confirm
            {isLoading && (
              <span className=" mr-3 ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Body>
      </Modal>

      {/* conformation modal */}
      <FundSuccessModal
        isStatic={true}
        handleSuccessHide={handleSuccessHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        title={"Request Successfully Sent!"}
        message={`PENDING TRANSACTION #: ${makeAPaymentDetails.id}`}
        isSuccessModal={isSuccessModal}
        onClickOfLink={onClickOfDownloadButton}
        linkUrl={"Download Receipt"}
        buttonLabel={"Go to Dashboard"}
        onClickOfSaveButton={() => {
          props.history.push("/dashboard");
        }}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <InfoModal
        message={infoMessage}
        isInfoModal={isInfoModal}
        handleInfoHide={handleInfoHide}
        infoIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </div>
        }
        buttonLabel={"Close"}
        onClick={handleInfoHide}
      />
    </>
  );
}

export default injectIntl(connect(null, null)(RequestPayment));
