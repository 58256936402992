import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import {
  cancelTransactionApi,
  getPaymentTransactionListApi,
  recentHistoryPDFApi,
  recentHistoryPrintApi
} from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { isUnauthorized } from "../../utils/utils";
import { HistoryCancelTransactionDialog } from "../History/history-cancel-transaction-dialog/HistoryCancelTransactionDialog";
import ErrorModal from "../Modal/ErrorModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { PaymentHistoryReceiptModal } from "./payment-history-receipt-modal/PaymentHistoryReceiptModal";
import { PaymentHistoryCard } from "./PaymentHistoryCard";
import { PaymentHistoryUIProvider } from "./PaymentHistoryUIContext";
import { initialFilter } from "./PaymentHistoryUIHelpers";

export function PaymentHistoryPage({ match }) {
  const [isLoadingForDownload, setLoadingForDownload] = useState(false);
  const [isLoadingForPrint, setLoadingForPrint] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isCancelTransactionModal, setCancelTransactionModal] = useState(false);
  const [
    isLoadingForCancelTransaction,
    setLoadingForCancelTransaction
  ] = useState(false);
  const [updateHistoryList, setUpdateHistoryList] = useState(0);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [paymentHistoryList, setPaymentHistoryList] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [
    paymentHistoryListTotalCount,
    setPaymentHistoryListTotalCount
  ] = useState(0);
  const [queryParams, setQueryParamsBase] = useState(initialFilter);

  useEffect(() => {
    const id = match.params.id;
    queryParams.filter.opponentUserId = id;
    setQueryParamsBase(queryParams);
  }, [match]);

  useEffect(() => {
    async function getPaymentHistoryList() {
      enableLoading();
      const paymentHistoryListResponse = await getPaymentTransactionListApi({
        queryParams
      });
      const { Error, count, data } = paymentHistoryListResponse || {};
      disableLoading();
      if (Error) {
        isUnauthorized({ response: paymentHistoryListResponse });
        setErrorMessage(Error.message);
        handleErrorModalShow();
        return;
      }
      setPaymentHistoryListTotalCount(count ? count : 0);
      setPaymentHistoryList(data ? data : []);
    }
    getPaymentHistoryList();
  }, [queryParams, updateHistoryList]);

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };
  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const paymentHistoryUIEvents = {
    openReceiptDialog: (row) => {
      setData(row);
      handleConfirmShow();
    },
    openCancelTransactionDialog: (row) => {
      setData(row);
      handleCancelTransactionShow();
    }
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingForDownload = () => {
    setLoadingForDownload(true);
  };

  const disableLoadingForDownload = () => {
    setLoadingForDownload(false);
  };

  const enablePrintButtonLoading = () => {
    setLoadingForPrint(true);
  };

  const disablePrintButtonLoading = () => {
    setLoadingForPrint(false);
  };

  const enableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(true);
  };

  const disableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleCancelTransactionShow = () => {
    setCancelTransactionModal(true);
  };

  const handleCancelTransactionHide = () => {
    setCancelTransactionModal(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const onClickOfDownloadButton = async () => {
    enableLoadingForDownload();
    const { id } = data;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`
    });
    disableLoadingForDownload();
    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  const onClickOfPrintButton = async () => {
    enablePrintButtonLoading();
    const { id } = data;
    const recentHistoryPrintResponse = await recentHistoryPrintApi({
      id
    });
    disablePrintButtonLoading();
    if (recentHistoryPrintResponse.Error) {
      isUnauthorized({ response: recentHistoryPrintResponse });
      setErrorMessage(recentHistoryPrintResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    enablePrintButtonLoading();
    setPdfUrl(recentHistoryPrintResponse.html);
    setTimeout(() => {
      disablePrintButtonLoading();
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 500);
  };

  const handleCancelTransaction = async () => {
    enableLoadingCancelTransaction();
    const { id } = data;
    const cancelTransactionResponse = await cancelTransactionApi({
      id
    });
    disableLoadingCancelTransaction();
    if (cancelTransactionResponse.Error) {
      isUnauthorized({ response: cancelTransactionResponse });
      setErrorMessage(cancelTransactionResponse.Error.message);
      handleErrorModalShow();
    } else {
      setUpdateHistoryList(Math.random());
      handleSuccessModalShow();
      setSuccessMessage("Transaction cancelled successfully");
      handleCancelTransactionHide();
    }
  };

  return (
    <>
      <PaymentHistoryUIProvider
        paymentHistoryUIEvents={paymentHistoryUIEvents}
        queryParams={queryParams}
        setQueryParamsBase={setQueryParamsBase}
      >
        <PaymentHistoryCard
          isLoading={isLoading}
          paymentHistoryList={paymentHistoryList}
          paymentHistoryListTotalCount={paymentHistoryListTotalCount}
        />
      </PaymentHistoryUIProvider>

      {isSuccessModalOpen && (
        <UpdatedSuccessfulModal
          message={successMessage}
          UpdatedSuccessfulModalShow={isSuccessModalOpen}
          UpdatedSuccessfulModalHide={handleSuccessModalHide}
          successIcon={
            <div className="success-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>
            </div>
          }
        />
      )}

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className="danger-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      )}

      {isCancelTransactionModal && (
        <HistoryCancelTransactionDialog
          isLoadingForCancelTransaction={isLoadingForCancelTransaction}
          isCancelTransactionShow={handleCancelTransactionShow}
          onHideCancelTransaction={handleCancelTransactionHide}
          onClickOfCancelTransaction={handleCancelTransaction}
        />
      )}

      {isConfirmModal && (
        <PaymentHistoryReceiptModal
          data={data}
          handleConfirmHide={handleConfirmHide}
          isConfirmModal={isConfirmModal}
          onClickOfDownloadButton={onClickOfDownloadButton}
          onClickOfPrintButton={onClickOfPrintButton}
          isLoadingForDownload={isLoadingForDownload}
          isLoadingForPrint={isLoadingForPrint}
        />
      )}
      <iframe
        className="d-none"
        srcDoc={pdfUrl}
        name="myframe"
        id="myframe"
        title="myframe"
      ></iframe>
    </>
  );
}
