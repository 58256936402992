import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

function MakePaymentWizardStepFive({ formik }) {
  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span className="svg-icon svg-icon-4x svg-icon-dark menu-icon mr-1">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/SendMoney.svg")}
              />
            </span>{" "}
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center mb-0">
              How much money are you Requesting?
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12 mt-10">
              <div className="form-group mb-4 fv-plugins-icon-container input-euro left">
                <input
                  type="number"
                  autocomplete="off"
                  className="form-control h-auto py-5 px-10"
                  placeholder="Amount*"
                  autoFocus
                  name="amount"
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.amount}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePaymentWizardStepFive;
