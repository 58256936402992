import React from "react";
import { defaultTheme } from "react-select";
const { colors } = defaultTheme;

export const MenuMaster = (props) => {
  const shadow = "hsla(218, 50%, 10%, 0.1)";
  return <div {...props} />;
};

export const BlanketMaster = (props) => <div {...props} />;

export const DropdownMaster = ({
  children,
  isOpen,
  target,
  onClose,
  isDisabled = false,
  ref,
}) => (
  <div
    ref={ref}
    aria-disabled={isDisabled}
    className={isDisabled ? "is-disabled" : ""}
  >
    {target}
    {isOpen ? <MenuMaster>{children}</MenuMaster> : null}
    {isOpen ? <BlanketMaster onClick={onClose} /> : null}
  </div>
);

export const ChevronDownMaster = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    stroke="currentColor"
    strokeWidth="2"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="css-i6dzq1"
  >
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

export const SvgMaster = (p) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    focusable="false"
    role="presentation"
    {...p}
  />
);

export const DropdownIndicatorMaster = () => (
  <div css={{ color: colors.neutral20, height: 24, width: 32 }}>
    <SvgMaster>
      <path
        d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </SvgMaster>
  </div>
);
