// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider
} from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage
} from "../../../../_metronic/_helpers";
import { Pagination } from "../../../../_metronic/_partials/controls";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import { usePaymentHistoryUIContext } from "../PaymentHistoryUIContext";
import * as uiHelpers from "../PaymentHistoryUIHelpers";
import * as columnFormatters from "./column-formatters";

export function PaymentHistoryTable({
  isLoading,
  paymentHistoryList,
  paymentHistoryListTotalCount
}) {
  const [userInstance] = useLocalStorage("userInstance", "");
  const history = useHistory();
  const paymentHistoryUIContext = usePaymentHistoryUIContext();
  const paymentHistoryUIProps = useMemo(() => {
    return {
      ids: paymentHistoryUIContext.ids,
      setIds: paymentHistoryUIContext.setIds,
      queryParams: paymentHistoryUIContext.queryParams,
      setQueryParams: paymentHistoryUIContext.setQueryParams,
      openReceiptDialog: paymentHistoryUIContext.openReceiptDialog,
      openCancelTransactionDialog:
        paymentHistoryUIContext.openCancelTransactionDialog
    };
  }, [paymentHistoryUIContext]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "NAME",
      formatter: columnFormatters.ReciptNameColumnFormatter,
      formatExtraData: {
        userId: userInstance.id
      },
      style: {
        minWidth: "280px"
      },
      classes: "notresponsive"
    },
    {
      dataField: "transaction_status",
      text: "STATUS",
      formatter: columnFormatters.StatusTypeFormatter,
      style: {
        minWidth: "150px"
      },
      attrs: {
        "data-label": "STATUS"
      }
    },
    {
      dataField: "amount",
      text: "AMOUNT",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.AmountColumnFormatter,
      attrs: {
        "data-label": "AMOUNT"
      }
    },
    {
      dataField: "commission",
      text: "TRANSACTION FEE",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.CommissionColumnFormatter,
      formatExtraData: {
        userId: userInstance.id
      },
      attrs: {
        "data-label": "TRANSACTION FEE"
      }
    },
    {
      dataField: "totalAmount",
      text: "TOTAL AMOUNT",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.TotalAmountColumnFormatter,
      formatExtraData: {
        userId: userInstance.id
      },
      attrs: {
        "data-label": "TOTAL AMOUNT"
      }
    },
    {
      dataField: "deposite_type",
      text: "TRANSACTION TYPE	",
      formatter: columnFormatters.DepositeTypeColumnFormatter,
      attrs: {
        "data-label": "TRANSACTION TYPE"
      }
    },
    {
      dataField: "confirmation_id",
      text: "CONFIRMATION #",
      // sort: true,
      // sortCaret: sortCaret,
      formatter: columnFormatters.ConfirmationIdColumnFormatter,
      attrs: {
        "data-label": "CONFIRMATION #"
      }
    },
    {
      dataField: "createdAt",
      text: "DATE & TIME",
      formatter: columnFormatters.DateTimeColumnFormatter,
      style: {
        minWidth: "170px"
      },
      attrs: {
        "data-label": "DATE & TIME"
      }
    },
    {
      dataField: "expecteddeliverydate",
      text: "EXPECTED DELIVERY DATE",
      formatter: columnFormatters.ExpectedDeliveryDateColumnFormatter,
      style: {
        minWidth: "170px"
      },
      attrs: {
        "data-label": "EXPECTED DELIVERY DATE"
      }
    },
    {
      dataField: "transaction_id",
      text: "PAYMENT DETAILSss",
      formatter: columnFormatters.PaymentDetailsColumnFormatter,
      formatExtraData: {
        userId: userInstance.id
      },
      style: {
        minWidth: "170px"
      },
      attrs: {
        "data-label": "PAYMENT DETAILS"
      }
    },

    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openReceiptDialog: paymentHistoryUIProps.openReceiptDialog,
        openCancelTransactionDialog:
          paymentHistoryUIProps.openCancelTransactionDialog,
        userId: userInstance.id,
        history: history
      },
      classes: "text-right pr-0 notresponsive",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px"
      }
    }
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: paymentHistoryListTotalCount ? paymentHistoryListTotalCount : 0,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: paymentHistoryUIProps.queryParams.pageSize,
    onPageChange: (page) => {
      if (page === 0) return;
    }
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination isLoading={isLoading} paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-head-bg table-borderless table-vertical-center history-table"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={paymentHistoryList ? paymentHistoryList : []}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  paymentHistoryUIProps.setQueryParams
                )}
                {...paginationTableProps}
              ></BootstrapTable>
              <NoRecordsFoundMessage entities={paymentHistoryList} />
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
