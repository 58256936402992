/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
export const ConfirmationIdColumnFormatter = (cellContent, row, rowIndex) => {
  let { confirmation_id } = row;
  if (!confirmation_id) confirmation_id = "-";
  return (
    <>
      <span className="text-dark-75 d-block">{confirmation_id}</span>
      <> </>
    </>
  );
};
