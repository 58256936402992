import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link, useHistory } from "react-router-dom";
import SubscribeFrom from "react-mailchimp-subscribe";
import "../../../../_metronic/_assets/sass/components/landing-page.scss";

const LandingPage = () => {
  const history = useHistory();
  const Form = () => (
    <SubscribeFrom
      url={
        "https://payxtreme.us14.list-manage.com/subscribe/post?u=4fd04e69b9e66f31c3cfc3646&id=da07d09ea9"
      }
    />
  );

  return (
    <div className="landingpage">
      <header className="header-main">
        <div className="container">
          <div className="header">
            {/* <!-- <div className="navicon"><i className="fa fa-bars"></i></div> --> */}
            <div className="logo">
              <img
                src={toAbsoluteUrl("/media/logos/PayXtreme.png")}
                alt="PayXtreme Logo"
              />
            </div>
            {/* <!-- <a className="btn log-in" href="#">Log In</a> --> */}
            <div className="support">
              <nav className="navbar">
                <ul
                  className="p-0 m-0"
                  style={{ padding: "0px", margin: "0px" }}
                >
                  <li className="nav-item">
                    <Link className="footer-link" to="/public/faqs">
                      FAQs
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="footer-link" to="/public/support">
                      Support
                    </Link>
                  </li>
                </ul>
              </nav>
              <button
                className="font-weight-bold ml-2 text-white font-weight-bolder btn btn-link"
                onClick={() => {
                  history.push("/auth")
                }}
              >
                Log In
              </button>
            </div>
          </div>
        </div>
      </header>
      <main>
        <section className="banner-main">
          <div className="container">
            <div className="banner">
              <div className="d-flex flex-wrap justify-content-center justify-content-md-between flex-md-row-reverse">
                <img
                  src={toAbsoluteUrl("/media/landingpageimages/operation.png")}
                  alt=""
                  style={{ marginTop: "-20px" }}
                />
                <div>
                  <h2>More control.</h2>
                  <h2>More possibilities.</h2>
                  <h2>Get more with PayXtreme.</h2>
                </div>
              </div>
              <p className="mt-5">
                PayXtreme is a new, secure way to send and request money. Easily
                complete digital transactions with friends, family, or
                businesses. All you need is the phone number of who you're
                paying and you can leave the rest to us.
              </p>
            </div>
          </div>
        </section>
        <section className="payxtreme-main">
          <div className="container">
            <h2>WHY PAYXTREME?</h2>
            <div className="row align-items-center">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="payxtreme">
                  <img
                    src={toAbsoluteUrl(
                      "/media/landingpageimages/secure-payment.png"
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="payxtreme">
                  <h3>Transparency and Control</h3>
                  <p>
                    Say goodbye to unauthorized payments. Once money passes our
                    verification process, we notify the recipient and give them
                    the ultimate choice to Accept or Reject the funds. This puts
                    money management directly in the hands of our users.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center flex-md-row-reverse">
              <div className="col-sm-12 col-md-4 col-lg-4">
                <div className="payxtreme">
                  <img
                    src={toAbsoluteUrl(
                      "/media/landingpageimages/direct-to-bank.png"
                    )}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-8 col-lg-8">
                <div className="payxtreme">
                  <h3>Direct to Your Bank</h3>
                  <p>
                    In payment apps, digital wallet complaints have been on the
                    rise since 2017. With PayXtreme, our users don't have to
                    worry about a digital wallet or funds sitting in their
                    PayXtreme account. Whether a payment is made via credit
                    card, debit card, ACH, or check images, the funds will be
                    directly deposited into the recipient's bank account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="payment-main">
          <div className="container">
            <h2>GET STARTED & MAKE A PAYMENT</h2>
            <div className="row align-items-center">
              {/* <!-- <div className="col-sm-12 col-md-1">
                        <div className="number"><span><img src="images/number_1.png" alt=""/></span></div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="icon"><img src="images/account.png" alt=""></div>
                    </div> --> */}
              <div className="col-sm-12 col-md-12">
                <div className="description">
                  <h3>Create an Account</h3>
                  <p>
                    Select a Personal or Business profile, enter your
                    information, and then connect at least 1 bank account to
                    your profile. We offer Plaid as a simple and safe way to
                    link your bank accounts.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/* <!-- <div className="col-sm-12 col-md-1">
                        <div className="number"><span><img src="images/number_2.png" alt=""></span></div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="icon"><img src="images/phone.png" alt=""></div>
                    </div> --> */}
              <div className="col-sm-12 col-md-12">
                <div className="description">
                  <h3>Enter a Phone Number</h3>
                  <p>
                    Once you choose to make a payment, enter the phone number of
                    the recipient. This ensures the transaction continues with
                    exactly who you intended.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/* <!-- <div className="col-sm-12 col-md-1">
                        <div className="number"><span><img src="images/number_3.png" alt=""></span></div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="icon"><img src="images/amount.png" alt=""></div>
                    </div> --> */}
              <div className="col-sm-12 col-md-12">
                <div className="description">
                  <h3>Pick Amount & Method</h3>
                  <p>
                    Enter the dollar amount of the payment. Then, select the
                    method of payment. We offer ACH, Check Images, and
                    Debit/Credit Card payments.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/* <!-- <div className="col-sm-12 col-md-1">
                        <div className="number"><span><img src="images/number_4.png" alt=""></span></div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="icon"><img src="images/lock.png" alt=""></div>
                    </div> --> */}
              <div className="col-sm-12 col-md-12">
                <div className="description">
                  <h3>Our Verification Process</h3>
                  <p>
                    Our goal is to make your money move quickly, but most
                    importantly, safely. Before the money reaches the recipient,
                    we verify that the funds and payment method are good and
                    sufficient.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              {/* <!-- <div className="col-sm-12 col-md-1">
                        <div className="number"><span><img src="images/number_5.png" alt=""></span></div>
                    </div>
                    <div className="col-sm-12 col-md-3">
                        <div className="icon"><img src="images/control.png" alt=""></div>
                    </div> --> */}
              <div className="col-sm-12 col-md-12">
                <div className="description">
                  <h3>You're in Control</h3>
                  <p>
                    Upon successful verification, we notify the recipient and
                    give them the ultimate choice to Accept or Reject the money.
                    Choosing to Accept sends the money through to their bank
                    account, while Rejecting sends the money back to the sender.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="footer-main">
        <div className="container">
          <div className="footer">
            {/* <h2>PayXtreme is Coming Soon</h2> */}
            <p>Subscribe to our newsletter to stay updated!</p>
            <Form />
            <div className="copyright">
              ©2022 PayXtreme, LLC. All Rights Reserved
              <Link
                to="/public/terms"
                className="font-weight-bold text-white font-weight-bolder"
              >
                Legal
              </Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
