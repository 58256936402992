/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NumberFormatter } from "../../../../utils/NumberFormatter";
export const TotalAmountColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { userId }
) => {
  const { amount, commission, senderId, transactionStatusId } = row || {};

  const renderTotalAmount = () => {
    if (transactionStatusId === 2) {
      return <>-</>;
    }

    const totalAmount =
      userId === senderId
        ? parseFloat(commission) + parseFloat(amount)
        : amount;

    return <NumberFormatter amount={totalAmount} />;
  };

  return (
    <>
      <span className="text-dark-75 d-block">{renderTotalAmount()}</span>
      <> </>
    </>
  );
};
