import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import {
  checkUserExistOrNotApi,
  loginApi,
  loginTwoFactorVerificationApi,
  signUpApi,
  resendtwofactorOtpApi,
  twoFactorAuthApi,
} from "../../../../axios/services/services";
import { useLocalStorage } from "../../../utils/useLocalStorage";
import ErrorModal from "../../Modal/ErrorModal";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import localStorage from "redux-persist/es/storage";
import LoginTwoFactorVerificationModal from "../../Modal/LoginTwoFactorVerificationModal";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { logger } from "../../../utils/Debug";
import LoginWithFBSignupModal from "../../Modal/LoginWithFBSignupModal";
import {
  LoginSchema,
  LoginWithFBSchema,
  LoginWithGoogleSchema,
} from "../../../utils/FormikSchema";
import LoginWithGoogleSignupModal from "../../Modal/LoginWithGoogleSignupModal";
import ABADisclosureModal from "../../Modal/ABADisclosureModal";
import {
  FACEBOOK_LOGIN_UNDERSCORE,
  GOOGLE_LOGIN_UNDERSCORE,
} from "../../../utils/const";

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
  cellnumber: "",
};

const twoFactorInitialValues = {
  twofactorauthotp: "",
};

const initialValuesLoginWithFB = {
  cellnumber: "",
  username: "",
  email: "",
};

const initialValuesLoginWithGoogle = {
  cellnumber: "",
  username: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const [isLoadingLoginWithFB, setLoadingLoginWithFB] = useState(false);
  const [
    isLoadingLoginWithFBMainButton,
    setLoadingLoginWithFBMainButton,
  ] = useState(false);
  const [
    isLoadingLoginWithGoogleMainButton,
    setLoadingLoginWithGoogleMainButton,
  ] = useState(false);
  const [isLoadingLoginWithGoogle, setLoadingLoginWithGoogle] = useState(false);
  const [isLoginWithFB, setLoginWithFB] = useState(false);
  const [isLoginWithGoogle, setLoginWithGoogle] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [
    isTwoFactorVerificationModalShow,
    setTwoFactorVerificationModal,
  ] = useState(false);
  const [getLoginResponse, setLoginResponse] = useState(null);
  const [loadingTwoFactor, setLoadingTwoFactor] = useState(false);
  const [loginWithFbDetails, setLoginWithFbDetails] = useState(null);
  const [loginWithGoogleDetails, setLoginWithGoogleDetails] = useState(null);
  const [isAbaDisclosureModalOpen, setAbaDiclosureModalOpen] = useState(false);
  const [
    disableEmailFieldForLoginWithFB,
    setDisableEmailFieldForLoginWithFB,
  ] = useState(false);

  const [signupType, setSignupType] = useState("Login");

  const twoFactorVerificationSchema = Yup.object().shape({
    remember_me: Yup.bool(),
    twofactorauthotp: Yup.string()
      .min(6, "Minimum 6 digits")
      .max(6, "Maximum 6 digits")
      .required("Required Field"),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingLoginWithFB = () => {
    setLoadingLoginWithFB(true);
  };

  const disableLoadingLoginWithFB = () => {
    setLoadingLoginWithFB(false);
  };

  const enableLoadingLoginWithFBMainButton = () => {
    setLoadingLoginWithFBMainButton(true);
  };

  const disableLoadingLoginWithFBMainButton = () => {
    setLoadingLoginWithFBMainButton(false);
  };

  const enableLoadingLoginWithGoogleMainButton = () => {
    setLoadingLoginWithGoogleMainButton(true);
  };

  const disableLoadingLoginWithGoogleMainButton = () => {
    setLoadingLoginWithGoogleMainButton(false);
  };

  const enableLoadingLoginWithGoogle = () => {
    setLoadingLoginWithGoogle(true);
  };

  const disableLoadingLoginWithGoogle = () => {
    setLoadingLoginWithGoogle(false);
  };

  const enableTwoFactorLoading = () => {
    setLoadingTwoFactor(true);
  };

  const disableTwoFactorLoading = () => {
    setLoadingTwoFactor(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleLoginWithFBShow = () => {
    setLoginWithFB(true);
  };

  const handleLoginWithFBHide = () => {
    setLoginWithFB(false);
  };

  const handleLoginWithGoogleShow = () => {
    setLoginWithGoogle(true);
  };

  const handleLoginWithGoogleHide = () => {
    setLoginWithGoogle(false);
  };

  const showTwoFactorVerificationModal = () => {
    setTwoFactorVerificationModal(true);
  };

  const hideTwoFactorVerificationModal = () => {
    setTwoFactorVerificationModal(false);
  };

  const handleLoginWithFBCancelButton = () => {
    formikLoginWithFB.handleReset();
    disableLoadingLoginWithFB();
    handleLoginWithFBHide();
  };

  const handleLoginWithGoogleCancelButton = () => {
    // logger("handleLoginWithGoogleCancelButton",{})
    formikLoginWithGoogle.handleReset();
    disableLoadingLoginWithGoogle();

    handleLoginWithGoogleHide();
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSignupType("Login");
      const { email, password, cellnumber } = values;
      enableLoading();
      setSubmitting(true);

      let loginObj = { email, password, cellnumber, isWeb: true };
      const loginResponse = await loginApi(loginObj);
      disableLoading();
      setSubmitting(false);

      if (loginResponse.Error) {
        const { message, statusCode } = loginResponse.Error;
        setErrorMessage(message);
        handleErrorModalShow();
        if (statusCode === 406) {
          setTimeout(() => {
            handleErrorModalHide();
            setAbaDiclosureModalOpen(true);
          }, 2500);
          return;
        }
        return;
      }
      let storedUsers = await localStorage.getItem("remember_users");
      const rememberUsersList =
        storedUsers?.length > 0 ? JSON.parse(storedUsers) : [];
      const rememberUser = rememberUsersList.find(
        (item) => item.userId === loginResponse.id
      );
      if (
        !rememberUser ||
        rememberUser?.isRemebmer === "0" ||
        (rememberUser?.isRemebmer === "1" &&
          new Date().getTime() >= new Date(rememberUser.expiryDate).getTime())
      ) {
        setLoginResponse(loginResponse);
        showTwoFactorVerificationModal();

        return false;
      }

      setUserInstance(loginResponse);
      localStorage.setItem("auth-token", loginResponse.token);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  const resendVerificationOtp = async (reqData) => {
    const resendOtpResponse = await resendtwofactorOtpApi(reqData);
    return resendOtpResponse;
  };

  const twoFactorAuthOtpSend = async (reqData) => {
    const twofactorauthRespnse = await twoFactorAuthApi(reqData);
  };

  const twoFactorAuth = async (reqData) => {
    const twofactorauthRespnse = await twoFactorAuthApi(reqData);
  };

  const twoFactorAuthFormik = useFormik({
    initialValues: twoFactorInitialValues,
    validationSchema: twoFactorVerificationSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      enableTwoFactorLoading();
      setSubmitting(true);
      let twoFactorVerificationObj = {
        twofactorauth_otp: values.twofactorauthotp,
        remember_me: values.remember_me ? "1" : "0",
        userId: getLoginResponse.id,
      };

      const twoFactorVerificationResponse = await loginTwoFactorVerificationApi(
        twoFactorVerificationObj
      );
      disableTwoFactorLoading();
      if (twoFactorVerificationResponse.Error) {
        setErrorMessage(twoFactorVerificationResponse.Error.message);
        handleErrorModalShow();
        resetForm();
        return;
      }
      setUserInstance(getLoginResponse);
      localStorage.setItem("auth-token", getLoginResponse.token);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
  });

  const formikLoginWithFB = useFormik({
    // STEP - 3 :::::: Login with FB
    initialValues: initialValuesLoginWithFB,
    validationSchema: LoginWithFBSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoadingLoginWithFB();
      const { cellnumber, username } = values;
      const isUserExist = await checkUserExistOrNotApi({
        cellnumber,
        username,
      });
      disableLoadingLoginWithFB();
      const { Error: ExistError } = isUserExist || {};
      if (ExistError) {
        const { message, statusCode } = ExistError || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      // Open ABA Popup
      setAbaDiclosureModalOpen(true);
    },
  });

  const formikLoginWithGoogle = useFormik({
    // STEP - 3 :::::: Login with Google
    initialValues: initialValuesLoginWithGoogle,
    validationSchema: LoginWithGoogleSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoadingLoginWithGoogle();
      const { cellnumber, username } = values;
      const isUserExist = await checkUserExistOrNotApi({
        cellnumber,
        username,
      });
      disableLoadingLoginWithGoogle();
      const { Error: ExistError } = isUserExist || {};
      if (ExistError) {
        const { message, statusCode } = ExistError || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      // Open ABA Popup
      setAbaDiclosureModalOpen(true);
    },
  });

  const signupAndLoginforFB = async () => {
    const { cellnumber, username, email } = formikLoginWithFB.values;
    const { name } = loginWithFbDetails || {};
    const nameArray = name.split(" ");
    const firstname = nameArray[0];
    const lastname = nameArray[1];
    const loginWithFBObj = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      // password: generatePassword(),
      // profile: url,
      cellnumber: cellnumber,
      username: username,
      social_signin: "Facebook",
      role_id: 2,
      is_aba_disclosure_signed: true,
    };
    // if user is not exist make a signup api call and pass required details
    enableLoadingLoginWithFB();
    const signUpResponse = await signUpApi(loginWithFBObj);
    disableLoadingLoginWithFB();
    const { Error } = signUpResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }

    // After Signup, Calling  Login API
    let loginObj = { email, isWeb: true, social_signin: "Facebook" };
    enableLoadingLoginWithFB();
    const loginResponse = await loginApi(loginObj);
    disableLoadingLoginWithFB();
    const { Error: LoginError, token } = loginResponse || {};
    if (LoginError) {
      const { message } = loginResponse.Error;
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setUserInstance(loginResponse);
    localStorage.setItem("auth-token", token);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const signupAndLoginforGoogle = async () => {
    const { cellnumber, username } = formikLoginWithGoogle.values;
    const { profileObj } = loginWithGoogleDetails || {};
    const { email, familyName, givenName, googleId, imageUrl, name } =
      profileObj || {};

    // Signup API Call with Login With Google
    const loginWithGoogleObj = {
      firstname: givenName,
      lastname: familyName,
      email: email,
      // password: generatePassword(),
      // profile: imageUrl,
      cellnumber: cellnumber,
      username: username,
      social_signin: "Google",
      role_id: 2,
      is_aba_disclosure_signed: true,
    };
    enableLoadingLoginWithGoogle();
    const signUpResponse = await signUpApi(loginWithGoogleObj);
    disableLoadingLoginWithGoogle();
    const { Error } = signUpResponse || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }

    // After Signup, Calling  Login API
    let loginObj = { email, isWeb: true, social_signin: "Google" };
    enableLoadingLoginWithGoogle();
    const loginResponse = await loginApi(loginObj);
    disableLoadingLoginWithGoogle();
    // logger("loginResponse", loginResponse);
    const { Error: LoginError, token } = loginResponse || {};
    if (LoginError) {
      const { message } = LoginError;
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setUserInstance(loginResponse);
    localStorage.setItem("auth-token", token);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const loginCallForFB = async (email) => {
    enableLoadingLoginWithFBMainButton();
    let loginObj = { email, isWeb: true, social_signin: "Facebook" };
    const loginResponse = await loginApi(loginObj);
    disableLoadingLoginWithFBMainButton();
    const { Error, token } = loginResponse || {};
    if (Error) {
      const { message, statusCode } = loginResponse.Error;
      setErrorMessage(message);
      handleErrorModalShow();
      if (statusCode === 406) {
        setSignupType("Facebook_Expiry");
        setTimeout(() => {
          handleErrorModalHide();
          setAbaDiclosureModalOpen(true);
        }, 2500);
        return;
      }
      return;
    }
    setUserInstance(loginResponse);
    localStorage.setItem("auth-token", token);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const loginCallForGoogle = async (email) => {
    let loginObj = { email, isWeb: true, social_signin: "Google" };
    const loginResponse = await loginApi(loginObj);
    const { Error, token } = loginResponse || {};
    if (Error) {
      const { message, statusCode } = Error;
      setErrorMessage(message);
      handleErrorModalShow();
      if (statusCode === 406) {
        setSignupType("Google_Expiry");
        // if user is not exist open a modal and ask for username and mobile number
        setTimeout(() => {
          handleErrorModalHide();
          setAbaDiclosureModalOpen(true);
        }, 2500);
        return;
      }
      return;
    }
    // store user details and auth-token in local storage and refresh page, so user will be redirected to dashboard
    setUserInstance(loginResponse);
    localStorage.setItem("auth-token", token);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const checkUserIsExistForFB = async (email) => {
    // STEP - 2 :::::: Login with FB
    enableLoadingLoginWithFBMainButton();
    const isUserExist = await checkUserExistOrNotApi({
      email,
      social_signin: "Facebook",
    });
    disableLoadingLoginWithFBMainButton();
    const { Error } = isUserExist || {};
    if (Error) {
      const { message, statusCode } = Error || {};
      if (statusCode === 402) {
        // if user is not exist open a modal and ask for username and mobile number
        handleLoginWithFBShow();
        return;
      }
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    // if user is already signed up with FB, directly make a call of Login api with social_login key
    loginCallForFB(email);
  };

  const checkUserIsExistForGoogle = async (email) => {
    // STEP - 2 :::: Login with Google
    enableLoadingLoginWithGoogleMainButton();
    const isUserExist = await checkUserExistOrNotApi({
      email,
      social_signin: "Google",
    });
    disableLoadingLoginWithGoogleMainButton();
    const { Error } = isUserExist || {};
    if (Error) {
      const { message, statusCode } = Error || {};
      if (statusCode === 402) {
        // if user is not exist open a modal and ask for username and mobile number
        handleLoginWithGoogleShow();
        return;
      }
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    // if user is already signed up with FB, directly make a call of Login api with social_login key
    loginCallForGoogle(email);
  };

  const responseFacebook = async (response) => {
    setDisableEmailFieldForLoginWithFB(false);
    // STEP - 1 :::::: Login with FB
    disableLoadingLoginWithFBMainButton();
    const { error, email, status } = response || {};

    // Status is unknown if end-user close the window
    if (status === "unknown") {
      return;
    }

    // display an error message if login with FB API throws any error
    if (error) {
      const { message } = error || {};
      if (message) {
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
    }

    // set email address for login with FB if user have email address in FB account
    if (email) {
      formikLoginWithFB.setFieldValue("email", email);
      setDisableEmailFieldForLoginWithFB(true);
    }
    // set details which are provided by the FB API
    setLoginWithFbDetails(response);
    // after successfully login of FB API, check that the user is exist on your system
    checkUserIsExistForFB(email);
  };

  const onSuccessOfLoginWithGoogle = async (response) => {
    // STEP - 1 :::: Login with Google
    disableLoadingLoginWithGoogleMainButton();
    // if Google API successfully called, store Google user details provided by the Google API
    setLoginWithGoogleDetails(response);
    const { profileObj } = response || {};
    const { email } = profileObj || {};
    // check that user is exist in the system
    checkUserIsExistForGoogle(email);
  };

  const onFailureOfLoginWithGoogle = (response) => {
    logger("Google Login Failure Response", response);
    const { details, error } = response || {};
    if (error) {
      if (error === "idpiframe_initialization_failed") {
        setErrorMessage(
          "Initialization is failed or Cookies is disabled in browser, Please enable cookies from settings for login with Google"
        );
      } else if (error === "popup_closed_by_user") {
        setErrorMessage("Login with Google cancelled by user or system");
      } else {
        setErrorMessage(details);
      }
    } else {
      setErrorMessage("Sorry, Something went wrong");
    }
    handleErrorModalShow();
  };

  const handleAbaDiclosureModalHide = () => {
    setAbaDiclosureModalOpen(false);
    if (signupType === FACEBOOK_LOGIN_UNDERSCORE && loginWithFbDetails) {
      signupAndLoginforFB();
    }
    if (signupType === GOOGLE_LOGIN_UNDERSCORE && loginWithGoogleDetails) {
      signupAndLoginforGoogle();
    }
  };

  const handleAbaDiclosureModalAfterUpdate = () => {
    setAbaDiclosureModalOpen(false);
  };

  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* {/ begin::Head /} */}
        <div className="text-left mb-10">
          <h3 className="h1 font-size-md-h1 font-weight-bolder">
            <img
              src={toAbsoluteUrl("/media/logos/PayXtreme.png")}
              height="70"
              alt=""
            />
          </h3>
          <div className="text-left pt-2 h6">
            <span className="font-weight-bold text-dark-50">
              Don't have an account yet?
            </span>
            <Link
              to="/auth/create-account"
              className="font-weight-bold ml-2 text-dark font-weight-bolder"
              id="kt_login_signup"
            >
              Sign Up!
            </Link>
          </div>

          {/* <div className="text-left pt-2">
            <span className=" text-dark-50">
              The new, most efficient and secure way to transfer and receive
              money. Payxtreme makes it easy to send money to friends and family
              or pay for services at your favorite businesses.
            </span>
          </div> */}
        </div>
        {/* {/ end::Head /} */}

        {/*begin::Form*/}
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
          autocomplete="off"
        >
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control-lg form-control h-auto py-4 py-md-5 px-6  ${getInputClasses(
                "email"
              )}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.email}
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control-lg form-control h-auto py-4 py-md-5 px-6  ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <i className="fas fa-times text-danger mr-1 text-center"></i>
                  {formik.errors.password}
                </div>
              </div>
            ) : null}
          </div>
          <div className="form-group">
            <Link
              to="/auth/forgot-password"
              className="h6 text-primary text-hover-primary my-3 text-right d-block font-weight-bolder"
              id="kt_login_forgot"
            >
              Forgot Password?
            </Link>
            <button
              id="kt_login_signin_submit"
              type="submit"
              disabled={loading}
              className={`btn-lg btn btn-brand font-weight-bold px-md-9 py-5 my-3 h5 w-100`}
            >
              <span>Sign In</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
            <span className={`d-block text-center py-2`}>OR</span>

            <FacebookLogin
              appId={process.env.REACT_APP_FB_APP_ID_TEST}
              autoLoad={false}
              fields="name,email,picture"
              callback={responseFacebook}
              render={(renderProps) => (
                <button
                  onClick={() => {
                    setSignupType("Facebook_Login");
                    renderProps.onClick();
                    enableLoadingLoginWithFBMainButton();
                  }}
                  type="button"
                  className={`btn-lg btn btn-facebook font-weight-bold px-md-9 py-md-4 py-5 my-3 h5 w-100 text-left`}
                >
                  <span>
                    <i className="socicon-facebook"></i> Continue with Facebook
                  </span>
                  {isLoadingLoginWithFBMainButton && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              )}
              isDisabled={isLoadingLoginWithFBMainButton}
            />

            <GoogleLogin
              clientId={
                "753860683931-7jv4bqv4mo6ir1ap40altvrupbj6ajo6.apps.googleusercontent.com"
              }
              buttonText="Continue with Google"
              onSuccess={onSuccessOfLoginWithGoogle}
              onFailure={(response) => {
                onFailureOfLoginWithGoogle(response);
                disableLoadingLoginWithGoogleMainButton();
              }}
              onLogoutSuccess={(response) => {
                logger("onLogoutSuccess", response);
                disableLoadingLoginWithGoogleMainButton();
              }}
              cookiePolicy={"single_host_origin"}
              autoLoad={false}
              render={(renderProps) => (
                <button
                  onClick={() => {
                    setSignupType("Google_Login");
                    renderProps.onClick();
                    enableLoadingLoginWithGoogleMainButton();
                  }}
                  disabled={
                    renderProps.disabled || isLoadingLoginWithGoogleMainButton
                  }
                  className={`btn-lg btn btn-google font-weight-bold px-md-9 py-md-4 py-5 h5 my-3 w-100 text-left`}
                >
                  <span>
                    <i className="socicon-google"></i> Continue with Google
                  </span>
                  {isLoadingLoginWithGoogleMainButton && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              )}
            />
          </div>
        </form>
        {/*end::Form*/}
      </div>
      {isTwoFactorVerificationModalShow && (
        <LoginTwoFactorVerificationModal
          isTwoFactorVerificationModalShow={isTwoFactorVerificationModalShow}
          isTwoFactorVerificationModalHide={hideTwoFactorVerificationModal}
          userId={userInstance.id}
          formik={twoFactorAuthFormik}
          loginResponse={getLoginResponse}
          loading={loadingTwoFactor}
          resendVerificationOtp={resendVerificationOtp}
          twoFactorAuthOtpSend={twoFactorAuthOtpSend}
        />
      )}

      {isLoginWithFB && (
        <LoginWithFBSignupModal
          isLoading={isLoadingLoginWithFB}
          isLoginWithFB={isLoginWithFB}
          handleLoginWithFBCancelButton={handleLoginWithFBCancelButton}
          formikLoginWithFB={formikLoginWithFB}
          disableEmailFieldForLoginWithFB={disableEmailFieldForLoginWithFB}
        />
      )}

      {isLoginWithGoogle && (
        <LoginWithGoogleSignupModal
          isLoading={isLoadingLoginWithGoogle}
          isLoginWithGoogle={isLoginWithGoogle}
          handleLoginWithGoogleCancelButton={handleLoginWithGoogleCancelButton}
          formikLoginWithGoogle={formikLoginWithGoogle}
        />
      )}

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      {isAbaDisclosureModalOpen && (
        <ABADisclosureModal
          pageType={signupType}
          isAbaDisclosureModalOpen={isAbaDisclosureModalOpen}
          handleAbaDiclosureModalHide={handleAbaDiclosureModalHide}
          handleAbaDiclosureModalAfterUpdate={
            handleAbaDiclosureModalAfterUpdate
          }
          email={
            loginWithFbDetails
              ? loginWithFbDetails.email
              : loginWithGoogleDetails
              ? loginWithGoogleDetails.profileObj.email
              : formik.values.email
          }
        />
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
