// export const SERVER_URL = "http://30.30.30.19:3001";
// export const SERVER_URL = "http://172.104.173.184:3001";
// export const SERVER_URL = "https://payxtremeapi.secretdemo.com"; // For QA
export const SERVER_URL = "https://devapipayxtreme.secretdemo.com"; // For Sufalam
// export const SERVER_URL = "https://api.payxtreme.com"; // For AWS
// export const SERVER_URL = "http://localhost:3001";
// export const SERVER_URL = "http://30.30.30.17:3002";


// SC API Server DEV
export const SC_SERVER_URL = "https://dev-api.scheduleclosings.com/v1/extreme/tx";

// SC API Server LIVE
// export const SC_SERVER_URL = "https://api.scheduleclosings.com/v1/extreme/tx";