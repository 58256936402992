import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { Layout } from "../../../_metronic/layout";
import BasePage from "../../BasePage";
import { useLocalStorage } from "../../utils/useLocalStorage";
import AuthPage from "./pages/AuthPage";
import LandingPage from "./pages/LandingPage";

export function LandingRoutes() {
  const [userInstance] = useLocalStorage("userInstance", "");

  return (
    <Switch>
      {!userInstance && <Redirect exact from="/" to="/home" />}
      <Route path="/home" component={LandingPage} />
      {!userInstance ? (
        <Route>
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      {!userInstance ? (
        <Redirect to="/auth/login" />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}
    </Switch>
  );
}
