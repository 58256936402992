import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Document, Page } from "react-pdf";
import TermsCondition from "../../../pdf/Terms-of-Service-5-2-22.pdf";
import PrivaryPolicy from "../../../pdf/Privacy-Policy-5-2-22.pdf";
import Transaction_Auth from "../../../pdf/Transaction-Authorization-5-11-22.pdf";
import Aba_Disclosure from "../../../pdf/ABA-8-16-22.pdf";
import { updateAbaDisclosureApi } from "../../../axios/services/services";
import { makeStyles } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import {
  ABADisclosureSchema,
  blankSchema,
  TADisclosureSchema,
} from "../../utils/FormikSchema";
import { useFormik } from "formik";
import ErrorModal from "./ErrorModal";
import UpdatedSuccessfulModal from "./UpdatedSuccessfulModal";

const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

const initialValues = {
  tranAuthName: "",
  abaDisclosureName: "",
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "800px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  connectorLine: {
    display: "none",
  },
  active: {
    backgroundColor: "#ff0000",
  },
}));

const pdfList = [
  {
    name: "PRIVACY POLICY",
    file: PrivaryPolicy,
  },
  {
    name: "TERMS OF SERVICE",
    file: TermsCondition,
  },
  {
    name: "TRANSACTION AUTHORIZATION",
    file: Transaction_Auth,
  },
  {
    name: "AFFILIATED BUSINESS DISCLOSURE",
    file: Aba_Disclosure,
  },
];

const ABADisclosureModal = ({
  pageType,
  isAbaDisclosureModalOpen,
  handleAbaDiclosureModalHide,
  handleAbaDiclosureModalAfterUpdate,
  email,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [isLoadingUpdateABA, setLoadingUpdateABA] = useState(false);
  const [pdfIndex, setPdfIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const currentPdf = pdfList[pdfIndex];
  const today = new Date();
  const date = today.setDate(today.getDate());
  const defaultValue = new Date(date).toISOString().split("T")[0];

  const onDocumentLoadSuccess = ({ numPages }) => {
    setLoading(false);
    setNumPages(numPages);
  };

  const enableLoadingUpdateABA = () => {
    setLoadingUpdateABA(true);
  };

  const disableLoadingUpdateABA = () => {
    setLoadingUpdateABA(false);
  };

  const handlePrevious = () => {
    if (pdfIndex > 0) {
      setPdfIndex(pdfIndex - 1);
    }
  };

  const updateAbaDisclosure = async () => {
    enableLoadingUpdateABA();
    const response = await updateAbaDisclosureApi({ email });
    disableLoadingUpdateABA();
    const { Error } = response || {};
    if (Error) {
      const { message } = Error || {};
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      `ABA disclosure is renewed successfully, please login again`
    );
    handleSuccessModalShow();
    setTimeout(() => {
      handleAbaDiclosureModalAfterUpdate();
    }, 2500);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const validationSchemaList = [
    blankSchema,
    blankSchema,
    TADisclosureSchema,
    ABADisclosureSchema,
  ];

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaList[pdfIndex],
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (pdfIndex === 3) {
        if (pageType === "Signup") {
          handleAbaDiclosureModalHide();
        }
        if (pageType === "Login") {
          updateAbaDisclosure();
        }
        if (pageType === "Facebook_Login") {
          handleAbaDiclosureModalHide();
        }
        if (pageType === "Google_Login") {
          handleAbaDiclosureModalHide();
        }
        if (pageType === "Facebook_Expiry") {
          updateAbaDisclosure();
        }
        if (pageType === "Google_Expiry") {
          updateAbaDisclosure();
        }
      } else {
        setPdfIndex(pdfIndex + 1);
      }
    },
  });

  return (
    <Modal
      size={"lg"}
      show={isAbaDisclosureModalOpen}
      onHide={handleAbaDiclosureModalHide}
      dialogClassName="modal-90w"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title>{currentPdf.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text">
        <div className="Example">
          <div className="Example__container">
            <div className="Example__container__document">
              {loading && <div>Loading...</div>}
              <Document
                file={currentPdf.file}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
              >
                {Array.from(new Array(numPages), (el, index) => (
                  <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                ))}
              </Document>
            </div>
          </div>
        </div>

        {pdfIndex === 2 && (
          <>
            <label className="pl-5 text-danger">
              By entering your full name, you are agreeing to the TRANSACTION
              AUTHORIZATION FORM.
            </label>
            <Form className="d-flex justify-content-between">
              <Form.Group
                className="mb-3"
                controlId="formBasicName"
                style={{ marginLeft: "20px" }}
              >
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Full Name"
                  name="tranAuthName"
                  {...formik.getFieldProps("tranAuthName")}
                />
                {formik.touched.tranAuthName && formik.errors.tranAuthName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.tranAuthName}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formBasicDate"
                style={{ marginLeft: "20px" }}
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={defaultValue}
                  disabled
                />
              </Form.Group>
            </Form>
          </>
        )}

        {pdfIndex === pdfList.length - 1 && (
          <>
            <label className="pl-5 text-danger">
              By entering your full name, you are agreeing to the AFFILIATED
              BUSINESS DISCLOSURE NOTICE AND ACKNOWLEDGMENT.
            </label>
            <Form className="d-flex justify-content-between">
              <Form.Group
                className="mb-3"
                controlId="formBasicName"
                style={{ marginLeft: "20px" }}
              >
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Your Full Name"
                  name="abaDisclosureName"
                  {...formik.getFieldProps("abaDisclosureName")}
                />
                {formik.touched.abaDisclosureName &&
                formik.errors.abaDisclosureName ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.abaDisclosureName}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="formBasicDate"
                style={{ marginLeft: "20px" }}
              >
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  defaultValue={defaultValue}
                  disabled
                />
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          disabled={pdfIndex === 0}
          onClick={handlePrevious}
          variant="btn btn-light-brand btn-lg"
          className={classes.button}
        >
          <span className="svg-icon menu-icon ml-0 mr-2">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-left.svg")}
            />
          </span>
          Previous
        </Button>
        <Button
          onClick={formik.handleSubmit}
          className={classes.button}
          variant="btn btn-brand btn-lg"
          disabled={isLoadingUpdateABA}
        >
          {pdfIndex === pdfList.length - 1 ? "I Agree" : "Next"}
          <span className="svg-icon menu-icon ml-2 mr-0">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Navigation/Arrow-right.svg")}
            />
            {isLoadingUpdateABA && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </span>
        </Button>
      </Modal.Footer>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </Modal>
  );
};

export default ABADisclosureModal;
