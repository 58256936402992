import React from "react";
import { Modal, Button } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export default function RadioButtonModal({
  isOpenRadiobuttonModal,
  hideRadioButtonModal,
  handleClick,
  option,
}) {
  return (
    <div>
      <Modal
        show={isOpenRadiobuttonModal}
        onHide={hideRadioButtonModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            Connect Your Bank
            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={hideRadioButtonModal}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-2">
            <label className="radio radio-success h5 cursor-pointer">
              <input
                type="radio"
                // className="radioinput"
                value="manually"
                checked={option === "manually"}
                name="manually"
                onClick={() => handleClick("manually")}
              />
              <span className="mr-1"></span>
              <label
                className="m-0 ml-2 cursor-pointer"
                onClick={() => handleClick("manually")}
              >
                Add Manually
              </label>
            </label>
            <p style={{ paddingLeft: "30px" }}>
              Securely enter your account and routing number. We'll send 2 small
              deposits to your account within 1-5 business days. Then, to verify
              and begin using the bank account, you'll need to enter the exact
              amounts of those deposits in your account settings.
            </p>
          </div>
          <div>
            <label
              className="radio radio-success h5 cursor-pointer"
              onClick={() => handleClick("plaid")}
            >
              <input
                type="radio"
                value="plaid"
                checked={option === "plaid"}
                name="plaid"
                onClick={() => handleClick("plaid")}
              />
              <span className="mr-1"></span>
              <label className="m-0 ml-2 cursor-pointer">
                Continue with Plaid{" "}
                <img
                  className="ml-3"
                  src={toAbsoluteUrl("/media/logos/Plaid_logo.svg")}
                  height="30"
                  alt=""
                />
              </label>
            </label>
            <p style={{ paddingLeft: "30px" }}>
              Plaid provides the safest and quickest way to connect your bank.
              Simply enter your online banking credentials and your account will
              be instantly authenticated.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-success mr-3" onClick={hideRadioButtonModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
