import React, { useEffect, useState } from "react";
import {
  cancelTransactionApi,
  historyListApi,
  recentHistoryPDFApi,
  recentHistoryPrintApi,
} from "../../../axios/services/services";
import { HistoryReceiptModal } from "./history-receipt-modal/HistoryReceiptModal";
import { HistoryCard } from "./HistoryCard";
import { HistoryUIProvider } from "./HistoryUIContext";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";
import { isUnauthorized } from "../../utils/utils";
import { HistoryCancelTransactionDialog } from "./history-cancel-transaction-dialog/HistoryCancelTransactionDialog";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
// import { RecentHostory } from "../Dashboard/RecentHostory";

export function HistoryPage({ history }) {
  const [isLoadingForDownload, setLoadingForDownload] = useState(false);
  const [isLoadingForPrint, setLoadingForPrint] = useState(false);
  const [
    isLoadingForCancelTransaction,
    setLoadingForCancelTransaction,
  ] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isCancelTransactionModal, setCancelTransactionModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [historyList, setHistoryList] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [currentPage, setCurrentPage] = useState(1);
  const [historyListTotalCount, setHistoryListTotalCount] = useState(0);
  const [updateHistoryList, setUpdateHistoryList] = useState(0);

  useEffect(() => {
    async function getHistoryList() {
      enableLoading();
      const historyListResponse = await historyListApi({
        currentPage,
        filterValues,
      });
      disableLoading();
      if (historyListResponse.Error) {
        isUnauthorized({ response: historyListResponse });
        setErrorMessage(historyListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setHistoryListTotalCount(
        historyListResponse && historyListResponse.count
          ? historyListResponse.count
          : 0
      );
      setHistoryList(
        historyListResponse && historyListResponse.data
          ? historyListResponse.data
          : []
      );
    }
    getHistoryList();
  }, [filterValues, currentPage, updateHistoryList]);

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };
  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const handleCancelTransactionShow = () => {
    setCancelTransactionModal(true);
  };

  const handleCancelTransactionHide = () => {
    setCancelTransactionModal(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleFilterValues = (values) => {
    setFilterValues(values);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enablePrintButtonLoading = () => {
    setLoadingForPrint(true);
  };

  const disablePrintButtonLoading = () => {
    setLoadingForPrint(false);
  };

  const enableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(true);
  };

  const disableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(false);
  };

  const historyUIEvents = {
    openReceiptDialog: (row) => {
      setData(row);
      handleConfirmShow();
    },
    openCancelTransactionDialog: (row) => {
      setData(row);
      handleCancelTransactionShow();
    },
  };

  const onClickOfDownloadButton = async () => {
    enableLoading();
    const { id } = data;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`,
    });
    disableLoading();
    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  const onClickOfPrintButton = async () => {
    enablePrintButtonLoading();
    const { id } = data;
    const recentHistoryPrintResponse = await recentHistoryPrintApi({
      id,
    });
    
    const { html, Error } = recentHistoryPrintResponse || {};
    disablePrintButtonLoading();
    if (Error) {
      const { message } = Error || {};
      isUnauthorized({ response: recentHistoryPrintResponse });
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    enablePrintButtonLoading();
    setPdfUrl(html);
    setTimeout(() => {
      disablePrintButtonLoading();
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 500);
  };

  const handleCancelTransaction = async () => {
    enableLoadingCancelTransaction();
    const { id } = data;
    const cancelTransactionResponse = await cancelTransactionApi({
      id,
    });
    disableLoadingCancelTransaction();
    if (cancelTransactionResponse.Error) {
      isUnauthorized({ response: cancelTransactionResponse });
      setErrorMessage(cancelTransactionResponse.Error.message);
      handleErrorModalShow();
    } else {
      setUpdateHistoryList(Math.random());
      handleSuccessModalShow();
      setSuccessMessage("Transaction cancelled successfully");
      handleCancelTransactionHide();
    }
  };

  return (
    <>
      <HistoryUIProvider historyUIEvents={historyUIEvents}>
        {isConfirmModal && (
          <HistoryReceiptModal
            data={data}
            handleConfirmHide={handleConfirmHide}
            isConfirmModal={isConfirmModal}
            onClickOfDownloadButton={onClickOfDownloadButton}
            onClickOfPrintButton={onClickOfPrintButton}
            isLoadingForDownload={isLoadingForDownload}
            isLoadingForPrint={isLoadingForPrint}
          />
        )}
        {isCancelTransactionModal && (
          <HistoryCancelTransactionDialog
            isLoadingForCancelTransaction={isLoadingForCancelTransaction}
            isCancelTransactionShow={handleCancelTransactionShow}
            onHideCancelTransaction={handleCancelTransactionHide}
            onClickOfCancelTransaction={handleCancelTransaction}
          />
        )}

        <HistoryCard
          isLoading={isLoading}
          historyList={historyList}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          filterValues={filterValues}
          handleFilterValues={handleFilterValues}
          historyListTotalCount={historyListTotalCount}
          userInstance={userInstance}
        />
      </HistoryUIProvider>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
      <iframe
        className="d-none"
        srcDoc={pdfUrl}
        name="myframe"
        id="myframe"
        title="myframe"
      ></iframe>
    </>
  );
}
