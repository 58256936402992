import "moment-timezone";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from "react-moment";
import { HH_MMA, MMMM_DO_YYYY, TIME_ZONE } from "../../utils/dateTimeFormats";
import { NumberFormatter } from "../../utils/NumberFormatter";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { getStatusClass } from "../../utils/utils";

function ReceiptModal({
  message,
  isConfirmModal,
  handleConfirmHide,
  onClickOfDownloadButton,
  onClickOfPrintButton,
  buttonLabel,
  buttonprintLabel,
  title,
  data,
  isLoadingForDownload,
  isLoadingForPrint
}) {
  const {
    amount,
    commission,
    senderId,
    sender,
    receiver,
    date,
    expecteddeliverydate,
    transaction_status,
    transactionStatusId,
    createdAt,
    confirmation_id,
    sc_transactionId,
    transaction_id,
    created_by
  } = data;
  const [userInstance] = useLocalStorage("userInstance", "");

  const renderTransactionStatus = () => {
    const { name = "" } = transaction_status || {};
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClass(name)} status-color text-capitalize`}
        >
          {name ? name : "-"}
        </span>
      </span>
    );
  };

  const renderExpectedDeliveryDate = () => {
    const { id: statusId } = transaction_status || {};
    if (statusId !== 3) {
      return <></>;
    }
    return (
      <div className="col-md-6 mb-4">
        <h5 className="text-secondary fs-14 font-weight-normal text-left">
          Expected Delivery Date
        </h5>
        <div className="text-dark fs-14 font-weight-bold text-left">
          <div>
            {`Pending Until `}
            <Moment
              date={expecteddeliverydate}
              format={MMMM_DO_YYYY}
              tz={TIME_ZONE}
            />
            <br />
            {"at "}
            <Moment
              date={expecteddeliverydate}
              format={HH_MMA}
              tz={TIME_ZONE}
            />
            {" EST"}
          </div>
        </div>
      </div>
    );
  };

  const renderPaymentDetails = () => {
    const { AccountName = "", other } = transaction_id || {};
    // const paymentDetails = JSON.parse(other) || {};
    const { AccountLastFour = "" } = other || {};
    const { id } = userInstance || {};
    if (id !== senderId) {
      return <></>;
    }

    return (
      <div className="col-md-6 mb-4">
        <h5 className="text-secondary fs-14 font-weight-normal text-left">
          Payment Details
        </h5>
        <div className="text-dark fs-14 font-weight-bold text-left">
          {/* <div>{`${AccountName} - ${AccountLastFour}`}</div> */}
          {AccountLastFour ? `XXXXXXX${AccountLastFour}` : "-"}
        </div>
      </div>
    );
  };

  const renderCommissionForSender = () => {
    const { id } = userInstance || {};
    return id === senderId ? (
      <div className="col-md-6 mb-4">
        <h5 className="text-secondary fs-14 font-weight-normal text-left">
          Transaction Fee
        </h5>
        <div className="text-dark fs-14 font-weight-bold text-left">
          <NumberFormatter amount={commission} />
        </div>
      </div>
    ) : (
      <></>
    );
  };

  const renderTotalAmountForSender = () => {
    const { id } = userInstance || {};
    return id === senderId ? (
      <div className="col-md-6 mb-4">
        <h5 className="text-secondary fs-14 font-weight-normal text-left">
          Total Amount
        </h5>
        <div className="text-dark fs-14 font-weight-bold text-left">
          <NumberFormatter amount={commission + amount} />
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Modal
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center pb-0">
          <h3 className="text-dark font-weight-bold fs-18 text-secondary mb-2">
            {title}
          </h3>
          <p className="text-center h2 font-weight-boldest text-dark mb-10 mt-2">
            <NumberFormatter amount={amount} />
          </p>
          <div className="row">
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Sent By
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {sender
                  ? data.sender.firstname + " " + data.sender.lastname
                  : ""}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Received By
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {receiver
                  ? data.receiver.firstname + " " + data.receiver.lastname
                  : ""}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Day/Time
              </h5>
              <div className="text-dark fs-14 font-weight-bold text-left">
                {createdAt && (
                  <div>
                    <Moment
                      date={createdAt}
                      format={MMMM_DO_YYYY}
                      tz={TIME_ZONE}
                    />
                    <br />
                    {"at "}
                    <Moment date={createdAt} format={HH_MMA} tz={TIME_ZONE} />
                  </div>
                )}
              </div>
            </div>
            {renderExpectedDeliveryDate()}
            {renderPaymentDetails()}
            {renderCommissionForSender()}
            {renderTotalAmountForSender()}
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Confirmation #:
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {confirmation_id ? confirmation_id : "-"}
              </p>
            </div>
            <div className="col-md-6 mb-4">
              <h5 className="text-secondary fs-14 font-weight-normal text-left">
                Status
              </h5>
              <p className="text-dark fs-14 font-weight-bold text-left">
                {renderTransactionStatus()}
              </p>
            </div>
            {sc_transactionId && (
              <div className="col-md-6 mb-4">
                <h5 className="text-secondary fs-14 font-weight-normal text-left">
                  Schedule Closings Trans. ID
                </h5>
                <p className="text-dark fs-14 font-weight-bold text-left">
                  {sc_transactionId}
                </p>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0">
          <Button
            variant="btn-lg btn-primary mt-5 mb-5 mr-2 px-10"
            onClick={onClickOfDownloadButton}
            disabled={isLoadingForDownload}
          >
            {buttonLabel}
            {isLoadingForDownload && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </Button>
          <Button
            variant="btn-lg btn-light-primary mt-5 mb-5 px-10"
            onClick={onClickOfPrintButton}
            disabled={isLoadingForPrint}
          >
            {buttonprintLabel}
            {isLoadingForPrint && (
              <span className="ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ReceiptModal;
