import React from "react";
import { Button, Modal } from "react-bootstrap";

function BlockReportModal({
  title,
  reasonMessage,
  infoMessage,
  message,
  checkboxtext,
  isBlockReportModalOpen,
  hideBlockReportModal,
  WarningIcon,
  saveButtonLabel,
  cancelButtonLabel,
  saveOnClick,
  cancelOnClick,
  reportReason,
  isReportSpam,
  loadingSave,
}) {
  return (
    <>
      <Modal
        show={isBlockReportModalOpen}
        onHide={hideBlockReportModal}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title className="mt-3">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="d-flex justify-content-center mt-5">{WarningIcon}</div> */}
          <label className="checkbox mb-8">
            <input
              type="checkbox"
              name="reportuser"
              onChange={(event) => {
                isReportSpam(event.target.checked);
              }}
            />
            <span className="mr-2"></span> {`${checkboxtext}`}
          </label>
          <p className="fs-16 text-dark confirmation-content">
            {reasonMessage}
          </p>
          <input
            type="text"
            placeholder="Reason for block"
            className="form-control"
            maxLength="512"
            onChange={(event) => {
              reportReason(event.target.value);
            }}
          ></input>

          <p className="mt-4 text-brand">{infoMessage}</p>
          <Modal.Footer className="p-0">
            <Button
              variant="btn-lg  btn btn-success px-8 mt-5"
              onClick={saveOnClick}
              disabled={loadingSave}
            >
              {saveButtonLabel}
              {loadingSave && (
                <span className=" mr-3 ml-3 spinner spinner-white"></span>
              )}
            </Button>
            <Button
              variant="btn-lg  btn-primary px-8 mt-5"
              onClick={cancelOnClick}
              disabled={loadingSave}
            >
              {cancelButtonLabel}
            </Button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default BlockReportModal;
