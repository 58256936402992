import React from "react";
import { maxLength, minLength } from "./utils";
const PasswordValidationDropdownPassword = ({ formik }) => {
  const {
    values: { password },
  } = formik || {};
  return (
    <>
      {minLength(password, 8) ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            Password should be at least 8 characters
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            Password should be at least 8 characters
          </div>
        </div>
      )}

      {maxLength(password, 64) && (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            Password should not be more than 64 characters
          </div>
        </div>
      )}

      {password.search(/[A-Z]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 uppercase letter (A - Z)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 uppercase letter (A - Z)
          </div>
        </div>
      )}

      {password.search(/[a-z]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 lowercase letter (a - z)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 lowercase letter (a - z)
          </div>
        </div>
      )}

      {password.search(/[0-9]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 number (0-9)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 number (0-9)
          </div>
        </div>
      )}

      {password.search(/[!#$%&?@^*()_`~"]/) < 0 ? (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block">
            <i className="fas fa-times text-danger mr-1 text-center"></i>
            At least 1 symbol (non-alphabetical character)
          </div>
        </div>
      ) : (
        <div className="fv-plugins-message-container">
          <div className="fv-help-block text-success">
            <i className="fas fa-check text-success mr-1 text-center"></i>
            At least 1 symbol (non-alphabetical character)
          </div>
        </div>
      )}
    </>
  );
};

export default PasswordValidationDropdownPassword;
