/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
export const DepositeTypeColumnFormatter = (cellContent, row, rowIndex) => {
  const { deposite_type, transactionStatusId } = row;

  const renderDepositType = () => {
    if (transactionStatusId === 2) {
      return <>-</>;
    }

    return deposite_type ? deposite_type.name : "-";
  };

  return (
    <>
      <span className="text-dark-75 d-block">{renderDepositType()}</span>
    </>
  );
};
