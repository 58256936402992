import React, { useEffect, useState } from "react";
import { Card, Button, ButtonToolbar, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"; //, checkIsActive
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { useFormik } from "formik";
import {
  acceptFundApi,
  addNewBankApi,
  bankListApi,
  recentHistoryPDFApi,
  transactionDetailsByIdApi,
} from "../../../axios/services/services";
import ErrorModal from "../Modal/ErrorModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { connect } from "react-redux";
import FundSuccessModal from "../Modal/FundSuccessModal";
import { isUnauthorized } from "../../utils/utils";

const initialValues = {
  bankname: "",
  bankaccounttype: "Savings",
  bankroutenumber: "",
  bankaccountnumber: "",
};

function BankInformation(props) {
  const subheader = useSubheader();
  subheader.setTitle("Bank Account Information");
  const { intl } = props;
  const transId = props.match.params.tid;
  const notificationId = props.match.params.nid;
  const [loading, setLoading] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isSuccessAcceptFundModal, setSuccessAcceptFundModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [acceptFundDetails, setAcceptFundDetails] = useState({});
  const [bankList, setBankList] = useState([]);
  const [selectedBank, setSelectedBank] = useState(null);

  useEffect(() => {
    async function getTransactionDetailsById() {
      const transactionDetailsResponse = await transactionDetailsByIdApi({
        id: transId,
      });
      if (transactionDetailsResponse.Error) {
        isUnauthorized({ response: transactionDetailsResponse });
        setErrorMessage(transactionDetailsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setTransactionDetails(transactionDetailsResponse);
    }
    getTransactionDetailsById();
  }, [transId]);

  async function getBankList() {
    const bankListResponse = await bankListApi();
    if (bankListResponse.Error) {
      setErrorMessage(bankListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    if (bankListResponse && bankListResponse.data) {
      setBankList(bankListResponse.data);
    } else {
      setBankList([]);
    }
  }

  useEffect(() => {
    getBankList();
  }, []);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessAcceptFundShow = () => {
    setSuccessAcceptFundModal(true);
  };

  const handleSuccessAcceptFundHide = () => {
    setSuccessAcceptFundModal(false);
  };

  const BankDetailSchema = Yup.object().shape({
    bankname: Yup.string()
      .min(4, "Bank name must be at least 4 characters")
      .max(128, "Bank name must not be more than 128 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    bankaccounttype: Yup.string().required(
      intl.formatMessage({
        id: "AUTH.VALIDATION.REQUIRED_FIELD",
      })
    ),
    bankroutenumber: Yup.string()
      .matches(/^[0-9]*$/, "Must be only digits")
      .min(9, "Bank routing number must be at least 9 digits")
      .max(9, "Bank routing number must not be more than 9 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    bankaccountnumber: Yup.string()
      .matches(/^[0-9]*$/, "Must be only digits")
      .min(9, "Account number must be at least 9 digits")
      .max(19, "Account number must not be more than 19 digits")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BankDetailSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // console.log("onSubmit");
    },
  });

  const handleSubmitButton = async () => {
    const { values } = formik;
    const { bankaccounttype, bankroutenumber, bankaccountnumber } = values;
    if (
      bankaccounttype.trim() === "" ||
      values.bankroutenumber.toString().trim() === "" ||
      values.bankaccountnumber.toString().trim() === ""
    ) {
      return;
    }
    enableLoading();
    let addNewBankObj = {
      userId: userInstance.id,
      bankId: selectedBank.id,
      account_type: bankaccounttype,
      bank_routing_number: "" + bankroutenumber,
      bank_account_number: "" + bankaccountnumber,
    };
    const addNewBankResponse = await addNewBankApi(addNewBankObj);
    disableLoading();
    if (addNewBankResponse.Error) {
      setErrorMessage(addNewBankResponse.Error.message);
      handleErrorModalShow();
      return;
    }

    let acceptFundObj = {
      isaccept: true,
      transactionId: transId,
      notificationreceiverId: notificationId,
    };
    enableLoading();
    const acceptFundResponse = await acceptFundApi(acceptFundObj);
    disableLoading();
    if (acceptFundResponse.Error) {
      isUnauthorized({ response: acceptFundResponse });
      setErrorMessage(acceptFundResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setAcceptFundDetails(acceptFundResponse);
    handleSuccessAcceptFundShow();
  };

  const onClickOfDownloadButton = async () => {
    const { id } = transactionDetails;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`,
    });

    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  return (
    <>
      <div className="notification-wrapper">
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <div>
              <form
                onSubmit={formik.handleSubmit}
                className="form fv-plugins-bootstrap fv-plugins-framework"
                autocomplete="off"
              >
                <div className="bank-info-wrap d-flex flex-column-fluid flex-center mt-lg-0">
                  <div className="w-lg-50">
                    <p className="text-center fs-13 text-gray-3">
                      It appears you never submitted your bank information. You
                      must fill out the information below and verify your bank
                      account in order to accept funds.
                    </p>
                    <div className="text-center mt-8 mb-5">
                      <i className="fa fa-landmark text-success icon-4x"></i>
                    </div>
                    <div className="text-center mt-3 d-block">
                      <span className="text-dark font-weight-boldest font-size-h5">
                        {/* Enable ACH, eChecks, and Wires */}
                        Connect Your Bank
                      </span>
                      <p className="text-secondary">
                        Bank Accounts are used for ACH Payments and Transaction
                        Fees. Also, funds you receive will directly deposit into
                        your Default Bank Account.
                      </p>
                    </div>
                    <div className="form-group mt-8">
                      <select
                        className="form-control h-auto py-3 px-6"
                        name="bankname"
                        value={formik.values.bankname}
                        {...formik.getFieldProps("bankname")}
                        onChange={(event) => {
                          const bankname = event.target.value;
                          formik.setFieldValue("bankname", event.target.value);
                          const [selectedBankDetails] = bankList.filter(
                            (bank) => bank.bank_name === bankname
                          );
                          setSelectedBank(selectedBankDetails);
                        }}
                      >
                        <option value="">Select Bank</option>
                        {bankList &&
                          bankList.map((bank, index) => (
                            <option value={bank.bank_name} key={index}>
                              {bank.bank_name}
                            </option>
                          ))}
                      </select>
                      {formik.touched.bankname && formik.errors.bankname ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {formik.errors.bankname}
                          </div>
                        </div>
                      ) : null}
                      <select
                        className="form-control h-auto py-3 px-6 mt-5"
                        name="bankaccounttype"
                        value={formik.values.bankaccounttype}
                        {...formik.getFieldProps("bankaccounttype")}
                      >
                        <option value="">Select Account Type</option>
                        <option value="Savings">Savings</option>
                        <option value="Checking">Checking</option>
                      </select>
                      {formik.touched.bankaccounttype &&
                      formik.errors.bankaccounttype ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {formik.errors.bankaccounttype}
                          </div>
                        </div>
                      ) : null}
                      <input
                        placeholder="Bank Routing Number*"
                        autocomplete="off"
                        type="number"
                        className="form-control h-auto py-3 px-6 mt-5"
                        name="bankroutenumber"
                        {...formik.getFieldProps("bankroutenumber")}
                      />
                      {formik.touched.bankroutenumber &&
                      formik.errors.bankroutenumber ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {formik.errors.bankroutenumber}
                          </div>
                        </div>
                      ) : null}
                      <input
                        placeholder="Bank Account Number*"
                        autocomplete="off"
                        type="number"
                        className="form-control h-auto py-3 px-6 mt-5"
                        name="bankaccountnumber"
                        {...formik.getFieldProps("bankaccountnumber")}
                      />
                      {formik.touched.bankaccountnumber &&
                      formik.errors.bankaccountnumber ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            <i className="fas fa-times text-danger mr-1 text-center"></i>
                            {formik.errors.bankaccountnumber}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <p className="text-left text-secondary fs-13">
                      We will deposit 2 small amounts into your bank account for
                      <span className="text-brand font-weight-bold">
                        {" "}
                        verification, usually takes 2-3 bussiness days.
                      </span>{" "}
                      When you receive the deposits, go to your Account Settings
                      and enter the amounts. This will verify your bank account
                      and you'll be good to go!
                    </p>
                    <div className="d-block mt-5">
                      <ButtonToolbar className="d-flex justify-content-center">
                        <Button
                          type="submit"
                          variant="btn btn-brand btn-lg w-100"
                          onClick={handleSubmitButton}
                          disabled={loading}
                        >
                          Submit
                          {loading && (
                            <span className="ml-3 mr-3 spinner spinner-white"></span>
                          )}
                        </Button>
                      </ButtonToolbar>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Card.Body>
        </Card>
      </div>
      {/* // Using Fund Success Modal for Accept Fund SuccessFull  */}
      <FundSuccessModal
        isStatic={true}
        isSuccessModal={isSuccessAcceptFundModal}
        handleSuccessHide={handleSuccessAcceptFundHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        title={"Accepted Funds successfully"}
        message={` PAYXTRME TRANSACTION #: ${transactionDetails &&
          transactionDetails.id}`}
        linkUrl={"Download Receipt"}
        onClickOfLink={onClickOfDownloadButton}
        buttonLabel={"Go to Profile"}
        onClickOfSaveButton={() => {
          props.history.push("/user-profile");
        }}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default injectIntl(connect(null, null)(BankInformation));
