import React, { useEffect, useState } from "react";
import { Card, ButtonToolbar, Nav, Tab, Row, Col } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DropdownButton } from "react-bootstrap";
import { Dropdown } from "react-bootstrap";
import {
  blockContactApi,
  contactListApi,
  myChatListingApi,
  removeContactApi,
  unreadChatCounterApi,
} from "../../../axios/services/services";
import InfiniteScroll from "react-infinite-scroll-component";
import { ContactSingleUserView } from "./ContactSingleUserView";
import ErrorModal from "../Modal/ErrorModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import LinesEllipsis from "react-lines-ellipsis";
import * as moment from "moment";
import Moment from "react-moment";
import "moment-timezone";
import { TIME_ZONE } from "../../utils/dateTimeFormats";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
// import Avatar from "@material-ui/core/Avatar";
import { Link } from "react-router-dom";
import { isUnauthorized } from "../../utils/utils";
import { CustomImage } from "../../utils/CustomImage";
import UpdatedSuccessfulModal from "../../modules/Modal/UpdatedSuccessfulModal";
import ConfirmationModal from "../Modal/ConfirmationModal";
import {
  YOU_DONT_HAVE_ANY_MESSAGES_DESC,
  YOU_DONT_HAVE_ANY_MESSAGES_YET,
} from "../../utils/const";

export function ContactandMessaging({ history }) {
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isPorfileViewShow, setPorfileView] = useState(false);
  const [contactList, setContactList] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedContact, setSelectedContact] = useState({});
  const [isUpdateContactList, setUpdateContactList] = useState(false);
  const [fetchMoreChatList, setFetchMoreChatList] = useState(true);
  const [fetchMoreContactList, setFetchMoreContactList] = useState(true);
  const [limit, setLimit] = useState(20);
  const [isReportSpam, setReportSpam] = useState(false);
  const [reportReason, setReportReason] = useState("");
  const [searchTextContact, setSearchTextContact] = useState("");
  const [sortIndexContact, setSortIndexContact] = useState(0);
  const [selectedSortContact, setSelectedSortContact] = useState(
    "Sort by: Select"
  );
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isBlockReportModalOpen, setIsBlockReportModalOpen] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [searchTextChat, setSearchTextChat] = useState("");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getContactList() {
      const contactListResponse = await contactListApi({
        searchTextContact,
        sortIndexContact,
        skip: 0,
      });
      if (contactListResponse.Error) {
        isUnauthorized({ response: contactListResponse });
        setErrorMessage(contactListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      if (
        contactListResponse &&
        contactListResponse.data &&
        (contactListResponse.data.length < limit ||
          contactListResponse.data.length === 0)
      ) {
        setFetchMoreContactList(false);
      }
      setContactList(
        contactListResponse?.data ? contactListResponse?.data : []
      );
    }
    getContactList();
  }, [isUpdateContactList, limit, searchTextContact, sortIndexContact]);

  useEffect(() => {
    async function getChatListing() {
      const { id } = userInstance;
      const myChatListingResponse = await myChatListingApi({
        id,
        skip: 0,
        searchTextChat,
      });
      if (myChatListingResponse.Error) {
        isUnauthorized({ response: myChatListingResponse });
        setErrorMessage(myChatListingResponse.Error.message);
        handleErrorModalShow();
        return;
      } else {
        if (
          myChatListingResponse.length < limit ||
          myChatListingResponse.length === 0
        ) {
          setFetchMoreChatList(false);
        }
        setChatList(myChatListingResponse);
      }
    }

    getChatListing();
  }, [limit, userInstance, searchTextChat]);

  const chatUnreadCounter = async (chatId) => {
    let chatCounterRespose = await unreadChatCounterApi(chatId);
  };

  const paginateContactList = async () => {
    const { id } = userInstance;
    const contactListResponse = await contactListApi({
      id,
      skip: contactList ? contactList.length : 0,
    });
    if (contactListResponse.Error) {
      isUnauthorized({ response: contactListResponse });
      setErrorMessage(contactListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    if (
      contactListResponse &&
      contactListResponse.data &&
      (contactListResponse.data.length < limit ||
        contactListResponse.data.length === 0)
    ) {
      setFetchMoreContactList(false);
    }
    setContactList((contacts) => [...contacts, ...contactListResponse.data]);
  };

  const loadMoreDataChat = async () => {
    const { id } = userInstance;
    const myChatListingResponse = await myChatListingApi({
      id,
      skip: chatList.length,
    });

    if (myChatListingResponse.Error) {
      isUnauthorized({ response: myChatListingResponse });
      setErrorMessage(myChatListingResponse.Error.message);
      handleErrorModalShow();
      return;
    } else {
      if (
        myChatListingResponse.length < limit ||
        myChatListingResponse.length === 0
      ) {
        setFetchMoreChatList(false);
      }
      setChatList((chats) => [...chats, ...myChatListingResponse]);
    }
  };

  const handleRemoveUserFromContactList = async () => {
    const { id } = selectedContact;
    enableLoading();
    const contactListResponse = await removeContactApi({
      id: id,
    });
    disableLoading();
    if (contactListResponse.Error) {
      isUnauthorized({ response: contactListResponse });
      setErrorMessage(contactListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage(
      `${selectedContact.addedcontact.firstname} ${selectedContact.addedcontact.lastname} removed successfully from your contact list`
    );
    handleSuccessModalShow();
    hideConfirmationModal();
    hidePorfileViewModal();
    setFetchMoreContactList(true);
    setContactList(null);
    updateContactList();
  };

  const handleMakeAPaymentContact = () => {
    const { added_contact_id } = selectedContact;
    if (added_contact_id) {
      history.push(`/make-payment/contactId=${added_contact_id}`);
    }
  };

  const handleRequestAPaymentContact = () => {
    const { added_contact_id } = selectedContact;
    if (added_contact_id) {
      history.push(`/request-payment/contactId=${added_contact_id}`);
    }
  };

  const showPorfileViewModal = () => {
    setPorfileView(true);
  };

  const hidePorfileViewModal = () => {
    setPorfileView(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addDataToSelectedContact = (data) => {
    setSelectedContact(data);
  };

  const removeDataFromSelectedContact = () => {
    setSelectedContact({});
  };

  const updateContactList = () => {
    setUpdateContactList(true);
  };

  const handleBlockReport = async () => {
    const { added_contact_id } = selectedContact;
    const blockContactObj = {
      added_contact_id,
      is_block_contact: true,
      is_report_contact: isReportSpam,
      block_reason: reportReason,
    };
    enableLoadingSave();
    const blockContactResponse = await blockContactApi(blockContactObj);
    disableLoadingSave();
    if (blockContactResponse.Error) {
      setErrorMessage(blockContactResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setSuccessMessage("You have successfully blocked");
    handleSuccessModalShow();
    hidePorfileViewModal();
    handleBlockReportModalHide();
    setFetchMoreContactList(true);
    setContactList(null);
    updateContactList();
  };

  const onChangeOfContactSearch = (event) => {
    setSearchTextContact(event.target.value);
  };

  // const onChangeOfChatSearch = (event) => {
  //   setSearchTextChat(event.target.value);
  // };

  const sortContactList = (eventKey) => {
    setSortIndexContact(parseInt(eventKey));
    if (parseInt(eventKey) === 0) {
      setSelectedSortContact("Sort by: Select");
    } else if (parseInt(eventKey) === 1) {
      setSelectedSortContact("Sort by: Alphabetical ASC");
    } else if (parseInt(eventKey) === 2) {
      setSelectedSortContact("Sort by: Alphabetical DESC");
    } else if (parseInt(eventKey) === 3) {
      setSelectedSortContact("Sort by: Date : New to Old");
    } else if (parseInt(eventKey) === 4) {
      setSelectedSortContact("Sort by: Date : Old To New");
    }
  };
  const OnClickOfOkButton = async () => {
    handleRemoveUserFromContactList();
  };

  const handleReportSpam = (isReportSpam) => {
    setReportSpam(isReportSpam);
  };

  const handleReportReason = (reportReason) => {
    setReportReason(reportReason);
  };
  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleBlockReportModalShow = () => {
    setIsBlockReportModalOpen(true);
  };

  const handleBlockReportModalHide = () => {
    setIsBlockReportModalOpen(false);
  };

  const enableLoadingSave = () => {
    setLoadingSave(true);
  };

  const disableLoadingSave = () => {
    setLoadingSave(false);
  };

  const showConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const renderUserProfilePic = (chat) => {
    let path = "";
    const { user_id, receiverId, userId } = chat;
    const { id } = userInstance;

    if (id === userId && receiverId && receiverId.profile) {
      path = getImageBasedOnNameUrl(receiverId.profile);
    } else if (id !== userId && user_id && user_id.profile) {
      path = getImageBasedOnNameUrl(user_id.profile);
    } else {
      path = toAbsoluteUrl("/media/users/default.jpg");
    }
    return path;
  };

  const renderLastMessageFromChat = (chat) => {
    let lastMessage = "";
    let isYouText = "";
    const { id } = userInstance;

    if (!id) return isYouText;
    if (!chat) return isYouText;
    const { chat_id } = chat;
    if (!chat_id) return isYouText;
    if (chat_id && chat_id.length > 0) {
      const { senderId, text } = chat_id[0];
      if (!senderId) return isYouText;
      if (id === senderId) {
        isYouText = "You: ";
      }

      lastMessage = text;
    }
    return isYouText + lastMessage;
  };

  const renderContactName = (contact) => {
    const { addedcontact } = contact || {};
    const { firstname = "", lastname = "", cellnumber = "" } =
      addedcontact || {};
    if (firstname || lastname) {
      return `${firstname} ${lastname}`;
    } else {
      return cellnumber;
    }
  };

  const renderChatDate = (chat) => {
    const { chat_id } = chat || {};
    const [chatItem] = chat_id || [];
    const { createdAt } = chatItem || {};
    return (
      <Moment
        date={createdAt}
        format={moment(createdAt).isSame(new Date(), "week") ? "ddd" : "MMM DD"}
        tz={TIME_ZONE}
      />
    );
  };

  return (
    <>
      <div className="contact-messaging-wrapper">
        <div className="contact-m-inner">
          <Card className="card-custom card-stretch">
            <Tab.Container id="right-tabs-example" defaultActiveKey="first">
              <Card.Header className="align-items-center border-bottom mt-4">
                <h3 className="card-title align-items-start flex-column d-none d-lg-flex">
                  <span className="font-weight-bold text-dark">
                    Contacts &amp; Messaging
                  </span>
                </h3>

                <Nav variant="pills" className="">
                  <Nav.Item>
                    <Nav.Link
                      className="btn font-weight-bolder mr-2 mobile-nav"
                      eventKey="first"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/User.svg"
                          )}
                        />
                      </span>
                      Contact List
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      className="btn font-weight-bolder"
                      eventKey="second"
                    >
                      <span className="svg-icon menu-icon mr-2">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Communication/Chat1.svg"
                          )}
                        />
                      </span>
                      Chats
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Card.Header>
              <div className="contact-m-body">
                <Card.Body>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <>
                        <div className="d-flex justify-content-between mb-8">
                          <div className="input-icon h-40 mr-3">
                            <span className="svg-icon menu-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/Search.svg"
                                )}
                              />
                            </span>
                            <input
                              type="text"
                              className="form-control form-control-md form-control-solid-brand h-40px"
                              placeholder="Search (Name, Username)"
                              onChange={onChangeOfContactSearch}
                            />
                          </div>
                          <div>
                            <ButtonToolbar>
                              {["down"].map((direction) => (
                                <DropdownButton
                                  drop={direction}
                                  variant="secondary"
                                  title={`${selectedSortContact}`}
                                  id={`dropdown-button-drop-${direction}`}
                                  className="font-weight-bold ml-3 gray-6"
                                  key={direction}
                                >
                                  <Dropdown.Item
                                    eventKey="0"
                                    onSelect={(eventKey, event) => {
                                      sortContactList(eventKey);
                                    }}
                                  >
                                    Select
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="1"
                                    onSelect={(eventKey, event) => {
                                      sortContactList(eventKey);
                                    }}
                                  >
                                    Alphabetical ASC
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="2"
                                    onSelect={(eventKey, event) => {
                                      sortContactList(eventKey);
                                    }}
                                  >
                                    Alphabetical DESC
                                  </Dropdown.Item>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    eventKey="3"
                                    onSelect={(eventKey, event) => {
                                      sortContactList(eventKey);
                                    }}
                                  >
                                    Date : New to Old
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    eventKey="4"
                                    onSelect={(eventKey, event) => {
                                      sortContactList(eventKey);
                                    }}
                                  >
                                    Date : Old To New
                                  </Dropdown.Item>
                                </DropdownButton>
                              ))}
                            </ButtonToolbar>
                          </div>
                        </div>

                        {contactList && contactList.length > 0 && (
                          <InfiniteScroll
                            dataLength={contactList.length}
                            next={paginateContactList}
                            hasMore={fetchMoreContactList}
                            loader={
                              <h4 style={{ textAlign: "center" }}>
                                Loading...
                              </h4>
                            }
                          >
                            <Row>
                              {contactList.map((contact, index) => (
                                <Col sm={12} md={6} key={index}>
                                  <div
                                    onClick={() => {
                                      addDataToSelectedContact(contact);
                                      showPorfileViewModal();
                                    }}
                                    className="cursor-pointer border-bottom d-flex justify-content-between mb-5"
                                  >
                                    <h6 className="text-secondary mb-5 font-weight-bolder fs-16 text-capitalize">
                                      {renderContactName(contact)}
                                    </h6>
                                    <span className="svg-icon svg-icon-brand">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Arrow-right.svg"
                                        )}
                                      />
                                    </span>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </InfiniteScroll>
                        )}

                        {contactList && contactList.length === 0 && (
                          <EmptyRecentActivityContent
                            image="/media/svg/illustrations/contact-message.svg"
                            title="You Don't Have Any Contacts Yet!"
                            description="Your Contact List will automatically be populated as you complete transactions."
                          />
                        )}
                      </>
                    </Tab.Pane>

                    <Tab.Pane eventKey="second">
                      <div className="input-icon h-40 mb-8">
                        <span className="svg-icon menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Search.svg"
                            )}
                          />
                        </span>
                        <input
                          type="text"
                          className="form-control form-control-md form-control-solid-brand h-40px"
                          placeholder="Search User"
                          id="generalSearch"
                          onChange={(event) => {
                            setSearchTextChat(event.target.value);
                          }}
                        />
                      </div>

                      {chatList && chatList.length > 0 && (
                        <>
                          <InfiniteScroll
                            dataLength={chatList.length}
                            next={loadMoreDataChat}
                            hasMore={fetchMoreChatList}
                            loader={
                              <h4 style={{ textAlign: "center" }}>
                                Loading...
                              </h4>
                            }
                          >
                            <Row className="lastnth-border-none">
                              {chatList.map((chat, index) => (
                                <Col sm={12} md={12} key={index}>
                                  <div
                                    onClick={async () => {
                                      chatUnreadCounter(chat.id);
                                      const myChatListingResponse = await myChatListingApi(
                                        {
                                          skip: 0,
                                          searchTextChat,
                                        }
                                      );
                                      setChatList(myChatListingResponse);
                                      const { id } = userInstance;
                                      if (!id) return;
                                      if (!chat) return;
                                      const { userId, receiver_id } = chat;
                                      if (!userId) return;
                                      if (!receiver_id) return;
                                      if (userId === id) {
                                        const path = `/single-chat/${receiver_id}`;
                                        history.push(path);
                                      } else {
                                        const path = `/single-chat/${userId}`;
                                        history.push(path);
                                      }
                                    }}
                                    className="d-flex align-items-start align-items-md-center border-bottom pb-5 mb-5 cursor-pointer"
                                  >
                                    <div className="d-flex align-items-start align-items-md-center flex-shrink-1">
                                      <div className="symbol symbol-50 symbol-circle mr-5">
                                        <CustomImage
                                          src={renderUserProfilePic(chat)}
                                        />
                                      </div>
                                      <div className="message-content">
                                        <h6 className="text-secondary font-weight-bolder fs-16 mb-1 text-capitalize">
                                          {userInstance.id === chat.userId && (
                                            <>
                                              {chat.receiverId.business_name
                                                ? `${chat.receiverId.firstname} ${chat.receiverId.lastname}, Member of ${chat.receiverId.business_name}`
                                                : chat.receiverId.firstname +
                                                  " " +
                                                  chat.receiverId.lastname}
                                            </>
                                          )}

                                          {userInstance.id !== chat.userId && (
                                            <>
                                              {chat.user_id.business_name
                                                ? `${chat.user_id.firstname} ${chat.user_id.lastname}, Member of ${chat.user_id.business_name}`
                                                : chat.user_id.firstname +
                                                  " " +
                                                  chat.user_id.lastname}
                                            </>
                                          )}
                                        </h6>

                                        <div className="mb-0 text-secondary fs-13 text-capitalize">
                                          <LinesEllipsis
                                            text={renderLastMessageFromChat(
                                              chat
                                            )}
                                            maxLine="2"
                                            ellipsis="..."
                                            trimRight
                                            basedOn="letters"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="notification-counter-time d-flex align-items-start align-items-md-center">
                                      <div className="notification-counter chat-counter badge badge-pill badge-success">
                                        {chat.unread_chat_counter &&
                                        chat.unread_chat_counter > 0
                                          ? chat.unread_chat_counter
                                          : ""}
                                      </div>
                                      <div className="d-flex justify-content-end ml-3 msg-time">
                                        {renderChatDate(chat)}
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              ))}
                            </Row>
                          </InfiniteScroll>
                        </>
                      )}
                      {chatList && chatList.length === 0 && (
                        <EmptyRecentActivityContent
                          image="/media/svg/illustrations/chat.svg"
                          title={YOU_DONT_HAVE_ANY_MESSAGES_YET}
                          description={YOU_DONT_HAVE_ANY_MESSAGES_DESC}
                        />
                      )}
                      <Link to="/single-chat" className="flot-button">
                        <span className="svg-icon svg-icon-white svg-icon-gray3 menu-icon svg-icon-2x mr-0">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus.svg"
                            )}
                          />
                        </span>
                      </Link>
                    </Tab.Pane>
                  </Tab.Content>
                </Card.Body>
              </div>
            </Tab.Container>
          </Card>
        </div>
      </div>
      <ContactSingleUserView
        isPorfileViewShow={isPorfileViewShow}
        hidePorfileViewModal={hidePorfileViewModal}
        data={selectedContact}
        handleBlockReport={handleBlockReport}
        isReportSpam={handleReportSpam}
        reportReason={handleReportReason}
        isBlockReportModalOpen={isBlockReportModalOpen}
        handleBlockReportModalHide={handleBlockReportModalHide}
        handleBlockReportModalShow={handleBlockReportModalShow}
        loadingSave={loadingSave}
        handleRequestAPaymentContact={handleRequestAPaymentContact}
        handleMakeAPaymentContact={handleMakeAPaymentContact}
        showConfirmationModal={showConfirmationModal}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0"></span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
      <ConfirmationModal
        title={"Remove Contact"}
        infoMessage={`Are you sure you want to Remove?`}
        isConfirmationModalOpen={isConfirmationModalOpen}
        hideConfirmationModal={hideConfirmationModal}
        okButtonLabel={"Remove"}
        OnClickOfOkButton={OnClickOfOkButton}
        cancelButtonLabel={"Cancel"}
        isLoading={isLoading}
      />
    </>
  );
}
