import { useFormik } from "formik";
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { HelpSupportSchema } from "../../../utils/FormikSchema";
import { normalizeInput } from "../../../utils/utils";
import { Button } from "react-bootstrap";
import ErrorModal from "../../Modal/ErrorModal";
import SVG from "react-inlinesvg";
import { supportApi } from "../../../../axios/services/services";
import UpdatedSuccessfulModal from "../../Modal/UpdatedSuccessfulModal";
import { useHistory } from "react-router-dom";

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  mobilenumber: "",
  supporttype: "",
  prefferedmethod: "",
  description: "",
};

function HelpSupport(props) {
  const [isLoading, setLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const history = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: HelpSupportSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const {
        firstname,
        lastname,
        email,
        mobilenumber,
        supporttype,
        prefferedmethod,
        description,
      } = values;
      enableLoading();
      let supportObj = {
        firstname,
        lastname,
        email,
        mobilenumber,
        supporttype,
        prefferedmethod,
        description,
      };
      const supportResponse = await supportApi(supportObj);
      disableLoading();
      const { Error } = supportResponse || {};
      if (Error) {
        const { message } = Error || {};
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }

      // show that your query successfully sent to the support team
      // clear form data
      setSuccessMessage(
        "Your query/feedback successfully sent to the support team"
      );
      handleSuccessModalShow();
      formik.handleReset();
    },
  });

  const handleChange = (event) => {
    const cellNumberWithFormat = normalizeInput(
      event.target.value,
      formik.values.mobilenumber
    );
    formik.setFieldValue("mobilenumber", cellNumberWithFormat);
  };

  return (
    <>
      <div className="container history-wrapper">
        {/* begin::Head */}
        <div className="row mx-auto">
          <div className="col-lg-4 col-md-12">
            <div className="vertical-center-support-image">
              <img
                className="align-bottom p-2"
                src={toAbsoluteUrl("/media/svg/illustrations/help-support.svg")}
                alt=""
                height={275}
              />
            </div>
          </div>
          <div className="col-lg-8 col-md-12">
            <div className="mb-1 text-center">
              <h3 className="h1 font-size-md-h1 font-weight-bolder">
                How can we help you?
              </h3>
            </div>
            <div className="mt-5">
              <div className="h3 font-size-md-h3 font-weight-bold text-center">
                For all questions, feedback, or technical issues, please email
                us at
                <span className="text-brand font-weight-bold">
                  {" "}
                  admin@payxtreme.com{" "}
                </span>
                or fill out the form below. We will get back to you as soon as
                possible!
              </div>
              <div className="mt-10">
                <div>
                  <form
                    onSubmit={formik.handleSubmit}
                    onReset={formik.handleReset}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                    autocomplete="off"
                  >
                    <div>
                      <div className="row g-2">
                        <div className="col-lg-6 col-md-12 mb-4">
                          <input
                            placeholder="First Name*"
                            type="text"
                            className="form-control h-auto py-5 px-6"
                            name="firstname"
                            {...formik.getFieldProps("firstname")}
                          />
                          {formik.touched.firstname &&
                          formik.errors.firstname ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.firstname}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                          <input
                            placeholder="Last Name*"
                            type="text"
                            className="form-control h-auto py-5 px-6"
                            name="lastname"
                            {...formik.getFieldProps("lastname")}
                          />
                          {formik.touched.lastname && formik.errors.lastname ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.lastname}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                          <input
                            placeholder="Email Address*"
                            type="email"
                            className="form-control h-auto py-5 px-6"
                            name="email"
                            {...formik.getFieldProps("email")}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.email}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                          <input
                            placeholder="Mobile Number*"
                            type="tel"
                            className="form-control h-auto py-5 px-6"
                            name="mobilenumber"
                            value={formik.values.mobilenumber}
                            {...formik.getFieldProps("mobilenumber")}
                            onChange={(event) => {
                              handleChange(event);
                            }}
                          />
                          {formik.touched.mobilenumber &&
                          formik.errors.mobilenumber ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.mobilenumber}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                          <select
                            className="form-control h-auto py-5 px-6"
                            name="supporttype"
                            value={formik.values.supporttype}
                            {...formik.getFieldProps("supporttype")}
                          >
                            <option value=""> Select Type </option>
                            <option value="question"> Question </option>
                            <option value="feedback"> Feedback </option>
                            <option value="technicalissue">
                              Technical Issue
                            </option>
                          </select>
                          {formik.touched.supporttype &&
                          formik.errors.supporttype ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.supporttype}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-lg-6 col-md-12 mb-4">
                          <select
                            className="form-control h-auto py-5 px-6"
                            name="prefferedmethod"
                            value={formik.values.prefferedmethod}
                            {...formik.getFieldProps("prefferedmethod")}
                          >
                            <option value=""> Preferred Contact Method </option>
                            <option value="email"> Email </option>
                            <option value="phonecall"> Phone call </option>
                          </select>
                          {formik.touched.prefferedmethod &&
                          formik.errors.prefferedmethod ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.prefferedmethod}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 mb-4">
                          <textarea
                            name="description"
                            className="form-control form-control-simple no-resize"
                            rows="5"
                            id="default-textarea"
                            placeholder="Type your message...*"
                            {...formik.getFieldProps("description")}
                          ></textarea>
                          {formik.touched.description &&
                          formik.errors.description ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                <i className="fas fa-times text-danger mr-1 text-center"></i>
                                {formik.errors.description}
                              </div>
                            </div>
                          ) : null}
                        </div>
                        <div className="fv-plugins-message-container pr-5 pl-5">
                          <div className="fv-help-block fs-16">
                            For technical issues, please provide as much
                            information as possible. If you have an account,
                            provide the mobile number associated with your
                            account and a transaction ID if you have one. Thank
                            you!
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end mt-5">
                        <Button
                          type="submit"
                          variant="btn btn-brand btn-lg mr-2 font-weight-bold my-5"
                          disabled={isLoading}
                        >
                          Submit
                          {isLoading && (
                            <span className="ml-3 mr-3 spinner spinner-white"></span>
                          )}
                        </Button>
                        <Button
                          variant="btn btn-secondary btn-lg font-weight-bold my-5"
                          onClick={() => {
                            history.push("/");
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* end::Head */}
      </div>
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default HelpSupport;
