/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import {
  HH_MMA,
  MMMM_DO_YYYY,
  TIME_ZONE,
} from "../../../../utils/dateTimeFormats";
export const ExpectedDeliveryDateColumnFormatter = (
  cellContent,
  row,
  rowIndex
) => {
  const { expecteddeliverydate, transaction_status } = row || {};
  const { id: statusId } = transaction_status || {};
  if (statusId !== 3) {
    return "-";
  }
  return (
    <>
      <span className={`text-capitalize text-dark-75 d-block`}>
        {`Pending Until `}
        <Moment
          date={expecteddeliverydate}
          format={MMMM_DO_YYYY}
          tz={TIME_ZONE}
        />
        <br />
        {"at "}
        <Moment date={expecteddeliverydate} format={HH_MMA} tz={TIME_ZONE} />
        {" EST"}
      </span>
    </>
  );
};
