import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Row, Col, Button, ButtonToolbar, Modal, Badge } from "react-bootstrap";
import { NumberFormatter } from "../../utils/NumberFormatter";
import { useLocalStorage } from "../../utils/useLocalStorage";

function AcceptFundPrimaryBankModal({
  isLoadingAcceptFundPrimaryBank,
  isAcceptFundPrimaryBankModal,
  fundingAccountList,
  selectedPaymentMethodDetails,
  setSelectedPaymentMethodDetails,
  handleAcceptFundPrimaryBankModalHide,
  onClickOfSaveButton,
  transactionDetails,
}) {
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");

  const renderReceiverName = (transactionDetails) => {
    return (
      <>
        {transactionDetails &&
          transactionDetails.senderId &&
          transactionDetails.senderId === userInstance.id &&
          transactionDetails.receiver &&
          transactionDetails.receiver.firstname &&
          transactionDetails.receiver.lastname &&
          `${transactionDetails.receiver.firstname} ${transactionDetails.receiver.lastname}`}

        {transactionDetails &&
          transactionDetails.senderId &&
          transactionDetails.senderId !== userInstance.id &&
          transactionDetails.sender &&
          transactionDetails.sender.firstname &&
          transactionDetails.sender.lastname &&
          `${transactionDetails.sender.firstname} ${transactionDetails.sender.lastname}`}
      </>
    );
  };

  const renderPrimayBank = () => {
    return (
      <Row className="mt-5">
        {fundingAccountList &&
          fundingAccountList
            .filter(
              (fundingAccount) =>
                fundingAccount.confirmed && fundingAccount.isPrimary
            )
            .map((fundingAccount, index) => {
              const { others } = fundingAccount || {};
              const accounts = JSON.parse(others) || {};
              const { AccountType, BankAccount, FundingAccountName = "" } =
                accounts || {};
              const { AccountLastFour = "" } = BankAccount || {};
              return (
                <React.Fragment key={index}>
                  {(AccountType === "Savings" ||
                    AccountType === "Checking") && (
                    <Col
                      sm={12}
                      md={12}
                      className="mb-3"
                      onClick={() => {
                        setSelectedPaymentMethodDetails(fundingAccount);
                      }}
                    >
                      <div className="saved-details border">
                        <div className="d-flex">
                          <div className="symbol symbol-50 mr-3">
                            <i className="fa fa-landmark text-success icon-3x"></i>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                            <div className="details text-left">
                              <h5 className="font-weight-bolder fs-16 mb-0 text-capitalize">
                                {FundingAccountName}
                              </h5>
                              <div className="text-secondary fs-13">
                                {`${AccountType} XXXXXXX${AccountLastFour}`}
                              </div>
                            </div>
                            <div className="right-info d-flex justify-content-end align-items-center">
                              <Badge
                                className="w-100"
                                style={{ backgroundColor: "#9b111e" }}
                                pill
                                variant="success"
                              >
                                Default
                              </Badge>
                              <div className="plus-btn">
                                <div className="btn btn-icon btn-circle cursor-pointer">
                                  {selectedPaymentMethodDetails &&
                                  selectedPaymentMethodDetails.id ===
                                    fundingAccount.id ? (
                                    <span className="svg-icon svg-icon-success menu-icon">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Check.svg"
                                        )}
                                      />
                                    </span>
                                  ) : (
                                    <span className="svg-icon svg-icon-brand menu-icon">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Navigation/Plus-Primary.svg"
                                        )}
                                      />
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
      </Row>
    );
  };

  const renderBanksNotConfirmed = () => {
    return (
      <>
        {fundingAccountList &&
          fundingAccountList
            .filter(
              (fundingAccount) =>
                fundingAccount.confirmed && !fundingAccount.isPrimary
            )
            .map((fundingAccount, index) => {
              const { others } = fundingAccount || {};
              const accounts = JSON.parse(others) || {};
              const { AccountType, BankAccount, FundingAccountName = "" } =
                accounts || {};
              const { AccountLastFour = "" } = BankAccount || {};
              return (
                <React.Fragment key={index}>
                  {(AccountType === "Savings" ||
                    AccountType === "Checking") && (
                    <Col
                      sm={12}
                      md={12}
                      className="mb-3"
                      onClick={() => {
                        setSelectedPaymentMethodDetails(fundingAccount);
                      }}
                    >
                      <div className="saved-details border">
                        <div className="d-flex">
                          <div className="symbol symbol-50 mr-3">
                            <i className="fa fa-landmark text-success icon-3x"></i>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                            <div className="details text-left">
                              <h5 className="font-weight-bolder fs-16 mb-0 text-capitalize">
                                {FundingAccountName}
                              </h5>
                              <div className="text-secondary fs-13">
                                {`${AccountType} XXXXXXX${AccountLastFour}`}
                              </div>
                            </div>
                            <div className="plus-btn">
                              <div className="btn btn-icon btn-circle cursor-pointer">
                                {selectedPaymentMethodDetails &&
                                selectedPaymentMethodDetails.id ===
                                  fundingAccount.id ? (
                                  <span className="svg-icon svg-icon-success menu-icon">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Check.svg"
                                      )}
                                    />
                                  </span>
                                ) : (
                                  <span className="svg-icon svg-icon-brand menu-icon">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Plus-Primary.svg"
                                      )}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
                </React.Fragment>
              );
            })}
      </>
    );
  };

  const { amount, transactionTypeId, deposite_type } = transactionDetails || {};
  const { name } = deposite_type || {};

  return (
    <>
      <Modal
        show={isAcceptFundPrimaryBankModal}
        onHide={handleAcceptFundPrimaryBankModalHide}
        dialogClassName="modal-150w"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop={"static"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            <div className="text-center ">
              <h2 className="text-dark mb-6">Review and Authorize</h2>
              <p className="fs-18 font-weight-normal text-dark">
                {`${transactionTypeId === 3 ? "Receive" : "Send"} `}
                <NumberFormatter amount={amount} />
                {` ${transactionTypeId === 3 ? "from" : "To"} @`}
                {renderReceiverName(transactionDetails)} {`via ${name}`}
              </p>
            </div>

            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={handleAcceptFundPrimaryBankModalHide}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0 acceptFundPrimarybankModalCloseIcon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          <>
            <div className="d-flex justify-content-center mt-5">
              <div className="bank-icon-wrapper">
                <i className="fa fa-landmark text-success icon-3x"></i>
              </div>
            </div>
            <h5 className="fs-16 font-weight-boldest mt-4">
              To accept and fulfill this{" "}
              {transactionTypeId === 3 ? "fund" : "request"}, select your
              payment method below:
            </h5>
            {/* <p className="text-left text-secondary">
              The funds will be deduct to below bank account.
            </p> */}
            <div className="col-lg-12 col-xl-12 account-setting mt-5">
              {renderPrimayBank()}
            </div>
            {/* <p className="text-left text-secondary">
              If you want to Change the funds to another bank account you have
              linked, please select bank account from below list to redirect the
              funds.
            </p> */}
            <div className="account-setting mt-5">
              {renderBanksNotConfirmed()}
            </div>
            <p className="text-left text-secondary fs-13">
              <span className="text-brand font-weight-bold">
                *This action will not change your primary account.
              </span>
            </p>
            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                variant="btn btn-success mr-3"
                onClick={onClickOfSaveButton}
                disabled={isLoadingAcceptFundPrimaryBank}
              >
                {transactionTypeId === 3 ? "Accept Funds" : "Accept Request"}
                {isLoadingAcceptFundPrimaryBank && (
                  <span className=" mr-3 ml-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={handleAcceptFundPrimaryBankModalHide}
                disabled={isLoadingAcceptFundPrimaryBank}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AcceptFundPrimaryBankModal;
