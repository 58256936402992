/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import "moment-timezone";
import { MM_DD_YY_hh_mm_A, TIME_ZONE } from "../../../../utils/dateTimeFormats";
export const ExpectedDeliveryDateColumnFormatter = (
  cellContent,
  row,
  rowIndex
) => {
  const { expecteddeliverydate, transaction_status } = row || {};
  const { id: statusId } = transaction_status || {};
  if (statusId !== 3) {
    return "-";
  }
  return (
    <>
      <span className={`text-capitalize text-dark`}>
        {`Pending Until `}
        <Moment
          date={expecteddeliverydate}
          format={MM_DD_YY_hh_mm_A}
          tz={TIME_ZONE}
        />
        {" EST"}
      </span>
    </>
  );
};
