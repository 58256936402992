import React from "react";

function MakePaymentWizardStepThree({ formik }) {
  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span>
              <i className="fas fa-home icon-4x text-dark"></i>
            </span>
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center mb-0">
              Verify the Property Address
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12 mt-10">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control h-auto py-5 px-6"
                  placeholder="Address 1*"
                  name="address1"
                  disabled={true}
                  {...formik.getFieldProps("address1")}
                />
                {formik.touched.address1 && formik.errors.address1 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.address1}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control h-auto py-5 px-6"
                  placeholder="Address 2"
                  name="address2"
                  disabled={true}
                  {...formik.getFieldProps("address2")}
                />
                {formik.touched.address2 && formik.errors.address2 ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.address2}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-6">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control h-auto py-5 px-6"
                  placeholder="City*"
                  name="city"
                  disabled={true}
                  {...formik.getFieldProps("city")}
                />
                {formik.touched.city && formik.errors.city ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.city}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control h-auto py-5 px-6"
                  placeholder="State/Province*"
                  name="state"
                  disabled={true}
                  {...formik.getFieldProps("state")}
                />
                {formik.touched.state && formik.errors.state ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.state}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="text"
                  className="form-control h-auto py-5 px-6"
                  placeholder="Country/Region*"
                  name="country"
                  disabled={true}
                  {...formik.getFieldProps("country")}
                />
                {formik.touched.country && formik.errors.country ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.country}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-6">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="number"
                  className="form-control h-auto py-5 px-6"
                  placeholder="Zip/Postal Code*"
                  name="zipcode"
                  disabled={true}
                  {...formik.getFieldProps("zipcode")}
                />
                {formik.touched.zipcode && formik.errors.zipcode ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.zipcode}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePaymentWizardStepThree;
