import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ENTER_THE_AMOUNT_AND_METHOD_OF_PAYMENT } from "../../../utils/const";

function MakePaymentWizardStepFive({
  formik,
  fundingAccountList,
  frontCheque,
  backCheque,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  selectedPaymentMethodDetails,
  setSelectedPaymentMethodDetails,
  setFrontCheque,
  setBackCheque,
  isGeneralPayment,
}) {
  const onChangeFontCheque = (imageList, addUpdateIndex) => {
    // data for submit
    setFrontCheque(imageList);
  };

  const onChangeBackCheque = (imageList, addUpdateIndex) => {
    // data for submit
    setBackCheque(imageList);
  };

  const renderCardType = () => {
    return <img alt="CC" src="media/svg/logos/credit-card.svg" />;
  };

  const renderNoBanksAndCards = () => {
    return (
      <div className="save-card-box mb-3">
        <span className="text-danger fs-14 font-weight-light">
          You do not have a bank account or card linked to your account. Please
          go to your Account Settings and add a payment method to continue.
        </span>
      </div>
    );
  };

  const renderNoBanksAndCardsNotConfirmed = () => {
    const unconfirmedList = fundingAccountList.filter(
      (fundingAccount) => !fundingAccount.confirmed
    );
    if (fundingAccountList.length === unconfirmedList.length) {
      return renderNoBanksAndCards();
    }

    return <></>;
  };

  const renderBanksAndCards = () => {
    return (
      <>
        {fundingAccountList &&
          fundingAccountList
            .filter((fundingAccount) => fundingAccount.confirmed)
            .map((fundingAccount, index) => {
              const { others } = fundingAccount || {};
              const accounts = JSON.parse(others) || {};
              const {
                AccountType,
                BankAccount,
                CardAccount,
                FundingAccountName = "",
              } = accounts || {};
              const { AccountLastFour = "", RoutingNumber = "" } =
                BankAccount || {};
              const { CreditCardLastFour, CardType } = CardAccount || {};
              return (
                <React.Fragment key={index}>
                  {(AccountType === "Savings" ||
                    AccountType === "Checking") && (
                    <div
                      className="save-card-box mb-3"
                      key={index}
                      onClick={() => {
                        setSelectedPaymentMethod("Bank");
                        setSelectedPaymentMethodDetails(fundingAccount);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-lg-50 symbol-circle border mr-5">
                          <span className="symbol-label bg-white">
                            <img
                              src={toAbsoluteUrl("/media/client-logos/ACH.png")}
                              alt=""
                            />
                          </span>
                        </div>
                        <div className="d-flex flex-column flex-grow-1">
                          <h6 className="text-dark font-weight-bold fs-14 text-capitalize">
                            {FundingAccountName}
                          </h6>
                          <span className="text-secondary fs-14 font-weight-light">
                            {`${AccountType} XXXXXXX${AccountLastFour}`}
                          </span>
                        </div>
                        <div className="plus-btn">
                          <div className="btn btn-icon btn-circle cursor-pointer">
                            {selectedPaymentMethodDetails &&
                            selectedPaymentMethodDetails.id ===
                              fundingAccount.id ? (
                              <span className="svg-icon svg-icon-success menu-icon">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Navigation/Check.svg"
                                  )}
                                />
                              </span>
                            ) : (
                              <span className="svg-icon svg-icon-brand menu-icon">
                                <SVG
                                  src={toAbsoluteUrl(
                                    "/media/svg/icons/Navigation/Plus-Primary.svg"
                                  )}
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {(AccountType === "CreditCard" ||
                    AccountType === "DebitCard") &&
                    isGeneralPayment && (
                      <div
                        className="save-card-box mb-3"
                        onClick={() => {
                          setSelectedPaymentMethod(AccountType);
                          setSelectedPaymentMethodDetails(fundingAccount);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-lg-50 symbol-circle border mr-5">
                            <span className="symbol-label bg-white">
                              {renderCardType()}
                            </span>
                          </div>
                          <div className="d-flex flex-column flex-grow-1">
                            <h6 className="text-dark font-weight-bold fs-14">
                              {FundingAccountName}
                            </h6>
                            <span className="text-secondary fs-14 font-weight-light">
                              {`XXXX-XXXX-XXXX-${CreditCardLastFour}`}
                            </span>
                          </div>
                          <div className="plus-btn">
                            <div className="btn btn-icon btn-circle cursor-pointer">
                              {selectedPaymentMethodDetails &&
                              selectedPaymentMethodDetails.id ===
                                fundingAccount.id ? (
                                <span className="svg-icon svg-icon-success menu-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Check.svg"
                                    )}
                                  />
                                </span>
                              ) : (
                                <span className="svg-icon svg-icon-brand menu-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Plus-Primary.svg"
                                    )}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </React.Fragment>
              );
            })}

        {fundingAccountList &&
          fundingAccountList.length === 0 &&
          renderNoBanksAndCards()}

        {fundingAccountList &&
          fundingAccountList.length > 0 &&
          renderNoBanksAndCardsNotConfirmed()}
      </>
    );
  };

  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span className="svg-icon svg-icon-4x svg-icon-dark menu-icon mr-1">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/SendMoney.svg")}
              />
            </span>{" "}
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center mb-0">
              {ENTER_THE_AMOUNT_AND_METHOD_OF_PAYMENT}
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12 mt-10">
              <div className="form-group mb-4 fv-plugins-icon-container input-euro left">
                <input
                  type="number"
                  className="form-control h-auto py-5 px-10"
                  placeholder="Amount*"
                  name="amount"
                  autoFocus
                  {...formik.getFieldProps("amount")}
                />
                {formik.touched.amount && formik.errors.amount ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.amount}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-12 mt-0 mb-2">
              <div className="mt-2">
                <h5 className="fs-14 font-weight-bolder">
                  Select Method of Payment
                </h5>
              </div>
            </div>
            <div className="col-md-12">
              <div className="selcect-card-wrap">
                {renderBanksAndCards()}
                {/* <div className="save-card-box mb-3">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-lg-50 symbol-circle border mr-5">
                      <span className="symbol-label bg-white">
                        <img
                          src={toAbsoluteUrl(
                            "/media/client-logos/visa-logo.png"
                          )}
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1">
                      <h6 className="text-dark font-weight-bold fs-14">
                        Sample Credit/Debit Card
                      </h6>
                      <span className="text-secondary fs-14 font-weight-light">
                        1234-XXXX-XXXX-9016
                      </span>
                    </div>
                    <div className="plus-btn">
                      <a href="#" className="btn btn-icon btn-circle">
                        <span className="svg-icon svg-icon-brand menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus-Primary.svg"
                            )}
                          />
                        </span>{" "}
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* {bankList &&
                  bankList
                    .filter((d) => d.confirmed)
                    .map((userBank, index) => (
                      <div
                        className="save-card-box mb-3"
                        key={index}
                        onClick={() => {
                          setSelectedPaymentMethod(userBank);
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="symbol symbol-50 symbol-lg-50 symbol-circle border mr-5">
                            <span className="symbol-label bg-white">
                              <img
                                src={toAbsoluteUrl(
                                  "/media/client-logos/ACH.png"
                                )}
                                alt=""
                              />
                            </span>
                          </div>
                          <div className="d-flex flex-column flex-grow-1">
                            <h6 className="text-dark font-weight-bold fs-14 text-capitalize">
                              {userBank &&
                                userBank.bank &&
                                userBank.bank.bank_name}
                            </h6>
                            <span className="text-secondary fs-14 font-weight-light">
                              Savings XXXXXXX
                              {userBank &&
                                userBank.bank_account_number &&
                                userBank.bank_account_number.substring(
                                  userBank.bank_account_number.length - 4
                                )}
                            </span>
                          </div>
                          <div className="plus-btn">
                            <div className="btn btn-icon btn-circle cursor-pointer">
                              {selectedPaymentMethod &&
                              userBank &&
                              userBank.id === selectedPaymentMethod.id ? (
                                <span className="svg-icon svg-icon-success menu-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Check.svg"
                                    )}
                                  />
                                </span>
                              ) : (
                                <span className="svg-icon svg-icon-brand menu-icon">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Plus-Primary.svg"
                                    )}
                                  />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))} */}

                {/* {bankList && bankList.length === 0 && (
                  <div className="save-card-box mb-3">
                    <span className="text-danger fs-14 font-weight-light">
                      No Bank Details Available, Please Add/Confirm Banks From
                      Account Settings!
                    </span>
                  </div>
                )} */}

                {/* <div className="save-card-box mb-3">
                  <div className="d-flex align-items-center">
                    <div className="symbol symbol-50 symbol-lg-50 symbol-circle border mr-5">
                      <span className="symbol-label bg-white">
                        <img
                          src={toAbsoluteUrl(
                            "/media/client-logos/WireTransfer.png"
                          )}
                          alt=""
                        />
                      </span>
                    </div>
                    <div className="d-flex flex-column flex-grow-1">
                      <h6 className="text-dark font-weight-bold fs-14">
                        Bank of America
                      </h6>
                      <span className="text-secondary fs-14 font-weight-light">
                        Savings XXXXXXX8534
                      </span>
                    </div>
                    <div className="plus-btn">
                      <a href="#" className="btn btn-icon btn-circle">
                        <span className="svg-icon svg-icon-brand menu-icon">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Plus-Primary.svg"
                            )}
                          />
                        </span>{" "}
                      </a>
                    </div>
                  </div>
                </div> */}

                {/* <div className="save-card-box mb-3 d-none">
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column flex-grow-1">
                      <h5 className="fs-14 font-weight-bold text-dark mb-4">
                        Capture Check
                      </h5>
                    </div>
                    <div
                      className="plus-btn"
                      onClick={() => {
                        setSelectedPaymentMethod("Check");
                        setSelectedPaymentMethodDetails(null);
                      }}
                    >
                      <div className="btn btn-icon btn-circle cursor-pointer">
                        {selectedPaymentMethod === "Check" ? (
                          <span className="svg-icon svg-icon-success menu-icon">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Check.svg"
                              )}
                            />
                          </span>
                        ) : (
                          <span className="svg-icon svg-icon-brand menu-icon">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Navigation/Plus-Primary.svg"
                              )}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6 col-md-6">
                      <div className="upload-cheque">
                        <ImageUploading
                          value={frontCheque}
                          onChange={onChangeFontCheque}
                          acceptType={["tiff"]}
                          maxNumber={1}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                              <button
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                              ></button>
                              <h5 onClick={onImageUpload}>
                                Select to Upload Front of Check
                              </h5>
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/images/front-cheque.png"
                                    )}
                                    alt=""
                                    width="100%"
                                    height="100%"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="upload-cheque">
                        <ImageUploading
                          // multiple
                          // accept="image/tiff,.tif"
                          fileTypeError="is not supported file extension"
                          value={backCheque}
                          onChange={onChangeBackCheque}
                          acceptType={["tiff"]}
                          maxNumber={1}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper">
                              <button
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                              ></button>
                              <h5 onClick={onImageUpload}>
                                Select to Upload Back of Check
                              </h5>
                              {imageList.map((image, index) => (
                                <div
                                  key={index}
                                  className="image-item"
                                  onClick={onImageUpload}
                                >
                                  <img
                                    src={toAbsoluteUrl(
                                      "/media/images/back-cheque.png"
                                    )}
                                    alt=""
                                    width="100%"
                                    height="100%"
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePaymentWizardStepFive;
