import React, { useEffect, useState } from "react";
// import { Route } from "react-router-dom";
import { Card, Button, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import SVG from "react-inlinesvg";
import MakePaymentWizardStepOne from "./MakePaymentWizardComponant/MakePaymentWizardStepOne";
import MakePaymentWizardStepTwo from "./MakePaymentWizardComponant/MakePaymentWizardStepTwo";
import MakePaymentWizardStepThree from "./MakePaymentWizardComponant/MakePaymentWizardStepThree";
import MakePaymentWizardStepFour from "./MakePaymentWizardComponant/MakePaymentWizardStepFour";
import MakePaymentWizardStepFive from "./MakePaymentWizardComponant/MakePaymentWizardStepFive";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Typography from "@material-ui/core/Typography";
import FundSuccessModal from "../Modal/FundSuccessModal";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useLocalStorage } from "../../utils/useLocalStorage";
import {
  addressSCTransactionApi,
  getBankDetailsApi,
  getCommissionBasedOnAmountApi,
  getFundingAccountsApi,
  makeAPaymentApi,
  recentHistoryPDFApi,
  singleUserDetailApi,
  uploadBackChequeApi,
  uploadFrontChequeApi,
  validateSCTransactionIdApi,
} from "../../../axios/services/services";
import ErrorModal from "../Modal/ErrorModal";
import { isUnauthorized } from "../../utils/utils";
import MakeAPaymentConfirmationModal from "../Modal/MakeAPaymentConfirmationModal";
import { logger } from "../../utils/Debug";
import {
  AmountSchema,
  PaymentVerifyAddressSchema,
  ProfileSchema,
  SCTrasnsactionIdSchema,
} from "../../utils/FormikSchema";
import InfoModal from "../Modal/InfoModal";
import { string } from "prop-types";

const initialValues = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  country: "USA",
  zipcode: "",
  cellnumber: "",
  scTransactionId: "",
};

const wizardStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Step-1", "Step-2", "Step-3", "Step-4", "Step-5"];
}

function MakePayment(props) {
  const [isLoading, setLoading] = useState(false);
  const [isLoadingNextButton, setLoadingNextButton] = useState(false);
  const classes = wizardStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [
    disableAuthorizeAndConfirmButton,
    setDisableAuthorizeAndConfirmButton,
  ] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [isSuccessModal, setSuccessModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");
  const [isGeneralPayment, setGeneralPayment] = useState(true);
  const [searchUserDetails, setSearchUserDetails] = useState(null);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [makeAPaymentDetails, setMakeAPaymentDetails] = useState({});
  // const [bankList, setBankList] = useState([]);
  const [frontCheque, setFrontCheque] = useState([]);
  const [backCheque, setBackCheque] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [
    selectedPaymentMethodDetails,
    setSelectedPaymentMethodDetails,
  ] = useState(null);
  const [paymentNotes, setPaymentNote] = useState("");
  const [paymentSCTransactionType, setPaymentSCTransactionType] = useState("");
  const [addressSCTransaction, setAddressSCTransaction] = useState(null);

  const [
    frontChequeAfterUploadImageName,
    setFrontChequeAfterUploadImageName,
  ] = useState("");
  const [
    backChequeAfterUploadImageName,
    setBackChequeAfterUploadImageName,
  ] = useState("");
  const [fundingAccountList, setFundingAccountList] = useState([]);
  const [isInfoModal, setInfoModal] = useState(false);
  const [commissionAmount, setCommissionAmount] = useState(0);

  const { contactid, txId } = props.match.params;

  useEffect(() => {
    if (txId) {
      setGeneralPayment(false);
      formik.setFieldValue("scTransactionId", txId);
    }
    if (!contactid) {
      formik.setFieldValue("cellnumber", "");
    }
  }, []);

  // useEffect(() => {
  //   async function getBankDetails() {
  //     const { id } = userInstance;
  //     const getBankDetailsResponse = await getBankDetailsApi({ id });
  //     if (getBankDetailsResponse.Error) {
  //       isUnauthorized({ response: getBankDetailsResponse });
  //       setErrorMessage(getBankDetailsResponse.Error.message);
  //       handleErrorModalShow();
  //       return;
  //     }
  //     setBankList(getBankDetailsResponse);
  //   }
  //   getBankDetails();
  // }, []);

  useEffect(() => {
    async function getFundingAccounts() {
      const getFundingAccountsResponse = await getFundingAccountsApi();
      if (getFundingAccountsResponse.Error) {
        isUnauthorized({ response: getFundingAccountsResponse });
        setErrorMessage(getFundingAccountsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setFundingAccountList(getFundingAccountsResponse);
    }
    getFundingAccounts();
  }, []);

  useEffect(() => {
    async function getsingleUserDetail() {
      if (!contactid) return;
      const singleUserDetailResponse = await singleUserDetailApi({
        id: contactid,
      });
      const { Error } = singleUserDetailResponse || {};
      if (Error) {
        isUnauthorized({ response: singleUserDetailResponse });
        setErrorMessage(Error.message);
        handleErrorModalShow();
        return;
      }
      setSearchUserDetails(singleUserDetailResponse);
      const { cellnumber } = singleUserDetailResponse;
      if (cellnumber) {
        formik.values.cellnumber = cellnumber;
      }
    }
    getsingleUserDetail();
  }, [contactid]);

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const handleSuccessShow = () => {
    setSuccessModal(true);
  };

  const handleSuccessHide = () => {
    setSuccessModal(false);
  };

  const handleSearchUserDetails = (searchUserDetails) => {
    setSearchUserDetails(searchUserDetails);
  };

  const handleFrontCheque = (imageList) => {
    setFrontCheque(imageList);
    uploadFrontCheque(imageList);
  };

  const handleBackCheque = (imageList) => {
    setBackCheque(imageList);
    uploadBackCheque(imageList);
  };

  const handleSelectedPaymentMethod = (selectedMethod) => {
    setSelectedPaymentMethod(selectedMethod);
  };

  const handleSelectedPaymentMethodDetails = (selectedMethodDetails) => {
    setSelectedPaymentMethodDetails(selectedMethodDetails);
  };

  const uploadFrontCheque = async (frontCheque) => {
    // enable loader
    const [checkimage] = frontCheque;
    let file = {
      file: checkimage.file,
      containerkey: 2,
    };
    const frontChequeReponse = await uploadFrontChequeApi(file);
    // disable loader
    if (frontChequeReponse.Error) {
      setFrontCheque([]);
      isUnauthorized({ response: frontChequeReponse });
      setErrorMessage(frontChequeReponse.Error.message);
      handleErrorModalShow();
      return;
    } else {
      setFrontChequeAfterUploadImageName(frontChequeReponse.newFilename);
    }
  };

  const uploadBackCheque = async (backCheque) => {
    // enable loader
    const [checkimage] = backCheque;
    let file = {
      file: checkimage.file,
      containerkey: 2,
    };
    const backChequeReponse = await uploadBackChequeApi(file);
    // disable loader

    if (backChequeReponse.Error) {
      setBackCheque([]);
      isUnauthorized({ response: backChequeReponse });
      setErrorMessage(backChequeReponse.Error.message);
      handleErrorModalShow();
      return;
    } else {
      setBackChequeAfterUploadImageName(backChequeReponse.newFilename);
    }
  };

  const validateSCTransactionId = () => {
    return new Promise(async (resolve, reject) => {
      const { scTransactionId } = formik.values;
      const scTransactionIdObj = { txId: scTransactionId };
      enableLoadingNextButton();
      const validateSCTransactionIdReponse = await validateSCTransactionIdApi(
        scTransactionIdObj
      );
      disableLoadingNextButton();
      if (validateSCTransactionIdReponse.Error) {
        const { Error } = validateSCTransactionIdReponse || {};
        const { message } = Error || {};
        isUnauthorized({ response: validateSCTransactionIdReponse });
        setErrorMessage(message);
        handleErrorModalShow();
        resolve(Error);
      } else {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);
        resolve(validateSCTransactionIdReponse);
      }
    });
  };

  const getAddressSCTransaction = async () => {
    const { scTransactionId } = formik.values;
    const scTransactionAddressObj = { txId: scTransactionId };
    enableLoadingNextButton();
    const addressSCTransactionReponse = await addressSCTransactionApi(
      scTransactionAddressObj
    );
    disableLoadingNextButton();
    if (addressSCTransactionReponse.Error) {
      isUnauthorized({ response: addressSCTransactionReponse });
      setErrorMessage(addressSCTransactionReponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setAddressSCTransaction(addressSCTransactionReponse);
    setSCTransactionDetailsToInputBoxes(addressSCTransactionReponse);
  };

  const setSCTransactionDetailsToInputBoxes = (addressSCTransactionReponse) => {
    const { address } = addressSCTransactionReponse || {};
    const {
      propertyStreetAddress = "",
      propertyStreetAddressSecond,
      propertyCity = "",
      propertyState = "",
      propertyZipCode = "",
      country = "",
    } = address || {};
    formik.setFieldValue("address1", propertyStreetAddress);
    formik.setFieldValue("address2", propertyStreetAddressSecond);
    formik.setFieldValue("city", propertyCity);
    formik.setFieldValue("state", propertyState);
    formik.setFieldValue("country", country);
    formik.setFieldValue("zipcode", propertyZipCode);
  };

  const getCommissionBasedOnAmount = async () => {
    const { amount } = formik.values;
    const { role_id } = userInstance || {};
    const commissionObj = {
      amount,
      transactionModeId: getTransactionType(),
      userType: "" + role_id,
      is_sc: !isGeneralPayment,
    };

    enableLoadingNextButton();
    const commissionReponse = await getCommissionBasedOnAmountApi(
      commissionObj
    );
    disableLoadingNextButton();
    const { Error } = commissionReponse || {};
    if (Error) {
      const { message } = Error || {};
      isUnauthorized({ response: commissionReponse });
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    setCommissionAmount(commissionReponse);
    handleConfirmShow();
  };

  function getStepContent(stepIndex, formik) {
    switch (stepIndex) {
      case 0:
        return (
          <MakePaymentWizardStepOne
            isGeneralPayment={isGeneralPayment}
            setGeneralPayment={setGeneralPayment}
          />
        );
      case 1:
        return (
          <MakePaymentWizardStepTwo
            formik={formik}
            hadleWhatisTxId={hadleWhatisTxId}
            handleIdontHaveTxId={handleIdontHaveTxId}
          />
        );
      case 2:
        return <MakePaymentWizardStepThree formik={formik} />;
      case 3:
        return (
          <MakePaymentWizardStepFour
            formik={formik}
            searchUserDetails={searchUserDetails}
            setSearchUserDetails={handleSearchUserDetails}
          />
        );
      case 4:
        return (
          <MakePaymentWizardStepFive
            formik={formik}
            fundingAccountList={fundingAccountList}
            frontCheque={frontCheque}
            backCheque={backCheque}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={handleSelectedPaymentMethod}
            selectedPaymentMethodDetails={selectedPaymentMethodDetails}
            setSelectedPaymentMethodDetails={handleSelectedPaymentMethodDetails}
            setFrontCheque={handleFrontCheque}
            setBackCheque={handleBackCheque}
            isGeneralPayment={isGeneralPayment}
          />
        );
      default:
        return (
          <MakePaymentWizardStepOne
            isGeneralPayment={isGeneralPayment}
            setGeneralPayment={setGeneralPayment}
          />
        );
    }
  }

  const handleNext = async () => {
    if (isGeneralPayment) {
      if (activeStep === 0) {
        setActiveStep(3);
      }
      if (activeStep === 3) {
        const { cellnumber } = formik.values;
        if (cellnumber.trim().length !== 14) {
          return;
        }
        const { cellnumber: userCellnumber } = userInstance;
        if (cellnumber === userCellnumber) {
          setErrorMessage("You can't make payment with your own phone number");
          handleErrorModalShow();
          return;
        } else {
          if (cellnumber) {
            setActiveStep(4);
          }
        }
      }
      if (activeStep === 4) {
        const { amount } = formik.values;

        if (formik.errors.amount) {
          return;
        }

        if (!amount) {
          setErrorMessage(
            "You must have to enter amount before submit payment"
          );
          handleErrorModalShow();
          return;
        }

        if (selectedPaymentMethod === "") {
          setErrorMessage(
            "You must enter a valid dollar amount and select a payment method to continue."
          );
          handleErrorModalShow();
          return;
        }
        if (amount) {
          getCommissionBasedOnAmount();
        }
      }
    } else {
      // SC Steps

      //Step 1 -  Enter Transaction Id
      if (activeStep === 1) {
        if (formik.errors.scTransactionId) {
          return;
        }
        const validateSCTxId = await validateSCTransactionId();
        if (validateSCTxId.exists) {
          getAddressSCTransaction();
        } else {
          setErrorMessage(
            "You have entered an invalid transaction ID. Please verify the correct ID and try again."
          );
          handleErrorModalShow();
          return;
        }
      }

      //Step 2 - Proprty Address
      if (activeStep === 2) {
        if (addressSCTransaction === null) {
          setErrorMessage(
            "Property address should not be blank, Try again later!"
          );
          handleErrorModalShow();
          return;
        }
      }

      // Step 3 - Recipient
      if (activeStep === 3) {
        const { cellnumber } = formik.values;
        if (cellnumber.trim().length !== 14) {
          return;
        }
        const { cellnumber: userCellnumber } = userInstance;
        if (cellnumber === userCellnumber) {
          setErrorMessage("You can't make payment with your own phone number");
          handleErrorModalShow();
          return;
        }
      }

      // Step 4 - Amount & Bank
      if (activeStep === 4) {
        const { amount } = formik.values;
        if (formik.errors.amount) {
          return;
        }

        if (!amount) {
          setErrorMessage(
            "You must have to enter amount before submit payment"
          );
          handleErrorModalShow();
          return;
        }

        if (selectedPaymentMethod === "") {
          setErrorMessage(
            "You must enter a valid dollar amount and select a payment method to continue."
          );
          handleErrorModalShow();
          return;
        }

        if (amount) {
          getCommissionBasedOnAmount();
        }
        return;
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  function handleBack() {
    if (isGeneralPayment) {
      if (activeStep === 3) {
        setActiveStep(0);
      }
      if (activeStep === 4) {
        setActiveStep(3);
      }
    } else {
      if (activeStep === 2) {
        formik.setFieldValue("address1", "");
        formik.setFieldValue("address2", "");
        formik.setFieldValue("city", "");
        formik.setFieldValue("state", "");
        formik.setFieldValue("country", "");
        formik.setFieldValue("zipcode", "");
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  function handleActiveStepReset() {
    setActiveStep(0);
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingNextButton = () => {
    setLoadingNextButton(true);
  };

  const disableLoadingNextButton = () => {
    setLoadingNextButton(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleInfoShow = () => {
    setInfoModal(true);
  };

  const handleInfoHide = () => {
    setInfoModal(false);
  };

  const hadleWhatisTxId = () => {
    setInfoMessage(
      "Schedule Closings Transaction IDs are used for validation and are required in order to complete real estate payments. Please visit ScheduleClosings.com and sign into your account to find the Transaction ID for the closing you want to make a payment for."
    );
    handleInfoShow();
  };

  const handleIdontHaveTxId = () => {
    setInfoMessage(
      "Schedule Closings Transaction IDs are only available to ScheduleClosings.com users. If you are not a part of a real estate closing on ScheduleClosings.com, please go back and select General Payment."
    );
    handleInfoShow();
  };

  const handleConfirmCancelButton = () => {
    setSearchUserDetails(null);
    setGeneralPayment(true);
    formik.values.amount = "";
    formik.values.cellnumber = "";
    formik.values.scTransactionId = "";
    formik.values.address1 = "";
    formik.values.address2 = "";
    formik.values.city = "";
    formik.values.state = "";
    formik.values.country = "";
    formik.values.zipcode = "";
    formik.handleReset();
    setDisableAuthorizeAndConfirmButton(false);
    setFrontCheque([]);
    setBackCheque([]);
    setSelectedPaymentMethod("");
    setSelectedPaymentMethodDetails(null);
    setPaymentNote("");
    setPaymentSCTransactionType("");
    setFrontChequeAfterUploadImageName("");
    setBackChequeAfterUploadImageName("");
    handleConfirmHide();
    handleActiveStepReset();
    props.history.push("/make-payment");
  };

  const handlePaymentNotes = (notes) => {
    setPaymentNote(notes);
  };

  const handlePaymentSCTransactionType = (scTransactionType) => {
    setPaymentSCTransactionType(scTransactionType);
  };

  const validationSchemaList = [
    null,
    SCTrasnsactionIdSchema,
    PaymentVerifyAddressSchema,
    ProfileSchema,
    AmountSchema,
  ];

  const getTransactionType = () => {
    // 1 = e-Check, 2 = ACH, 3 = CC, 4 = Wire Transfer, 5 = General, 6 = Debit Card
    if (selectedPaymentMethod === "Bank") {
      return 2; //ACH
    } else if (selectedPaymentMethod === "CreditCard") {
      return 3; //CC
    } else if (selectedPaymentMethod === "DebitCard") {
      return 6; //Debit Card
    } else if (selectedPaymentMethod === "Check") {
      return 1; //e-Check
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchemaList[activeStep],
    onSubmit: (values, { setStatus, setSubmitting }) => {
      onSubmitOfMakePayment();
    },
  });

  const onSubmitOfMakePayment = async () => {
    if (!isGeneralPayment && paymentSCTransactionType.trim().length === 0) {
      setErrorMessage("Type should be required");
      handleErrorModalShow();
      return;
    }

    if (!isGeneralPayment && paymentNotes.trim().length === 0) {
      setErrorMessage("Notes should not be blank");
      handleErrorModalShow();
      return;
    }

    try {
      setDisableAuthorizeAndConfirmButton(true);
      const {
        amount,
        cellnumber,
        address1,
        address2,
        city,
        state,
        country,
        zipcode,
        scTransactionId,
      } = formik.values;
      const { fundingAccountId } = selectedPaymentMethodDetails || {};
      const { id: userId } = userInstance;
      const { id: recipientUserId } = searchUserDetails || {};

      const makeAPaymentObj = {
        amount,
        cellnumber: cellnumber,
        senderId: userId,
        ...(recipientUserId && { receiverId: recipientUserId }),
        transactionTypeId: 3, // 1 = General, 2 = Request Payment, 3 = Make Payment
        transactionStatusId: 1, // 1 = Pending, 2 = Rejected, 3 = Accepted, 4 = Completed, 5 = Cancelled, 6 = Declined, 7 = Failed, 8 = Awaiting Processing, 9 = Being Processed, 10 = Hold, 11 = Returned / Refunded, 12 = Returned Ineligible, 13 = Refund Processing
        transactionModeId: getTransactionType(), // 1 = e-Check, 2 = ACH, 3 = CC, 4 = Wire Transfer, 5 = General
        ...(!isGeneralPayment && { sc_transactionId: scTransactionId }),
        ...(!isGeneralPayment && { address_1: address1 }),
        ...(!isGeneralPayment && { address_2: address2 }),
        ...(!isGeneralPayment && { city_name: city }),
        ...(!isGeneralPayment && { state_name: state }),
        ...(!isGeneralPayment && { country_name: country }),
        ...(!isGeneralPayment && { zip_code: zipcode }),
        ...(!isGeneralPayment && { sc_type: paymentSCTransactionType }), // "Due Dilligence", "Earnest Money", "Other"
        ...(selectedPaymentMethod !== "Check" && { fundingAccountId }), // For ACH and CC/DC fundingAccontId will be passed
        ...(frontChequeAfterUploadImageName &&
          selectedPaymentMethod === "Check" && {
            front_cheque: frontChequeAfterUploadImageName,
          }),
        ...(backChequeAfterUploadImageName &&
          selectedPaymentMethod === "Check" && {
            back_cheque: backChequeAfterUploadImageName,
          }),
        ...(paymentNotes && { other: paymentNotes }),
        created_by: userId,
      };
      // logger("makeAPaymentObj", makeAPaymentObj);
      enableLoading();
      const makeAPaymentResponse = await makeAPaymentApi(makeAPaymentObj);
      setDisableAuthorizeAndConfirmButton(false);
      disableLoading();
      if (makeAPaymentResponse.Error) {
        isUnauthorized({ response: makeAPaymentResponse });
        setErrorMessage(makeAPaymentResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      handleSuccessShow();
      handleConfirmHide();
      setMakeAPaymentDetails(makeAPaymentResponse);
    } catch (error) {
      logger("handleMakeAPayment", error);
      setDisableAuthorizeAndConfirmButton(false);
      disableLoading();
    }
  };

  const onClickOfDownloadButton = async () => {
    enableLoading();
    const { id } = makeAPaymentDetails;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`,
    });
    disableLoading();
    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  return (
    <>
      <div>
        <Card className="card-custom card-stretch make-payment-wrapper">
          <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
            <h3 className="card-title align-items-start flex-column">
              <span className="font-weight-bold text-dark">Make a Payment</span>
            </h3>
          </Card.Header>
          <Card.Body>
            <div className="col-xl-10 offset-xl-1">
              <div className="d-flex justify-content-md-center">
                <div className={classes.root}>
                  <Stepper
                    className="d-none"
                    activeStep={activeStep}
                    alternativeLabel
                  >
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <div>
                    {activeStep === getSteps().length ? (
                      <div>
                        <Typography className={classes.instructions}>
                          All steps completed
                        </Typography>
                        <Button onClick={handleActiveStepReset}>Reset</Button>
                      </div>
                    ) : (
                      <div>
                        <form
                          // onSubmit={formik.handleSubmit}
                          onKeyDown={(keyEvent) => {
                            if (keyEvent.keyCode === 13) {
                              keyEvent.preventDefault();
                            }
                          }}
                          className="form fv-plugins-bootstrap fv-plugins-framework"
                          autocomplete="off"
                        >
                          {getStepContent(activeStep, formik)}
                          <div>
                            <div className="d-flex justify-content-between mt-15 mb-3">
                              {activeStep !== 0 && (
                                <Button
                                  disabled={
                                    activeStep === 0 || isLoadingNextButton
                                  }
                                  variant="btn btn-light-brand btn-lg"
                                  onClick={handleBack}
                                  className={classes.button}
                                >
                                  <span className="svg-icon menu-icon ml-0 mr-2">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Navigation/Arrow-left.svg"
                                      )}
                                    />
                                  </span>{" "}
                                  Previous
                                </Button>
                              )}
                              {activeStep === 0 && <div></div>}
                              <Button
                                variant="btn btn-brand btn-lg"
                                onClick={handleNext}
                                className={classes.button}
                                disabled={isLoadingNextButton}
                              >
                                {activeStep === getSteps().length - 1
                                  ? "Submit"
                                  : "Next"}
                                <span className="svg-icon menu-icon ml-2 mr-0">
                                  <SVG
                                    src={toAbsoluteUrl(
                                      "/media/svg/icons/Navigation/Arrow-right.svg"
                                    )}
                                  />
                                  {isLoadingNextButton && (
                                    <span
                                      style={{
                                        top: "10px",
                                        right: "0px",
                                        float: "right",
                                      }}
                                      className="mr-3 ml-3 spinner spinner-white"
                                    ></span>
                                  )}
                                </span>
                              </Button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>

      {/* Make A Payment Confirmation Modal */}
      <MakeAPaymentConfirmationModal
        isGeneralPayment={isGeneralPayment}
        selectedPaymentMethod={selectedPaymentMethod}
        formik={formik}
        frontCheque={frontCheque}
        backCheque={backCheque}
        isLoading={isLoading}
        isConfirmModal={isConfirmModal}
        handleConfirmHide={handleConfirmHide}
        amount={formik.values.amount}
        firstname={searchUserDetails ? searchUserDetails.firstname : ""}
        lastname={searchUserDetails ? searchUserDetails.lastname : ""}
        cellnumber={formik.values.cellnumber}
        scTransactionId={formik.values.scTransactionId}
        setPaymentNote={handlePaymentNotes}
        setPaymentSCTransactionType={handlePaymentSCTransactionType}
        commissionAmount={commissionAmount}
        // handleMakeAPayment={handleMakeAPayment}
        handleConfirmCancelButton={handleConfirmCancelButton}
        disableAuthorizeAndConfirmButton={disableAuthorizeAndConfirmButton}
      />

      {/* conformation modal */}
      <FundSuccessModal
        isStatic={true}
        handleSuccessHide={handleSuccessHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        title={"Funds successfully sent!"}
        message={`PayXtreme Transaction #: ${makeAPaymentDetails.id}`}
        isSuccessModal={isSuccessModal}
        linkUrl={"Download Receipt"}
        onClickOfLink={onClickOfDownloadButton}
        buttonLabel={"Go to Dashboard"}
        onClickOfSaveButton={() => {
          props.history.push("/dashboard");
        }}
      ></FundSuccessModal>

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <InfoModal
        message={infoMessage}
        isInfoModal={isInfoModal}
        handleInfoHide={handleInfoHide}
        infoIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Info-circle.svg")}
              />
            </span>
          </div>
        }
        buttonLabel={"Close"}
        onClick={handleInfoHide}
      />
    </>
  );
}

export default injectIntl(connect(null, null)(MakePayment));
