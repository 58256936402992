import React, { useEffect, useState } from "react";
import { Card, Button, ButtonToolbar, Tab, Nav } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import {
  addAsContactApi,
  checkUserIsAddedAsContactApi,
  earilierNotificationListApi,
  markAsReadNotificationApi,
  pendingNotificationListApi,
  sendReminderApi,
} from "../../../axios/services/services";
import {
  isKeyExistInObject,
  isObjectEmpty,
  isUnauthorized,
} from "../../utils/utils";
import { CustomImage } from "../../utils/CustomImage";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import { useLocalStorage } from "../../utils/useLocalStorage";
import InfiniteScroll from "react-infinite-scroll-component";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import SVG from "react-inlinesvg";
import ErrorModal from "../Modal/ErrorModal";
import Moment from "react-moment";
import "moment-timezone";
import { useSubheader } from "../../../_metronic/layout";

export function NotificationListing({ history }) {
  const subheader = useSubheader();
  subheader.setTitle("Notifications");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [notificaionPendingList, setNotificaionPendingList] = useState(null);
  const [notificaionEarlierList, setNotificaionEarlierList] = useState(null);
  const [isPaginate, setPaginate] = useState(true);
  const [isPendingPaginate, setPendingPaginate] = useState(true);
  const [limit, setLimit] = useState(20);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoadingSendReminder, setLoadingSendReminder] = useState(false);
  const [isLoadingAddAsContact, setLoadingAddAsContact] = useState(false);
  const [currentIndexAddAsContact, setCurrentIndexAddAsContact] = useState(0);

  useEffect(() => {
    async function getEarlierNotificationList() {
      const earlierNotificationListResponse = await earilierNotificationListApi(
        { skip: 0 }
      );
      const { Error } = earlierNotificationListResponse || {};
      if (Error) {
        const { message } = Error || {};
        isUnauthorized({ response: earlierNotificationListResponse });
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }

      if (
        earlierNotificationListResponse &&
        (earlierNotificationListResponse.length < limit ||
          earlierNotificationListResponse.length === 0)
      ) {
        setPaginate(false);
      }

      setNotificaionEarlierList(
        earlierNotificationListResponse ? earlierNotificationListResponse : []
      );
    }
    getEarlierNotificationList();
  }, []);

  useEffect(() => {
    async function getPendingNotificationList() {
      const pendingNotificationListResponse = await pendingNotificationListApi({
        skip: 0,
      });
      const { Error } = pendingNotificationListResponse || {};
      if (Error) {
        const { message } = Error || {};
        isUnauthorized({ response: pendingNotificationListResponse });
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }

      if (
        pendingNotificationListResponse &&
        (pendingNotificationListResponse.length < limit ||
          pendingNotificationListResponse.length === 0)
      ) {
        setPendingPaginate(false);
      }

      setNotificaionPendingList(
        pendingNotificationListResponse ? pendingNotificationListResponse : []
      );
    }
    getPendingNotificationList();
  }, []);

  useEffect(() => {
    markAsReadNotification();
  }, []);

  async function markAsReadNotification() {
    const markAsReadNotificationResponse = await markAsReadNotificationApi({});
    if (markAsReadNotificationResponse.Error) {
      isUnauthorized({ response: markAsReadNotificationResponse });
      setErrorMessage(markAsReadNotificationResponse.Error.message);
      handleErrorModalShow();
      return;
    }
  }

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const enableLoadingSendReminder = () => {
    setLoadingSendReminder(true);
  };

  const disableLoadingSendReminder = () => {
    setLoadingSendReminder(false);
  };

  const enableLoadingAddAsContact = () => {
    setLoadingAddAsContact(true);
  };

  const disableLoadingAddAsContact = () => {
    setLoadingAddAsContact(false);
  };

  const handleErrorModalOkButton = () => {
    handleErrorModalHide();
  };

  const paginateEarlierNotificationList = async () => {
    const skip = notificaionEarlierList ? notificaionEarlierList.length : 0;
    const notificationListResponse = await earilierNotificationListApi({
      skip,
    });
    const { Error } = notificationListResponse || {};
    if (Error) {
      const { message } = Error || {};
      isUnauthorized({ response: notificationListResponse });
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    if (
      notificationListResponse &&
      (notificationListResponse.length < limit ||
        notificationListResponse.length === 0)
    ) {
      setPaginate(false);
    }
    setNotificaionEarlierList((notifications) => [
      ...notifications,
      ...notificationListResponse,
    ]);
  };

  const paginatePendingNotificationList = async () => {
    const skip = notificaionPendingList ? notificaionPendingList.length : 0;
    const notificationListResponse = await pendingNotificationListApi({
      skip,
    });
    const { Error } = notificationListResponse || {};
    if (Error) {
      const { message } = Error || {};
      isUnauthorized({ response: notificationListResponse });
      setErrorMessage(message);
      handleErrorModalShow();
      return;
    }
    if (
      notificationListResponse &&
      (notificationListResponse.length < limit ||
        notificationListResponse.length === 0)
    ) {
      setPendingPaginate(false);
    }
    setNotificaionPendingList((notifications) => [
      ...notifications,
      ...notificationListResponse,
    ]);
  };

  const handleSendReminder = async (notification) => {
    let sendReminderObj = {
      id: notification.entity_id,
    };
    enableLoadingSendReminder();
    const sendReminderResponse = await sendReminderApi(sendReminderObj);
    disableLoadingSendReminder();
    if (sendReminderResponse.Error) {
      isUnauthorized({ response: sendReminderResponse });
      setErrorMessage(sendReminderResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    let reminderContactName = "";

    if (
      !isObjectEmpty(notification) &&
      notification.senderId === userInstance.id
    ) {
      reminderContactName =
        notification.receiver.firstname + " " + notification.receiver.lastname;
    } else if (
      !isObjectEmpty(notification) &&
      notification.senderId !== userInstance.id
    ) {
      reminderContactName =
        notification.sender.firstname + " " + notification.sender.lastname;
    }
    if (reminderContactName !== "") {
      setSuccessMessage(`Payment reminder successfully sent`);
      // to ${reminderContactName}
      handleSuccessModalShow();
    }
  };

  const handleAddAsContact = async (notification) => {
    let addContactObj = {
      userId: userInstance.id,
      added_contact_id:
        notification.senderId === userInstance.id
          ? notification.receiverId
          : notification.senderId,
    };
    enableLoadingAddAsContact();
    const addAsContactResponse = await addAsContactApi(addContactObj);
    disableLoadingAddAsContact();
    if (addAsContactResponse.Error) {
      isUnauthorized({ response: addAsContactResponse });
      setErrorMessage(addAsContactResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    let reminderContactName = "";

    if (
      !isObjectEmpty(notification) &&
      !isObjectEmpty(notification.receiver) &&
      notification.senderId === userInstance.id
    ) {
      reminderContactName =
        notification.receiver.firstname + " " + notification.receiver.lastname;
    } else if (
      !isObjectEmpty(notification) &&
      !isObjectEmpty(notification.sender) &&
      notification.senderId !== userInstance.id
    ) {
      reminderContactName =
        notification.sender.firstname + " " + notification.sender.lastname;
    }
    if (reminderContactName !== "") {
      setSuccessMessage(
        `${reminderContactName} is successfully added to your contacts`
      );
      handleSuccessModalShow();
    }
  };

  const renderUserProfile = (notification) => {
    return !isObjectEmpty(notification) &&
      isKeyExistInObject(notification, "receiver") &&
      !isObjectEmpty(notification.receiver) &&
      isKeyExistInObject(notification.receiver, "profile") &&
      notification.senderId === userInstance.id
      ? getImageBasedOnNameUrl(notification.receiver.profile)
      : !isObjectEmpty(notification.sender) &&
        isKeyExistInObject(notification, "sender") &&
        isKeyExistInObject(notification.sender, "profile")
      ? getImageBasedOnNameUrl(notification.sender.profile)
      : toAbsoluteUrl("/media/users/default.jpg");
  };

  const checkUserIsAddedAsContact = async (notification) => {
    const { senderId, receiverId } = notification;
    const { id } = userInstance;
    let added_contact_id = "";
    if (senderId === id) {
      added_contact_id = receiverId;
    } else {
      added_contact_id = senderId;
    }
    const checkUserIsAddedAsContactResponse = await checkUserIsAddedAsContactApi(
      {
        added_contact_id,
      }
    );
    if (checkUserIsAddedAsContactResponse.Error) {
      isUnauthorized({ response: checkUserIsAddedAsContactResponse });
      setErrorMessage(checkUserIsAddedAsContactResponse.Error.message);
      handleErrorModalShow();
      return;
    }

    return isObjectEmpty(checkUserIsAddedAsContactResponse) ? false : true;
  };

  return (
    <>
      <div className="notification-wrapper">
        <div className="notification-m-inner">
          <Card style={{ width: "100%" }}>
            <Tab.Container id="right-tabs-example" defaultActiveKey="first">
              <Card.Header className="align-items-center border-bottom p-0">
                <div className="col-12">
                  <Nav variant="pills" className="">
                    <Nav.Item className="w-50 mr-0">
                      <Nav.Link
                        className="btn font-weight-bolder mr-2 mobile-nav d-block text-center fs-22"
                        eventKey="first"
                      >
                        Pending Notifications
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="w-50 mr-0">
                      <Nav.Link
                        className="btn font-weight-bolder d-block text-center fs-22"
                        eventKey="second"
                      >
                        Earlier Notifications
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Card.Header>
              <Card.Body>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <>
                      <Card.Title>Action Required</Card.Title>
                      {notificaionPendingList &&
                        notificaionPendingList.length > 0 && (
                          <>
                            <InfiniteScroll
                              dataLength={notificaionPendingList.length}
                              next={paginatePendingNotificationList}
                              hasMore={isPendingPaginate}
                              loader={
                                <h4 style={{ textAlign: "center" }}>
                                  Loading...
                                </h4>
                              }
                              scrollableTarget="right-tabs-example-tabpane-first"
                            >
                              {notificaionPendingList.map(
                                (notification, index) => (
                                  <div
                                    className="d-flex align-items-md-center mb-5 pb-5 border-bottom"
                                    key={index}
                                  >
                                    <div className="symbol symbol-50 symbol-lg-60 symbol-circle mr-5">
                                      <CustomImage
                                        src={renderUserProfile(notification)}
                                      />
                                    </div>
                                    <div className="d-flex align-items-md-center justify-content-md-between flex-wrap flex-md-nowrap w-100">
                                      <span className="text-dark">
                                        {notification &&
                                          notification.text_html && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: notification.text_html,
                                              }}
                                            />
                                          )}
                                        <small className="form-text text-muted">
                                          <span className="svg-icon mr-1">
                                            <SVG
                                              src={toAbsoluteUrl(
                                                "/media/svg/icons/Home/Clock.svg"
                                              )}
                                            />
                                          </span>
                                          <Moment
                                            date={notification.createdAt}
                                            fromNow
                                          />
                                        </small>
                                      </span>

                                      <div className="d-flex justify-content-end notification-btn mt-5 mt-md-0 pl-0 pl-md-7">
                                        <ButtonToolbar className="d-flex button-wrap">
                                          {notification.status_id === 1 &&
                                            notification.notificationTypeId ===
                                              1 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-150 mr-3"
                                                onClick={() => {
                                                  let url = `/notification-payment/transId=${notification.entity_id}/notificationId=${notification.id}`;
                                                  history.push(url);
                                                }}
                                              >
                                                Accept or Reject
                                              </Button>
                                            )}
                                          {notification.status_id === 1 &&
                                            notification.notificationTypeId ===
                                              7 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-150 mr-3"
                                                onClick={() => {
                                                  let url = `/notification-payment/transId=${notification.entity_id}/notificationId=${notification.id}`;
                                                  history.push(url);
                                                }}
                                              >
                                                View Details
                                              </Button>
                                            )}
                                          {notification.notificationTypeId ===
                                            6 &&
                                            notification.status_id === 1 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-180 mr-3"
                                                onClick={() => {
                                                  handleSendReminder(
                                                    notification
                                                  );
                                                  setCurrentIndexAddAsContact(
                                                    index
                                                  );
                                                }}
                                              >
                                                Send Reminder
                                                {isLoadingSendReminder &&
                                                  currentIndexAddAsContact ===
                                                    index && (
                                                    <span className=" mr-3 ml-3 spinner spinner-white"></span>
                                                  )}
                                              </Button>
                                            )}
                                          {notification.notificationTypeId !==
                                            5 &&
                                            notification.notificationTypeId !==
                                              4 && (
                                              <Button
                                                variant="btn btn-light-primary"
                                                onClick={() => {
                                                  const {
                                                    senderId,
                                                    receiverId,
                                                  } = notification;
                                                  const { id } = userInstance;
                                                  if (senderId === id) {
                                                    history.push(
                                                      `/single-chat/${receiverId}`
                                                    );
                                                  } else {
                                                    history.push(
                                                      `/single-chat/${senderId}`
                                                    );
                                                  }
                                                }}
                                              >
                                                <i className="flaticon2-chat-1"></i>
                                                Chat
                                              </Button>
                                            )}
                                        </ButtonToolbar>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </InfiniteScroll>
                          </>
                        )}
                      {notificaionPendingList &&
                        notificaionPendingList.length === 0 && (
                          <EmptyRecentActivityContent
                            image="/media/svg/illustrations/notifications.svg"
                            title="You Don't Have Any Notifications Yet!"
                            description="You will begin receiving notifications after you send or receive your first payment."
                          />
                        )}
                    </>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <>
                      <Card.Title>Completed Actions</Card.Title>
                      {notificaionEarlierList &&
                        notificaionEarlierList.length > 0 && (
                          <>
                            <InfiniteScroll
                              dataLength={notificaionEarlierList.length}
                              next={paginateEarlierNotificationList}
                              hasMore={isPaginate}
                              loader={
                                <h4 style={{ textAlign: "center" }}>
                                  Loading...
                                </h4>
                              }
                              scrollableTarget="right-tabs-example-tabpane-second"
                            >
                              {notificaionEarlierList.map(
                                (notification, index) => (
                                  <div
                                    className="d-flex align-items-md-center mb-5 pb-5 border-bottom"
                                    key={index}
                                  >
                                    <div className="symbol symbol-50 symbol-lg-60 symbol-circle mr-5">
                                      <CustomImage
                                        src={`${
                                          !isObjectEmpty(notification) &&
                                          isKeyExistInObject(
                                            notification,
                                            "receiver"
                                          ) &&
                                          !isObjectEmpty(
                                            notification.receiver
                                          ) &&
                                          isKeyExistInObject(
                                            notification.receiver,
                                            "profile"
                                          ) &&
                                          notification.senderId ===
                                            userInstance.id
                                            ? getImageBasedOnNameUrl(
                                                notification.receiver.profile
                                              )
                                            : !isObjectEmpty(
                                                notification.sender
                                              ) &&
                                              isKeyExistInObject(
                                                notification,
                                                "sender"
                                              ) &&
                                              isKeyExistInObject(
                                                notification.sender,
                                                "profile"
                                              )
                                            ? getImageBasedOnNameUrl(
                                                notification.sender.profile
                                              )
                                            : toAbsoluteUrl(
                                                "/media/users/default.jpg"
                                              )
                                        }`}
                                      />
                                    </div>
                                    <div className="d-flex align-items-md-center justify-content-md-between flex-wrap flex-md-nowrap w-100">
                                      <span className="text-dark">
                                        {notification &&
                                          notification.text_html && (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: notification.text_html,
                                              }}
                                            />
                                          )}
                                        <small className="form-text text-muted">
                                          <span className="svg-icon mr-1">
                                            <SVG
                                              src={toAbsoluteUrl(
                                                "/media/svg/icons/Home/Clock.svg"
                                              )}
                                            />
                                          </span>
                                          <Moment
                                            date={notification.createdAt}
                                            fromNow
                                          />
                                        </small>
                                      </span>
                                      <div className="d-flex justify-content-end notification-btn mt-5 mt-md-0 pl-0 pl-md-7">
                                        <ButtonToolbar className="d-flex button-wrap">
                                          {notification.status_id === 2 &&
                                            notification.notificationTypeId ===
                                              7 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-180 mr-3"
                                                onClick={() => {
                                                  let url = `/notification-payment/transId=${notification.entity_id}/notificationId=${notification.id}`;
                                                  history.push(url);
                                                }}
                                              >
                                                View Details
                                              </Button>
                                            )}

                                          {notification.status_id === 2 &&
                                            notification.notificationTypeId ===
                                              2 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-180 mr-3"
                                                onClick={() => {
                                                  let url = `/notification-payment/transId=${notification.entity_id}/notificationId=${notification.id}`;
                                                  history.push(url);
                                                }}
                                              >
                                                View Details
                                              </Button>
                                            )}

                                          {notification.notificationTypeId ===
                                            6 &&
                                            notification.status_id === 2 && (
                                              <Button
                                                variant="btn btn-success btn-brand w-180 mr-3"
                                                onClick={() => {
                                                  handleSendReminder(
                                                    notification
                                                  );
                                                  setCurrentIndexAddAsContact(
                                                    index
                                                  );
                                                }}
                                              >
                                                Send Reminder
                                                {isLoadingSendReminder &&
                                                  currentIndexAddAsContact ===
                                                    index && (
                                                    <span className=" mr-3 ml-3 spinner spinner-white"></span>
                                                  )}
                                              </Button>
                                            )}
                                          {notification.notificationTypeId ===
                                            3 && (
                                            <>
                                              {checkUserIsAddedAsContact(
                                                notification
                                              ) ? (
                                                <Button className="btn btn-success w-180 mr-3">
                                                  <span className="svg-icon">
                                                    <SVG
                                                      src={toAbsoluteUrl(
                                                        "/media/svg/icons/Navigation/Check.svg"
                                                      )}
                                                    />
                                                  </span>
                                                  Added
                                                </Button>
                                              ) : (
                                                <Button
                                                  variant="btn btn-success btn-brand w-180 mr-3"
                                                  onClick={() => {
                                                    setCurrentIndexAddAsContact(
                                                      index
                                                    );
                                                    handleAddAsContact(
                                                      notification
                                                    );
                                                  }}
                                                >
                                                  Add as Contact
                                                  {isLoadingAddAsContact &&
                                                    currentIndexAddAsContact ===
                                                      index && (
                                                      <span className=" mr-3 ml-3 spinner spinner-white"></span>
                                                    )}
                                                </Button>
                                              )}
                                            </>
                                          )}
                                          {notification.notificationTypeId !==
                                            5 &&
                                            notification.notificationTypeId !==
                                              4 && (
                                              <Button
                                                variant="btn btn-light-primary"
                                                onClick={() => {
                                                  const {
                                                    senderId,
                                                    receiverId,
                                                  } = notification;
                                                  const { id } = userInstance;
                                                  if (senderId === id) {
                                                    history.push(
                                                      `/single-chat/${receiverId}`
                                                    );
                                                  } else {
                                                    history.push(
                                                      `/single-chat/${senderId}`
                                                    );
                                                  }
                                                }}
                                              >
                                                <i className="flaticon2-chat-1"></i>
                                                Chat
                                              </Button>
                                            )}
                                        </ButtonToolbar>
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </InfiniteScroll>
                          </>
                        )}
                      {notificaionEarlierList &&
                        notificaionEarlierList.length === 0 && (
                          <EmptyRecentActivityContent
                            image="/media/svg/illustrations/notifications.svg"
                            title="You Don't Have Any Notifications Yet!"
                            description="You will begin receiving notifications after you send or receive your first payment."
                          />
                        )}
                    </>
                  </Tab.Pane>
                </Tab.Content>
              </Card.Body>
            </Tab.Container>
          </Card>
        </div>
      </div>
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalOkButton}
      />
    </>
  );
}
