import React from "react";
import { Button, Modal } from "react-bootstrap";

function SuccessModal({
  message,
  isConfirmModal,
  handleConfirmHide,
  onClick,
  buttonLabel,
  successIcon,
  formElement,
  backdrop,
}) {
  return (
    <>
      <Modal
        backdrop={backdrop ? "static" : ""}
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            {successIcon}
          </div>

          <p className="text-center fs-16 text-dark confirmation-content">
            {message}
          </p>
          <div>{formElement}</div>
          <Button variant="btn-lg btn-success px-8 mt-5 mb-5" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default SuccessModal;
