import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";

function MakePaymentWizardStepTwo({
  formik,
  hadleWhatisTxId,
  handleIdontHaveTxId,
}) {
  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span className="svg-icon svg-icon-5x svg-icon-dark menu-icon mr-1">
              {/* <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/TransectionId.svg"
                )}
              /> */}
              <img
                src={toAbsoluteUrl("/media/images/sc-logo.png")}
                height="100"
                alt="SC"
              />
            </span>
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center mb-0">
              Enter Your Transaction ID
            </h5>
            <p className="fs-14 font-weight-normal text-secondary text-center mt-2">
              What Schedule Closings Transaction will this payment be a part of?
            </p>
            <div
              className="d-block text-brand fs-13 font-weight-normal text-center cursor-pointer"
              onClick={hadleWhatisTxId}
            >
              What is my Transaction ID?
            </div>
          </div>
          <div className="text mt-5">
            <div className="form-group mb-4 fv-plugins-icon-container">
              <input
                type="text"
                autoComplete="off"
                autoFocus={false}
                className="form-control h-auto py-5 px-6"
                placeholder="Transaction ID*"
                name="scTransactionId"
                {...formik.getFieldProps("scTransactionId")}
              />
              {formik.touched.scTransactionId &&
              formik.errors.scTransactionId ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <i className="fas fa-times text-danger mr-1 text-center"></i>
                    {formik.errors.scTransactionId}
                  </div>
                </div>
              ) : null}
            </div>
            {/* <div className="form-group m-p-custom-select mb-4 fv-plugins-icon-container">
              <select
                className="form-control h-auto py-5 px-6 mt-3 text-muted"
                id="exampleSelect1"
              >
                <option>Your Role in the Transaction*</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div> */}
            <div
              className="d-block text-center text-md-right text-brand fs-14 font-weight-normal cursor-pointer"
              onClick={handleIdontHaveTxId}
            >
              I don't have the Transaction ID
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MakePaymentWizardStepTwo;
