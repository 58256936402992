import React, { useEffect, useState } from "react";
// import { Route } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import NumberFormat from "react-number-format";
import { counterApi } from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"; //, checkIsActive
import {
  TOTAL_PAYMENTS_MADE,
  TOTAL_PAYMENTS_RECEIVED
} from "../../utils/const";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { isUnauthorized } from "../../utils/utils";
import ErrorModal from "../Modal/ErrorModal";
import PayWithModal from "../Modal/PayWithModal";
import DashboardPendingActions from "./DashboardPendingActions";
import { RecentHostory } from "./RecentHostory";

export function DashboardPage(props) {
  const [counter, setCounter] = useState({});
  const [isPayWithModal, setPayWithModal] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInstance] = useLocalStorage("userInstance", "");
  const [counterFilter, setCounterFilter] = useState("");
  const [updateCounter, setUpdateCounter] = useState(0);

  useEffect(() => {
    const paymentUserId = localStorage.getItem("isPaymentPage");
    if (paymentUserId) {
      props.history.push(`/payments/uid=${paymentUserId}`);
      setTimeout(() => {
        localStorage.removeItem("isPaymentPage");
      }, 500);
    }
  });

  useEffect(() => {
    async function getCounters() {
      const counterObjs = {
        userId: userInstance.id,
        counterFilter
      };

      const counterResponse = await counterApi(counterObjs);
      if (counterResponse && counterResponse.Error) {
        isUnauthorized({ response: counterResponse });
        setErrorMessage(counterResponse.Error.message);
        handleErrorModalShow();
        return;
      }

      if (counterResponse && counterResponse.length > 0) {
        const counterRes = counterResponse[0];
        const { usermetum } = counterRes || {};
        const { available_funds, total_sent, total_received } = usermetum || {};

        let counterObj = {
          available_funds,
          total_sent,
          total_received
        };
        setCounter(counterObj);
      } else {
        let counterObj = {
          available_funds: 0,
          total_sent: 0,
          total_received: 0
        };
        setCounter(counterObj);
      }
    }

    getCounters();
  }, [userInstance.id, counterFilter, updateCounter]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handlePayWithModalShow = () => {
    setPayWithModal(true);
  };

  const handlePayWithModalHide = () => {
    setPayWithModal(false);
  };

  const updateDashboardCounter = () => {
    setUpdateCounter(Math.random());
  };

  return (
    <>
      <div className="history-wrapper container">
        <div className="row">
          <div className="col-md-12">
            <div className="dashboard-header d-md-flex justify-content-md-between align-items-center">
              <div className="title d-none d-md-block">
                <h4 className="card-title align-items-center">
                  <span className="card-label font-weight-bolder text-dark">
                    Dashboard
                  </span>
                </h4>
              </div>
              <div className="tool-bar">
                <Form.Group
                  controlId="exampleForm.ControlSelect1"
                  className="d-sm-flex mb-md-3 align-items-center"
                >
                  <Form.Label style={{ minWidth: "70px" }}>
                    Filter by
                  </Form.Label>
                  <Form.Control
                    as="select"
                    onChange={(event) => {
                      setCounterFilter(event.target.value);
                    }}
                  >
                    <option value={""}>Select Duration</option>
                    {/* <option value={"all"}>All</option> */}
                    <option value={"today"}>Today</option>
                    <option value={"week"}>Last Week</option>
                    <option value={"month"}>Last Month</option>
                    <option value={"year"}>Last Year</option>
                  </Form.Control>
                  <div className="pay-with-btn d-none">
                    <button
                      className="btn btn-light-brand font-weight-bolder font-size-sm ml-3"
                      onClick={handlePayWithModalShow}
                    >
                      Pay with PayXtream
                    </button>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-4">
            <Card className="ard-custom card-stretch mb-5">
              <Card.Body>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-light mr-5">
                    <span className="svg-icon svg-icon-4x svg-icon-brand menu-icon mr-1">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Wallet2.svg"
                        )}
                      />
                    </span>{" "}
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <a
                      href="#"
                      className="text-dark mb-0 text-hover-primary font-size-h3 font-weight-boldest"
                    >
                      <NumberFormat
                        value={counter.available_funds}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </a>
                    <span className="text-secondary fs-13 font-weight-bold">
                      Balance in PayXtreme
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div> */}

          <div className="col-md-6">
            <Card className="ard-custom card-stretch mb-5">
              <Card.Body>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-light mr-5">
                    <span className="svg-icon svg-icon-4x svg-icon-success menu-icon mr-1">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Wallet.svg"
                        )}
                      />
                    </span>{" "}
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="text-dark mb-0 text-hover-primary font-size-h3 font-weight-boldest">
                      <NumberFormat
                        value={counter.total_sent}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                    <span className="text-secondary fs-13 font-weight-bold">
                      {TOTAL_PAYMENTS_MADE}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div className="col-md-6">
            <Card className="ard-custom card-stretch mb-5">
              <Card.Body>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-light mr-5">
                    <span className="svg-icon svg-icon-4x svg-icon-warning menu-icon mr-1">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Shopping/Money.svg"
                        )}
                      />
                    </span>{" "}
                  </div>
                  <div className="d-flex flex-column flex-grow-1">
                    <div className="text-dark mb-0 text-hover-primary font-size-h3 font-weight-boldest">
                      <NumberFormat
                        value={counter.total_received}
                        displayType="text"
                        thousandSeparator={true}
                        prefix="$"
                        decimalSeparator="."
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                    </div>
                    <span className="text-secondary fs-13 font-weight-bold">
                      {TOTAL_PAYMENTS_RECEIVED}
                    </span>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        <DashboardPendingActions />
        <RecentHostory updateDashboardCounter={updateDashboardCounter} />
      </div>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <PayWithModal
        title="Select Pay With PayXtream Option"
        message="Select make a payment or request a payment"
        isSuccessModal={isPayWithModal}
        handleSuccessHide={handlePayWithModalHide}
        handlePaymentTypeSelection={(value) => {
          if (value === "makeAPayment") {
            props.history.push("/make-payment");
          } else {
            props.history.push("/request-payment");
          }
        }}
      />
    </>
  );
}
