/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { useLocalStorage } from "../../../../../app/utils/useLocalStorage";
import {
  isUnauthorized,
  removeUserDetailsFromLocalStorage,
} from "../../../../../app/utils/utils";
import ConfirmationModal from "../../../../../app/modules/Modal/ConfirmationModal";
import {
  logoutApi,
  singleUserDetailApi,
} from "../../../../../axios/services/services";
import ErrorModal from "../../../../../app/modules/Modal/ErrorModal";
import UpdatedSuccessfulModal from "../../../../../app/modules/Modal/UpdatedSuccessfulModal";
import { CustomImage } from "../../../../../app/utils/CustomImage";
import { getImageBasedOnNameUrl } from "../../../../../axios/services/apiList";
import { logger } from "../../../../../app/utils/Debug";

export function UserProfileDropdown() {
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = React.useState("");

  const { user } = useSelector((state) => state.auth, shallowEqual);

  useEffect(() => {
    async function getsingleUserDetail() {
      const { id } = userInstance;
      const singleUserDetailResponse = await singleUserDetailApi({ id });
      // logger("singleUserDetailResponse", singleUserDetailResponse);
      if (singleUserDetailResponse.Error) {
        isUnauthorized({ response: singleUserDetailResponse });
        setErrorMessage(singleUserDetailResponse.Error.message);
        handleErrorModalShow();
        return;
      } else {
        setUserDetails(singleUserDetailResponse);
      }
    }
    getsingleUserDetail();
  }, [user]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const handleLogout = () => {
    showConfirmationModal();
  };

  const showConfirmationModal = () => {
    setConfirmationModalOpen(true);
  };

  const hideConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleCloseModal = () => {
    hideConfirmationModal()
  }

  const OnClickOfOkButton = async () => {
    const { id } = userInstance;
    enableLoading();
    let logoutObj = {
      id,
    };
    const logoutResponse = await logoutApi(logoutObj);
    disableLoading();
    if (logoutResponse.Error) {
      isUnauthorized({ response: logoutResponse });
      setErrorMessage(logoutResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    hideConfirmationModal()
    setSuccessMessage("You are successfully logged out!");
    handleSuccessModalShow();
    setTimeout(() => {
      removeUserDetailsFromLocalStorage();
    }, 1500);
  };

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-user-profile"
        >
          <div
            className={
              "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
            }
          >
            <div className="d-block">
              <span className="text-secondory font-weight-bold font-size-base d-none d-md-inline mr-1">
                Hi,
              </span>
              <span className="text-dark font-weight-bolder font-size-base mr-3 d-none d-md-inline">
                {userDetails &&
                  `${userDetails.firstname ? userDetails.firstname : ""} ${
                    userDetails.lastname ? userDetails.lastname : ""
                  }`}
              </span>
              <span className="font-size-base-small text-secondory mr-3 d-block text-right">
                {userDetails &&
                  `@${userDetails.username ? userDetails.username : ""}`}
              </span>
            </div>
            <span className="symbol symbol-35 symbol-circle">
              <CustomImage src={getImageBasedOnNameUrl(userDetails.profile)} />
            </span>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-md">
          <div className="navi navi-spacer-x-0 pt-5">
            <Link to="/user-profile" className="navi-item px-5 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-dark" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer text-dark">
                    Account settings
                  </div>
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer d-block px-5 py-5">
              <div className="navi-item cursor-pointer " onClick={handleLogout}>
                <div className="navi-link">
                  <div className="navi-icon mr-2">
                    <span className="svg-icon svg-icon-brand menu-icon">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Navigation/Sign-out.svg"
                        )}
                      />
                    </span>
                  </div>
                  <div className="navi-text">
                    <div className="font-weight-bold cursor-pointer text-brand">
                      Sign Out
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      <ConfirmationModal
        title={"Logout"}
        infoMessage={"Are you sure you want to logout?"}
        isConfirmationModalOpen={isConfirmationModalOpen}
        handleCloseModal={handleCloseModal}
        okButtonLabel={"Logout"}
        OnClickOfOkButton={OnClickOfOkButton}
        cancelButtonLabel={"Cancel"}
        isLoading={isLoading}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
