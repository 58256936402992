import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function PayWithModal({
  title,
  message,
  isSuccessModal,
  handleSuccessHide,
  handlePaymentTypeSelection,
}) {
  const handleMakeAPayment = (event) => {
    handlePaymentTypeSelection(event.target.value);
  };

  return (
    <>
      <Modal
        show={isSuccessModal}
        onHide={handleSuccessHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            <div className="success-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-3x mr-0">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Shopping/Credit-card.svg"
                  )}
                />
              </span>
            </div>
          </div>
          <h3 className="text-dark font-weight-bold fs-20 text-center">
            {title}
          </h3>
          <p className="text-center fs-14 font-weight-normal text-secondary mb-10">
            {message}
          </p>
          <div className="form-group">
            <div className="radio-inline justify-content-center mt-8">
              <label className="radio radio-success radio-lg">
                <input
                  type="radio"
                  name="ChoosePayment"
                  value="makeAPayment"
                  onChange={handleMakeAPayment}
                />
                <span></span>Make a Payment
              </label>
              <label className="radio radio-success radio-lg">
                <input
                  type="radio"
                  name="ChoosePayment"
                  value="requestAPayment"
                  onChange={handleMakeAPayment}
                />
                <span></span>Request a Payment
              </label>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PayWithModal;
