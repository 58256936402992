import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import ErrorModal from "../Modal/ErrorModal";
import { isUnauthorized } from "../../utils/utils";
import { singleUserDetailApi } from "../../../axios/services/services";
import { CustomImage } from "../../utils/CustomImage";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import { useLocalStorage } from "../../utils/useLocalStorage";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";

function Payments(props) {
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [inviteUserDetails, setInviteUserDetails] = useState(null);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");

  const { id, profile, firstname, lastname, username } =
    inviteUserDetails || {};

  const { uid } = props.match.params;
  const { id: userId } = userInstance || {};

  useEffect(() => {
    async function getsingleUserDetail() {
      if (!uid) return;
      const singleUserDetailResponse = await singleUserDetailApi({
        id: uid,
      });
      const { Error } = singleUserDetailResponse || {};
      if (Error) {
        const { message } = Error || {};
        isUnauthorized({ response: singleUserDetailResponse });
        setErrorMessage(message);
        handleErrorModalShow();
        return;
      }
      setInviteUserDetails(singleUserDetailResponse);
    }
    getsingleUserDetail();
  }, [uid]);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
    props.history.push(`/`);
  };

  const handleMakeAPayment = () => {
    if (id) {
      props.history.push(`/make-payment/contactId=${id}`);
    }
  };

  const handleRequestAPayment = () => {
    if (id) {
      props.history.push(`/request-payment/contactId=${id}`);
    }
  };

  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">Payments</span>
          </h3>
        </Card.Header>
        <Card.Body>
          {inviteUserDetails && (
            <div className="col-md-10 offset-md-1">
              <div className="d-flex justify-content-center">
                <Card className="p-4 w-100">
                  <div>
                    <>
                      <div
                        className="image-input-payments text-center"
                        id="kt_profile_avatar"
                      >
                        <CustomImage
                          classNames="image-input-wrapper rounded-circle"
                          src={`${getImageBasedOnNameUrl(profile)}`}
                        />
                      </div>
                      <div className="form-group text-center mt-5">
                        <div>
                          <h4 className="font-weight-boldest font-size-h4 text-dark">
                            {firstname} {lastname}
                          </h4>
                          <span className="font-size-base-small text-secondory mr-3 d-block text-center">
                            {`@${username ? username : ""}`}
                          </span>
                        </div>
                      </div>
                      <div className="row text-center">
                        <div className="col md-12">
                          <button
                            className={`btn btn-success font-weight-bold my-5 mr-2`}
                            onClick={handleMakeAPayment}
                            disabled={uid === userId}
                          >
                            Make a payment
                          </button>
                          <button
                            className={`btn btn-success font-weight-bold my-5 mr-2`}
                            onClick={handleRequestAPayment}
                            disabled={uid === userId}
                          >
                            Request a payment
                          </button>
                        </div>
                      </div>
                      {uid === userId && (
                        <div className="saved-details border border-warning rounded p-3 mt-5">
                          <div className="d-flex">
                            <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                              <div className="col-11">
                                <div className="details">
                                  <div className="text-danger fs-14">
                                    You can't Make a Payment or Request a
                                    payment,
                                    <br /> To your own payment link, Please try
                                    with other contacts.
                                  </div>
                                </div>
                              </div>
                              <div className="col-1">
                                <div className="right-info">
                                  <span className="svg-icon svg-icon-2x svg-icon-warning menu-icon mr-1">
                                    <SVG
                                      src={toAbsoluteUrl(
                                        "/media/svg/icons/Code/Warning-1-circle.svg"
                                      )}
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                </Card>
              </div>
            </div>
          )}
          {inviteUserDetails === null && (
            <>
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/invalid-link.svg"
                title="Payment link not found"
                description="Payment link is invalid, Please try with valid contacts."
              />
            </>
          )}
        </Card.Body>
      </Card>
      {/* conformation modal */}

      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default Payments;
