import React from "react";
import { Formik } from "formik";

export function HistoryFilter({ filterValues }) {
  const applyFilter = (values) => {
    filterValues(values);
  };

  return (
    <>
      <Formik
        initialValues={{
          depositType: "",
          searchText: "",
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autocomplete="off"
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="depositType"
                  placeholder="Filter by Payment Method"
                  onChange={(e) => {
                    setFieldValue("depositType", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.depositType}
                >
                  <option value="">All</option>
                  {/* <option value="0">eCheck</option> */}
                  <option value="1">ACH</option>
                  <option value="2">Credit Card</option>
                  {/* <option value="3">Wire Transfer</option> */}
                  {/* <option value="4">General</option> */}
                  <option value="5">Debit Card</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Payment Method
                </small>
              </div>
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="status"
                  placeholder="Filter by Status"
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.status}
                >
                  <option value="">All</option>
                  <option value="1">Pending</option>
                  <option value="2">Rejected</option>
                  <option value="3">Accepted</option>
                  <option value="4">Approved</option>
                  <option value="5">Cancelled</option>
                  <option value="6">Declined</option>
                  <option value="7">Failed</option>
                  <option value="8">Awaiting Processing</option>
                  <option value="9">Being Processed</option>
                  <option value="10">Hold</option>
                  <option value="11">Returned / Refunded</option>
                  <option value="12">Returned Ineligible</option>
                  <option value="13">Refund Processing</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Status
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autocomplete="off"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
