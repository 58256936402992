import { isEqual, isFunction } from "lodash";
import React, { createContext, useCallback, useContext, useState } from "react";

const PaymentHistoryUIContext = createContext();

export function usePaymentHistoryUIContext() {
  return useContext(PaymentHistoryUIContext);
}

export const PaymentHistoryUIConsumer = PaymentHistoryUIContext.Consumer;

export function PaymentHistoryUIProvider({
  paymentHistoryUIEvents,
  children,
  queryParams,
  setQueryParamsBase
}) {
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    openReceiptDialog: paymentHistoryUIEvents.openReceiptDialog,
    openCancelTransactionDialog:
      paymentHistoryUIEvents.openCancelTransactionDialog
  };

  return (
    <PaymentHistoryUIContext.Provider value={value}>
      {children}
    </PaymentHistoryUIContext.Provider>
  );
}
