import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { register } from "../_redux/authCrud";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"; //, checkIsActive
import SuccessModal from "../../Modal/SuccessModal";

const initialValues = {
  fullname: "",
  email: "",
  username: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function TwoFactoryAccount(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [isConfirmModal, setConfirmModal] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, "Name must be at least 3 characters")
      .max(64, "Name must not be more than 64 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    email: Yup.string()
      .email("Please enter valid Email")
      .min(8, "Email must be at least 8 characters")
      .max(132, "Email must not be more than 132 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    username: Yup.string()
      .min(3, "Username must be at least 6 characters")
      .max(50, "Username must not be more than 50 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .max(132, "Password must not be more than 132 characters")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Passwords do not match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      "You must accept the terms and conditions"
    ),
  });

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      enableLoading();
      register(values.email, values.fullname, values.username, values.password)
        .then(({ data: { accessToken } }) => {
          props.register(accessToken);
          disableLoading();
          setSubmitting(false);
        })
        .catch(() => {
          setSubmitting(false);
          setStatus(
            intl.formatMessage({
              id: "AUTH.VALIDATION.INVALID_LOGIN",
            })
          );
          disableLoading();
        });
      props.history.push("/auth/reset-password");
    },
  });

  return (
    <>
      <div
        className="login-form login-forgot create-account"
        style={{ display: "block" }}
      >
        <div>
          <div className="text-center">
            <div className="d-flex justify-content-center">
              <div className="lock-icon">
                <span className="svg-icon menu-icon svg-icon-brand svg-icon-4x">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
                  />
                </span>{" "}
              </div>
            </div>
            <p className="fs-14 mb-0 text-dark">
              Please enter the 4 digit code sent to your
            </p>
            <p className="fs-14 text-danger">PHONE (xxx) xxx-4329 below:</p>
          </div>
          <div className="d-flex justify-content-center align-items-center otp-wrapper">
            <div
              id="otp"
              className="inputs d-flex flex-row justify-content-center mt-2"
            >
              <input
                type="text"
                className="form-control mr-4"
                maxLength="1"
                autoFocus
              ></input>
              <input
                type="text"
                className="form-control mr-4"
                maxLength="1"
              ></input>
              <input
                type="text"
                className="form-control mr-4"
                maxLength="1"
              ></input>
              <input type="text" className="form-control" maxLength="1"></input>
            </div>
          </div>
          <div className="text-center mt-5">
            <p className="fs-12 font-weight-light">
              Didn't Receive the Code or it Expired?{" "}
              <a href="" className="text-danger font-weight-bold">
                Resend
              </a>
            </p>
          </div>
          <div className="text-center mb-5">
            <a
              onClick={handleConfirmShow}
              className="fs-12 mt-4 font-weight-light d-block text-brand"
            >
              Email Me a Code Instead
            </a>
          </div>
          <div className="form-group d-block">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-brand font-weight-bolder px-9 py-4 my-3 w-100"
              disabled={formik.isSubmitting}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
      <SuccessModal
        message={"Please enter your email to get 4 digit code"}
        formElement={
          <div className="form-group mb-4 fv-plugins-icon-container">
            <input
              type="text"
              className={`form-control h-auto py-5 px-6 ${getInputClasses(
                "text"
              )}`}
              placeholder="Enter Email"
              name="password"
            />
          </div>
        }
        isConfirmModal={isConfirmModal}
        handleConfirmHide={handleConfirmHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Readed-mail.svg"
                )}
              />
            </span>
          </div>
        }
        buttonLabel={"Send to Email"}
        onClick={() => {
          props.history.push("/auth/login");
        }}
      ></SuccessModal>
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(TwoFactoryAccount));
