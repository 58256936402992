import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";

function Faqs(props) {
  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="mr-5">
          <img
            src={toAbsoluteUrl("/media/svg/illustrations/faq1.svg")}
            alt=""
            height="200"
          />
        </div>

        <div className="row p-5">
          <div className="col-xl-6 col-md-12 pr-10">
            <div className="mt-5">
              <h6 className="strong">
                Do I need to link a bank account to use PayXtreme?
              </h6>
              <div>
                Yes! You must have a verified bank account linked to your
                PayXtreme account. When you receive funds, they deposit directly
                into the bank account you have selected.
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">How do you verify my bank account?</h6>
              <div>
                When you submit your bank account information, we will send 2
                small deposits to the bank account. When you receive the
                deposits, you'll enter the exact amounts in your Settings. If
                entered correctly, you're all set! If entered incorrectly, we
                cannot verify the bank account and you will need to start from
                the beginning by entering your bank account information again.
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">How do refunds work on PayXtreme?</h6>
              <div>
                You can only receive a "refund" in the following 2 scenarios.
                First, if your funds do not pass our verification process, the
                funds will return to you. Secondly, if your funds successfully
                pass our verification process, you can still choose to cancel
                your payment anytime before the recipient has accepted the
                funds, in effect returning the funds to you. Beyond those 2
                scenarios, we cannot process any refunds.
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">
                What makes PayXtreme safer and better than competitors?
              </h6>
              <div>
                We have created a multi-factor verification process that none of
                our competitors offer. First, anytime funds are sent, we put the
                money through a 24 to 72 hour verification process in which we
                can guarantee the money being sent is true and valid. Secondly,
                if the funds successfully pass our verification process, we then
                allow the recipient of the money to make the ultimate decision
                of Accepting or Declining the transfer. Overall, not only are we
                doing our best to ensure good funds are being transferred, but
                we are putting the control in the hands of our customers. This
                helps to prevent fraudulent activity and payment errors.
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">
                Can I make payments with a credit or debit card?
              </h6>
              <div>
                Yes and no! For any general payment, yes, you can absolutely use
                your credit or debit card. Conversely, we may have custom
                transaction workflows built for companies we've integrated with
                in which they don't allow credit or debit card payments. For
                example, our integration with ScheduleClosings.com. They are a
                real estate based company and therefore only allow their custom
                transactions to be completed via ACH, Wire, or Check Images.
                Overall, the use of credit or debit cards varies on PayXtreme
                depending on the type of payment you are making.
              </div>
            </div>

            <div className="mt-10">
              <h6 className="strong">
                What methods of payment does PayXtreme offer?
              </h6>
              <div>
                We offer ACH, Wires, Check Images, and credit or debit card
                payments for any general payment. Please note that for any
                custom transaction workflows we offer for companies we've
                integrated with(non-general payments), certain payment methods
                may be restricted.
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-md-12">
            <div className="mt-5">
              <h6 className="strong">
                Do you offer Two-Factor Authentication?
              </h6>
              <div>
                Yes! PayXtreme is safe and secure in nature, but we still highly
                suggest adding that extra layer of security by enabling
                Two-Factor Authentication in your Settings. You can receive One
                Time Passcodes to your email or mobile number.
              </div>
            </div>

            <div className="mt-10">
              <h6 className="strong">
                How do I send payments and request payments?
              </h6>
              <div>
                You can make or request payments from the menu or from one of
                your Contact's profiles.
              </div>
              <div className="mt-5">
                If you proceed from the menu, you'll need to enter the mobile
                number of the person you want to pay or request money from. If
                they have an account, they'll pop right up! If they don't have
                an account, you can still proceed and it will send them an
                invite to join. Please note, if you are going through a custom
                transaction workflow built for companies we've integrated with,
                there may be other validations needed beyond just mobile phone
                number.
              </div>
              <div className="mt-5">
                If you proceed from a Contact's profile, you can directly pay
                them or request payment from them without needing to enter their
                mobile phone number again
              </div>
              <div className="mt-5">
                Whether you're making or requesting a payment, we'll send you
                notifications throughout the life of the transaction to guide
                you and keep you updated!
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">
                The person I paid never Accepted or Declined. What do I do?
              </h6>
              <div>
                You can go to the notification you received when you paid this
                person and send them a reminder. You can enter a note and we
                will send in-app and email notification to them. Additionally,
                you can send another message to this person in the
                Contacts/Messaging tab or even reach out to them outside of
                PayXtreme.
                <h6 className="strong">
                  <u>
                    If they haven't responded after 3 weeks, the funds will be
                    returned to you.
                  </u>
                </h6>
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong">
                I see "Schedule Closings Transaction" when I go to Make a
                Payment or Request a Payment. What is this?
              </h6>
              <div>
                Schedule Closings is the first custom transaction workflow that
                we built and integrated with. For any standard payment or
                requested payment, proceed with General Payment. When you see
                "Schedule Closings Transaction," it's referring to a specific
                transaction workflow that is linked to activity on someone's
                ScheduleClosings.com account.
              </div>
            </div>
            <div className="mt-10">
              <h6 className="strong"></h6>
              <div></div>
            </div>
            <div className="mt-10">
              <h6 className="strong"></h6>
              <div></div>
            </div>
          </div>
        </div>
        {/* end::Head */}
      </div>
    </>
  );
}

export default Faqs;
