import React from "react";
import NumberFormat from "react-number-format";

export function NumberFormatter({ amount, className }) {
  return (
    <NumberFormat
      className={className}
      value={amount}
      displayType="text"
      thousandSeparator={true}
      prefix="$"
      decimalSeparator="."
      decimalScale={2}
      fixedDecimalScale={true}
    />
  );
}
