import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfigTest = {
//   apiKey: "AIzaSyCWtpzZt47v0vniFOwbNx2oFHroLxfQhtI",
//   authDomain: "payxtremedevpush.firebaseapp.com",
//   projectId: "payxtremedevpush",
//   storageBucket: "payxtremedevpush.appspot.com",
//   messagingSenderId: "385789889095",
//   appId: "1:385789889095:web:751fcba6c3e7ebe2543f62",
//   measurementId: "G-K1QDEX4MYC",
// };

const firebaseConfigLive = {
  apiKey: "AIzaSyB0LhedyQNzWBYWWeK8c4b0BDx-MENEh9Q",
  authDomain: "payxtreme.firebaseapp.com",
  projectId: "payxtreme",
  storageBucket: "payxtreme.appspot.com",
  messagingSenderId: "673112020842",
  appId: "1:673112020842:web:698f1879d38805f688b713",
  measurementId: "G-H261ZZX4Q0",
};

const vapidKeyLive =
  "BI28loxXPYJyOQdbPI44REd5vfeqs8lgYm7SnuOhfmyIgPJiweXJd6ZYrV-x_VqbTHI7jdenRC13-Tdu92cz1tM"; // for AWS Live
// const vapidKeyTest =
//   "BLmr9pf4A_oFK7G3wskfOlRJhTRG6rDxs5YtFKXut7GCMD11EbZBSmv-Xas7yT6dJg1vK_mAf8yk4mUguT0Ubj4"; // for Test

const firebaseApp = initializeApp(firebaseConfigLive);
const messaging = getMessaging(firebaseApp);

function firebaseCustom(setTokenFound) {
  getToken(messaging, {
    vapidKey: vapidKeyLive,
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("currentToken");
        // console.log(currentToken);
        setTokenFound(currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // ...
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // ...
    });
}

onMessage(messaging, (payload) => {
  // console.log("Message received. ", payload);
  // ...
});

export default firebaseCustom;
