// / eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/img-redundant-alt /
import "moment-timezone";
import React, { useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import Moment from "react-moment";
import { useHistory } from "react-router-dom";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import {
  cancelTransactionApi,
  historyListApi,
  recentHistoryApi,
  recentHistoryPDFApi,
  recentHistoryPrintApi
} from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { CustomImage } from "../../utils/CustomImage";
import { MM_DD_YY_hh_mm_A, TIME_ZONE } from "../../utils/dateTimeFormats";
import { NumberFormatter } from "../../utils/NumberFormatter";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { getStatusClass, isUnauthorized } from "../../utils/utils";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import { HistoryCancelTransactionDialog } from "../History/history-cancel-transaction-dialog/HistoryCancelTransactionDialog";
import ErrorModal from "../Modal/ErrorModal";
import ReceiptModal from "../Modal/ReceiptModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import RecentHistoryActionsButtons from "./RecentHistoryActionsButtons";

export function RecentHostory({ updateDashboardCounter }) {
  const [isLoadingForDownload, setLoadingForDownload] = useState(false);
  const [
    isLoadingForCancelTransaction,
    setLoadingForCancelTransaction
  ] = useState(false);
  let [isCancelTransactionModal, setCancelTransactionModal] = useState(false);
  const [isLoadingForPrint, setLoadingForPrint] = useState(false);
  const [recentHistory, setRecentHistory] = useState(null);
  const [selectedRecentHistory, setSelectedRecentHistory] = useState({});
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [data, setData] = useState({});
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [updateHistoryList, setUpdateHistoryList] = useState(0);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [pdfUrl, setPdfUrl] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [historyList, setHistoryList] = useState(null);
  const [filterValues, setFilterValues] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [historyListTotalCount, setHistoryListTotalCount] = useState(0);

  const history = useHistory();

  const handleCancelTransactionShow = () => {
    setCancelTransactionModal(true);
  };

  const handleCancelTransactionHide = () => {
    setCancelTransactionModal(false);
  };

  useEffect(() => {
    async function getRecentHistory() {
      const recentHistoryResponse = await recentHistoryApi();
      if (recentHistoryResponse.Error) {
        isUnauthorized({ response: recentHistoryResponse });
        setErrorMessage(recentHistoryResponse.Error.message);
        handleErrorModalShow();
        return;
      }

      setRecentHistory(recentHistoryResponse ? recentHistoryResponse : []);
    }
    getRecentHistory();
  }, [updateHistoryList, filterValues, currentPage]);

  useEffect(() => {
    async function getHistoryList() {
      const historyListResponse = await historyListApi({
        currentPage,
        filterValues
      });
      if (historyListResponse.Error) {
        isUnauthorized({ response: historyListResponse });
        setErrorMessage(historyListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setHistoryListTotalCount(
        historyListResponse && historyListResponse.count
          ? historyListResponse.count
          : 0
      );
      setHistoryList(
        historyListResponse && historyListResponse.data
          ? historyListResponse.data
          : []
      );
    }
    getHistoryList();
  }, [filterValues, currentPage, updateHistoryList]);

  const enableLoading = () => {
    setLoadingForDownload(true);
  };

  const disableLoading = () => {
    setLoadingForDownload(false);
  };

  const enablePrintButtonLoading = () => {
    setLoadingForPrint(true);
  };

  const disablePrintButtonLoading = () => {
    setLoadingForPrint(false);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };
  const handleCancelShow = () => {
    setCancelTransactionModal(true);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const addDataToSelectedRecentHistory = (data) => {
    setSelectedRecentHistory(data);
  };
  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };
  const removeDataFromSelectedRecentHistory = () => {
    setSelectedRecentHistory({});
  };

  const handleErrorModalOkButton = () => {
    handleErrorModalHide();
  };

  const enableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(true);
  };
  const disableLoadingCancelTransaction = () => {
    setLoadingForCancelTransaction(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };
  const handleConfirmShow = () => {
    setConfirmModal(true);
  };
  const handleFilterValues = (values) => {
    setFilterValues(values);
  };
  const historyUIEvents = {
    openReceiptDialog: (row) => {
      setData(row);
      handleConfirmShow();
    },
    openCancelTransactionDialog: (row) => {
      setData(row);
      handleCancelTransactionShow();
    }
  };

  const onClickOfDownloadButton = async () => {
    enableLoading();
    const { id } = selectedRecentHistory;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`
    });
    disableLoading();
    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  const onClickOfPrintButton = async () => {
    enablePrintButtonLoading();
    const { id } = selectedRecentHistory;
    const recentHistoryPrintResponse = await recentHistoryPrintApi({
      id
    });
    if (recentHistoryPrintResponse.Error) {
      isUnauthorized({ response: recentHistoryPrintResponse });
      setErrorMessage(recentHistoryPrintResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    setPdfUrl(recentHistoryPrintResponse.html);
    setTimeout(() => {
      disablePrintButtonLoading();
      const iframe = document.getElementById("myframe");
      const iframeWindow = iframe.contentWindow || iframe;
      iframe.focus();
      iframeWindow.print();
    }, 500);
  };

  const handleCancelTransaction = async () => {
    enableLoadingCancelTransaction();
    const { id } = selectedRecentHistory;

    const cancelTransactionResponse = await cancelTransactionApi({
      id
    });
    disableLoadingCancelTransaction();
    if (cancelTransactionResponse.Error) {
      isUnauthorized({ response: cancelTransactionResponse });
      setErrorMessage(cancelTransactionResponse.Error.message);
      handleErrorModalShow();
    } else {
      setUpdateHistoryList(Math.random());
      handleSuccessModalShow();
      setSuccessMessage("Transaction cancelled successfully");
      handleCancelTransactionHide();
      updateDashboardCounter();
    }
  };

  const renderRecentRecipientName = (recent) => {
    const { id } = userInstance;
    const {
      senderId,
      transactionTypeId,
      sender,
      receiver,
      receiverId
    } = recent;
    const { firstname, lastname, profile } = receiver || {};
    const {
      firstname: senderFirstName,
      lastname: senderLastName,
      profile: senderProfile
    } = sender;

    const renderRecipientTitle = () => {
      if (transactionTypeId === 5) {
        return "You Have Transfered To Bank";
      }
      if (transactionTypeId === 2) {
        return id === senderId
          ? "Payment Requested From"
          : "Payment Requested To";
      }
      return id === senderId ? "You Paid" : "You Received From";
    };

    const renderProfilePic = () => {
      let profilePic = "";
      if (sender && receiver) {
        if (id === senderId) {
          profilePic = profile;
        } else if (id === receiverId) {
          profilePic = senderProfile;
        }
      }
      if (profilePic === "" || profilePic === null) {
        return toAbsoluteUrl("/media/users/default.jpg");
      }
      return getImageBasedOnNameUrl(profilePic);
    };

    const renderRecipientName = () => {
      if (transactionTypeId === 5) {
        return "";
      }
      if (id === senderId) {
        return <p>{firstname + " " + lastname}</p>;
      }
      if (id === receiverId) {
        return <p>{senderFirstName + " " + senderLastName}</p>;
      }
    };

    return (
      <td className="py-5 notresponsive">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-50 symbol-circle mr-5">
            <CustomImage src={renderProfilePic(recent)} />
          </div>
          <div>
            <span className="text-dark font-weight-bolder text-hover-primary mb-1 d-block">
              {renderRecipientTitle()}
              <br />
              <div className="text-brand text-capitalize">
                {renderRecipientName()}
              </div>
            </span>
          </div>
        </div>
      </td>
    );
  };

  const renderTransactionStatus = (recent) => {
    const { transaction_status } = recent || {};
    const { name = "" } = transaction_status || {};
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClass(name)} status-color text-capitalize`}
        >
          {name ? name : "-"}
        </span>
      </span>
    );
  };

  const renderExpectedDeliveryDate = (recent) => {
    const { expecteddeliverydate, transaction_status } = recent || {};
    const { id: statusId } = transaction_status || {};
    if (statusId !== 3) {
      return "-";
    }

    return (
      <>
        <span className={`text-capitalize`}>
          {`Pending Until `}
          <Moment
            date={expecteddeliverydate}
            format={MM_DD_YY_hh_mm_A}
            tz={TIME_ZONE}
          />
          {" EST"}
        </span>
      </>
    );
  };

  const renderPaymentDetails = (recent) => {
    const { id } = userInstance || {};
    const { transaction_id, created_by } = recent || {};
    if (id !== created_by) {
      return <>-</>;
    }
    const { AccountName = "", other } = transaction_id || {};
    const { AccountLastFour = "" } = other || {};
    return (
      <>
        <span className={`text-capitalize`}>
          {/* {`${AccountName} - ${AccountLastFour}`} */}
          {AccountLastFour ? `XXXXXXX${AccountLastFour}` : "-"}
        </span>
      </>
    );
  };

  const renderCommissionForSender = (recent) => {
    const { commission, commission_sender, transactionStatusId } = recent || {};
    const { id } = userInstance || {};

    const c_user = (( recent.receiver.role_id === 3 && (recent.receiver.auto_deduct_commission && !recent.sender.auto_deduct_commission ) 
                  || (recent.receiver.auto_deduct_commission && recent.receiver.role_id === 3 &&  recent.sender.role_id === 2 )
                  ) ) ?
                   recent.receiver.id : recent.sender.id

    return (
      <td data-label="Transaction Fee">
        <span className="text-dark-75 d-block">
          {transactionStatusId === 2 ? (
            <>-</>
          ) : 
          commission_sender === id ? (
            <NumberFormatter amount={commission} />
          ) : (
            <>-</>
          )
          }
        </span>
      </td>
    );
  };

  const renderTotalAmount = (recent) => {
    const { commission, amount, commission_sender, transactionStatusId } = recent || {};
    const { id } = userInstance || {};

    return (
      <td data-label="Total Amount">
        <span className="text-dark-75 d-block">
          {transactionStatusId === 2 ? (
            <>-</>
          ) : (
            <NumberFormatter
              amount={
                commission_sender === id
                  ? parseFloat(commission) + parseFloat(amount)
                  : amount
              }
            />
          )}
        </span>
      </td>
    );
  };

  const renderDepositType = (recent) => {
    const { deposite_type, transactionStatusId } = recent || {};
    const { name } = deposite_type || {};
    return (
      <td data-label="Transaction Type">
        <span className="text-dark-75 d-block">
          {transactionStatusId === 2 ? <>-</> : deposite_type ? name : "-"}
        </span>
      </td>
    );
  };

  return (
    <>
      <div
        className={`card card-custom mb-0 card-stretch gutter-b dashboard-history mt-5`}
      >
        {/* {/ Head /} */}
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-center">
            <span className="card-label font-weight-bolder text-dark">
              Recent Activity
            </span>
          </h3>
          <div className="card-toolbar">
            <button
              onClick={() => {
                history.push("/history-listing");
              }}
              className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
            >
              View History
            </button>
          </div>
        </div>

        {/* {/ Body /} */}
        <div className="card-body pt-0 pb-3">
          {recentHistory && recentHistory.length > 0 && (
            <div className="tab-content">
              <div className="table-responsive">
                <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                  <thead>
                    <tr className="text-left text-uppercase">
                    <th style={{ minWidth: "160px" }}>
                        Action
                      </th>
                      <th className="pl-7" style={{ minWidth: "280px" }}>
                        Name
                      </th>
                      <th style={{ minWidth: "150px" }}>Status</th>
                      <th style={{ minWidth: "100px" }}>Amount</th>
                      <th style={{ minWidth: "100px" }}>Transaction Fee</th>
                      <th style={{ minWidth: "100px" }}>Total Amount</th>
                      <th style={{ minWidth: "100px" }}>Transaction Type</th>
                      <th style={{ minWidth: "150px" }}>Date &amp; Time</th>
                      <th style={{ minWidth: "150px" }}>
                        Expected Delivery Date
                      </th>
                      <th style={{ minWidth: "150px" }}>Payment Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentHistory.map((recent, index) => (
                      <tr key={index}>
                        {
                          <RecentHistoryActionsButtons
                            recent={recent}
                            addDataToSelectedRecentHistory={
                              addDataToSelectedRecentHistory
                            }
                            handleConfirmShow={handleConfirmShow}
                            handleCancelShow={handleCancelShow}
                          />
                        }
                        {renderRecentRecipientName(recent)}
                        <td data-label="Status">
                          <span className="text-dark-75 d-block">
                            {renderTransactionStatus(recent)}
                          </span>
                        </td>
                        <td data-label="Amount">
                          <span className="text-dark-75 d-block">
                            <NumberFormatter amount={recent.amount} />
                          </span>
                        </td>
                        {renderCommissionForSender(recent)}
                        {renderTotalAmount(recent)}
                        {renderDepositType(recent)}

                        <td data-label="Date &amp; Time">
                          <span className="text-dark-75 d-block">
                            <Moment
                              date={recent.createdAt}
                              format={MM_DD_YY_hh_mm_A}
                              tz={TIME_ZONE}
                            />
                            {" EST"}
                          </span>
                        </td>
                        <td data-label="Expected Delivery Date">
                          <span className="text-dark-75 d-block">
                            {renderExpectedDeliveryDate(recent)}
                          </span>
                        </td>
                        <td data-label="Payment Details">
                          <span className="text-dark-75 d-block">
                            {renderPaymentDetails(recent)}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          {recentHistory && recentHistory.length === 0 && (
            <EmptyRecentActivityContent
              image="/media/svg/illustrations/recent-activity.svg"
              title="No Recent Activity Available!"
              description="There is no recent activity available yet. Your recent activity will display after you send or receive your first payment."
            />
          )}
        </div>
        {/* <HistoryUIProvider historyUIEvents={historyUIEvents}>
          {isConfirmModal && (
            <HistoryReceiptModal
              data={data}
              handleConfirmHide={handleConfirmHide}
              isConfirmModal={isConfirmModal}
              onClickOfDownloadButton={onClickOfDownloadButton}
              onClickOfPrintButton={onClickOfPrintButton}
              isLoadingForDownload={isLoadingForDownload}
              isLoadingForPrint={isLoadingForPrint}
            />
          )}
          {isCancelTransactionModal && (
            <HistoryCancelTransactionDialog
              isLoadingForCancelTransaction={isLoadingForCancelTransaction}
              isCancelTransactionShow={handleCancelTransactionShow}
              onHideCancelTransaction={handleCancelTransactionHide}
              onClickOfCancelTransaction={handleCancelTransaction}
            />
          )}
        </HistoryUIProvider> */}
        {isConfirmModal && (
          <ReceiptModal
            handleConfirmHide={handleConfirmHide}
            title={"Confirmation"}
            data={selectedRecentHistory}
            isConfirmModal={isConfirmModal}
            buttonLabel={"Download"}
            buttonprintLabel={"Print"}
            onClickOfDownloadButton={onClickOfDownloadButton}
            onClickOfPrintButton={onClickOfPrintButton}
            isLoadingForDownload={isLoadingForDownload}
            isLoadingForPrint={isLoadingForPrint}
          />
        )}

        {isCancelTransactionModal && (
          <HistoryCancelTransactionDialog
            isLoadingForCancelTransaction={isLoadingForCancelTransaction}
            isCancelTransactionShow={handleCancelTransactionShow}
            onHideCancelTransaction={handleCancelTransactionHide}
            onClickOfCancelTransaction={handleCancelTransaction}
          />
        )}

        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className="danger-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalOkButton}
        />
      </div>
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
      <iframe
        className="d-none"
        srcDoc={pdfUrl}
        name="myframe"
        id="myframe"
        title="myframe"
      ></iframe>
    </>
  );
}
