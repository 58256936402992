import React from "react";
import { Spinner } from "react-bootstrap";
const Loader = () => {
  return (
    <>
      <div className="text-center w-100">
        {/* <span className="pr-4">
        <Spinner animation="border" variant="primary" />
      </span>
      <span className="pr-4">
        <Spinner animation="border" variant="secondary" />
      </span> */}
        <span className="pr-4">
          <Spinner animation="border" variant="success" />
        </span>
        <span className="pr-4">
          <Spinner animation="border" variant="danger" />
        </span>
        <span className="pr-4">
          <Spinner animation="border" variant="warning" />
        </span>
        <span className="pr-4">
          <Spinner animation="border" variant="info" />
        </span>
        {/* <span className="pr-4">
        <Spinner animation="border" variant="light" />
      </span>
      <span className="pr-4">
        <Spinner animation="border" variant="dark" />
      </span> */}
      </div>
    </>
  );
};

export default Loader;
