import React from "react";
import { Button, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"; //, checkIsActive
import BlockReportModal from "../../modules/Modal/BlockReportModal";
import { useHistory } from "react-router-dom";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import { CustomImage } from "../../utils/CustomImage";

export function ContactSingleUserView({
  isPorfileViewShow,
  hidePorfileViewModal,
  data,
  handleMakeAPaymentContact,
  handleRequestAPaymentContact,
  handleBlockReport,
  isReportSpam,
  reportReason,
  isBlockReportModalOpen,
  handleBlockReportModalHide,
  handleBlockReportModalShow,
  loadingSave,
  showConfirmationModal,
}) {
  let history = useHistory();
  const { id, addedcontact = {} } = data;
  const {
    firstname,
    lastname,
    business_name,
    username,
    profile,
  } = addedcontact;

  return (
    <>
      <Modal
        className="right"
        show={isPorfileViewShow}
        onHide={hidePorfileViewModal}
      >
        <Modal.Header className="border-0 justify-content-end py-2" closeButton>
          <Button
            variant=" p-0 d-flex justify-content-end"
            onClick={hidePorfileViewModal}
          >
            <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body className="d-flex align-items-start flex-column">
          {/* begin: user details */}
          <div className="full-border pb-7 mb-7 w-100">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-70 mr-5 align-self-start align-self-xxl-center symbol-circle">
                <CustomImage src={getImageBasedOnNameUrl(profile)} />
                <div className="verify-badge label label-md label-success ">
                  <span className="svg-icon svg-icon-sm svg-icon-white mr-0">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Navigation/Check.svg"
                      )}
                    />
                  </span>
                </div>
              </div>
              <div>
                <h6 className="font-weight-bolder font-size-h5 text-dark text-hover-primary mb-1">
                  {`${firstname} ${lastname}`}
                </h6>
                <div className="text-secondary fs-13">{`${
                  business_name ? business_name : ""
                }`}</div>
                <div className="text-secondary fs-13">{`@${
                  username ? username : ""
                }`}</div>
              </div>
            </div>
            <div className="btn-group w-100 mt-7" role="group" aria-label="">
              <button type="button" className="btn btn-success">
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                  />
                </span>
                Added
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  history.push(`/single-chat/${addedcontact.id}`);
                }}
              >
                <span className="svg-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Chat1.svg"
                    )}
                  />
                </span>
                Chat
              </button>
            </div>
          </div>
          {/* end: user details */}
          {/* begin: transaction button */}
          <div className="trans-btn mb-auto w-100">
            <div
              className="d-flex align-items-center py-4 text-brand fs-16 cursor-pointer"
              onClick={handleMakeAPaymentContact}
            >
              <span className="svg-icon svg-icon-brand svg-icon-lg mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
                />
              </span>
              Make a Payment
            </div>
            <div
              className="d-flex align-items-center py-4 text-brand fs-16 cursor-pointer"
              onClick={handleRequestAPaymentContact}
            >
              <span className="svg-icon svg-icon-brand svg-icon-lg mr-3">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                />
              </span>
              Request Payment
            </div>
          </div>
          {/* end: transaction button */}
          {/* begin: action btn*/}
          <div className="align-self-end w-100 pt-10 ">
            <div
              className="cursor-pointer border-bottom d-flex justify-content-between mb-5"
              onClick={() => {
                history.push(`/payment-history-listing/${addedcontact.id}`);
              }}
            >
              <h6 className="text-secondary mb-5 fs-16">
                View Payment History
              </h6>
              <span className="svg-icon svg-icon-brand">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-right.svg"
                  )}
                />
              </span>
            </div>

            <div
              className="cursor-pointer border-bottom d-flex justify-content-between mb-5"
              onClick={() => {
                showConfirmationModal();
              }}
            >
              <h6 className="text-secondary mb-5 fs-16" role="button">
                Remove from Contact List
              </h6>
              <span className="svg-icon svg-icon-brand">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-right.svg"
                  )}
                />
              </span>
            </div>
            <div
              className="cursor-pointer border-bottom d-flex justify-content-between mb-5"
              onClick={() => {
                handleBlockReportModalShow(id);
              }}
            >
              <h6
                className="text-secondary mb-5 fs-16 text-brand font-weight-bolder pt-4 pt-md-0"
                role="button"
              >
                Block/Report
              </h6>
              <span className="svg-icon svg-icon-brand">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Navigation/Arrow-right.svg"
                  )}
                />
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <BlockReportModal
        title="Block & Report Contact"
        checkboxtext="Report Spam"
        reasonMessage={`Tell us why you want to Block/Report`}
        infoMessage={`You would not be able to make a payment or request a payment with ${firstname} ${lastname}`}
        isBlockReportModalOpen={isBlockReportModalOpen}
        hideBlockReportModal={handleBlockReportModalHide}
        saveButtonLabel={"Block"}
        saveOnClick={handleBlockReport}
        cancelButtonLabel={"Cancel"}
        cancelOnClick={handleBlockReportModalHide}
        reportReason={reportReason}
        isReportSpam={isReportSpam}
        loadingSave={loadingSave}
      />
    </>
  );
}
