/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import { MM_DD_YY_hh_mm_A, TIME_ZONE } from "../../../../utils/dateTimeFormats";
import "moment-timezone";

export const DateTimeColumnFormatter = (cellContent, row, rowIndex) => (
  <>
    <span className="text-dark-75 d-block">
      <Moment date={row.createdAt} format={MM_DD_YY_hh_mm_A} tz={TIME_ZONE} />
      {" EST"}
    </span>
    <> </>
  </>
);
