import React from "react";
import { Button, Modal } from "react-bootstrap";

function InfoModal({
  message,
  isInfoModal,
  handleInfoHide,
  infoIcon,
  buttonLabel,
  onClick,
  backdrop
}) {
  return (
    <>
      <Modal
        // backdrop={backdrop ? "static" : ""}
        show={isInfoModal}
        onHide={handleInfoHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            {infoIcon}
          </div>

          <p className="text-center fs-16 text-dark confirmation-content">
            {message}
          </p>
          <Button variant="btn-lg btn-danger px-8 mt-5 mb-5" onClick={onClick}>
            {buttonLabel}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default InfoModal;
