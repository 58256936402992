import React, { Suspense, lazy, useEffect, useState } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./modules/Dashboard/DashboardPage";
import { NotificationListing } from "./modules/Notifications/NotificationListing";
import { NotificationPayment } from "./modules/Notifications/NotificationPayment";
// import { HistoryListing } from "./modules/History/HistoryListing";
import { HistoryPage } from "./modules/History/HistoryPage";
import { PaymentHistoryPage } from "./modules/View Payment History/PaymentHistoryPage";
import { ContactandMessaging } from "./modules/ContactAndMessaging/ContactandMessaging";
import MakePayment from "./modules/MakePayment/MakePayment";
import RequestPayment from "./modules/RequestPayment/RequestPayment";
import SimpleChat from "./modules/SimpleChat/SimpleChat";
import BankInformation from "./modules/Notifications/BankInformation";
import firebaseCustom from "./firebase";
import { useLocalStorage } from "./utils/useLocalStorage";
import { firebaseDeviceTokenApi } from "../axios/services/services";
import HelpSupport from "./modules/Auth/pages/HelpSupport";
import Payments from "./modules/InviteUser/Payments";
import Faqs from "./modules/Auth/pages/Faqs";
import Terms from "./modules/Terms/Terms";
import PrivacyPolicy from "./modules/Privacy Policy/PrivacyPolicy";
import ABADisclosure from "./modules/ABA Disclosure/ABADisclosure";
import TransactionAuthForm from "./modules/Transaction Auth Form/TransactionAuthForm";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  const [isTokenFound, setTokenFound] = useState("");
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");

  useEffect(() => {
    try {
      firebaseCustom(setTokenFound);
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (userInstance) {
      sendTokenToServer();
    }
  }, [isTokenFound]);

  const sendTokenToServer = async () => {
    if (isTokenFound === "") return;
    const firebaseDeviceTokenObj = {
      devicetoken: isTokenFound,
    };
    const firebaseDeviceTokenResponse = await firebaseDeviceTokenApi(
      firebaseDeviceTokenObj
    );
  };

  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/auth/login" to="/dashboard" />
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/notifications" component={NotificationListing} />
        <Route
          path="/notification-payment/transId=:tid/notificationId=:nid"
          component={NotificationPayment}
        />
        <Route
          path="/bank-information/transId=:tid/notificationId=:nid"
          component={BankInformation}
        />
        {/* <Route path="/history-listing" component={HistoryListing} /> */}
        <Route exact path="/make-payment" component={MakePayment} />
        <Route
          exact
          path="/make-payment/contactId=:contactid"
          component={MakePayment}
        />
        <Route exact path="/make-payment/txId=:txId" component={MakePayment} />
        <Route exact path="/request-payment" component={RequestPayment} />
        <Route
          exact
          path="/request-payment/contactId=:contactid"
          component={RequestPayment}
        />
        <Route
          exact
          path="/request-payment/txId=:txId"
          component={RequestPayment}
        />
        {/* <Route path="/transfer-balance" component={TransferBalance} /> */}
        <Route path="/contact-and-messaging" component={ContactandMessaging} />
        <Route path="/single-chat/:id?" component={SimpleChat} />
        {/* <ContentRoute path="/builder" component={BuilderPage} /> */}
        {/* <ContentRoute path="/my-page" component={MyPage} /> */}
        {/* <Route path="/google-material" component={GoogleMaterialPage} /> */}
        {/* <Route path="/react-bootstrap" component={ReactBootstrapPage} /> */}
        {/* <Route path="/e-commerce" component={ECommercePage} /> */}
        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/history-listing" component={HistoryPage} />
        <Route
          path="/payment-history-listing/:id?"
          component={PaymentHistoryPage}
        />
        <Route path="/support" component={HelpSupport} />
        <Route exact path="/payments/uid=:uid" component={Payments} />
        <Route path="/faqs" component={Faqs} />
        <ContentRoute path="/terms" component={Terms} />
        <ContentRoute path="/privacypolicy" component={PrivacyPolicy} />
        <ContentRoute path="/aba-disclosure" component={ABADisclosure} />
        <ContentRoute
          path="/transaction-auth-form"
          component={TransactionAuthForm}
        />
        {/* <Redirect to="error/error-v1" /> */}
      </Switch>
    </Suspense>
  );
}
