import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import {
  confirmBankApi,
  getFundingAccountsApi
} from "../../../axios/services/services";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { PENDING_MICRODEPOSIT_BANK_VERIFICATION } from "../../utils/const";
import { ConfirmBankSchema } from "../../utils/FormikSchema";
import Loader from "../../utils/Loader";
import { isUnauthorized } from "../../utils/utils";
import ConfirmBankModal from "../Modal/ConfirmBankModal";
import ErrorModal from "../Modal/ErrorModal";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";

const initialValuesConfirmBank = {
  amountone: "",
  amounttwo: ""
};

const DashboardPendingActions = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [fundingAccountList, setFundingAccountList] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [confirmBankDetails, setConfirmBankDetails] = useState(null);
  const [isConfirmBankAccountModalShow, setConfirmBankAccountModal] = useState(
    false
  );
  const [isLoadingConfirmBank, setLoadingConfirmBank] = useState(false);
  const [isUpdateBankDetails, setUpdateBankDetails] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [pendingAccountList, setPendingAccountList] = useState(null);

  useEffect(() => {
    async function getFundingAccounts() {
      enableLoading();
      const getFundingAccountsResponse = await getFundingAccountsApi();
      disableLoading();
      if (getFundingAccountsResponse.Error) {
        isUnauthorized({ response: getFundingAccountsResponse });
        setErrorMessage(getFundingAccountsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setFundingAccountList(getFundingAccountsResponse);
      const pendingBanks = getFundingAccountsResponse.filter((data)=> data.confirmed === false);
      setPendingAccountList (pendingBanks);
    }
    getFundingAccounts();
  }, [isUpdateBankDetails]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableLoadingConfirmBank = () => {
    setLoadingConfirmBank(true);
  };

  const disableLoadingConfirmBank = () => {
    setLoadingConfirmBank(false);
  };

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const showConfirmBankAccountModal = () => {
    setConfirmBankAccountModal(true);
  };

  const hideConfirmBankAccountModal = () => {
    setConfirmBankAccountModal(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleConfirmBank = () => {
    formikConfirmBank.handleSubmit();
  };

  const formikConfirmBank = useFormik({
    initialValues: initialValuesConfirmBank,
    validationSchema: ConfirmBankSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      enableLoadingConfirmBank();
      const { amountone, amounttwo } = values;
      const { entity_id } = confirmBankDetails;
      let confirmBankObj = {
        id: entity_id,
        amount_one: amountone.toString(),
        amount_two: amounttwo.toString()
      };
      const confirmBankResponse = await confirmBankApi(confirmBankObj);
      disableLoadingConfirmBank();
      setSubmitting(false);
      if (confirmBankResponse.Error) {
        isUnauthorized({ response: confirmBankResponse });
        setErrorMessage(confirmBankResponse.Error.message);
        handleErrorModalShow();
        return;
      } else {
        hideConfirmBankAccountModal();
        setUpdateBankDetails(Math.random());
        formikConfirmBank.resetForm();
        setSuccessMessage("Bank confirmed successfully");
        handleSuccessModalShow();
      }
    },
    onReset: (values, { resetForm }) => {}
  });

  const renderNoPendingVerifications = () => {
    const noPending =
      fundingAccountList &&
      fundingAccountList.filter((account) => account.confirmed === false);
    return (
      <>
        {fundingAccountList && noPending.length === 0 && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible text-center w-100">
            <div className="alert-text font-weight-bold">
              No Microdeposit bank verification pending
            </div>
          </div>
        )}
      </>
    );
  };

  const renderFinances = () => {
    return (
      <Row>
        {isLoading && <Loader />}
        {fundingAccountList &&
          fundingAccountList.map((fundingAccount, index) => {
            const { others, confirmed } = fundingAccount || {};
            const accounts = JSON.parse(others) || {};
            const { AccountType, BankAccount, FundingAccountName = "" } =
              accounts || {};
            const { AccountLastFour = "" } = BankAccount || {};
            return (
              <React.Fragment key={index}>
                {(AccountType === "Savings" || AccountType === "Checking") &&
                  !confirmed && (
                    <Col sm={12} md={12} className="mb-3">
                      <div className="saved-details">
                        <div className="d-flex">
                          <div className="symbol symbol-50 mr-3">
                            <i className="fa fa-landmark text-success icon-3x"></i>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                            <div className="details">
                              <h5 className="font-weight-bolder fs-16 mb-0 text-capitalize">
                                {FundingAccountName}
                              </h5>
                              <div className="text-secondary fs-13">
                                {`${AccountType} XXXXXXX${AccountLastFour}`}
                              </div>
                            </div>

                            <div className="right-info mr-3">
                              {!confirmed && (
                                <>
                                  <Button
                                    variant="btn btn-light-warning btn-hover-warning font-weight-bolder btn-sm"
                                    onClick={() => {
                                      setConfirmBankDetails(fundingAccount);
                                      formikConfirmBank.handleReset();
                                      showConfirmBankAccountModal();
                                    }}
                                  >
                                    <span className="svg-icon svg-icon-2x svg-icon-warning menu-icon mr-1">
                                      <SVG
                                        src={toAbsoluteUrl(
                                          "/media/svg/icons/Code/Warning-1-circle.svg"
                                        )}
                                          title="Confirm Bank"
                                      />
                                    </span>
                                    Confirm Bank
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  )}
              </React.Fragment>
            );
          })}
        {renderNoPendingVerifications()}
      </Row>
    );
  };

  return (
    <>
      {
        pendingAccountList && pendingAccountList.length > 0 ?
        <div className="row">
          <div className="col-md-12">
            <Card className="card-custom card-stretch">
              <div className="card-header border-0 py-5">
                <h3 className="card-title align-items-center">
                  <span className="card-label font-weight-bolder text-dark">
                    {PENDING_MICRODEPOSIT_BANK_VERIFICATION}
                  </span>
                </h3>
                <div className="card-toolbar">
                  <button
                    onClick={() => {
                      history.push("/user-profile#finances");
                    }}
                    className="btn btn-light-primary font-weight-bolder font-size-sm mr-3"
                  >
                    View Account Settings
                  </button>
                </div>
              </div>

              <Card.Body className="py-3">{renderFinances()}</Card.Body>
            </Card>
          </div>
        </div>
        :
        null
      }

      {isErrorModalOpen && (
        <ErrorModal
          message={errorMessage}
          isErrorModalOpen={isErrorModalOpen}
          hideErrorModal={handleErrorModalHide}
          errorIcon={
            <div className="danger-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </div>
          }
          errorButtonLabel={"Close"}
          errorOnClick={handleErrorModalHide}
        />
      )}

      {isConfirmBankAccountModalShow && (
        <ConfirmBankModal
          isLoadingConfirmBank={isLoadingConfirmBank}
          formik={formikConfirmBank}
          confirmBankDetails={confirmBankDetails}
          isConfirmBankAccountModalShow={isConfirmBankAccountModalShow}
          hideConfirmBankAccountModal={hideConfirmBankAccountModal}
          onClickOfSaveButton={handleConfirmBank}
        />
      )}

      {isSuccessModalOpen && (
        <UpdatedSuccessfulModal
          message={successMessage}
          UpdatedSuccessfulModalShow={isSuccessModalOpen}
          UpdatedSuccessfulModalHide={handleSuccessModalHide}
          successIcon={
            <div className="success-icon-wrapper text-center">
              <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
                />
              </span>
            </div>
          }
        />
      )}
    </>
  );
};

export default DashboardPendingActions;
