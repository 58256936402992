/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { isUnauthorized } from "../../../../../app/utils/utils";
import ErrorModal from "../../../../../app/modules/Modal/ErrorModal";
import {
  markAsReadNotificationApi,
  notificationCounterApi,
} from "../../../../../axios/services/services";
import { Badge } from "react-bootstrap";

export function AsideMenuList({ layoutProps }) {
  const [errorMessage, setErrorMessage] = useState("");
  const [notificationCounter, setNotificationCounter] = useState("");
  const [chatCounter, setChatCounter] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [updateChatCounter, setUpdateChatCounter] = useState(0);

  async function getNotificationCounter() {
    const notificationCounterResponse = await notificationCounterApi({});
    if (notificationCounterResponse.Error) {
      isUnauthorized({ response: notificationCounterResponse });
      setErrorMessage(notificationCounterResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    const { unread_count = "", total_unread_chat_count = "" } =
      notificationCounterResponse || {};
    if (unread_count > 0) {
      setNotificationCounter(unread_count);
    } else {
      setNotificationCounter("");
    }

    if (total_unread_chat_count > 0) {
      setChatCounter(total_unread_chat_count);
    } else {
      setChatCounter("");
    }
  }

  useEffect(() => {
    getNotificationCounter();
  }, [updateChatCounter]);

  async function markAsReadNotification() {
    const markAsReadNotificationResponse = await markAsReadNotificationApi({});
    if (markAsReadNotificationResponse.Error) {
      isUnauthorized({ response: markAsReadNotificationResponse });
      setErrorMessage(markAsReadNotificationResponse.Error.message);
      handleErrorModalShow();
      return;
    }
  }

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text gray-3">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/contact-and-messaging",
            false
          )}`}
          aria-haspopup="true"
          onClick={() => {
            setUpdateChatCounter(Math.random());
          }}
        >
          <NavLink className="menu-link" to="/contact-and-messaging">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Group-chat.svg"
                )}
              />
            </span>
            <span className="menu-text gray-3">
              Contacts/Messaging
              {chatCounter && (
                <Badge
                  className="ml-2 notification-counter"
                  style={{ backgroundColor: "#9b111e" }}
                  pill
                  variant="success"
                >
                  {chatCounter}
                </Badge>
              )}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/history-listing",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/history-listing">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")}
              />
            </span>
            <span className="menu-text">History</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/make-payment", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/make-payment">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
              />
            </span>
            <span className="menu-text gray-3">Make a Payment</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/request-payment",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/request-payment">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")} />
            </span>
            <span className="menu-text gray-3">Request a Payment</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/transfer-balance",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transfer-balance">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/MC.svg")} />
            </span>
            <span className="menu-text gray-3">Transfer Balance</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/pay-vendor", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/pay-vendor">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Credit-card.svg")} />
            </span>
            <span className="menu-text gray-3">Pay a Vendor</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notifications", false)}`}
          aria-haspopup="true"
          onClick={() => {
            markAsReadNotification();
          }}
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Notification2.svg"
                )}
              />
            </span>
            <span className="menu-text">
              Notifications
              {notificationCounter && (
                <Badge
                  className="ml-2 notification-counter"
                  style={{ backgroundColor: "#9b111e" }}
                  pill
                  variant="success"
                >
                  {notificationCounter}
                </Badge>
              )}
            </span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/support", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/support">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Devices/Headphones.svg")}
              />
            </span>
            <span className="menu-text gray-3">Help/Support</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/faqs", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/faqs">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Question-circle.svg")}
              />
            </span>
            <span className="menu-text gray-3">FAQs</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item ${getMenuItemActive("/terms", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/terms">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
              />
            </span>
            <span className="menu-text gray-3">Terms & Conditions</span>
          </NavLink>
        </li> */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/privacypolicy", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/privacypolicy">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Lock.svg")}
              />
            </span>
            <span className="menu-text gray-3">Privacy Policy</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}
