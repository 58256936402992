/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NumberFormatter } from "../../../../utils/NumberFormatter";
export const AmountColumnFormatter = (cellContent, row, rowIndex) => {
  const { amount } = row || {};
  return (
    <>
      <span className="text-dark-75 d-block">
        <NumberFormatter amount={amount} />
      </span>
      <> </>
    </>
  );
};
