/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {
  Button,
  ButtonToolbar,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { currentHr } from "../../../../utils/utils";

export const ActionsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { openReceiptDialog, openCancelTransactionDialog, userId, history }
) => {
  const { transactionStatusId, created_by, notification_receivers } = row;

  const renderViewDownloadReceiptButton = () => {
    return (
      <OverlayTrigger
        placement="auto"
        delay={{ show: 250, hide: 400 }}
        overlay={(props) => (
          <Tooltip id="button-tooltip" {...props}>
            View/Download Receipt
          </Tooltip>
        )}
      >
        <Button
          variant="btn btn-light-primary btn-hover-primary font-size-sm text-nowrap btn-icon m-1 btn-sm"
          onClick={() => {
            openReceiptDialog(row);
          }}
          disabled={transactionStatusId === 2 || transactionStatusId === 5} // 2 = Rejected, 5 = Cancelled
        >
          {/* View/Download Receipt */}
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <i className="fa fa-eye" />
          </span>
        </Button>
      </OverlayTrigger>
    );
  };

  const renderCancelButton = () => {
    return (
      <>
        {userId === created_by &&
          currentHr < 18 &&
          (transactionStatusId === 1 || transactionStatusId === 8) && (
            <OverlayTrigger
              placement="auto"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  Cancel Transaction
                </Tooltip>
              )}
            >
              <Button
                variant="btn btn-light-danger btn-hover-danger font-size-sm text-nowrap btn-icon m-1 btn-sm"
                onClick={() => {
                  openCancelTransactionDialog(row);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-danger">
                  <i className="fa fa-times"></i>
                </span>
              </Button>
            </OverlayTrigger>
          )}
      </>
    );
  };

  const renderAcceptRejectButton = () => {
    const [notification_receiver] = notification_receivers || [];
    return (
      <>
        {notification_receiver.status_id === 1 &&
          notification_receiver.notificationTypeId === 1 && (
            <OverlayTrigger
              placement="auto"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  Accept or Reject Funds
                </Tooltip>
              )}
            >
              <Button
                variant="btn btn-light-success btn-hover-success font-size-sm text-nowrap btn-icon m-1 btn-sm"
                onClick={() => {
                  let url = `/notification-payment/transId=${notification_receiver.entity_id}/notificationId=${notification_receiver.id}`;
                  history.push(url);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-success">
                  <i className="fa fa-check"></i>
                </span>
              </Button>
            </OverlayTrigger>
          )}
      </>
    );
  };

  const renderViewDetailsButton = () => {
    const [notification_receiver] = notification_receivers || [];
    return (
      <>
        {(notification_receiver.status_id === 1 ||
          notification_receiver.status_id === 2) &&
          notification_receiver.notificationTypeId === 7 && (
            <OverlayTrigger
              placement="auto"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id="button-tooltip" {...props}>
                  View Details
                </Tooltip>
              )}
            >
              <Button
                variant="btn btn-light-info btn-hover-info font-size-sm text-nowrap btn-icon m-1 btn-sm"
                onClick={() => {
                  let url = `/notification-payment/transId=${notification_receiver.entity_id}/notificationId=${notification_receiver.id}`;
                  history.push(url);
                }}
              >
                <span className="svg-icon svg-icon-md svg-icon-info">
                  <i className="fa fa-info"></i>
                </span>
                {/* View Details */}
              </Button>
            </OverlayTrigger>
          )}
      </>
    );
  };

  return (
    <>
      <ButtonToolbar className="view-download-btn justify-content-md-end">
        {renderViewDownloadReceiptButton()}
        {renderAcceptRejectButton()}
        {renderViewDetailsButton()}
        {renderCancelButton()}
      </ButtonToolbar>
    </>
  );
};
