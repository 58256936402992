import axios from "axios";
import download from "downloadjs";
import { SC_SERVER_URL, SERVER_URL } from "../app/ServerConfig";
import { getPublicIP } from "../app/utils/utils";

export const getCall = (url) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getPDFCall = (url, customPdfName) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        const content = res.headers["content-type"];
        download(res.data, customPdfName + ".pdf", content);
        resolve({ content });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const getPDFPrintCall = (url) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .get(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
        responseType: "blob",
      })
      .then((res) => {
        resolve({ res });
      })
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .post(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const patchCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .patch(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const putCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .put(baseUrl, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const deleteCall = (url, data = {}) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    const baseUrl = `${SERVER_URL}${url}`;
    axios
      .delete(baseUrl, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
          IP: ip,
        },
        credentials: "include",
      })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });

export const postCallWithMedia = (url, data = {}, containerkey) =>
  new Promise(async (resolve) => {
    const ip = await getPublicIP();
    let container = "";
    if (containerkey === 1) {
      container = "profilepic";
    } else if (containerkey === 2) {
      container = "cheque";
    } else {
    }

    const baseUrl = `${SERVER_URL}${url}`;
    const formData = new FormData();
    formData.append(container, data.file);

    axios({
      method: "POST",
      url: baseUrl,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("auth-token")}`,
        IP: ip,
      },
    })
      .then((res) => (res.status === 204 ? {} : res.data))
      .then((json) => resolve(json))
      .catch((error) => {
        if (error && error.response) {
          resolve(error.response.data);
        }
      });
  });
