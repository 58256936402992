import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

function LoginTwoFactorVerificationModal({
  isTwoFactorVerificationModalShow,
  isTwoFactorVerificationModalHide,
  formik,
  loginResponse,
  loading,
  resendVerificationOtp,
}) {
  const {
    twofactorauth_mail = "",
    twofactorauth_cellnumber = "",
    otp_twofactorauth = "",
  } = loginResponse || {};
  const user_Id = loginResponse.id;
  const [isLabel, setLable] = useState("cellnumber");
  const [isotp, setOptModal] = useState(false);
  const [twoFactor, setTwoFactor] = useState(twofactorauth_cellnumber);
  const [emailFactor, setEmailFactor] = useState(twofactorauth_mail);
  const [isResendOtp, setResendOtp] = useState(false);
  let [time, setTime] = useState(59);
  const [otp, setOtp] = useState(otp_twofactorauth);
  const [check, setCheck] = useState(false);
  let [myInterval, setMyInterval] = useState(null);

  const handleShow = async () => {
    const reqData = {
      userId: loginResponse.id,
    };
    if (isLabel === "cellnumber") {
      reqData["cellnumber"] = loginResponse.twofactorauth_cellnumber;
    } else {
      reqData["email"] = loginResponse.twofactorauth_mail;
    }
    const res = await resendVerificationOtp(reqData);
    if (res) {
      setOptModal(true);
      setMyInterval(
        setInterval(() => {
          if (time > 0) {
            setTime((time -= 1));
          }
        }, 1000)
      );
      setTimeout(() => {
        setResendOtp(true);
        setTime(59);
      }, 59000);
    }
  };

  const onResendOtp = async () => {
    const reqData = {
      userId: loginResponse.id,
    };
    if (isLabel === "cellnumber") {
      reqData["cellnumber"] = loginResponse.twofactorauth_cellnumber;
    } else {
      reqData["email"] = loginResponse.twofactorauth_mail;
    }
    const res = await resendVerificationOtp(reqData);
    if (res) {
      setResendOtp(false);
      setMyInterval(
        setInterval(() => {
          if (time >= 0) {
            setTime((time -= 1));
          }
        }, 1000)
      );
      setTimeout(() => {
        setResendOtp(true);
        setTime(59);
      }, 59000);
    }
  };

  const handleGoBackButton = () => {
    setOptModal(false);
    clearInterval(myInterval);
    setMyInterval(null);
    setTime(59);
  };

  const maskingFunction = (phoneNumber) => {
    let subNum = phoneNumber.toString().substring(0, 5);
    let lastsubnum = phoneNumber.toString().substring(11, 15);
    subNum = subNum + " " + "XXX" + "-" + lastsubnum;
    return subNum;
  };
  const maskingMail = (twofactorauth_mail) => {
    let sunMail = twofactorauth_mail.toString().substring(0, 3);
    let lastmiddlemail = twofactorauth_mail.split("@")[1];
    let lastsubmail = lastmiddlemail.substring(0, 1);
    sunMail = sunMail + "****" + "@" + lastsubmail + "******";
    return sunMail;
  };

  const handleChange = (e) => {
    setCheck(e.target.checked);
    var today = new Date();
    var priorDate = new Date(new Date().setDate(today.getDate() + 30));
    let storedUsers = localStorage.getItem("remember_users");
    const rememberUsersList =
      storedUsers?.length > 0 ? JSON.parse(storedUsers) : [];
    const userIndex = rememberUsersList.findIndex(
      (item) => item.userId === user_Id
    );
    let rememberUser = {
      userId: user_Id,
      isRemebmer: e.target.checked ? "1" : "0",
      expiryDate: priorDate,
    };
    if (userIndex > -1) {
      rememberUsersList[userIndex] = rememberUser;
    } else {
      rememberUsersList.push(rememberUser);
    }
    localStorage.setItem("remember_users", JSON.stringify(rememberUsersList));
  };

  return (
    <>
      {
        <Modal
          show={isTwoFactorVerificationModalShow}
          onHide={isTwoFactorVerificationModalHide}
        >
          <Modal.Header>
            <Modal.Title className="font-weight-boldest fs-16">
              Two-Factor Authentication
            </Modal.Title>
            <Button
              variant=" p-0 d-flex justify-content-end rounded-close"
              onClick={isTwoFactorVerificationModalHide}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Header>
          {isotp ? (
            <Modal.Body>
              <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
                  {" "}
                  <p className="text-secondary">
                    {isLabel === "cellnumber"
                      ? `Enter the verification code we sent to your mobile number${maskingFunction(
                          twofactorauth_cellnumber
                        )}`
                      : `Enter the verification code we sent to your email
                    ${maskingMail(twofactorauth_mail)}`}
                  </p>
                  <div className="resend-btn-group d-flex align-items-center">
                    {isResendOtp ? (
                      <button onClick={onResendOtp} className="btn resend-code">
                        Resend Code
                      </button>
                    ) : (
                      <p className="resend-code">00:{time}</p>
                    )}
                  </div>
                  <input
                    placeholder="Enter Verification Code*"
                    autoComplete="off"
                    type="number"
                    value={otp}
                    name="twofactorauthotp"
                    className="form-control h-auto py-3 px-6 mt-5"
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                    {...formik.getFieldProps("twofactorauthotp")}
                  />
                  {formik.touched.twofactorauthotp &&
                  formik.errors.twofactorauthotp ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formik.errors.twofactorauthotp}
                      </div>
                    </div>
                  ) : null}
                  <label>
                    <span className="payxtrem-checkbox">
                      {" "}
                      <input
                        type="checkbox"
                        checked={check}
                        value={check ? "0" : "1"}
                        name="remember_me"
                        onChange={handleChange}
                      />
                      &nbsp;Remember this device
                    </span>
                  </label>
                </Form.Group>
                <Modal.Footer className="border-0 p-0">
                  <Button
                    variant="Light"
                    size="lg"
                    className="px-10"
                    disabled={loading}
                    onClick={handleGoBackButton}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="success"
                    size="lg"
                    type="submit"
                    className="px-10"
                    disabled={loading}
                  >
                    Verify
                    {loading && (
                      <span className=" mr-3 ml-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          ) : (
            <Modal.Body>
              <Form onSubmit={formik.handleSubmit}>
                <p className="text-secondary">
                  For added security, Two-Factor Authentication is required for
                  all PayXtreme users. Continue below to receive a one-time
                  password to your mobile phone or email.
                </p>
                <Form.Group>
                  <>
                    <a className="">
                      {" "}
                      {isLabel === "cellnumber" ? "Mobile Number" : "Email"}
                    </a>
                    <a
                      href="#"
                      onClick={() =>
                        setLable(
                          isLabel === "cellnumber" ? "email" : "cellnumber"
                        )
                      }
                      className="btn"
                      style={
                        isLabel === "cellnumber"
                          ? {
                              color: "#3699ff",
                              marginLeft: 272,
                              marginTop: -36,
                            }
                          : {
                              color: "#3699ff",
                              marginLeft: 203,
                              marginTop: -36,
                            }
                      }
                    >
                      {isLabel === "cellnumber"
                        ? "Send Code Via Email"
                        : "Send Code Via Mobile Number"}
                    </a>
                  </>
                  {isLabel === "cellnumber" && (
                    <input
                      autoComplete="off"
                      type="string"
                      disabled
                      label={isLabel === "cellnumber"}
                      className="form-control h-auto py-3 px-6 mt-5"
                      name="cellnumber"
                      value={twoFactor}
                      onChange={(event) => setTwoFactor(event.target.value)}
                    />
                  )}
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formik.errors.phoneNumber}
                      </div>
                    </div>
                  ) : null}
                  {isLabel === "email" && (
                    <input
                      placeholder="email"
                      autoComplete="off"
                      type="email"
                      label={isLabel === "email"}
                      className="form-control h-auto py-3 px-6 mt-5"
                      name="emailFactor"
                      value={emailFactor}
                      disabled
                      onChange={(event) => {
                        setEmailFactor(event.target.value);
                      }}
                    />
                  )}
                  {formik.touched.email && formik.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formik.errors.email}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Modal.Footer className="border-0 p-0">
                  <Button
                    variant="success"
                    size="lg"
                    className="px-10"
                    disabled={loading}
                    onClick={handleShow}
                    // onClick={handleNextClick}
                  >
                    Next
                    {loading && (
                      <span className=" mr-3 ml-3 spinner spinner-white"></span>
                    )}
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal.Body>
          )}
        </Modal>
      }
    </>
  );
}
export default LoginTwoFactorVerificationModal;
