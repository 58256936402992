import React, { useState, useEffect, useRef } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardFooter,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers"; //, checkIsActive
import SVG from "react-inlinesvg";
import {
  contactListApi,
  myChatListingApi,
  oneToOneChatListingApi,
  unreadChatCounterApi,
  sendChatApi,
  singleUserDetailApi,
} from "../../../axios/services/services";
import { useLocalStorage } from "../../utils/useLocalStorage";
import Moment from "react-moment";
import "moment-timezone";
import LinesEllipsis from "react-lines-ellipsis";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorModal from "../Modal/ErrorModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { MMMM_DO_YYYY_H_MM_SS_A } from "../../utils/dateTimeFormats";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  isKeyExistInObject,
  isObjectEmpty,
  isUnauthorized,
} from "../../utils/utils";
import { CustomImage } from "../../utils/CustomImage";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";

const initialValues = {
  text: "",
};

function SimpleChat(props) {
  const [loading, setLoading] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [oneToOneChatList, setOneToOneChatList] = useState([]);
  const [chatList, setChatList] = useState([]);
  const [singleUserDetail, setSingleUserDetail] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [fetchMoreChatList, setFetchMoreChatList] = useState(false);
  const [fetchMoreContactList, setFetchMoreContactList] = useState(true);
  const [contactList, setContactList] = useState(null);
  const [searchChatOrContact, setSearchChatOrContact] = useState("");
  const [isUpdateOneToOneChatList, setUpdateOneToOneChatList] = useState(0);
  const [fetchMoreOneToOneChatList, setFetchMoreOneToOneChatList] = useState(
    true
  );

  const [showContactListMobile, setShowContactListMobile] = useState(false);
  const contactListRef = useRef(null);
  const contactListOverlayRef = useRef(null);

  let [getChatId, setChatId] = useState("");
  const id = props.match.params.id;

  const ChatSchema = Yup.object().shape({
    text: Yup.string()
      .min(1, "At least 1 character required")
      .max(512, "Message Must not be more than 521 characters")
      .required("Required field"),
  });

  useEffect(() => {
    async function getsingleUserDetail() {
      if (!id) return;
      const singleUserDetailResponse = await singleUserDetailApi({ id });
      if (singleUserDetailResponse.Error) {
        isUnauthorized({ response: singleUserDetailResponse });
        setErrorMessage(singleUserDetailResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setSingleUserDetail(singleUserDetailResponse);
    }
    getsingleUserDetail();
  }, [id]);

  useEffect(() => {
    async function getChatListing() {
      const { id } = userInstance;
      const myChatListingResponse = await myChatListingApi({
        id,
        skip: 0,
        searchTextChat: searchChatOrContact,
      });
      if (myChatListingResponse.Error) {
        isUnauthorized({ response: myChatListingResponse });
        setErrorMessage(myChatListingResponse.Error.message);
        handleErrorModalShow();
        return;
      } else {
        if (myChatListingResponse.length >= limit) {
          setFetchMoreChatList(true);
        }
        setChatList(myChatListingResponse);
      }
    }

    getChatListing();
  }, [limit, userInstance, searchChatOrContact, isUpdateOneToOneChatList]);

  useEffect(() => {
    async function getOneToOneChatListing() {
      if (!id) return;
      const oneToOneChatListingResponse = await oneToOneChatListingApi({
        id,
        skip: 0,
      });

      if (oneToOneChatListingResponse.Error) {
        isUnauthorized({ response: oneToOneChatListingResponse });
        setErrorMessage(oneToOneChatListingResponse.Error.message);
        handleErrorModalShow();
        return;
      } else {
        if (
          oneToOneChatListingResponse.length < limit ||
          oneToOneChatListingResponse.length === 0
        ) {
          setFetchMoreOneToOneChatList(false);
        }
        setOneToOneChatList(oneToOneChatListingResponse);
      }
    }

    getOneToOneChatListing();
  }, [limit, id, isUpdateOneToOneChatList]);

  useEffect(() => {
    async function getContactList() {
      const contactListResponse = await contactListApi({
        searchTextContact: searchChatOrContact,
        skip: 0,
      });
      if (contactListResponse.Error) {
        isUnauthorized({ response: contactListResponse });
        setErrorMessage(contactListResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      if (
        contactListResponse &&
        contactListResponse.data &&
        (contactListResponse.data.length < limit ||
          contactListResponse.data.length === 0)
      ) {
        setFetchMoreContactList(false);
      }
      setContactList(
        contactListResponse?.data ? contactListResponse?.data : []
      );
    }
    getContactList();
  }, [limit, searchChatOrContact]);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema: ChatSchema,

    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      enableLoading();

      const { id } = userInstance;

      let sendChatObj = {
        userId: id,
        receiver_id: props.match.params.id,
        text: values.text,
        created_by: id,
      };
      const sendChatResponse = await sendChatApi(sendChatObj);
      setChatId(getChatId);
      disableLoading();
      setSubmitting(false);
      if (sendChatResponse.Error) {
        isUnauthorized({ response: sendChatResponse });
        setErrorMessage(sendChatResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      resetForm();
      setUpdateOneToOneChatList(Math.random());
    },
  });

  const paginateChatList = async () => {
    if (chatList && chatList.length <= limit) {
      return;
    }
    const { id } = userInstance;
    const myChatListingResponse = await myChatListingApi({
      id,
      skip: chatList.length,
      searchTextChat: searchChatOrContact,
    });

    if (myChatListingResponse.Error) {
      isUnauthorized({ response: myChatListingResponse });
      setErrorMessage(myChatListingResponse.Error.message);
      handleErrorModalShow();
      return;
    } else {
      if (
        myChatListingResponse.length < limit ||
        myChatListingResponse.length === 0
      ) {
        setFetchMoreChatList(false);
      }
      setChatList((chats) => [...chats, ...myChatListingResponse]);
    }
  };

  const chatUnreadCounter = async (getChatId) => {
    let chatCounterRespose = await unreadChatCounterApi(getChatId);
  };

  const paginateContactList = async () => {
    if (contactList && contactList.length <= limit) {
      return;
    }
    const contactListResponse = await contactListApi({
      skip: contactList ? contactList.length : 0,
      searchTextContact: searchChatOrContact,
    });
    if (contactListResponse.Error) {
      isUnauthorized({ response: contactListResponse });
      setErrorMessage(contactListResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    if (
      !isObjectEmpty(contactListResponse) &&
      isKeyExistInObject(contactListResponse, "data") &&
      (contactListResponse.data.length < limit ||
        contactListResponse.data.length === 0)
    ) {
      setFetchMoreContactList(false);
    }
    setContactList((contacts) => [...contacts, ...contactListResponse.data]);
  };

  const paginateOneToOneChatListing = async () => {
    if (!id) return;
    if (oneToOneChatList && oneToOneChatList.length <= limit) {
      return;
    }
    const oneToOneChatListingResponse = await oneToOneChatListingApi({
      id,
      skip: oneToOneChatList ? oneToOneChatList.length : 0,
    });
    if (oneToOneChatListingResponse.Error) {
      isUnauthorized({ response: oneToOneChatListingResponse });
      setErrorMessage(oneToOneChatListingResponse.Error.message);
      handleErrorModalShow();
      return;
    } else {
      if (
        oneToOneChatListingResponse.length < limit ||
        oneToOneChatListingResponse.length === 0
      ) {
        setFetchMoreOneToOneChatList(false);
      }
      setOneToOneChatList((oneToOneChat) => [
        ...oneToOneChat,
        ...oneToOneChatListingResponse,
      ]);
    }
  };

  const renderFirstNameAndLastNameChatHeader = (contact) => {
    const { addedcontact } = contact || {};
    const { firstname, lastname } = addedcontact || {};
    return <>{`${firstname} ${lastname}`}</>;
  };

  const renderRecieverProfilePic = (chat) => {
    const { chat_id } = chat || {};
    const { user_id, receiverId } = chat_id || {};
    const { id: userId, profile } = user_id || {};
    const { profile: profileReceiver } = receiverId || {};
    if (userId === userInstance.id) {
      return getImageBasedOnNameUrl(profileReceiver);
    } else {
      return getImageBasedOnNameUrl(profile);
    }
  };

  const renderProfilePicContactList = (contact) => {
    const defaultPath = toAbsoluteUrl("/media/users/default.jpg");
    if (!contact) return defaultPath;
    const { addedcontact } = contact;
    if (!addedcontact) return defaultPath;
    const { profile } = addedcontact;
    if (!profile) return defaultPath;
    if (profile) return getImageBasedOnNameUrl(profile);
  };

  const renderProfilePicChatList = (chat) => {
    let path = "";
    const { user_id, receiverId, userId } = chat;
    const { id } = userInstance;

    if (id === userId && receiverId && receiverId.profile) {
      path = getImageBasedOnNameUrl(receiverId.profile);
    } else if (id !== userId && user_id && user_id.profile) {
      path = getImageBasedOnNameUrl(user_id.profile);
    } else {
      path = toAbsoluteUrl("/media/users/default.jpg");
    }
    return path;
  };

  const showHideContactListForMobile = () => {
    if (showContactListMobile) {
      contactListRef.current.classList.remove("offcanvas-mobile-on");
      contactListOverlayRef.current.classList.remove(
        "offcanvas-mobile-overlay"
      );
    } else {
      contactListRef.current.classList.add("offcanvas-mobile-on");
      contactListOverlayRef.current.classList.add("offcanvas-mobile-overlay");
    }
  };

  return (
    <>
      <div className="d-flex flex-row">
        <div
          className="flex-row-auto offcanvas-mobile w-350px w-xl-400px"
          id="kt_chat_aside"
          ref={contactListRef}
        >
          <Card className="mb-0">
            <CardBody className="chat-card-body">
              <InputGroup className="input-group-solid">
                <InputGroup.Prepend>
                  <InputGroup.Text id="inputGroupPrepend">
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                    </span>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  className="py-4 h-auto"
                  type="text"
                  autoComplete="off"
                  placeholder="User Name or Email"
                  aria-describedby="inputGroupPrepend"
                  onChange={(event) => {
                    setSearchChatOrContact(event.target.value);
                  }}
                />
              </InputGroup>

              <div className={`search-scroll-height`}>
                {searchChatOrContact &&
                  searchChatOrContact.length > 0 &&
                  chatList &&
                  chatList.length > 0 && <h6 className="mt-2">CHATS</h6>}

                {chatList && chatList.length > 0 && (
                  <InfiniteScroll
                    className={`${
                      searchChatOrContact.length === 0
                        ? "flexible-height-infinite-scroll"
                        : ""
                    }`}
                    dataLength={chatList.length}
                    next={paginateChatList}
                    hasMore={fetchMoreChatList}
                    loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                  >
                    {chatList.map((chat, index) => (
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-between chat-link cursor-pointer"
                        onClick={async () => {
                          chatUnreadCounter(chat.id);
                          const myChatListingResponse = await myChatListingApi({
                            skip: 0,
                            searchTextChat: searchChatOrContact,
                          });
                          setChatList(myChatListingResponse);
                          contactListRef.current.classList.remove(
                            "offcanvas-mobile-on"
                          );
                          contactListOverlayRef.current.classList.remove(
                            "offcanvas-mobile-overlay"
                          );
                          const { id } = userInstance;
                          if (!id) return;
                          if (!chat) return;
                          const { userId, receiver_id } = chat;
                          if (!userId) return;
                          if (!receiver_id) return;
                          if (userId === id) {
                            const path = `/single-chat/${receiver_id}`;
                            props.history.push(path);
                          } else {
                            const path = `/single-chat/${userId}`;
                            props.history.push(path);
                          }
                        }}
                      >
                        <div
                          className="d-flex align-items-center"
                          style={{ width: "calc(100% - 70px)" }}
                        >
                          <div className="symbol symbol-circle symbol-50 mr-3">
                            <CustomImage src={renderProfilePicChatList(chat)} />
                          </div>
                          <div className="d-flex flex-column text-truncate">
                            <span className="text-capitalize text-dark-75 text-hover-primary font-weight-bold font-size-lg">
                              {userInstance.id === chat.userId && (
                                <>
                                  {`${chat.receiverId.firstname} ${chat.receiverId.lastname}`}
                                </>
                              )}

                              {userInstance.id !== chat.userId && (
                                <>
                                  {`${chat.user_id.firstname} ${chat.user_id.lastname}`}
                                </>
                              )}
                            </span>
                            <span className="text-muted font-weight-bold font-size-sm text-truncate d-flex">
                              <div className="notification-counter chat-counter badge badge-pill badge-success mr-1">
                                {chat.unread_chat_counter &&
                                chat.unread_chat_counter > 0
                                  ? chat.unread_chat_counter
                                  : ""}
                              </div>

                              <LinesEllipsis
                                className="text-capitalize"
                                text={
                                  chat &&
                                  chat.chat_id &&
                                  chat.chat_id.length > 0
                                    ? chat.chat_id[0].text
                                    : ""
                                }
                                maxLine="1"
                                ellipsis="..."
                                trimRight
                                basedOn="letters"
                              />
                            </span>
                          </div>
                        </div>

                        <div className="d-flex flex-column align-items-end">
                          <span className="text-muted font-weight-bold font-size-sm text-right">
                            <Moment
                              date={
                                chat &&
                                chat.chat_id &&
                                chat.chat_id.length > 0 &&
                                chat.chat_id[0].createdAt
                                  ? chat.chat_id[0].createdAt
                                  : new Date()
                              }
                              fromNow
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                  </InfiniteScroll>
                )}
                {/*  */}
                {((searchChatOrContact && searchChatOrContact.length > 0) ||
                  (chatList && chatList.length === 0)) &&
                  contactList &&
                  contactList.length > 0 && (
                    <>
                      <h6 className="mt-2"> CONTACTS </h6>
                      <InfiniteScroll
                        className={`${
                          searchChatOrContact.length === 0
                            ? "custom-infinite-scroll-height"
                            : ""
                        }`}
                        dataLength={contactList}
                        next={paginateContactList}
                        hasMore={fetchMoreContactList}
                        loader={
                          <h4 style={{ textAlign: "center" }}>Loading...</h4>
                        }
                      >
                        {contactList.map((contact, index) => (
                          <div
                            key={index}
                            className="d-flex align-items-center justify-content-between chat-link cursor-pointer"
                            onClick={() => {
                              contactListRef.current.classList.remove(
                                "offcanvas-mobile-on"
                              );
                              contactListOverlayRef.current.classList.remove(
                                "offcanvas-mobile-overlay"
                              );
                              const { addedcontact } = contact;
                              if (!addedcontact) return;
                              if (addedcontact) {
                                const { id } = addedcontact;
                                const path = `/single-chat/${id}`;
                                props.history.push(path);
                              }
                            }}
                          >
                            <div
                              className="d-flex align-items-center"
                              style={{ width: "calc(100% - 70px)" }}
                            >
                              <div className="symbol symbol-circle symbol-50 mr-3">
                                <CustomImage
                                  src={renderProfilePicContactList(contact)}
                                />
                              </div>
                              <div className="d-flex flex-column text-truncate">
                                <span className="text-dark-75 text-hover-primary font-weight-bold font-size-lg text-capitalize">
                                  {renderFirstNameAndLastNameChatHeader(
                                    contact
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    </>
                  )}

                {chatList &&
                  contactList &&
                  chatList.length === 0 &&
                  contactList.length === 0 && (
                    <EmptyRecentActivityContent
                      className="no-chat-found"
                      image="/media/svg/illustrations/chat.svg"
                      title="No Chats Found"
                      description=""
                    />
                  )}
              </div>
            </CardBody>
          </Card>
        </div>

        <div
          ref={contactListOverlayRef}
          id="mobile-overlay"
          onClick={() => {
            contactListRef.current.classList.remove("offcanvas-mobile-on");
            contactListOverlayRef.current.classList.remove(
              "offcanvas-mobile-overlay"
            );
            setShowContactListMobile(false);
          }}
        ></div>

        <div className="flex-row-fluid ml-lg-8" id="kt_chat_content">
          <Card className="mb-0">
            {id && (
              <>
                <div>
                  <div className="card-header align-items-center px-4 py-3 d-flex">
                    <div className="text-left flex-grow-1">
                      <Button
                        variant="btn-clean"
                        type="button"
                        className="btn btn-clean btn-sm btn-icon btn-icon-md d-lg-none"
                        id="kt_app_chat_toggle"
                        onClick={() => {
                          showHideContactListForMobile();
                        }}
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Adress-book2.svg"
                            )}
                          />
                        </span>
                      </Button>
                    </div>
                    {singleUserDetail && (
                      <div className="text-center flex-grow-1">
                        <div className="text-dark-75 font-weight-bold font-size-h5 text-capitalize">
                          {`${singleUserDetail.firstname} ${singleUserDetail.lastname}`}
                          {singleUserDetail.business_name && (
                            <div className="text-muted text-center flex-grow-1 font-size-h6">
                              {`Member of ${singleUserDetail.business_name}`}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="text-center flex-grow-1"></div>
                  </div>
                </div>
                <CardBody className="message-card">
                  <div
                    id="scrollableDiv"
                    style={{
                      display: "flex",
                      flexDirection: "column-reverse",
                    }}
                  >
                    <InfiniteScroll
                      className="flexible-height-infinite-scroll"
                      dataLength={
                        oneToOneChatList ? oneToOneChatList.length : []
                      }
                      next={paginateOneToOneChatListing}
                      hasMore={fetchMoreOneToOneChatList}
                      style={{
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                      inverse={true} //
                      loader={
                        <h4 style={{ textAlign: "center" }}>Loading...</h4>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      {oneToOneChatList.map((chat, index) => (
                        <div className="messages" key={index}>
                          {userInstance.id !== chat.senderId && (
                            <div className="chat is-you">
                              <div className="chat-avatar">
                                <div className="symbol symbol-circle symbol-40">
                                  <CustomImage
                                    src={renderRecieverProfilePic(chat)}
                                  />
                                </div>
                              </div>

                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    <Moment
                                      date={chat.createdAt}
                                      format={MMMM_DO_YYYY_H_MM_SS_A}
                                    />
                                  </Tooltip>
                                }
                              >
                                <div className="chat-content">
                                  <div className="chat-bubbles">
                                    <div className="chat-bubble">
                                      <div className="chat-msg">
                                        {chat.text}
                                      </div>
                                      <ul className="chat-msg-more p-0">
                                        <li></li>
                                        <li></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </OverlayTrigger>
                            </div>
                          )}

                          {userInstance.id === chat.senderId && (
                            <div className="chat is-me">
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip>
                                    <Moment
                                      date={chat.createdAt}
                                      format={MMMM_DO_YYYY_H_MM_SS_A}
                                    />
                                  </Tooltip>
                                }
                              >
                                <div className="chat-content">
                                  <div className="chat-bubbles">
                                    <div className="chat-bubble">
                                      <div className="chat-msg">
                                        <div>{chat.text}</div>
                                      </div>
                                      <ul className="chat-msg-more p-0">
                                        <li></li>
                                        <li></li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </OverlayTrigger>
                            </div>
                          )}
                        </div>
                      ))}
                    </InfiniteScroll>
                    {/* {id &&
                      oneToOneChatList &&
                      oneToOneChatList.length === 0 && (
                        <EmptyRecentActivityContent
                          image="/media/svg/illustrations/chat.svg"
                          title={`${
                            userInstance &&
                            singleUserDetail &&
                            userInstance.id !== singleUserDetail.id
                              ? "Start a chat with " +
                                singleUserDetail.firstname +
                                " " +
                                singleUserDetail.lastname
                              : ""
                          }`}
                          description=""
                        />
                      )} */}
                  </div>
                </CardBody>
                <CardFooter className="align-items-center chat-footer">
                  <div className="chat-editor-form">
                    <form
                      className="form-row"
                      onSubmit={formik.handleSubmit}
                      autocomplete="off"
                    >
                      <div className="form-control-wrap col">
                        <textarea
                          name="text"
                          className="form-control form-control-simple no-resize"
                          rows="1"
                          id="default-textarea"
                          placeholder="Type your message..."
                          onKeyDown={(e) => {
                            if (formik.values.text.trim().length === 0) {
                              return;
                            }
                            if (e.keyCode === 13 && !e.shiftKey) {
                              formik.handleSubmit();
                            }
                          }}
                          {...formik.getFieldProps("text")}
                        ></textarea>
                      </div>
                      <ul className="p-0 list-style-none chat-editor-tools mb-0 col-auto">
                        <li></li>
                        <li>
                          <button
                            variant="brand"
                            className="btn btn-circle btn-brand btn-icon btn-shadow"
                            type="submit"
                            disabled={loading}
                          >
                            <span className="svg-icon svg-icon-white svg-icon-lg">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Communication/Send.svg"
                                )}
                              />
                            </span>
                          </button>
                          {loading && (
                            <span className="ml-3 spinner spinner-danger"></span>
                          )}
                        </li>
                      </ul>
                    </form>
                  </div>
                </CardFooter>
              </>
            )}

            {!id && oneToOneChatList && oneToOneChatList.length === 0 && (
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/chat.svg"
                title="Lets Chat!"
                description="Choose contact to send message"
              />
            )}
          </Card>
        </div>
      </div>
      {/* <ContactandMessaging  
               unread_counter = {unread_counter}/> */}
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0"></span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default SimpleChat;
