import React from "react";
import { Card } from "react-bootstrap";
import { HistoryFilter } from "./history-filter/HistoryFilter";
import { HistoryTable } from "./history-table/HistoryTable";
import EmptyRecentActivityContent from "../EmptyPage/EmptyRecentActivityContent";
import { TRANSACTION_HISTORY } from "../../utils/const";

export function HistoryCard({
  isLoading,
  historyList,
  currentPage,
  setCurrentPage,
  filterValues,
  handleFilterValues,
  historyListTotalCount,
  userInstance,
  paginateHistoryList,
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">
              {TRANSACTION_HISTORY}
            </span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div>
            <HistoryFilter filterValues={handleFilterValues} />
            {historyList && historyList.length > 0 && (
              <HistoryTable
                isLoading={isLoading}
                historyList={historyList}
                historyListTotalCount={historyListTotalCount}
                userInstance={userInstance}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                paginateHistoryList={paginateHistoryList}
                filterValues={filterValues}
              />
            )}

            {historyList && historyList.length === 0 && (
              <EmptyRecentActivityContent
                image="/media/svg/illustrations/history.svg"
                title="No History Available!"
                description="You don't have any transactions to keep in your History yet. Your History will be available after you send or receive your first payment."
              />
            )}
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
