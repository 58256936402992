/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Moment from "react-moment";
import {
  HH_MMA,
  MMMM_DO_YYYY,
  TIME_ZONE,
} from "../../../../utils/dateTimeFormats";
import "moment-timezone";

export const DateTimeColumnFormatter = (cellContent, row, rowIndex) => (
  <>
    <span className="text-dark-75 d-block">
      {" "}
      <Moment date={row.createdAt} format={MMMM_DO_YYYY} tz={TIME_ZONE} />
      <br />
      {"at "}
      <Moment date={row.createdAt} format={HH_MMA} tz={TIME_ZONE} /> EST
    </span>
    <> </>
  </>
);
