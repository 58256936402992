import React from "react";
import { Card } from "react-bootstrap";
import { PaymentHistoryFilter } from "./payment-history-filter/PaymentHistoryFilter";
import { PaymentHistoryTable } from "./payment-history-table/PaymentHistoryTable";

export function PaymentHistoryCard({
  isLoading,
  paymentHistoryList,
  paymentHistoryListTotalCount
}) {
  return (
    <>
      <Card className="card-custom card-stretch make-payment-wrapper">
        <Card.Header className="align-items-center border-bottom mt-4 d-none d-lg-flex">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bold text-dark">
              View Payment History
            </span>
          </h3>
        </Card.Header>
        <Card.Body>
          <div>
            <PaymentHistoryFilter />
            <PaymentHistoryTable
              isLoading={isLoading}
              paymentHistoryList={paymentHistoryList}
              paymentHistoryListTotalCount={paymentHistoryListTotalCount}
            />
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
