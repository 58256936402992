import React from "react";
import ReceiptModal from "../../../../app/modules/Modal/ReceiptModal";

export function HistoryReceiptModal({
  data,
  isConfirmModal,
  handleConfirmHide,
  onClickOfDownloadButton,
  onClickOfPrintButton,
  isLoadingForDownload,
  isLoadingForPrint,
}) {
  return (
    <ReceiptModal
      data={data}
      title={"Confirmation"}
      isConfirmModal={isConfirmModal}
      handleConfirmHide={handleConfirmHide}
      buttonLabel={"Download"}
      buttonprintLabel={"Print"}
      onClickOfDownloadButton={onClickOfDownloadButton}
      onClickOfPrintButton={onClickOfPrintButton}
      isLoadingForDownload={isLoadingForDownload}
      isLoadingForPrint={isLoadingForPrint}
    />
  );
}
