import React from "react";
import { Button, Modal } from "react-bootstrap";
import { NumberFormatter } from "../../utils/NumberFormatter";

function MakeAPaymentConfirmationModal({
  isGeneralPayment,
  selectedPaymentMethod,
  formik,
  frontCheque,
  backCheque,
  isLoading,
  isConfirmModal,
  handleConfirmHide,
  amount,
  firstname,
  lastname,
  cellnumber,
  scTransactionId,
  setPaymentNote,
  setPaymentSCTransactionType,
  commissionAmount,
  is_sc,
  handleMakeAPayment,
  handleConfirmCancelButton,
  disableAuthorizeAndConfirmButton,
}) {
  const renderPayVia = () => {
    if (selectedPaymentMethod === "Check") {
      return "e-Check";
    } else if (selectedPaymentMethod === "Bank") {
      return "ACH";
    } else if (selectedPaymentMethod === "CreditCard") {
      return "Credit Card";
    } else if (selectedPaymentMethod === "DebitCard") {
      return "Debit Card";
    } else {
      return "";
    }
  };

  const renderCellnumberOrFirstname = () => {
    if (firstname && lastname) {
      return "@" + firstname + " " + lastname;
    } else {
      return cellnumber;
    }
  };

  return (
    <>
      <Modal
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            <div className="text-center mb-10">
              <h1 className="font-weight-bolder text-dark mb-6">
                Review and Authorize
              </h1>
              <p className="fs-18 font-weight-normal text-dark">
                {`Send `}
                <NumberFormatter amount={amount} />
                {` to ${renderCellnumberOrFirstname()} via ${renderPayVia()}`}
              </p>
              <span className="row">
                <span className="text-danger w-100">
                  *Transaction Fee:
                  <NumberFormatter
                    className={"ml-1 font-weight-bold"}
                    amount={commissionAmount}
                  />
                </span>
                <span className="text-danger w-100">
                  <u>PayXtreme fees are non-refundable.</u>
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            {!isGeneralPayment && (
              <div className="col-md-6 mb-5">
                <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                  SCHEDULE CLOSINGS TRANS. ID:
                </h6>
                <p className="text-dark fs-14 font-weight-bold text-left">
                  {scTransactionId}
                </p>
              </div>
            )}

            {!isGeneralPayment && (
              <div className="col-md-6 mb-5">
                <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                  TYPE: *
                </h6>
                {/* <p className="text-dark fs-14 font-weight-bold text-left"> */}
                <select
                  className="form-control h-auto py-5 px-6"
                  name="state"
                  onChange={(event) => {
                    setPaymentSCTransactionType(event.target.value);
                  }}
                >
                  <option value="">Select Type</option>
                  {/* <option value="Due Dilligence">Due Dilligence</option> */}
                  <option value="Earnest Money">Earnest Money</option>
                  <option value="Other">Other</option>
                </select>
                {/* </p> */}
              </div>
            )}

            <div className="col-md-12 mb-5">
              <h6 className="text-secondary fs-14 font-weight-normal mb-1 text-left">
                Send a Note to the Recipient{" "}
                {isGeneralPayment ? "(Optional)" : "*"}
              </h6>
              <p className="text-dark fs-14 font-weight-bold text-left">
                <div className="form-group fv-plugins-icon-container">
                  <input
                    type="text"
                    className="form-control h-auto py-5 px-6"
                    placeholder="Note"
                    name="notes"
                    autoFocus
                    maxLength="512"
                    onInput={(event) => {
                      setPaymentNote(event.target.value);
                    }}
                  />
                </div>
              </p>
            </div>
          </div>
          <div className="modal-button-wrapper mb-3 mt-7">
            <button
              className="btn btn-light-warning font-weight-bold mr-2"
              onClick={handleConfirmHide}
              disabled={isLoading}
            >
              Edit Info
            </button>
            <button
              className="btn btn-light-primary font-weight-bold mr-2"
              onClick={handleConfirmCancelButton}
              disabled={isLoading}
            >
              Cancel
            </button>
          </div>

          <Button
            variant="btn-lg btn-success btn-brand mt-5 py-4 mb-5 w-100"
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={isLoading}
          >
            Authorize and Confirm
            {isLoading && (
              <span className=" mr-3 ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default MakeAPaymentConfirmationModal;
