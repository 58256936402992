/**
 * Entry application component used to compose providers and render Routes.
 * */
import React, { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
// import { Footer } from "../_metronic/layout/components/footer/Footer";
// import firebase from "./firebase";
import { gapi } from "gapi-script";

export default function App({ store, persistor, basename }) {
  useEffect(() => {
    gapi.load("client:auth2", () => {
      gapi.client.init({
        clientId:
          "673112020842-4bh24jttnt0ggk3i5phclorslj718ai9.apps.googleusercontent.com",
        // plugin_name: "payxtreme",
        scope: "openid profile email",
      });
    });
  }, []);

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <Routes />
                <CookieConsent
                  location="bottom"
                  buttonText="Accept"
                  cookieName="https://www.payxtreme.com"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                  expires={150}
                >
                  We use cookies on our website to give you the most relevant
                  and optimized experience by remembering your preferences and
                  repeat visits. By clicking "Accept," you agree to the use of
                  all cookies.
                </CookieConsent>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
