import React from "react";
import { ButtonToolbar } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { normalizeInput } from "../../utils/utils";

function LoginWithFBSignupModal({
  isLoading,
  isLoginWithFB,
  handleLoginWithFBCancelButton,
  formikLoginWithFB,
  disableEmailFieldForLoginWithFB,
}) {
  const handleChange = (event) => {
    const cellNumberWithFormat = normalizeInput(
      event.target.value,
      formikLoginWithFB.values.cellnumber
    );
    formikLoginWithFB.setFieldValue("cellnumber", cellNumberWithFormat);
  };

  return (
    <>
      <Modal
        show={isLoginWithFB}
        onHide={handleLoginWithFBCancelButton}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            Login with Facebook
            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={handleLoginWithFBCancelButton}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formikLoginWithFB.handleSubmit} autocomplete="off">
            <div>
              <p className="text-center fs-13 text-gray-3">
                It appears you have not submitted your profile information. You
                must fill out profile information below and verify your account.
              </p>
            </div>
            <div className="form-group mt-8">
              {/* // inputs */}

              <div className="row">
                <div className="col-md-12 mb-4">
                  <input
                    placeholder="Email Address*"
                    type="email"
                    className="form-control h-auto py-5 px-6"
                    name="email"
                    disabled={disableEmailFieldForLoginWithFB}
                    {...formikLoginWithFB.getFieldProps("email")}
                  />
                  {formikLoginWithFB.touched.email &&
                  formikLoginWithFB.errors.email ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formikLoginWithFB.errors.email}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12 mb-4">
                  <input
                    placeholder="Mobile Number*"
                    type="tel"
                    className="form-control h-auto py-5 px-6"
                    name="cellnumber"
                    value={formikLoginWithFB.values.cellnumber}
                    {...formikLoginWithFB.getFieldProps("cellnumber")}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                  />
                  {formikLoginWithFB.touched.cellnumber &&
                  formikLoginWithFB.errors.cellnumber ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formikLoginWithFB.errors.cellnumber}
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="col-md-12">
                  <input
                    placeholder="Username*"
                    type="text"
                    autocomplete="off"
                    className="form-control h-auto py-5 px-6 mb-2"
                    name="username"
                    value=""
                    {...formikLoginWithFB.getFieldProps("username")}
                  />
                  {formikLoginWithFB.touched.username &&
                  formikLoginWithFB.errors.username ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <i className="fas fa-times text-danger mr-1 text-center"></i>
                        {formikLoginWithFB.errors.username}
                      </div>
                    </div>
                  ) : null}
                  <span className="d-block mb-5 mt-5">
                    Note: your other information will be kept private, your
                    Username will be public
                  </span>
                </div>
              </div>
            </div>
            <p className="text-left text-secondary fs-13">
              You have to provide contact details and bank details with us,
              before checkout payment, go to your Account Settings and add bank
              details and contact details.
            </p>
            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                type="submit"
                variant="btn btn-success mr-3"
                disabled={isLoading}
              >
                Login
                {isLoading && (
                  <span className="ml-3 mr-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={handleLoginWithFBCancelButton}
                disabled={isLoading}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default LoginWithFBSignupModal;
