import React, { useState } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CREATE_AN_ACCOUNT_FOR_FREE } from "../../../utils/const";

function CreateAccount(props) {
  const [loading, setLoading] = useState(false);
  const [signupType, setSignupType] = useState(1);
  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  return (
    <div
      className="login-form login-forgot create-account"
      style={{ display: "block" }}
    >
      <div className="text-left mb-10 ">
        <h3 className="font-size-h1 mb-4">
          <img
            src={toAbsoluteUrl("/media/logos/PayXtreme.png")}
            height="80"
            alt=""
          />
        </h3>
        <div className="mb-15 h4">{CREATE_AN_ACCOUNT_FOR_FREE}</div>
        <div className="form-group mb-10">
          <div className="radio-list">
            <label className="radio radio-success mb-5 h6">
              <input
                type="radio"
                name="personalaccount"
                checked={signupType === 1 ? true : false}
                onChange={() => {
                  setSignupType(1);
                }}
              />
              <span></span>
              Personal Account
            </label>
            <label className="radio radio-success h6">
              <input
                type="radio"
                name="businessaccount"
                checked={signupType === 2 ? true : false}
                onChange={() => {
                  setSignupType(2);
                }}
              />
              <span></span>
              Business Account
            </label>
          </div>
        </div>
        {/* <button
          id="kt_login_signin_submit"
          type="button"
          disabled={formik.isSubmitting}
          className={`btn btn-brand font-weight-bold px-9 py-4 my-3 w-100`}
        >
          <span>Continue</span>
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button> */}
        <button
          className="btn-lg btn btn-brand font-weight-bold px-9 py-5 my-3 w-100 h6"
          // to={`/auth/signup-profile/${signupType}`}
          onClick={() => {
            enableLoading();
            setTimeout(() => {
              disableLoading();
              props.history.push(`/auth/signup-profile/${signupType}#step1`);
            }, 500);
          }}
        >
          Continue
          {loading && <span className="ml-3 spinner spinner-white"></span>}
        </button>
        <span className="font-weight-bold text-dark-50 h6 sign-account">
          Already have an Account?
          <a
            href="login"
            className="font-weight-bold ml-2 text-dark font-weight-bolder"
          >
            Sign In
          </a>
        </span>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(CreateAccount));
