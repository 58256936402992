import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field } from "formik";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const options = {
  cMapUrl: "cmaps/",
  cMapPacked: true,
  standardFontDataUrl: "standard_fonts/",
};

function TermsModal({ isTermsModalOpen, handleTermsModalHide }) {
  const pdfArray = [];
  const [pdfIndex, setPdfIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const currentPdf = pdfArray[pdfIndex];

  const handlePrevious = () => {
    if (pdfIndex > 0) {
      setPdfIndex(pdfIndex - 1);
    }
  };

  const handleNext = () => {
    if (pdfIndex < pdfArray.length) {
      setPdfIndex(pdfIndex + 1);
    }
  };

  return (
    <>
      <h1>
        {pdfArray.map((items, index) => {
          return <Button>{items}</Button>;
        })}
      </h1>{" "}
      <Modal
        size={"lg"}
        show={isTermsModalOpen}
        onHide={handleTermsModalHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text">
          <div className="Example">
            <div className="Example__container">
              <div className="Example__container__document">
                <Document
                  file={currentPdf}
                  onLoadSuccess={onDocumentLoadSuccess}
                  options={options}
                >
                  {Array.from(new Array(numPages), (el, index) => (
                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                  ))}
                </Document>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex" }}>
          <Button
            disabled={pdfIndex === 0}
            onClick={handlePrevious}
            type="button"
          >
            Previous
          </Button>

          <Button
            // disabled={pdfIndex === pdfArray.length - 1}
            onClick={
              pdfIndex === pdfArray.length - 1
                ? handleTermsModalHide
                : handleNext
            }
            type="button"
            style={
              pdfIndex === pdfArray.length - 1
                ? { backgroundColor: "#1bc5bd", borderColor: "#1bc5bd" }
                : { backgroundColor: "#3699ff", borderColor: "#3699ff" }
            }
          >
            {pdfIndex === pdfArray.length - 1 ? "Submit" : "Next"}
          </Button>
        </Modal.Footer>
        {pdfIndex === pdfArray.length - 1 && (
          <>
            <label>By typing your name you are agree terms & conditions.</label>
            <Form className="m-3 harsh">
              <label>Enter Your Name</label>
              <br />
              <input
                type="text"
                className="form-control"
                placeholder="Enter Your Name*"
              />
              <br />
              <DatePicker
                selected={startDate}
                // className="form-control "
                // minDate={stopDate}
                // maxDate={stopDate}
                onChange={(date) => setStartDate(date, Field)}
              />
              <br />
              <p className="badge badge-danger"> </p>
            </Form>
          </>
        )}
      </Modal>
    </>
  );
}
export default TermsModal;
