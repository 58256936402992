/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link, Switch, Redirect, useLocation, Route } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  ContentRoute,
  useHtmlClassService,
} from "../../../../_metronic/layout";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import CreateAccount from "../pages/CreateAccount";
import SignupProfile from "../../SignupWizard/SignupProfile";
import ResetPassword from "./ResetPassword";
import TwoFactoryAccount from "./TwoFactoryAccount";
import PrivacyPolicy from "../../Privacy Policy/PrivacyPolicy";
import Terms from "../../Terms/Terms";
import { connect, useDispatch } from "react-redux";
import * as auth from "../../Auth";

const AuthPage = (props) => {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true),
    };
  }, [uiService]);
  let uid = "";
  const location = useLocation();
  const dispatch = useDispatch();
  const paymentPath = location.pathname.split("=");
  const [mainPath] = paymentPath || {};
  if (mainPath === "/payments/uid") {
    uid = paymentPath[1];
    dispatch(props.setPaymentUserId(uid));
    localStorage.setItem("isPaymentPage", uid);
  }

  return (
    <>
      <div className="d-flex flex-column flex-root">
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          <div
            className="login-aside position-relative d-flex flex-row-auto bgi-size-cover bgi-no-repeat pt-10 pb-10 pl-0 pr-0"
            style={{
              backgroundImage: `url(${toAbsoluteUrl(
                "/media/bg/pantmany.png"
              )})`,
            }}
          ></div>
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-md-center mt-10 mt-md-30 mt-lg-0">
              <Switch>
                <ContentRoute
                  path="/auth/signup-profile/:type"
                  component={SignupProfile}
                />

                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/create-account"
                  component={CreateAccount}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute
                  path="/auth/reset-password/:id"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/twofactory-account"
                  component={TwoFactoryAccount}
                />
                <ContentRoute
                  path="/auth/privacy-policy"
                  component={PrivacyPolicy}
                />
                <ContentRoute path="/auth/terms" component={Terms} />
                <ContentRoute
                  path="/auth/privacypolicy"
                  component={PrivacyPolicy}
                />
                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`footer bg-white py-4 flex-lg-column login-footer  ${layoutProps.footerClasses}`}
        id="kt_footer"
      >
        <div className={`${layoutProps.footerContainerClasses}`}>
          <div className="text-dark order-2 order-md-1 text-center">
            <div className="footer">
              <div className="right">
                <Link
                  to="/public/privacypolicy"
                  target="_blank"
                  className="text-hover-primary ml-4"
                >
                  Policy
                </Link>
                <Link
                  to="/public/terms"
                  target="_blank"
                  className="text-hover-primary ml-4"
                >
                  Terms
                </Link>
                <Link to="/public/support" className="faq">
                  Support
                </Link>
                <Link to="/public/faqs" className="faq">
                  FAQs
                </Link>
              </div>
              <div className="left">
                <p className="m-0 text-black">
                  &copy; PayXtreme, LLC. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, auth.actions)(AuthPage);
