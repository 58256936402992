import React from "react";
import HelpSupport from "./HelpSupport";

function HelpSupportWrapper(props) {
  return (
    <div className="m-10">
      <HelpSupport />
    </div>
  );
}

export default HelpSupportWrapper;
