import * as Yup from "yup";
import valid from "card-validator";

export const ForgotPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Passwords must have least uppercase letter, lowercase letter, number and symbol"
    )
    .required("Required Field"),
  confirmpassword: Yup.string()
    .required("Required Field")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref("password")], "Passwords do not match")
    }),
  otp: Yup.string()
    .matches(/^[0-9]*$/, "Must be only digits")
    .min(4, "Must be exactly 4 digits")
    .max(4, "Must be exactly 4 digits")
    .required("Required Field")
});

export const LoginWithFBSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required Field"),
  cellnumber: Yup.string()
    .matches(/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/, "Wrong mobile number format")
    .min(13, "Phone number must be at least 13 digits")
    .max(14, "Phone number must not be more than 14 digits")
    .required("Required Field"),
  username: Yup.string()
    .min(6, "User name must be at least 6 characters")
    .max(64, "User name must not be more than 64 characters")
    .matches(
      "^(?=[a-zA-Z0-9._]{6,64}$)(?!.*[_.]{2})[^_.].*[^_.]$",
      "Username is not valid, Username must be combination of letters, numbers or underscore and dot"
    )
    .required("Required Field")
});

export const LoginWithGoogleSchema = Yup.object().shape({
  cellnumber: Yup.string()
    .matches(/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/, "Wrong mobile number format")
    .min(13, "Phone number must be at least 13 digits")
    .max(14, "Phone number must not be more than 14 digits")
    .required("Required Field"),
  username: Yup.string()
    .min(6, "User name must be at least 6 characters")
    .max(64, "User name must not be more than 64 characters")
    .matches(
      "^(?=[a-zA-Z0-9._]{6,64}$)(?!.*[_.]{2})[^_.].*[^_.]$",
      "Username is not valid, Username must be combination of letters, numbers or underscore and dot"
    )
    .required("Required Field")
});

export const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter valid email address")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required Field"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(132, "Password must not be more than 132 characters")
    .required("Required Field")
});

export const blankSchema = Yup.object().shape({});

export const ABADisclosureSchema = Yup.object().shape({
  abaDisclosureName: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(132, "Name must not be more than 132 characters")
    .required("Required Field")
});

export const TADisclosureSchema = Yup.object().shape({
  tranAuthName: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(132, "Name must not be more than 132 characters")
    .required("Required Field")
});

// Make A Payment Schema
export const SCTrasnsactionIdSchema = Yup.object().shape({
  scTransactionId: Yup.string()
    .min(36, "Transaction Id must be at least 36 characters")
    .max(36, "Transaction Id must not be more than 36 characters")
    .required("Required Field")
});

export const ProfileSchema = Yup.object().shape({
  cellnumber: Yup.string()
    .matches(/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/, "Wrong mobile number format")
    .min(13, "Phone number must be at least 13 digits")
    .max(14, "Phone number must not be more than 14 digits")
    .required("Required Field")
});

export const AmountSchema = Yup.object().shape({
  amount: Yup.number()
    .positive()
    .min(0.1, "Amount must be at greater than 0")
    .test("is-decimal", "Amount must be valid", (value) =>
      (value + "").match(/^\d*(\.)?(\d{0,2})?$/)
    )
    .required("Required Field")
});

export const PaymentVerifyAddressSchema = Yup.object().shape({
  address1: Yup.string()
    .min(2, "Address 1 must be at least 2 characters")
    .max(512, "Address 1 must not be more than 512 characters")
    .required("Required Field"),
  address2: Yup.string(),
  city: Yup.string()
    .min(2, "City must be at least 2 characters")
    .max(64, "City must not be more than 64 characters")
    .required("Required Field"),
  state: Yup.string()
    .min(2, "State must be at least 2 characters")
    .max(64, "State must not be more than 64 characters")
    .required("Required Field"),
  country: Yup.string()
    .min(2, "Country must be at least 2 characters")
    .max(64, "Country must not be more than 64 characters")
    .required("Required Field"),
  zipcode: Yup.string()
    .matches(/^[0-9]*$/, "Must be only digits")
    .min(5, "Zipcode must be at least 5 digits")
    .max(5, "Zipcode must not be more than 5 digits")
    .required("Required Field")
});

export const CardSchema = Yup.object().shape({
  accountType: Yup.string().required("Required field"),
  cardName: Yup.string()
    .min(1, "Card holder's name must be at least 1 characters")
    .max(128, "Card holder's name must not be more than 128 characters")
    .test(
      "cardName",
      "Card holder name is invalid",
      (value) => valid.cardholderName(value).isValid
    ) // return true false based on validation
    .required("Required field"),
  cardNumber: Yup.string()
    .max(19, "Card number must not be more than 19 digits")
    .test(
      "cardNumber",
      "Card number is invalid",
      (value) => valid.number(value).isValid
    ) // return true false based on validation
    .required("Required field"),
  cardExpiryDate: Yup.string()
    .max(5, "Not a valid expiry date. Example: MM/YY")
    .test(
      "cardExpiryDate",
      "Expiry date is invalid  Example: MM/YY",
      (value) => valid.expirationDate(value).isValid
    ) // return true false based on validation
    .required("Required field")
  // cardCVV: Yup.string()
  //   .max(3, "CVV must not be more than 3 digits")
  //   .test("cardCVV", "CVV is invalid", (value) => valid.cvv(value).isValid) // return true false based on validation
  //   .required("Required field"),
});

export const EditCardSchema = Yup.object().shape({
  cardnickname: Yup.string()
    .min(1, "Card nick name must be at least 1 characters")
    .max(128, "Card nick name be more than 128 characters")
    .required("Required field")
});

export const HelpSupportSchema = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z ]+$/, "Only characters are allowed in firstname")
    .min(2, "First name must be at least 2 characters")
    .max(64, "First name must not be more than 64 characters")
    .required("Required field"),
  lastname: Yup.string()
    .trim()
    .matches(/^[a-zA-Z]+$/, "Only characters are allowed in lastname")
    .min(2, "Last name must be at least 2 characters")
    .max(64, "Last must not be more than 64 characters")
    .required("Required field"),
  email: Yup.string()
    .email("Please enter valid Email")
    .min(8, "Email must be at least 8 characters")
    .max(132, "Email must not be more than 132 characters")
    .required("Required field"),
  mobilenumber: Yup.string()
    .matches(/^\(?\d{3}\)?-? *\d{3}-? *-?\d{4}$/, "Wrong mobile number format")
    .min(13, "Mobile number must be at least 13 digits")
    .max(14, "Mobile number must not be more than 14 digits")
    .required("Required field"),
  supporttype: Yup.string().required("Required field"),
  prefferedmethod: Yup.string().required("Required field"),
  description: Yup.string()
    .trim()
    .min(2, "Description must be at least 2 characters")
    .max(512, "Description must not be more than 512 characters")
    .required("Required field")
});

export const BankDetailSchema = Yup.object().shape({
  banknickname: Yup.string()
    .min(4, "Bank name must be at least 4 characters")
    .max(128, "Bank name must not be more than 128 characters")
    .required("Required Field"),
  bankaccounttype: Yup.string().required("Required Field"),
  bankroutenumber: Yup.string()
    .matches(/^[0-9]*$/, "Must be only digits")
    .min(9, "Bank routing number must be at least 9 digits")
    .max(9, "Bank routing number must not be more than 9 digits")
    .required("Required Field"),

  bankaccountnumber: Yup.string()
    .matches(/^[0-9]*$/, "Must be only digits")
    .min(9, "Account number must be at least 9 digits")
    .max(19, "Account number must not be more than 19 digits")
    .required("Required Field")
});

export const ChangePasswordSchema = Yup.object().shape({
  oldpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .required("Required field"),
  newpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .matches(
      "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      "Passwords must have least uppercase letter, lowercase letter, number and symbol"
    )
    .required("Required field"),
  confirmpassword: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .max(64, "Password must not be more than 64 characters")
    .when("newpassword", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("newpassword")],
        "New password and Confirm password didn't match"
      )
    })
    .required("Required field")
});

export const ConfirmBankSchema = Yup.object().shape({
  amountone: Yup.string()
    .matches(/^([0-9]*[.])?[0-9]+$/, "Must be only digits")
    .min(1, "Amount must be at least 1 digits")
    .max(4, "Amount must not be more than 4 digits")
    .required("Required field"),
  amounttwo: Yup.string()
    .matches(/^([0-9]*[.])?[0-9]+$/, "Must be only digits")
    .min(1, "Amount must be at least 1 digits")
    .max(4, "Amount must not be more than 4 digits")
    .required("Required field")
});
