import React from "react";
import { Button, Modal } from "react-bootstrap";

function FundSuccessModal({
  message,
  isSuccessModal,
  handleSuccessHide,
  onClick,
  buttonLabel,
  successIcon,
  linkUrl,
  title,
  onClickOfLink,
  onClickOfSaveButton,
  isStatic,
}) {
  return (
    <>
      <Modal
        backdrop={isStatic ? "static" : false}
        show={isSuccessModal}
        onHide={handleSuccessHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <div className="d-flex justify-content-center mt-5">
            {successIcon}
          </div>
          <h3 className="text-dark font-weight-bold fs-24 text-center">
            {title}
          </h3>
          <p className="text-center fs-14 font-weight-normal text-secondary mb-10">
            {message}
          </p>
          <div
            className="d-block text-brand font-weight-normal fs-14 cursor-pointer"
            onClick={onClickOfLink}
          >
            {linkUrl}
          </div>
          <Button
            variant="btn-lg btn-success mt-5 mb-5"
            onClick={onClickOfSaveButton}
          >
            {buttonLabel}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default FundSuccessModal;
