import React, { useEffect, useState } from "react";
import { Card, Button, ButtonToolbar } from "react-bootstrap";
import { useSubheader } from "../../../_metronic/layout";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import {
  getStatusClass,
  isObjectEmpty,
  isUnauthorized,
} from "../../utils/utils";
import {
  acceptFundApi,
  addAsContactApi,
  checkUserIsAddedAsContactApi,
  getBankDetailsApi,
  getFundingAccountsApi,
  notificationDetailsByIdApi,
  recentHistoryPDFApi,
  transactionDetailsByIdApi,
} from "../../../axios/services/services";
import ErrorModal from "../Modal/ErrorModal";
import { useLocalStorage } from "../../utils/useLocalStorage";
import { CustomImage } from "../../utils/CustomImage";
import { getImageBasedOnNameUrl } from "../../../axios/services/apiList";
import Moment from "react-moment";
import "moment-timezone";
import { HH_MM_A, MM_DD_YYYY, TIME_ZONE } from "../../utils/dateTimeFormats";
import UpdatedSuccessfulModal from "../Modal/UpdatedSuccessfulModal";
import RejectFundModal from "../Modal/RejectFundModal";
import AcceptFundModal from "../Modal/AcceptFundModal";
import FundSuccessModal from "../Modal/FundSuccessModal";
import AcceptFundPrimaryBankModal from "../Modal/AcceptFundPrimaryBankModal";
import { NumberFormatter } from "../../utils/NumberFormatter";
// import ConformFundAccept from "../Modal/ConformFundAccept ";

export function NotificationPayment(props) {
  const subheader = useSubheader();
  subheader.setTitle("Notification Payment");
  const transId = props.match.params.tid;
  const notificationId = props.match.params.nid;
  const [isLoadingAcceptFund, setLoadingAcceptFund] = useState(false);
  const [
    isLoadingAcceptFundPrimaryBank,
    setLoadingAcceptFundPrimaryBank,
  ] = useState(false);
  // const [ isLoadingConformFundAccept , setLoadingConformFundAccept ] =useState(false)
  const [isLoadingRejectFund, setLoadingRejectFund] = useState(false);
  const [isLoadingAddAsContact, setLoadingAddAsContact] = useState(false);
  const [userInstance, setUserInstance] = useLocalStorage("userInstance", "");
  const [isConfirmModal, setConfirmModal] = useState(false);
  const [
    isAcceptFundPrimaryBankModal,
    setAcceptFundPrimaryBankModal,
  ] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isRejectModal, setRejectModal] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [notificationDetails, setNotificationDetails] = useState(null);
  const [
    isOppositeUserAddedAsContact,
    setOppositeUserAddedAsContact,
  ] = useState(false);
  const [bankList, setBankList] = useState([]);
  const [isSuccessAcceptFundModal, setSuccessAcceptFundModal] = useState(false);
  const [acceptFundDetails, setAcceptFundDetails] = useState({});
  const [rejectFundDetails, setRejectFundDetails] = useState({});
  const [acceptRejectNotes, setAcceptRejectNotes] = useState("");
  const [fundingAccountList, setFundingAccountList] = useState([]);
  const [
    selectedPaymentMethodDetails,
    setSelectedPaymentMethodDetails,
  ] = useState(null);

  const checkUserIsAddedAsContact = async (id) => {
    const checkUserIsAddedAsContactResponse = await checkUserIsAddedAsContactApi(
      {
        added_contact_id: id,
      }
    );
    if (checkUserIsAddedAsContactResponse.Error) {
      isUnauthorized({ response: checkUserIsAddedAsContactResponse });
      setErrorMessage(checkUserIsAddedAsContactResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    isObjectEmpty(checkUserIsAddedAsContactResponse)
      ? setOppositeUserAddedAsContact(false)
      : setOppositeUserAddedAsContact(true);

    return;
  };

  useEffect(() => {
    async function getTransactionDetailsById() {
      const transactionDetailsResponse = await transactionDetailsByIdApi({
        id: transId,
      });
      if (transactionDetailsResponse.Error) {
        isUnauthorized({ response: transactionDetailsResponse });
        setErrorMessage(transactionDetailsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setTransactionDetails(transactionDetailsResponse);
      checkUserIsAddedAsContact(
        transactionDetailsResponse &&
          transactionDetailsResponse.senderId === userInstance.id
          ? transactionDetailsResponse.receiverId
          : transactionDetailsResponse.senderId
      );
    }
    getTransactionDetailsById();
  }, [transId, userInstance.id]);

  useEffect(() => {
    async function getNotificationDetailsById() {
      const notificationDetailsResponse = await notificationDetailsByIdApi({
        id: notificationId,
      });
      if (notificationDetailsResponse.Error) {
        isUnauthorized({ response: notificationDetailsResponse });
        setErrorMessage(notificationDetailsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setNotificationDetails(notificationDetailsResponse);
    }
    getNotificationDetailsById();
  }, [notificationId]);

  useEffect(() => {
    async function getBankDetails() {
      const { id } = userInstance;
      const getBankDetailsResponse = await getBankDetailsApi({ id });
      if (getBankDetailsResponse.Error) {
        isUnauthorized({ response: getBankDetailsResponse });
        setErrorMessage(getBankDetailsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setBankList(getBankDetailsResponse);
    }
    getBankDetails();
  }, []);

  useEffect(() => {
    async function getFundingAccounts() {
      const getFundingAccountsResponse = await getFundingAccountsApi();
      if (getFundingAccountsResponse.Error) {
        isUnauthorized({ response: getFundingAccountsResponse });
        setErrorMessage(getFundingAccountsResponse.Error.message);
        handleErrorModalShow();
        return;
      }
      setFundingAccountList(getFundingAccountsResponse);
      if (getFundingAccountsResponse && getFundingAccountsResponse.length > 0) {
        const [primaryFundingAccount] = getFundingAccountsResponse.filter(
          (fundingAccount) =>
            fundingAccount.confirmed && fundingAccount.isPrimary
        );
        handleSelectedPaymentMethodDetails(primaryFundingAccount);
      }
    }
    getFundingAccounts();
  }, []);

  const enableLoadingAcceptFund = () => {
    setLoadingAcceptFund(true);
  };

  const disableLoadingAcceptFund = () => {
    setLoadingAcceptFund(false);
  };

  const enableLoadingAcceptFundPrimaryBank = () => {
    setLoadingAcceptFundPrimaryBank(true);
  };

  const disableLoadingAcceptFundPrimaryBank = () => {
    setLoadingAcceptFundPrimaryBank(false);
  };

  const enableLoadingRejectFund = () => {
    setLoadingRejectFund(true);
  };

  const disableLoadingRejectFund = () => {
    setLoadingRejectFund(false);
  };

  const handleConfirmShow = () => {
    setConfirmModal(true);
  };

  const handleConfirmHide = () => {
    setConfirmModal(false);
  };

  const handleAcceptFundPrimaryBankModalShow = () => {
    setAcceptFundPrimaryBankModal(true);
  };

  const handleAcceptFundPrimaryBankModalHide = () => {
    setAcceptFundPrimaryBankModal(false);
  };

  const handleRejectShow = () => {
    setRejectModal(true);
  };

  const handleRejectHide = () => {
    setRejectModal(false);
  };
  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleErrorModalOkButton = () => {
    handleErrorModalHide();
  };

  const enableLoadingAddAsContact = () => {
    setLoadingAddAsContact(true);
  };

  const disableLoadingAddAsContact = () => {
    setLoadingAddAsContact(false);
  };

  const handleSuccessModalShow = () => {
    setIsSuccessModalOpen(true);
  };

  const handleSuccessModalHide = () => {
    setIsSuccessModalOpen(false);
  };

  const handleSuccessAcceptFundShow = () => {
    setSuccessAcceptFundModal(true);
  };

  const handleSuccessAcceptFundHide = () => {
    setSuccessAcceptFundModal(false);
  };

  const renderReceiverName = (transactionDetails) => {
    return (
      <>
        {transactionDetails &&
          transactionDetails.senderId &&
          transactionDetails.senderId === userInstance.id &&
          transactionDetails.receiver &&
          transactionDetails.receiver.firstname &&
          transactionDetails.receiver.lastname &&
          `${transactionDetails.receiver.firstname} ${transactionDetails.receiver.lastname}`}

        {transactionDetails &&
          transactionDetails.senderId &&
          transactionDetails.senderId !== userInstance.id &&
          transactionDetails.sender &&
          transactionDetails.sender.firstname &&
          transactionDetails.sender.lastname &&
          `${transactionDetails.sender.firstname} ${transactionDetails.sender.lastname}`}
      </>
    );
  };

  const renderProfilePic = (transactionDetails) => {
    if (
      transactionDetails &&
      transactionDetails.senderId &&
      transactionDetails.senderId === userInstance.id &&
      transactionDetails.receiver &&
      transactionDetails.receiver.profile
    ) {
      return getImageBasedOnNameUrl(transactionDetails.receiver.profile);
    } else if (
      transactionDetails &&
      transactionDetails.senderId &&
      transactionDetails.senderId !== userInstance.id &&
      transactionDetails.sender &&
      transactionDetails.sender.profile
    ) {
      return getImageBasedOnNameUrl(transactionDetails.sender.profile);
    } else {
      return toAbsoluteUrl("/media/users/default.jpg");
    }
  };

  const renderUserName = (transactionDetails) => {
    if (
      transactionDetails &&
      transactionDetails.senderId &&
      transactionDetails.senderId === userInstance.id &&
      transactionDetails.receiver &&
      transactionDetails.receiver.username
    ) {
      return "@" + transactionDetails.receiver.username;
    } else if (
      transactionDetails &&
      transactionDetails.senderId &&
      transactionDetails.senderId !== userInstance.id &&
      transactionDetails.sender &&
      transactionDetails.sender.username
    ) {
      return "@" + transactionDetails.sender.username;
    } else {
      return "";
    }
  };

  const renderTransactionAddress = (transactionDetails) => {
    let address = "";
    if (!transactionDetails) {
      return;
    }

    if (transactionDetails.address_1) {
      address = address.concat(`${transactionDetails.address_1},`);
    }
    if (transactionDetails.address_2) {
      address = address.concat(` ${transactionDetails.address_2},`);
    }
    if (transactionDetails.city) {
      address = address.concat(` ${transactionDetails.city},`);
    }
    if (transactionDetails.state_province) {
      address = address.concat(` ${transactionDetails.state_province},`);
    }
    if (transactionDetails.country_region) {
      address = address.concat(` ${transactionDetails.country_region},`);
    }
    if (transactionDetails.zip_code) {
      address = address.concat(` ${transactionDetails.zip_code}`);
    }

    return address === "" ? "-" : address;
  };

  const renderStatus = () => {
    const { transaction_status } = transactionDetails || {};
    const { name } = transaction_status || {};
    return (
      <span className="text-dark-75 d-block">
        <span
          className={`${getStatusClass(name)} status-color text-capitalize`}
        >
          {name ? name : "-"}
        </span>
      </span>
    );
  };

  const handleAddAsContact = async () => {
    let addContactObj = {
      userId: userInstance.id,
      added_contact_id:
        transactionDetails.senderId === userInstance.id
          ? transactionDetails.receiverId
          : transactionDetails.senderId,
    };
    enableLoadingAddAsContact();
    const addAsContactResponse = await addAsContactApi(addContactObj);
    disableLoadingAddAsContact();
    if (addAsContactResponse.Error) {
      isUnauthorized({ response: addAsContactResponse });
      setErrorMessage(addAsContactResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    let reminderContactName = "";

    if (
      !isObjectEmpty(transactionDetails) &&
      !isObjectEmpty(transactionDetails.receiver) &&
      transactionDetails.senderId === userInstance.id
    ) {
      reminderContactName =
        transactionDetails.receiver.firstname +
        " " +
        transactionDetails.receiver.lastname;
    } else if (
      !isObjectEmpty(transactionDetails) &&
      !isObjectEmpty(transactionDetails.sender) &&
      transactionDetails.senderId !== userInstance.id
    ) {
      reminderContactName =
        transactionDetails.sender.firstname +
        " " +
        transactionDetails.sender.lastname;
    }
    if (reminderContactName !== "") {
      setSuccessMessage(
        `${reminderContactName} is successfully added to your contacts`
      );
      handleSuccessModalShow();
    }
    checkUserIsAddedAsContact(
      transactionDetails && transactionDetails.senderId === userInstance.id
        ? transactionDetails.receiverId
        : transactionDetails.senderId
    );
  };

  const handleAcceptFundButton = () => {
    // const { transactionTypeId } = transactionDetails || {};
    // const confirmedFundingAccount = fundingAccountList.filter(
    //   (fundingAccount) => {
    //     const { confirmed, others } = fundingAccount || {};
    //     const fundingAccoutnDetails = JSON.parse(others) || {};
    //     const { AccountType } = fundingAccoutnDetails || {};

    //     if (
    //       confirmed &&
    //       (AccountType === "Savings" || AccountType === "Checking")
    //     ) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   }
    // );
    // if (transactionTypeId === 2) {
    // handleAcceptFundPrimaryBankModalShow();
    // handleConfirmHide();
    // } else {
    //   acceptFundApiCall();
    // }
    handleAcceptFundPrimaryBankModalShow();
    handleConfirmHide();
  };

  const handleRejectFundButton = async () => {
    let rejectFundObj = {
      isaccept: false,
      transactionId: transId,
      notificationreceiverId: notificationId,
      ...(acceptRejectNotes && {
        accept_reject_note: acceptRejectNotes.trim(),
      }),
    };
    enableLoadingRejectFund();
    const rejectFundResponse = await acceptFundApi(rejectFundObj);
    disableLoadingRejectFund();
    if (rejectFundResponse.Error) {
      isUnauthorized({ response: rejectFundResponse });
      setErrorMessage(rejectFundResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    handleRejectHide();
    setRejectFundDetails(rejectFundResponse);
    setSuccessMessage(`Funds Rejected successfully`);
    handleSuccessModalShow();
    setTimeout(() => {
      props.history.push("/notifications");
    }, 1500);
  };

  const handleCancelFundButton = () => {
    disableLoadingAcceptFund();
    handleConfirmHide();
  };

  const handleCancelAcceptFundPrimaryBankModalButton = () => {
    disableLoadingAcceptFundPrimaryBank();
    handleAcceptFundPrimaryBankModalHide();
  };

  const handleSelectedPaymentMethodDetails = (selectedMethodDetails) => {
    setSelectedPaymentMethodDetails(selectedMethodDetails);
  };

  const onClickOfDownloadButton = async () => {
    const { id } = transactionDetails;
    const recentHistoryPDFResponse = await recentHistoryPDFApi({
      id,
      customPdfName: `trasaction-details-conf-${id}`,
    });

    if (recentHistoryPDFResponse.Error) {
      isUnauthorized({ response: recentHistoryPDFResponse });
      setErrorMessage(recentHistoryPDFResponse.Error.message);
      handleErrorModalShow();
    }
    return;
  };

  const acceptFundApiCall = async () => {
    // If bank details is not added then redirect to /bank-information with transactionId and notificationid, Also fill up the data first
    if (bankList && bankList.length === 0) {
      let url = `/bank-information/transId=${transId}/notificationId=${notificationId}`;
      props.history.push(url);
      return;
    }
    const { fundingAccountId } = selectedPaymentMethodDetails || {};
    let acceptFundObj = {
      isaccept: true,
      transactionId: transId,
      notificationreceiverId: notificationId,
      ...(acceptRejectNotes && {
        accept_reject_note: acceptRejectNotes.trim(),
      }),
      ...(fundingAccountId && {
        fundingAccountId: fundingAccountId,
      }),
    };
    enableLoadingAcceptFund();
    enableLoadingAcceptFundPrimaryBank();
    const acceptFundResponse = await acceptFundApi(acceptFundObj);
    disableLoadingAcceptFundPrimaryBank();
    disableLoadingAcceptFund();

    if (acceptFundResponse.Error) {
      isUnauthorized({ response: acceptFundResponse });
      setErrorMessage(acceptFundResponse.Error.message);
      handleErrorModalShow();
      return;
    }

    setAcceptFundDetails(acceptFundResponse);
    handleSuccessAcceptFundShow();
  };

  const handleAcceptFundPrimaryBankModalButton = () => {
    acceptFundApiCall();
  };

  const renderAcceptRejectButtons = () => {
    const { transactionStatusId, transactionTypeId } = transactionDetails || {};
    const { id } = userInstance || {};
    const { created_by } = transactionDetails || {};
    if (created_by !== id) {
      if (
        (transactionStatusId === 1 && transactionTypeId === 2) ||
        (transactionStatusId === 4 && transactionTypeId === 3)
      ) {
        // Transaction Status 1 ::: Pending
        // Transaction Status 4 ::: Approved
        // Transaction Type 2 ::: Request a payment
        // Transaction Type 3 ::: Make a payment
        return (
          <div className="payment-btn mt-8">
            <ButtonToolbar className="d-flex button-wrap">
              <Button
                variant="btn btn-success w-150 mr-3"
                onClick={() => {
                  handleConfirmShow();
                }}
              >
                {transactionTypeId === 3 ? "Accept Funds" : "Accept Request"}
              </Button>
              <Button
                variant="btn btn-light-dark w-150"
                onClick={handleRejectShow}
              >
                {transactionTypeId === 3 ? "Reject Funds" : "Reject Request"}
              </Button>
            </ButtonToolbar>
          </div>
        );
      } else {
        return <></>;
      }
    }
  };

  return (
    <>
      <div className="notification-wrapper">
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <div className="d-flex flex-column flex-md-row align-items-center">
              <div className="mr-5 symbol symbol-100 symbol-circle ">
                <CustomImage src={renderProfilePic(transactionDetails)} />
              </div>
              <div className="pt-3 pt-md-0">
                <span className="text-center text-md-left d-block font-weight-boldest text-dark h3 text-capitalize">
                  {renderReceiverName(transactionDetails)}
                </span>
                <div className="text-center text-md-left text-secondary fs-13">
                  {renderUserName(transactionDetails)}
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-flex-end">
                    {transactionDetails && (
                      <ButtonToolbar className="d-flex button-wrap">
                        {isOppositeUserAddedAsContact ? (
                          <Button className="btn btn-success mr-3">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Navigation/Check.svg"
                                )}
                              />
                            </span>
                            Added
                          </Button>
                        ) : (
                          <Button
                            variant="btn btn-success btn-brand w-170 mr-3"
                            onClick={handleAddAsContact}
                            disabled={isLoadingAddAsContact}
                          >
                            Add as Contact
                            {isLoadingAddAsContact && (
                              <span className=" mr-3 ml-3 spinner spinner-white"></span>
                            )}
                          </Button>
                        )}
                        <Button
                          variant="btn btn-light-primary"
                          onClick={() => {
                            const { senderId, receiverId } = transactionDetails;
                            const { id } = userInstance;
                            if (id === senderId) {
                              props.history.push(`/single-chat/${receiverId}`);
                            } else {
                              props.history.push(`/single-chat/${senderId}`);
                            }
                          }}
                        >
                          <i className="flaticon2-chat-1"></i>
                          Chat
                        </Button>
                      </ButtonToolbar>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed my-7"></div>
            <span className="text-dark fs-16">
              {notificationDetails && notificationDetails.text_html && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: notificationDetails.text_html,
                  }}
                />
              )}
            </span>
            <div className="separator separator-dashed my-7"></div>
            <div className="row">
              <div className="col-md-6">
                <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                  PROPERTY ADDRESS:
                </h5>
                <span className="d-block text-dark mb-5">
                  {renderTransactionAddress(transactionDetails)}
                </span>
              </div>
              <div className="col-md-6">
                <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                  SC PAYMENT TYPE:
                </h5>
                <span className="d-block text-dark mb-5">
                  {transactionDetails && transactionDetails.sc_type
                    ? transactionDetails.sc_type
                    : "-"}
                </span>
              </div>
              <div className="col-md-6">
                <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                  TRANSACTION STARTING DATE:
                </h5>
                <span className="d-block text-dark mb-5">
                  {transactionDetails && transactionDetails.createdAt && (
                    <Moment
                      date={transactionDetails.createdAt}
                      format={MM_DD_YYYY}
                      tz={TIME_ZONE}
                    />
                  )}
                </span>
              </div>
              <div className="col-md-6">
                <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                  TRANSACTION STARTING TIME:
                </h5>
                <span className="d-block text-dark mb-5">
                  {transactionDetails && transactionDetails.createdAt && (
                    <>
                      <Moment
                        date={transactionDetails.createdAt}
                        format={HH_MM_A}
                        tz={TIME_ZONE}
                      />
                      {" EST"}
                    </>
                  )}
                </span>
              </div>
              {userInstance &&
                transactionDetails &&
                userInstance.id === transactionDetails.senderId && (
                  <div className="col-md-6">
                    <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                      TRANSACTION FEE:
                    </h5>
                    <span className="d-block text-dark mb-5">
                      {transactionDetails && transactionDetails.createdAt && (
                        <NumberFormatter
                          amount={transactionDetails.commission}
                        />
                      )}
                    </span>
                  </div>
                )}

              <div className="col-md-6">
                <h5 className="font-size-sm text-gray-3 font-weight-bold mb-2">
                  STATUS:
                </h5>
                <span className="d-block text-dark mb-5">{renderStatus()}</span>
              </div>
            </div>
            <div className="separator separator-dashed my-2"></div>
            <div className="message-box">
              <h5 className="mt-5 mb-3">Message</h5>
              <Card style={{ width: "100%" }}>
                <Card.Body className="py-3 px-3">
                  <Card.Text className="text-capitalize">
                    {transactionDetails && transactionDetails.other
                      ? transactionDetails.other
                      : "-"}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>

            {transactionDetails && transactionDetails.accept_reject_note && (
              <>
                <div className="separator separator-dashed my-2"></div>
                <div className="message-box">
                  <h5 className="mt-5 mb-3">Accept/Reject Notes</h5>
                  <Card style={{ width: "100%" }}>
                    <Card.Body className="py-3 px-3">
                      <Card.Text className="text-capitalize">
                        {transactionDetails.accept_reject_note}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </>
            )}

            {renderAcceptRejectButtons()}
          </Card.Body>
        </Card>
      </div>

      {isConfirmModal && (
        <AcceptFundModal
          isLoadingAcceptFund={isLoadingAcceptFund}
          isConfirmModal={isConfirmModal}
          transactionDetails={transactionDetails}
          handleConfirmHide={handleCancelFundButton}
          onClickOfSaveButton={handleAcceptFundButton}
          setAcceptRejectNotes={setAcceptRejectNotes}
        />
      )}

      {isAcceptFundPrimaryBankModal && (
        <AcceptFundPrimaryBankModal
          isLoadingAcceptFundPrimaryBank={isLoadingAcceptFundPrimaryBank}
          isAcceptFundPrimaryBankModal={isAcceptFundPrimaryBankModal}
          fundingAccountList={fundingAccountList}
          selectedPaymentMethodDetails={selectedPaymentMethodDetails}
          setSelectedPaymentMethodDetails={handleSelectedPaymentMethodDetails}
          handleAcceptFundPrimaryBankModalHide={
            handleCancelAcceptFundPrimaryBankModalButton
          }
          onClickOfSaveButton={handleAcceptFundPrimaryBankModalButton}
          transactionDetails={transactionDetails}
        />
      )}

      {/* {isAcceptFundPrimaryBankModal && (
        <ConformFundAccept
        isLoadingAcceptFundPrimaryBank={isLoadingAcceptFundPrimaryBank}
        isAcceptFundPrimaryBankModal={isAcceptFundPrimaryBankModal}
        fundingAccountList={fundingAccountList}
        selectedPaymentMethodDetails={selectedPaymentMethodDetails}
        setSelectedPaymentMethodDetails={handleSelectedPaymentMethodDetails}
        handleAcceptFundPrimaryBankModalHide={
          handleCancelAcceptFundPrimaryBankModalButton
          }
          onClickOfSaveButton={handleAcceptFundPrimaryBankModalButton}
        />
      )} */}

      <RejectFundModal
        isLoadingRejectFund={isLoadingRejectFund}
        isRejectModal={isRejectModal}
        transactionDetails={transactionDetails}
        handleRejectHide={() => {
          handleRejectHide();
          disableLoadingRejectFund();
        }}
        onClickOfSaveButton={handleRejectFundButton}
        setAcceptRejectNotes={setAcceptRejectNotes}
      />
      {/* // Using Fund Success Modal for Accept Fund SuccessFull  */}
      <FundSuccessModal
        isStatic={true}
        isSuccessModal={isSuccessAcceptFundModal}
        handleSuccessHide={handleSuccessAcceptFundHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
        title={"Accepted Funds successfully"}
        message={` PAYXTRME TRANSACTION #: ${transactionDetails &&
          transactionDetails.id}`}
        linkUrl={"Download Receipt"}
        onClickOfLink={onClickOfDownloadButton}
        buttonLabel={"Go to Notifications"}
        onClickOfSaveButton={() => {
          props.history.push("/notifications");
        }}
      />
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalOkButton}
      />
      <UpdatedSuccessfulModal
        message={successMessage}
        UpdatedSuccessfulModalShow={isSuccessModalOpen}
        UpdatedSuccessfulModalHide={handleSuccessModalHide}
        successIcon={
          <div className="success-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")}
              />
            </span>
          </div>
        }
      />
    </>
  );
}
