export const ProductStatusCssClasses = ["success", "info", ""];
export const ProductStatusTitles = ["Selling", "Sold"];
export const ProductConditionCssClasses = ["success", "danger", ""];
export const ProductConditionTitles = ["New", "Used"];
export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const PaymentHistoryReceiptionTypeTitles = [
  "You Paid",
  "You Received From"
];
export const PaymentHistoryDepositeTypeTitles = [
  "eCheck",
  "ACH",
  "Credit Card",
  "Wire Transfer"
];
export const sizePerPageList = [{ text: "10", value: 10 }];
export const initialFilter = {
  filter: { opponentUserId: "" },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
