import React, { useState } from "react";
import { ButtonToolbar } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

import { ModalProgressBar } from "../../../_metronic/_partials/controls";

function ConfirmationModal({
  title,
  infoMessage,
  isConfirmationModalOpen,
  handleCloseModal,
  infoIcon,
  okButtonLabel,
  OnClickOfOkButton,
  cancelButtonLabel,
  isLoading,
}) {
  return (
    <>
      <Modal
        show={isConfirmationModalOpen}
        onHide={handleCloseModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        {isLoading && <ModalProgressBar />}
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>{infoMessage}</span>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="d-flex justify-content-end button-wrap">
            <Button
              variant="btn btn-success mr-3"
              onClick={OnClickOfOkButton}
              disabled={isLoading}
            >
              {okButtonLabel}
              {isLoading && (
                <span className=" mr-3 ml-3 spinner spinner-white"></span>
              )}
            </Button>
            <Button
              variant="btn btn-light-dark"
              onClick={handleCloseModal}
              disabled={isLoading}
            >
              {cancelButtonLabel}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default ConfirmationModal;
