/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
export const PaymentDetailsColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { userId }
) => {
  const { transaction_id, created_by } = row || {};

  const { AccountName = "", other } = transaction_id || {};
  // const paymentDetails = JSON.parse(other) || {};
  const { AccountLastFour = "" } = other || {};
  if (userId !== created_by) {
    return <>-</>;
  }
  return (
    <>
      <span className={`text-capitalize text-dark-75 d-block`}>
        {/* {`${AccountName} - ${AccountLastFour}`} */}
        {AccountLastFour ? `XXXXXXX${AccountLastFour}` : "-"}
      </span>
    </>
  );
};
