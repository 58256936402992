import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button, ButtonToolbar, Modal, Form } from "react-bootstrap";

function AcceptFundModal({
  isLoadingAcceptFund,
  isConfirmModal,
  transactionDetails,
  handleConfirmHide,
  onClickOfSaveButton,
  onClickOfCancelButton,
  setAcceptRejectNotes
}) {
  const { transactionTypeId } = transactionDetails || {};
  return (
    <>
      <Modal
        show={isConfirmModal}
        onHide={handleConfirmHide}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="d-flex justify-content-between w-100"
            id="contained-modal-title-vcenter"
          >
            {`Confirm - ${
              transactionTypeId === 3 ? "Accept Funds" : "Accept Request"
            }?`}

            <Button
              variant="btn p-0 d-flex justify-content-end"
              onClick={handleConfirmHide}
            >
              <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
                />
              </span>
            </Button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form autocomplete="off">
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label className="font-size-lg">
                Response (optional)
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Text here"
                rows="5"
                style={{ resize: "none" }}
                onChange={(event) => {
                  setAcceptRejectNotes(event.target.value);
                }}
              />
            </Form.Group>
            <ButtonToolbar className="d-flex justify-content-end button-wrap">
              <Button
                variant="btn btn-success mr-3"
                onClick={onClickOfSaveButton}
                disabled={isLoadingAcceptFund}
              >
                {transactionTypeId === 3 ? "Accept Funds" : "Accept Request"}
                {isLoadingAcceptFund && (
                  <span className=" mr-3 ml-3 spinner spinner-white"></span>
                )}
              </Button>
              <Button
                variant="btn btn-light-dark"
                onClick={() => {
                  handleConfirmHide();
                }}
                disabled={isLoadingAcceptFund}
              >
                Cancel
              </Button>
            </ButtonToolbar>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AcceptFundModal;
