/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { getImageBasedOnNameUrl } from "../../../../../axios/services/apiList";
import { CustomImage } from "../../../../utils/CustomImage";

export const ReciptNameColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { userId }
) => {
  const { transactionTypeId, sender, receiver, senderId, receiverId } = row;
  const { firstname = "", lastname = "", profile = "" } = receiver || {};
  const {
    firstname: senderFirstName = "",
    lastname: senderLastName = "",
    profile: senderProfile = "",
  } = sender || {};

  const renderRecipientTitle = () => {
    if (transactionTypeId === 5) {
      return "You Have Transfered To Bank";
    }
    if (transactionTypeId === 2) {
      return userId === senderId
        ? "Payment Requested From"
        : "Payment Requested To";
    }
    return userId === senderId ? "You Paid" : "You Received From";
  };

  const renderRecipientName = () => {
    if (transactionTypeId === 5) {
      return "";
    }
    if (userId === senderId) {
      return <p>{firstname + " " + lastname}</p>;
    }
    if (userId === receiverId) {
      return <p>{senderFirstName + " " + senderLastName}</p>;
    }
  };

  const renderProfilePic = () => {
    let profilePic = "";
    if (userId === senderId) {
      profilePic = profile;
    } else if (userId === receiverId) {
      profilePic = senderProfile;
    }
    if (profilePic === "" || profilePic === null) {
      return toAbsoluteUrl("/media/users/default.jpg");
    }
    return getImageBasedOnNameUrl(profilePic);
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <div className="symbol symbol-50 symbol-circle mr-5">
          <CustomImage src={renderProfilePic()} />
        </div>
        <div>
          <span className="text-dark font-weight-bolder text-hover-primary mb-1 d-block">
            {renderRecipientTitle()}
            <br />
            <div className="text-brand text-capitalize">
              {renderRecipientName()}
            </div>
          </span>
        </div>
      </div>
    </>
  );
};
