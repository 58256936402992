/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NumberFormatter } from "../../../../utils/NumberFormatter";
export const AmountColumnFormatter = (cellContent, row, rowIndex) => (
  <>
    <span className="text-dark-75 d-block">
      <NumberFormatter amount={row.amount} />
    </span>
    <> </>
  </>
);
