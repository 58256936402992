/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Switch, Route } from "react-router-dom";
import { LandingRoutes } from "./modules/Auth/LandingRoutes";
import Faqs from "./modules/Auth/pages/Faqs";
import Terms from "./modules/Terms/Terms";
import PrivacyPolicy from "./modules/Privacy Policy/PrivacyPolicy";
import HelpSupportWrapper from "./modules/Auth/pages/HelpSupportWrapper";

export function Routes(props) {
  return (
    <Switch>
      <Route path="/public/privacypolicy" component={PrivacyPolicy} />
      <Route path="/public/terms" component={Terms} />
      <Route path="/public/support" component={HelpSupportWrapper} />
      <Route path="/public/faqs" component={Faqs} />
      {/* {getRedirectionPath()} */}
      <LandingRoutes />
    </Switch>
    
  );
}
