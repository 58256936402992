export const FACEBOOK_LOGIN_UNDERSCORE = "Facebook_Login";
export const GOOGLE_LOGIN_UNDERSCORE = "Google_Login";
export const CREATE_AN_ACCOUNT_FOR_FREE =
  "We're excited you're here! Create your free account by selecting an Account Type below,";
export const FORGOT_PASSWPRD_DESC =
  "Enter the Email Address or Mobile Number associated with your account and we'll send you a code to reset your password";
export const FORGOT_PASSWPRD_PLACE_HOLDER = "Email Address or Mobile Number*";
export const TOTAL_PAYMENTS_MADE = "Total Payments Made";
export const TOTAL_PAYMENTS_REQUESTED = "Total Payments Requested";
export const TOTAL_PAYMENTS_RECEIVED = "Total Payments Received";
export const YOU_DONT_HAVE_ANY_MESSAGES_YET = "You Don’t Have Any Messages Yet";
export const YOU_DONT_HAVE_ANY_MESSAGES_DESC =
  " If you want to start a Chat thread, click the Plus button below or go to your Contact List and click on one of your Contacts!";
export const TRANSACTION_HISTORY = "Transaction History";
export const SELECT_A_PAYMENT_TYPE_BELOW = "Select a Payment Type below";
export const ENTER_THE_RECIPIENTS_MOBILE_NUMBER =
  "Enter the Recipient's Mobile Number";
export const ENTER_THE_AMOUNT_AND_METHOD_OF_PAYMENT =
  "Enter the Amount and Method of Payment";
export const SELECT_A_REQUEST_TYPE_BELOW = "Select a Request Type below";
export const PENDING_MICRODEPOSIT_BANK_VERIFICATION =
  "Pending Microdeposit Bank Verification";
export const PENDING_ACTIONS = "Pending Actions";
