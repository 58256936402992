import { SERVER_URL } from "../../app/ServerConfig";

export function checkUserExistOrNotUrl() {
  let url = "/users/verifyUser";
  return url;
}

export function signUpUrl() {
  let url = "/users/register";
  return url;
}

export function verifyOTPUrl() {
  let url = "/users/verifyOTP";
  return url;
}

export function loginUrl() {
  let url = "/users/loginUser";
  return url;
}

export function resendtwofactorOtpUrl() {
  let url = "/users/resendtwofactorOtp";
  return url;
}

export function uploadProfilePicUrl() {
  let url = "/uploads/upload";
  return url;
}

export function counterUrl({ userId, counterFilter }) {
  let url = `/users/getUserwithusermeta`;
  if (counterFilter && counterFilter !== "") {
    if (counterFilter === "all") {
      url = url.concat("?filter=all");
    } else if (counterFilter === "today") {
      url = url.concat("?filter=today");
    } else if (counterFilter === "week") {
      url = url.concat("?filter=week");
    } else if (counterFilter === "month") {
      url = url.concat("?filter=month");
    } else if (counterFilter === "year") {
      url = url.concat("?filter=year");
    }
  }
  return url;
}

export function recentHistoryUrl() {
  let url = "/transactions/getRecentHistory";
  return url;
}

export function contactListUrl({ searchTextContact, sortIndexContact, skip }) {
  let url = `/contacts/getMyContact?limit=20&offset=${skip}`;
  if (searchTextContact && searchTextContact !== "") {
    url = url.concat(`&search=${searchTextContact}`);
  }
  if (sortIndexContact !== 0) {
    if (sortIndexContact === 1) {
      url = url.concat(`&order=firstname ASC`);
    } else if (sortIndexContact === 2) {
      url = url.concat(`&order=firstname DESC`);
    } else if (sortIndexContact === 3) {
      url = url.concat(`&order=createdAt ASC`);
    } else if (sortIndexContact === 4) {
      url = url.concat(`&order=createdAt DESC`);
    }
  }
  return url;
}

export function removeContactUrl() {
  let url = "/contacts/deleteContact/";
  return url;
}

export function historyListUrl({ currentPage, filterValues }) {
  let skip = (currentPage - 1) * 10;
  let url = `/transactions/getTransactionHistoryListWeb?limit=10&offset=${skip}`;
  if (
    filterValues &&
    "depositType" in filterValues &&
    filterValues.depositType !== ""
  ) {
    url = url.concat(
      `&transactionModeId=${parseInt(filterValues.depositType) + 1}`
    );
  }

  if (filterValues && "status" in filterValues && filterValues.status !== "") {
    url = url.concat(`&transactionStatusId=${filterValues.status}`);
  }

  if (
    filterValues &&
    "searchText" in filterValues &&
    filterValues.searchText !== ""
  ) {
    url = url.concat(`&search=${filterValues.searchText}`);
  }

  return url;
}

export function singleUserDetailUrl({ id }) {
  let url = `/users/getUserById/${id}`;
  return url;
}

export function editUserDetailUrl({ id }) {
  let url = `/users/${id}`;
  return url;
}

export function editProfilePicUrl({ id }) {
  let url = `/users/${id}`;
  return url;
}

export function uploadAutoAcceptFundUrl({ id }) {
  let url = `/users/${id}`;
  return url;
}

export function uploadPayFeeForCustomerUrl({ id }) {
  let url = `/users/${id}`;
  return url;
}

export function unreadChatCounterUrl(getChatId) {
  return `/chats/updateChatCounter?chat_id=${getChatId}`;
}

export function forgotPasswordUrl() {
  let url = "/users/verifyEmailResetPassword";
  return url;
}

export function blockContactUrl() {
  let url = "/contacts/blockContact";
  return url;
}

export function resetPasswordUrl() {
  let url = "/users/resetPassword";
  return url;
}

export function changePasswordUrl() {
  let url = `/users/changePassword`;
  return url;
}

export function myChatListingUrl({ searchTextChat, skip }) {
  let url = `/chats/myChatListing?limit=20&offset=${skip}&search`;
  if (searchTextChat && searchTextChat !== "") {
    url = url.concat(`=${searchTextChat}`);
  }
  return url;
}

export function updateChatCounteUrl({ chat_id }) {
  let url = `/chats/updateChatCounter/${chat_id}`;
  return url;
}

export function oneToOneChatListingUrl({ id, skip }) {
  let url = `/chatdetails/oneToOneChat?receiver_id=${id}&limit=10&offset=${skip}`;
  return url;
}

export function sendChatUrl() {
  let url = `/chats/sendChat`;
  return url;
}

export function getImageBasedOnNameUrl(imageName) {
  let url = `${SERVER_URL}/downloads/download?folder_name=profilepic&image=${imageName}&id=${localStorage.getItem(
    "auth-token"
  )}`;
  return url;
}

export function getPdfBasedOnNameUrl({ file, folder }) {
  let url = `/downloads/download?folder_name=${
    folder ? folder : ""
  }&image=${file}&id=${localStorage.getItem("auth-token")}`;
  return url;
}

export function resendOTPUrl() {
  let url = `/users/sendOtp`;
  return url;
}

export function resendEmailOTPUrl() {
  let url = `/users/sendOtp`;
  return url;
}

export function resendCellNumberOTPUrl() {
  let url = `/users/sendOtp`;
  return url;
}

export function recentHistoryPDFUrl({ id }) {
  let url = `/transactions/generatePdfForPayment/${id}`;
  return url;
}

export function recentHistoryPrintPDFUrl({ id }) {
  let url = `/transactions/paymentExport/${id}`;
  return url;
}

export function getPaymentTransactionListUrl({ queryParams }) {
  const { pageNumber, pageSize, filter } = queryParams || {};
  let skip = (pageNumber - 1) * 10;
  const { opponentUserId, depositType, searchText } = filter || {};
  let url = `/transactions/getTransactionList?opponentUserId=${opponentUserId}&limit=${pageSize}&offset=${skip}`;
  if (depositType) {
    url = url.concat(`&transactionModeId=${parseInt(depositType) + 1}`);
  }
  if (searchText) {
    url = url.concat(`&search=${searchText}`);
  }
  return url;
}

export function notificationListUrl({ skip }) {
  let url = `/notificationreceivers/myNotifications?limit=20&offset=${skip}`;
  return url;
}

export function pendingNotificationListUrl({ skip }) {
  let url = `/notificationreceivers/myPendingNotifications?limit=20&offset=${skip}`;
  return url;
}

export function sendReminderUrl() {
  let url = `/transactions/sentReminder`;
  return url;
}

export function addAsContactUrl() {
  let url = `/contacts/addContact`;
  return url;
}

export function transactionDetailsByIdUrl({ id }) {
  let url = `/transactions/getTransactionbyId/${id}`;
  return url;
}

export function logoutUrl({ id }) {
  let url = `/accesstoken/Logout/${id}`;
  return url;
}

export function deleteBankUrl({ bankId }) {
  let url = `/userbank/removeBank/${bankId}`;
  return url;
}

export function deleteCardUrl({ bankId }) {
  let url = `/usercards/deleteCard/${bankId}`;
  return url;
}

export function notificationDetailsByIdUrl({ id }) {
  let url = `/notificationreceivers/getNotificationById/${id}`;
  return url;
}

export function checkUserIsAddedAsContactUrl() {
  let url = `/contacts/checkUserAddedAsContact`;
  return url;
}

export function searchUserUrl({ cellnumber }) {
  let url = `/users/searchUser?search=${cellnumber}`;
  return url;
}

export function makeAPaymentUrl() {
  let url = `/transactions/makePayment`;
  return url;
}

export function requestAPaymentUrl() {
  let url = `/transactions/requestPayment`;
  return url;
}

export function getBankDetailsUrl({ id }) {
  let url = `/userbank/getBankDetails/${id}`;
  return url;
}

export function acceptFundUrl() {
  let url = `/transactions/acceptReject`;
  return url;
}

export function addNewBankUrl() {
  let url = `/userbank/addBank`;
  return url;
}

export function editBankUrl({ id }) {
  let url = `/userbank/editUserBank/${id}`;
  return url;
}

export function twoFactorAuthUrl() {
  let url = "/users/twoFactorAuthentication";
  return url;
}

export function getDocsUrl() {
  let url = `/abadocs/getDocs`;
  return url;
}

export function getUpdateAbaDisclosureUrl({ email }) {
  let url = `/abadocs/update_disclosure/${email}`;
  return url;
}

export function twoFactorVerificationUrl() {
  let url = `/users/verifyOtpTwoFactorAuth`;
  return url;
}

export function transferToBankUrl() {
  let url = `/transactions/transfertobank`;
  return url;
}

export function countryListUrl() {
  let url = `/country/getCountryList?limit=500&offset=0&isActiveOnly=1`;
  return url;
}

export function stateListUrl({ countryId }) {
  let url = `/state/getStateList?limit=500&offset=0&country=${countryId}&isActiveOnly=1`;
  return url;
}

export function cityListUrl({ stateId, isActiveOnly }) {
  let url = `/city/getCityList?limit=500&offset=0&state_id=${stateId}&isActiveOnly=1`;
  return url;
}

export function bankListUrl() {
  let url = `/banks/getBanksList?limit=500&offset=0`;
  return url;
}

export function confirmBankUrl() {
  let url = `/userbank/confirmBank`;
  return url;
}

export function uploadFrontChequeUrl() {
  let url = `/uploads/upload`;
  return url;
}

export function loginTwoFactorVerificationUrl() {
  let url = `/users/verifyLoginOtpTwoFactorAuth`;
  return url;
}

export function firebaseDeviceTokenUrl() {
  let url = `/usermeta/storeToken`;
  return url;
}

export function validateSCTransactionIdUrl({ txId }) {
  let url = `/sc/getValidateTransactionId?txId=${txId}`;
  return url;
}

export function addressSCTransactionUrl({ txId }) {
  let url = `/sc/getPropertyAddress?txId=${txId}`;
  return url;
}

export function sendTransactionDetailsSCUrl() {
  let url = `/payment/create/callback`;
  return url;
}

export function addCardUrl() {
  let url = `/userbank/storeCard`;
  return url;
}

export function editCardUrl({ id }) {
  let url = `/usercards/editCard/${id}`;
  return url;
}

export function getFundingAccountsUrl() {
  let url = `/userfundingaccount/fundingAccounts`;
  return url;
}

export function notificationCounterUrl() {
  let url = `/notificationreceivers/notificationCount`;
  return url;
}

export function markAsReadNotificationUrl() {
  let url = `/notificationreceivers/markAsReadNotification`;
  return url;
}

export function cancelTransactionUrl({ id }) {
  let url = `/transactions/canceltxn/${id}`;
  return url;
}

export function supportUrl() {
  let url = `/support/support`;
  return url;
}

export function primaryBankConfirmUrl() {
  let url = `/userbank/changePrimary`;
  return url;
}

export function getCommissionBasedOnAmountUrl() {
  let url = `/commissions/getCommission`;
  return url;
}

export function generateLinkTokenUrl() {
  let url = `/users/generateLinkToken`;
  return url;
}

export function publicTokenUrl() {
  let url = `/users/storePlaidAccessToken`;
  return url;
}

export function editProfile2FAUrl() {
  let url = `/users/twoFactorAuthentication`;
  return url;
}

export function editProfile2FAVerifyUrl() {
  let url = `/users/verifyOtpTwoFactorAuth`;
  return url;
}
