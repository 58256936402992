import React from "react";
import { Formik } from "formik";
import { usePaymentHistoryUIContext } from "../PaymentHistoryUIContext";
import { useMemo } from "react";
import { isEqual } from "lodash";

export function PaymentHistoryFilter({}) {
  const paymentHistoryUIContext = usePaymentHistoryUIContext();
  const paymentHistoryUIProps = useMemo(() => {
    return {
      queryParams: paymentHistoryUIContext.queryParams,
      setQueryParams: paymentHistoryUIContext.setQueryParams
    };
  }, [paymentHistoryUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      paymentHistoryUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, paymentHistoryUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      paymentHistoryUIProps.setQueryParams(newQueryParams);
    }
  };

  const prepareFilter = (queryParams, values) => {
    const { depositType, searchText } = values;
    const newQueryParams = { ...queryParams };
    const filter = {};

    filter.depositType = depositType !== "" ? depositType : undefined;
    filter.searchText = searchText !== "" ? searchText : undefined;
    newQueryParams.filter = { ...newQueryParams.filter, ...filter };
    return newQueryParams;
  };

  return (
    <>
      <Formik
        initialValues={{
          depositType: "",
          searchText: ""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue
        }) => (
          <form
            onSubmit={handleSubmit}
            className="form form-label-right"
            autoComplete="off"
          >
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="depositType"
                  placeholder="Filter by Deposit Type"
                  onChange={(e) => {
                    setFieldValue("depositType", e.target.value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.depositType}
                >
                  <option value="">All</option>
                  {/* <option value="0">eCheck</option> */}
                  <option value="1">ACH</option>
                  <option value="2">Credit Card</option>
                  {/* <option value="3">Wire Transfer</option> */}
                  {/* <option value="4">General</option> */}
                  <option value="5">Debit Card</option>
                </select>
                <small className="form-text text-muted">
                  <b>Filter</b> by Deposit Type
                </small>
              </div>
              <div className="col-lg-2">
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
