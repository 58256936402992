/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { NumberFormatter } from "../../../../utils/NumberFormatter";
export const CommissionColumnFormatter = (
  cellContent,
  row,
  rowIndex,
  { userId }
) => {
  const { commission, senderId, transactionStatusId } = row || {};

  const renderCommission = () => {
    if (transactionStatusId === 2) {
      return <>-</>;
    }

    return userId === senderId ? (
      <NumberFormatter amount={commission} />
    ) : (
      <>-</>
    );
  };

  return (
    <>
      <span className="text-dark-75 d-block">{renderCommission()}</span>
      <> </>
    </>
  );
};
