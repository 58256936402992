import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

function ConfirmBankModal({
  isLoadingConfirmBank,
  formik,
  isConfirmBankAccountModalShow,
  hideConfirmBankAccountModal,
  confirmBankDetails,
  onClickOfSaveButton,
}) {
  const { others } = confirmBankDetails || {};
  const accounts = JSON.parse(others) || {};
  const { AccountType, BankAccount, FundingAccountName = "" } = accounts || {};
  const { AccountLastFour = "" } = BankAccount || {};
  return (
    <>
      <Modal
        centered
        show={isConfirmBankAccountModalShow}
        onHide={hideConfirmBankAccountModal}
      >
        <Modal.Header className="border-0 justify-content-end pb-0" closeButton>
          <Button
            variant=" p-0 d-flex justify-content-end rounded-close"
            onClick={hideConfirmBankAccountModal}
          >
            <span className="svg-icon svg-icon-secondary svg-icon-2x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          <div className="d-flex justify-content-center mt-5">
            <div className="bank-icon-wrapper">
              <i className="fa fa-landmark text-success icon-3x"></i>
            </div>
          </div>
          <h5 className="fs-18 font-weight-boldest mt-4">Confirm your bank</h5>
          <p className="text-center text-secondary mb-5">
            {`We've sent 2 small deposits to your ${FundingAccountName}`}
            <br className="d-none d-md-block" />
            {`${AccountType} XXXXXXX${AccountLastFour} account`}
          </p>
          <p className="text-left text-secondary">
            After the amounts appear in your bank account activity, enter them
            below to confirm you own the account.
          </p>
          <Form.Group controlId="" className="mb-3">
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  Amount One
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                autoComplete="off"
                placeholder="Amount One"
                aria-describedby="inputGroupPrepend"
                name="amountone"
                {...formik.getFieldProps("amountone")}
              />
            </InputGroup>
            {formik.touched.amountone && formik.errors.amountone ? (
              <div className="fv-plugins-message-container text-right">
                <div className="fv-help-block">{formik.errors.amountone}</div>
              </div>
            ) : null}
          </Form.Group>

          <Form.Group controlId="" className="mb-3">
            <InputGroup size="lg">
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">
                  Amount Two
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="number"
                autoComplete="off"
                placeholder="Amount Two"
                aria-describedby="inputGroupPrepend"
                name="amounttwo"
                {...formik.getFieldProps("amounttwo")}
              />
            </InputGroup>
            {formik.touched.amounttwo && formik.errors.amounttwo ? (
              <div className="fv-plugins-message-container text-right">
                <div className="fv-help-block">{formik.errors.amounttwo}</div>
              </div>
            ) : null}
          </Form.Group>

          <Button
            variant="success"
            size="lg"
            block
            onClick={onClickOfSaveButton}
            disabled={isLoadingConfirmBank}
          >
            Confirm
            {isLoadingConfirmBank && (
              <span className=" mr-3 ml-3 spinner spinner-white"></span>
            )}
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ConfirmBankModal;
