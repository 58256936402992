import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { getImageBasedOnNameUrl } from "../../../../axios/services/apiList";
import { searchUserApi } from "../../../../axios/services/services";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { CustomImage } from "../../../utils/CustomImage";
import { isUnauthorized, normalizeInput } from "../../../utils/utils";
import ErrorModal from "../../Modal/ErrorModal";

function MakePaymentWizardStepFour({
  searchUserDetails,
  setSearchUserDetails,
  formik,
}) {
  const [isLoading, setLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    isShowMobileNumberNotExistsWarning,
    setShowMobileNumberNotExistsWarning,
  ] = useState(false);

  const handleErrorModalShow = () => {
    setIsErrorModalOpen(true);
  };

  const handleErrorModalHide = () => {
    setIsErrorModalOpen(false);
  };

  const handleChange = (event) => {
    const cellNumberWithFormat = normalizeInput(
      event.target.value,
      formik.values.cellnumber
    );
    formik.setFieldValue("cellnumber", cellNumberWithFormat);
  };

  const handleInput = (event) => {
    if (event.target.value.length === 14) {
      getSearchUsers(event.target.value);
    } else {
      setSearchUserDetails(null);
      setShowMobileNumberNotExistsWarning(false);
    }
  };

  const getSearchUsers = async (cellnumber) => {
    setLoading(true);
    const searchUserResponse = await searchUserApi({
      cellnumber: cellnumber,
    });
    setLoading(false);
    if (searchUserResponse && searchUserResponse.Error) {
      isUnauthorized({ response: searchUserResponse });
      setErrorMessage(searchUserResponse.Error.message);
      handleErrorModalShow();
      return;
    }
    if (searchUserResponse && searchUserResponse.length > 0) {
      setSearchUserDetails(searchUserResponse[0]);
    } else {
      setSearchUserDetails(null);
      setShowMobileNumberNotExistsWarning(true);
    }
  };

  return (
    <>
      <div className="row make-payment-inner mt-2">
        <div className="col-md-10 offset-md-1">
          <div className="text-center">
            <span className="svg-icon svg-icon-4x svg-icon-dark menu-icon mr-1">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/WhoBlack.svg")}
              />
            </span>{" "}
          </div>
          <div className="mt-4">
            <h5 className="font-weight-bold text-center mb-0">
              Who Are You Requesting Money From?
            </h5>
          </div>
          <div className="row">
            <div className="col-md-12 mt-10">
              <div className="form-group mb-4 fv-plugins-icon-container">
                <input
                  type="tel"
                  className={`form-control h-auto py-5 px-6`}
                  placeholder="Recipient(phone number)"
                  name="cellnumber"
                  value={formik.values.cellnumber}
                  autoFocus
                  {...formik.getFieldProps("cellnumber")}
                  onInput={(event) => {
                    handleInput(event);
                  }}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                />
                {isLoading && (
                  <span
                    style={{ top: "-30px", right: "20px", float: "right" }}
                    className="mr-3 ml-3 spinner spinner-primary"
                  ></span>
                )}
                {formik.touched.cellnumber && formik.errors.cellnumber ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <i className="fas fa-times text-danger mr-1 text-center"></i>
                      {formik.errors.cellnumber}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {searchUserDetails && searchUserDetails.id && (
            <>
              <div>
                <p className="font-weight-bold text-secondary fs-14 mb-3 mt-3">
                  People on PayXtreme
                </p>
              </div>
              <div className="saved-details border border-success rounded p-3">
                <div className="d-flex">
                  <div className="symbol symbol-50 symbol-circle mr-3 border border-secondary">
                    <CustomImage
                      src={`${getImageBasedOnNameUrl(
                        searchUserDetails.profile
                      )}`}
                    />
                  </div>
                  <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                    <div className="details">
                      <h5 className="font-weight-bolder fs-16 mb-0">
                        {`${searchUserDetails.firstname} ${searchUserDetails.lastname}`}
                      </h5>
                      <div className="text-secondary fs-13">
                        {`@${searchUserDetails.username}`}
                      </div>
                    </div>
                    <div className="right-info">
                      <span className="svg-icon svg-icon-2x svg-icon-success menu-icon mr-1">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Double-check.svg"
                          )}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {!searchUserDetails && isShowMobileNumberNotExistsWarning && (
            <>
              <div>
                <p className="font-weight-bold text-secondary fs-14 mb-3 mt-3">
                  People on PayXtreme
                </p>
              </div>
              <div className="saved-details border border-warning rounded p-3">
                <div className="d-flex">
                  <div className="d-flex flex-wrap justify-content-between w-100 align-items-center">
                    <div className="col-11">
                      <div className="details">
                        <div className="text-danger fs-14">
                          We didn't find a PayXtreme account linked with this
                          phone number. You can still continue and we will send
                          an invitation link to the phone number you provided
                          via SMS. Click Next to continue
                        </div>
                      </div>
                    </div>
                    <div className="col-1">
                      <div className="right-info">
                        <span className="svg-icon svg-icon-2x svg-icon-warning menu-icon mr-1">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/Warning-1-circle.svg"
                            )}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <ErrorModal
        message={errorMessage}
        isErrorModalOpen={isErrorModalOpen}
        hideErrorModal={handleErrorModalHide}
        errorIcon={
          <div className="danger-icon-wrapper text-center">
            <span className="svg-icon menu-icon svg-icon-white svg-icon-4x mr-0">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")}
              />
            </span>
          </div>
        }
        errorButtonLabel={"Close"}
        errorOnClick={handleErrorModalHide}
      />
    </>
  );
}

export default MakePaymentWizardStepFour;
